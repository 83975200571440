import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from "../Auth";

const AccountDelete: React.FunctionComponent<{}> = () => {

    const { t } = useTranslation();

    const { user: currentUser } = useContext(AuthContext);

    const [error, setError] = useState("");

    const onDeleteAccount = (): void => {
        setError("");
        if (confirm(t("deleteAccount.areYouSure"))) {  // eslint-disable-line no-restricted-globals
            if (currentUser) {
                currentUser.delete().then(() => {
                    alert(t("deleteAccount.accountDeleted")); // eslint-disable-line no-restricted-globals
                }).catch(function (error) {
                    if (error.code) {
                        switch (error.code) {
                            case "auth/requires-recent-login":
                                setError(t("deleteAccount.logInAgain"));
                                break;
                            default:
                                setError(t("deleteAccount.error") + " " + error.message);
                        }
                    } else {
                        setError(t("deleteAccount.unknownError"));
                    }
                });
            }
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4>{t("deleteAccount.deleteAccount")}</h4>
            </div>
            <div className="card-body">

                {error &&
                    <div className="text-danger">{error}</div>
                }

                <div className="pb-3">{t("deleteAccount.areYouSure")}</div>

                <div>
                    <button onClick={onDeleteAccount} className="btn btn-danger" color="danger">{t("deleteAccount.deleteAccount")}</button>
                </div>

            </div>
        </div>
    );
}

export default AccountDelete;