import React from "react";

import ValidationAlert from "../ValidationAlert";
import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { Lookups } from "../../lookups/Lookups";
import uniqid from "uniqid";
import { formatLargeCredits } from "../../utilities/Utilities";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onSetHullType: (hullType: string) => void;
}

const ShipDesignStep2HullType: React.FunctionComponent<IProps> = (props: IProps) => {

    const ship = props.shipTraits;

    const lookups = Lookups.getInstance();

    const onSelectHullType = (e: any): void => {
        const hullType = e.target.value;
        props.onSetHullType(hullType);
    }

    const getHullTypeDropdown = () => {

        interface DDLOption {
            name: string;
            value: string;
        }

        let options: DDLOption[] = lookups.shipHulls.map((sh) => {
            return { name: sh.name, value: sh.name };
        });

        return (
            <select onChange={onSelectHullType} value={props.shipTraits.hullType}>
                <option value="">-- select hull type--</option>
                {options.map((o) => <option key={o.value} value={o.name}>{o.name}</option>)}
            </select>
        )
    }

    const formatSpeed = (speed: number) => {
        if (speed === -1000) { return "N/A"; }
        return speed;
    }

    const getShipHullTable = () => {
        const theHull = lookups.shipHulls.find((sh) => sh.name === props.shipTraits.hullType);
        if (theHull) {
            return (
                <>
                    <div className="small mt-2 mb-1"><b>Base Hull Stats</b></div>
                    <div className="row border-bottom pt-1 pb-1" key={uniqid()} >
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">{theHull.class} Class</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">{formatLargeCredits(theHull.cost)}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">Speed&nbsp;{formatSpeed(theHull.speed)}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">Armor&nbsp;{theHull.armor}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">HP&nbsp;{theHull.hp}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">Crew&nbsp;{theHull.minimumCrew}/{theHull.maximumCrew}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">AC&nbsp;{theHull.ac}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">Power&nbsp;{theHull.power}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">Mass&nbsp;{theHull.mass}</div>
                        <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 small">Hardpoints&nbsp;{theHull.hardpoints}</div>
                    </div>
                    <div className="small mt-2">{theHull.desc}</div>
                </>
            );
        }
        return null;
    }

    return (
        <div className="chargenSection">

            {/* <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >{getCharacterDesc(props.charTraits)} {getCollapseIcon()}</h2></div> */}

            {/* <div className={sectionClassName}> */}
            <div className="">

                {!props.readOnly &&
                    <div>
                        {getHullTypeDropdown()}
                    </div>
                }

                {props.readOnly &&
                    <div className="small"><b>Hull Class:</b> {props.shipTraits.hullType}</div>
                }

                {props.shipTraits.hullType.trim() !== "" &&
                    <>{getShipHullTable()}</>
                }

                {ship.showValidation && ship.validationCodes.indexOf("noName") > -1 &&
                    <ValidationAlert msg="Name is required" />
                }

            </div>

        </div >
    );

}

export default ShipDesignStep2HullType;

