import { CharacterTraits } from './../interfaces/CharacterTraits';
import app from "../firebase";

import * as toastr from "toastr";

const getClassName = (c: CharacterTraits) => {
    const selectedClassNames = c.levelOne.classes.map((c) => c.className);

    let className = "";
    if (c.levelOne.isAdventurer) {
        className = "Adventurer (" + selectedClassNames.sort().join("/").replace(/Partial/gi, "").replace(/ /ig, "").replace(/ /ig, "") + ")";
    } else {
        if(selectedClassNames.length > 0) {
            className = selectedClassNames[0];
        } else {
            className = "";
        }
    }

    return className;
}

const getBasicDataToSave =  (c: CharacterTraits, currentUser: firebase.User) => {
    return {
        background: c.background.backgroundName,
        className: getClassName(c),
        origin: c.basicTraits.origin, 
        isPublic: c.basicTraits.isPublic,
        level: c.level,
        name: c.basicTraits.name,
        name_lower: c.basicTraits.name.toLowerCase(),
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}

const getTraitsDataToSave =  (c: CharacterTraits, currentUser: firebase.User) => {
    return {
        name: c.basicTraits.name,
        traits: c,
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}


export const SaveNewCharacter = async (
    currentUser: firebase.User,
    characterTraits: CharacterTraits,
    setCharacterId: any,
) => {

    const c = characterTraits;

    if (currentUser) {

        let characterId = "";
        
        // Save basic details of character into 'character' collection, used for listing characters.
        const basicDataToSave = getBasicDataToSave(c, currentUser);

        const db = app.firestore();
        await db.collection("character")
            .add(basicDataToSave)
            .then((docRef) => {
                characterId = docRef.id;
                setCharacterId(characterId);
            })
            .catch(function (error) {
                toastr.error(error);
            });

        // Save details of character into 'characterTraits' collection, used for editing a character.

        const characterTraitsDataToSave = getTraitsDataToSave(c, currentUser);
        characterTraits.id = characterId;

        await db.collection("characterTraits")
            .doc(characterId)
            .set(characterTraitsDataToSave)
            .catch(function (error) {
                toastr.error(error);
            });
    }
}

export const UpdateCharacter = async (
    currentUser: firebase.User | null,
    characterTraits: CharacterTraits,
    characterId: string,

) => {

    const c = characterTraits;

    if (currentUser) {

        // Update basic details of character into 'character' collection, used for listing characters.
        const basicDataToSave = getBasicDataToSave(c, currentUser);

        const db = app.firestore();
        await db.collection("character")
            .doc(characterId)
            .set(basicDataToSave)
            .catch(function (error) {
                toastr.error(error);
            });

        // Update details of character into 'characterTraits' collection, used for editing a character.
        const characterTraitsDataToSave = getTraitsDataToSave(c, currentUser);

        await db.collection("characterTraits")
            .doc(characterId)
            .set(characterTraitsDataToSave)
            .catch(function (error) {
                toastr.error(error);
            });
    }
}