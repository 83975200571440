import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import app from "../firebase";

import { AuthContext } from "../Auth";

import * as toastr from "toastr";


const AdminCharacters: React.FunctionComponent<unknown> = (props: any) => {

    const { t } = useTranslation();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [characters, setCharacters] = useState<Array<any>>([]);
    const [reloadCharacters, setReloadCharacters] = useState(0);
    const [orderBy, setOrderBy] = useState("name_lower");


    useEffect(() => {
        // Create an scoped async function in the hook.
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {
                    const db = app.firestore();
                    const data = await db.collection("character")
                        .orderBy(orderBy, "asc")
                        .get();
                    const characters: any[] = [];
                    data.forEach((doc) => {
                        characters.push({ ...doc.data(), id: doc.id });
                    });
                    characters.forEach((c) => {
                        if (!c.origin || c.origin === "" || c.origin === undefined || c.origin === null) { c.origin = "Human"; }
                    })
                    setCharacters(characters);
                    setLoading(false);
                }
            }
            catch (error: any) {
                toastr.error(error);
                setLoading(false);
            }
        }
        // Execute the created function directly
        asyncUseEffect();
    }, [user, reloadCharacters, orderBy]); // Will load characters after rendering 

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const getClassName = (className: string) => {
        let cn = className.replace("Adventurer", "");
        cn = cn.replace("(", "");
        cn = cn.replace(")", "");
        return cn.trim();
    }

    const getShortClassName = (className: string) => {
        let cn = className.replace("Adventurer", "");
        cn = cn.replace("(", "");
        cn = cn.replace(")", "");
        cn = cn.replace("Expert", "Exp");
        cn = cn.replace("Warrior", "War");
        cn = cn.replace("Psychic", "Psy");
        return cn.trim();
    }

    const displayLevel = (rawLevel: any) => {
        if (!rawLevel) {
            return "1"
        } else {
            return rawLevel;
        }
    }

    const characterRows = characters.map((c) =>
        <tr key={c.id}>
            <td style={{ width: "30%" }} className="" ><Link to={"/characterDesigner/" + c.id}>{isBlank(c.name, "(No name)")}</Link><span className="d-inline-block d-md-none">&nbsp;({isBlank(getShortClassName(c.className), "n/a")} {displayLevel(c.level)})</span></td>
            <td style={{ width: "15%" }} className="d-none d-md-table-cell">{isBlank(getClassName(c.className), "n/a")} {displayLevel(c.level)}</td>
            <td style={{ width: "15%" }} className="d-none d-md-table-cell">{isBlank(c.origin, "Human")}</td>
            <td style={{ width: "15%" }} className="d-none d-md-table-cell">{isBlank(c.background, "n/a")}</td>
            <td style={{ width: "25%" }} className="d-none d-md-table-cell">{isBlank(c.user_email, "n/a")}</td>
        </tr>
    )

    const orderByCharacterName = () => {
        setOrderBy("name_lower");
        setReloadCharacters(reloadCharacters + 1);
    }

    const orderByUser = () => {
        setOrderBy("user_email");
        setReloadCharacters(reloadCharacters + 1);
    }

    const orderByOrigin = () => {
        setOrderBy("origin");
        setReloadCharacters(reloadCharacters + 1);
    }

    const orderByLevel = () => {
        setOrderBy("level");
        setReloadCharacters(reloadCharacters + 1);
    }

    return (
        <div className="pageBody">

            <h1 className="pageHeading">All Characters</h1>

            <p>{characters.length} characters.</p>

            <div style={{ marginBottom: "10px" }}>Order by:&nbsp;
                <button onClick={orderByCharacterName} style={{ marginRight: "10px" }}>Character Name</button>
                <button onClick={orderByOrigin} style={{ marginRight: "10px" }}>Origin</button>
                <button onClick={orderByLevel} style={{ marginRight: "10px" }}>Level</button>
                <button onClick={orderByUser}>User</button>
            </div>

            {!loading && characters.length > 0 &&

                <table className="table table-striped table-sm w-auto">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: "30%" }} className="">Name</th>
                            <th scope="col" style={{ width: "15%" }} className="d-none d-md-table-cell">Class & Level</th>
                            <th scope="col" style={{ width: "15%" }} className="d-none d-md-table-cell">Origin</th>
                            <th scope="col" style={{ width: "15%" }} className="d-none d-md-table-cell">Background</th>
                            <th scope="col" style={{ width: "25%" }} className="d-none d-md-table-cell">User</th>
                        </tr>
                    </thead>
                    <tbody>
                        {characterRows}
                    </tbody>
                </table>
            }

            {!loading && characters.length === 0 &&
                <p>{t("characters.currentlyNoCharacters")}</p>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}

export default AdminCharacters;
