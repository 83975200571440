import React from "react";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";
import { formatLargeCredits } from "../../utilities/Utilities";
import { handleIndefiniteLifeSupport, handleSpeed } from "../../utilities/starshipMainUtilities";

interface IProps {
    shipTraits: StarshipTraits;
}

const ShipDesignStep10Stats: React.FunctionComponent<IProps> = (props: IProps) => {

    const shipDerivedStats = new ShipDerivedStats(props.shipTraits);

    const className1 = "col-6 col-sm-3 col-md-2 col-lg-2";
    const className2 = "col-6 col-sm-3 col-md-4 col-lg-2";

    const className3 = "col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-2";
    const className4 = "col-9 col-sm-10 col-md-10 col-lg-10 col-xl-10 mt-2";

    const getAutoSupportBots = () => {
        if (props.shipTraits.crewData.crewAutomationSupportBots > 0) {
            return formatLargeCredits(props.shipTraits.crewData.crewAutomationSupportBots * 1000) + " for " + props.shipTraits.crewData.crewAutomationSupportBots + " automation support bots, ";
        }
        return null;
    }

    return (
        <div className="chargenSection">
            <h2>Ship Stats</h2>

            <div className="row pt-1 pb-1">
                <div className={className1}><b>Speed:</b></div>
                <div className={className2}>{handleSpeed(shipDerivedStats.speed)}</div>
                <div className={className1}><b>HP:</b></div>
                <div className={className2}>{shipDerivedStats.hp}</div>
                <div className={className1}><b>AC:</b></div>
                <div className={className2}>{shipDerivedStats.ac}</div>
                <div className={className1}><b>Armor:</b></div>
                <div className={className2}>{shipDerivedStats.armor}</div>
            </div>

            <div className="row pt-1 pb-1">
                <div className={className1}><b>Hull&nbsp;Class:</b></div>
                <div className={className2}>{shipDerivedStats.hullClass}</div>
                <div className={className1}><b>Power:</b></div>
                <div className={className2}>{shipDerivedStats.powerAvailable}/{shipDerivedStats.powerAvailable - shipDerivedStats.totals.totalPowerUsed}&nbsp;free</div>
                <div className={className1}><b>Mass:</b></div>
                <div className={className2}>{shipDerivedStats.massAvailable}/{shipDerivedStats.massAvailable - shipDerivedStats.totals.totalMassUsed}&nbsp;free</div>
                <div className={className1}><b>Hard Points:</b></div>
                <div className={className2}>{shipDerivedStats.hardPointsAvailable}/{shipDerivedStats.hardPointsAvailable - shipDerivedStats.totals.totalHardPointsUsed} free</div>
                <div className={className1}><b>Fuel&nbsp;Loads:</b></div>
                <div className={className2}>{props.shipTraits.currentFuelLoads}/{shipDerivedStats.fuelLoadsMax} max</div>
            </div>

            <div className="row pt-1 pb-1" >
                <div className={className1}><b>Crew&nbsp;(min):</b></div>
                <div className={className2}>{shipDerivedStats.crewMin}</div>
                <div className={className1}><b>Crew&nbsp;(cur):</b></div>
                <div className={className2}>{shipDerivedStats.crewTotal}</div>
                <div className={className1}><b>Life&nbsp;Sup.:</b></div>
                <div className={className2}>{shipDerivedStats.lifeSupportMax}</div>
                <div className={className1}><b>Life&nbsp;Months.:</b></div>
                <div className={className2}>{handleIndefiniteLifeSupport(shipDerivedStats.lifeSupportMonths)}</div>
            </div>

            <div className="row pt-1 pb-1" >
                <div className={className1}><b>Def.&nbsp;Skill:</b></div>
                <div className={className2}>+{props.shipTraits.crewData.crewDefaultSkill}</div>
                <div className={className1}><b>Gunner&nbsp;Skill:</b></div>
                <div className={className2}>+{props.shipTraits.crewData.crewGunnerSkill}</div>
                <div className={className1}><b>NPC&nbsp;CP:</b></div>
                <div className={className2}>{props.shipTraits.crewData.crewNPCCommandPoints}</div>
            </div>


            <div className="row pt-1 pb-1" >
                <div className={className3}><b>Weapons:</b></div>
                <div className={className4}>{shipDerivedStats.getWeaponsList()}</div>

                <div className={className3}><b>Defenses:</b></div>
                <div className={className4}>{shipDerivedStats.getFittingsList("defenses")}</div>

                <div className={className3}><b>Ammunition:</b></div>
                <div className={className4}>{shipDerivedStats.getAmmoList()}</div>

                <div className={className3}><b>Fittings:</b></div>
                <div className={className4}>{shipDerivedStats.getFittingsList("fitting")}</div>

                <div className={className3}><b>Mods:</b></div>
                <div className={className4}>{shipDerivedStats.getModsList()}</div>

                <div className={className3}><b>Cargo:</b></div>
                <div className={className4}>{shipDerivedStats.getCargoList()}</div>

                <div className={className3}><b>Cost:</b></div>
                <div className={className4}>{formatLargeCredits(shipDerivedStats.totals.totalCost)} base price, {formatLargeCredits(shipDerivedStats.totals.totalAmmoCost)} ammunition, {getAutoSupportBots()} {formatLargeCredits(shipDerivedStats.totals.totalCost * 0.05)} six-month maintenance, {formatLargeCredits(props.shipTraits.crewData.crewCurrent * 120 * 365)} yearly crew cost for {props.shipTraits.crewData.crewCurrent} crew  </div>

            </div>
        </div>
    );

}

export default ShipDesignStep10Stats;