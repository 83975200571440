import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import CharacterDesignMenu from "../CharacterDesignMenu";

import { AuthContext } from "../../Auth";

import StarshipDesignScreen from "./StarshipDesignScreen";
import Audit from "./Audit";
import { getInitialStarshipTraits, StarshipTraits } from "../../interfaces/StarshipTraits";
import { useEffectHookLoadStarship } from "../../hooks/useEffectHookLoadStarship";

interface RouteParams {
    id: string
}

const StarshipDesigShare: React.FunctionComponent<{}> = () => {

    const { user: currentUser } = useContext(AuthContext);

    // Get the ship Id from the URL route (if any). 
    let { id: IdInQuerystring } = useParams<RouteParams>();

    const [screen, setScreen] = useState("design");
    const [starshipId, setStarshipId] = useState("");
    const [starshipTraits, setStarshipTraits] = useState(getInitialStarshipTraits());
    const [goToCharacterList, setGoToCharacterList] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const cancel = () => {
        setGoToCharacterList(true);
    }

    const onSetStarshipTraits = (ship: StarshipTraits) => {
        // // save ship traits into session storage:
        // localStorage.setItem("tryShipTraits", JSON.stringify(starshipTraits, null, 2));
        // setStarshipTraits(ship);
    }

    // useEffect hook to load character if id in querystring. 
    useEffectHookLoadStarship(true, IdInQuerystring, currentUser, setStarshipId, setStarshipTraits, setIsLoading);

    if (!starshipTraits.isPublic) {
        return (
            <div>
                <h1>Starship Stats</h1>

                {isLoading &&
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }

                {!isLoading &&
                    <p>This ship is not publically shared.</p>
                }
            </div>
        )
    }

    return (
        <>

            {isLoading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

            <div className="chargenSection">
                {screen === "design" &&
                    <h1>Starship Stats</h1>
                }

                {screen === "audit" &&
                    <h1>Starship Audit</h1>
                }

                {screen === "sheet" &&
                    <h1>Starship Download</h1>
                }

            </div>

            <CharacterDesignMenu
                demoMode={true}
                readOnly={true}
                screen={screen}
                setScreen={(screen: string) => setScreen(screen)}
                saveCharacter={() => null}
                cancel={cancel}
            />

            {screen === "design" &&
                <StarshipDesignScreen starshipTraits={starshipTraits} setStarshipTraits={onSetStarshipTraits} readOnly={true} />}

            {screen === "audit" &&
                <Audit shipTraits={starshipTraits} />
            }

            {screen === "sheet" &&
                <p>Coming soon!</p>
            }

            <CharacterDesignMenu
                demoMode={true}
                readOnly={true}
                screen={screen}
                setScreen={(screen: string) => setScreen(screen)}
                saveCharacter={() => null}
                cancel={cancel}
            />

            {/* <div><pre>{localStorage.getItem("tryShipTraits")}</pre></div> */}

        </>

    );
}

export default StarshipDesigShare;

