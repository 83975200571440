import React, { useState } from "react";
import $ from "jquery";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";

interface IProps {
    shipTraits: StarshipTraits;
    displayModal: boolean;
    onSetCrewDefaultSkill: (skill: number) => void;
    onSetCrewGunnerSkill: (skill: number) => void;
    onSetCrewNPCCommandPoints: (cp: number) => void;
    onSetCrewCurrent: (crew: number) => void;
    onSetPassengersCurrent: (passengers: number) => void;
    onClose: () => void;
}

const SelectCrewModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const modalId = "crew_modal";

    const [crewCurrent, setCrewCurrent] = useState(props.shipTraits.crewData.crewCurrent);
    const [passengersCurrent, setPassengersCurrent] = useState(props.shipTraits.crewData.passengersCurrent);

    const shipDerivedStats = new ShipDerivedStats(props.shipTraits);

    if (props.displayModal) {
        ($("#" + modalId) as any).modal('show');
    }

    if (!props.displayModal) {
        ($("#" + modalId) as any).modal('hide');
    }

    const onCloseModal = () => {
        props.onClose();
    }

    const onSetCrewCurrent = (e: any) => {
        e.preventDefault();
        setCrewCurrent(e.currentTarget.value);

        let val = parseInt(e.currentTarget.value);
        if (!isNaN(val) && val > -1) {
            props.onSetCrewCurrent(val);
        }
    }

    const onSetPassengersCurrent = (e: any) => {
        e.preventDefault();
        setPassengersCurrent(e.currentTarget.value);

        let val = parseInt(e.currentTarget.value);
        if (!isNaN(val) && val > -1) {
            props.onSetPassengersCurrent(val);
        }
    }

    return (
        <div id={modalId} className="modal" tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Select Crew</h5>
                        <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <p><b>Crew and Passengers</b></p>

                        <div className="row mb-2">
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="crewSkill">Crew minimum</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-2 col-lg-2 col-xl-2 small">
                                {shipDerivedStats.crewMin}
                            </div>
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="crewSkill">Crew maximum</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-4 col-lg-4 col-xl-4 small">
                                {shipDerivedStats.lifeSupportMax}
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="crew">Current Crew</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-2 col-lg-2 col-xl-2 small">
                                <input id="crew" type="number" className="ml-1 shortNum" value={crewCurrent} onChange={(e) => onSetCrewCurrent(e)} onBlur={() => setCrewCurrent(props.shipTraits.crewData.crewCurrent)} />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="crew">Current Passengers</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-4 col-lg-4 col-xl-4 small">
                                <input id="crew" type="number" className="ml-1 shortNum" value={passengersCurrent} onChange={(e) => onSetPassengersCurrent(e)} onBlur={() => setPassengersCurrent(props.shipTraits.crewData.passengersCurrent)} />
                            </div>
                            <div className="col-12 smaller mt-2">
                                Crew and passengers may be humans, aliens, or VI robots. All make similar demands on the ship's life support systems, whether that includes air, water, food, living quarters, maintenance facilities or energy.
                            </div>
                        </div>

                        <p><b>Crew Capabilities</b></p>

                        <div className="row mb-2">
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="crewSkill">Default&nbsp;Crew&nbsp;Skill&nbsp;Modifier</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-2 col-lg-2 col-xl-2 small">
                                <select id="crewSkill" className="ml-1" value={props.shipTraits.crewData.crewDefaultSkill} onChange={(e) => props.onSetCrewDefaultSkill(parseInt(e.currentTarget.value))}>
                                    <option value={0}>+0</option>
                                    <option value={1}>+1</option>
                                    <option value={2}>+2</option>
                                    <option value={3}>+3</option>
                                    <option value={4}>+4</option>
                                    <option value={5}>+5</option>
                                    <option value={6}>+6</option>
                                    <option value={7}>+7</option>
                                    <option value={8}>+8</option>
                                    <option value={9}>+9</option>
                                    <option value={10}>+10</option>
                                </select>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="gunnerSkill">Gunners'&nbsp;Skill&nbsp;Modifier</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-4 col-lg-4 col-xl-4 small">
                                <select id="gunnerSkill" className="ml-1" value={props.shipTraits.crewData.crewGunnerSkill} onChange={(e) => props.onSetCrewGunnerSkill(parseInt(e.currentTarget.value))}>
                                    <option value={0}>+0</option>
                                    <option value={1}>+1</option>
                                    <option value={2}>+2</option>
                                    <option value={3}>+3</option>
                                    <option value={4}>+4</option>
                                    <option value={5}>+5</option>
                                    <option value={6}>+6</option>
                                    <option value={7}>+7</option>
                                    <option value={8}>+8</option>
                                    <option value={9}>+9</option>
                                    <option value={10}>+10</option>
                                </select>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="npcCOM">NPC Command Points</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-2 col-lg-2 col-xl-9 small">
                                <select id="npcCOM" className="ml-1" value={props.shipTraits.crewData.crewNPCCommandPoints} onChange={(e) => props.onSetCrewNPCCommandPoints(parseInt(e.currentTarget.value))}>
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>Close</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SelectCrewModal;

