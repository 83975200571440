import React from "react";
import {
    onAddCargo,
    onBuyOrSellFitting,
    onBuyOrSellMod,
    onDeleteCargo,
    onSelectAutoWeapon,
    onSetAmmunition,
    onSetAmmunitionRemaining,
    onSetCrewAutomationSupportBots,
    onSetCrewCurrent,
    onSetCrewDefaultSkill,
    onSetCrewGunnerSkill,
    onSetCrewNPCCommandPoints,
    onSetCurrentFuelLoads,
    onSetExampleShip,
    onSetFoxerDronesExtraMass,
    onSetFoxerDronesRemaining,
    onSetHullType,
    onSetIsPublic,
    onSetName,
    onSetNotes,
    onSetPassengersCurrent
} from "../../utilities/starshipMainUtilities";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import ShipDesignStep1Name from "./ShipDesignStep1Name";
import ShipDesignStep2HullType from "./ShipDesignStep2HullType";
import ShipDesignStep3Fittings from "./ShipDesignStep3Fittings";
import ShipDesignStep4Defenses from "./ShipDesignStep4Defenses";
import ShipDesignStep5Weapons from "./ShipDesignStep5Weapons";
import ShipDesignStep6Mods from "./ShipDesignStep6Mods";
import ShipDesignStep10Stats from "./ShipDesignStep10Stats";
import ShipDesignStep7Crew from "./ShipDesignStep7Crew";
import ShipDesignStep8Cargo from "./ShipDesignStep8Cargo";
import { Cargo } from "../../interfaces/Cargo";
import ShipDesignStep11Validation from "./ShipDesignStep11Validation";
import ShipDesignStep12Notes from "./ShipDesignStep12Notes";

interface RouteParams {
    id: string
}

interface IProps {
    starshipTraits: StarshipTraits;
    setStarshipTraits: (shipTraits: StarshipTraits) => void;
    readOnly: boolean;
}

const StarshipDesignMain: React.FunctionComponent<IProps> = (props: IProps) => {

    const shipTraits = props.starshipTraits;

    // Updating character traits in state: 
    const onSetStarshipTraits = (shipTraits: StarshipTraits) => {
        const updatedTraits = { ...shipTraits };
        props.setStarshipTraits(updatedTraits);
    }

    return (
        <>

            <ShipDesignStep1Name
                shipTraits={shipTraits}
                readOnly={props.readOnly}
                onSetName={(name: string) => onSetName(name, shipTraits, onSetStarshipTraits)}
                onSetExampleShip={(name: string) => onSetExampleShip(name, shipTraits, onSetStarshipTraits)}
                onSetIsPublic={(isPublic: boolean) => onSetIsPublic(isPublic, shipTraits, onSetStarshipTraits)}
            />

            <ShipDesignStep2HullType
                shipTraits={shipTraits}
                readOnly={props.readOnly}
                onSetHullType={(hullName: string) => onSetHullType(hullName, shipTraits, onSetStarshipTraits)}
            />

            {props.starshipTraits.hullType !== "" &&
                <>
                    <ShipDesignStep3Fittings
                        shipTraits={shipTraits}
                        readOnly={props.readOnly}
                        onBuyOrSellFitting={(id: string, isBuy: boolean) => onBuyOrSellFitting(id, isBuy, shipTraits, onSetStarshipTraits)}
                    />

                    <ShipDesignStep4Defenses
                        shipTraits={shipTraits}
                        readOnly={props.readOnly}
                        onBuyOrSellFitting={(id: string, isBuy: boolean) => onBuyOrSellFitting(id, isBuy, shipTraits, onSetStarshipTraits)}
                        onSetFoxerDronesExtraMass={(weaponUniqId: string, extraFreeMass: number) => onSetFoxerDronesExtraMass(weaponUniqId, extraFreeMass, shipTraits, onSetStarshipTraits)}
                        onSetFoxerDronesRemaining={(weaponUniqId: string, roundsRemaining: number) => onSetFoxerDronesRemaining(weaponUniqId, roundsRemaining, shipTraits, onSetStarshipTraits)}
                    />

                    <ShipDesignStep5Weapons
                        shipTraits={shipTraits}
                        readOnly={props.readOnly}
                        onBuyOrSellFitting={(id: string, isBuy: boolean) => onBuyOrSellFitting(id, isBuy, shipTraits, onSetStarshipTraits)}
                        onSetAmmunition={(weaponId: string, ammunition: number) => onSetAmmunition(weaponId, ammunition, shipTraits, onSetStarshipTraits)}
                        onSetAmmunitionRemaining={(weaponId: string, ammunitionRemaining: number) => onSetAmmunitionRemaining(weaponId, ammunitionRemaining, shipTraits, onSetStarshipTraits)}
                        onSelectAutoWeapon={(autoUniqId: string, weaponUniqId: string) => onSelectAutoWeapon(autoUniqId, weaponUniqId, shipTraits, onSetStarshipTraits)}
                    />

                    <ShipDesignStep6Mods
                        shipTraits={shipTraits}
                        readOnly={props.readOnly}
                        onBuyOrSellMod={(id: string, isBuy: boolean) => onBuyOrSellMod(id, isBuy, shipTraits, onSetStarshipTraits)}
                    />

                    <ShipDesignStep8Cargo
                        shipTraits={shipTraits}
                        readOnly={props.readOnly}
                        onSetCrewDefaultSkill={(skill: number) => onSetCrewDefaultSkill(skill, shipTraits, onSetStarshipTraits)}
                        onSetCrewGunnerSkill={(skill: number) => onSetCrewGunnerSkill(skill, shipTraits, onSetStarshipTraits)}
                        onSetCrewNPCCommandPoints={(cp: number) => onSetCrewNPCCommandPoints(cp, shipTraits, onSetStarshipTraits)}
                        onSetCrewCurrent={(crew: number) => onSetCrewCurrent(crew, shipTraits, onSetStarshipTraits)}
                        onSetCrewAutomationSupportBots={(bots: number) => onSetCrewAutomationSupportBots(bots, shipTraits, onSetStarshipTraits)}
                        onSetCurrentFuelLoads={(fuelLoads: number) => onSetCurrentFuelLoads(fuelLoads, shipTraits, onSetStarshipTraits)}
                        onAddCargo={(cargo: Cargo) => onAddCargo(cargo, shipTraits, onSetStarshipTraits)}
                        onDeleteCargo={(uniqid: string) => onDeleteCargo(uniqid, shipTraits, onSetStarshipTraits)}
                    />

                    <ShipDesignStep7Crew
                        shipTraits={shipTraits}
                        readOnly={props.readOnly}
                        onSetCrewDefaultSkill={(skill: number) => onSetCrewDefaultSkill(skill, shipTraits, onSetStarshipTraits)}
                        onSetCrewGunnerSkill={(skill: number) => onSetCrewGunnerSkill(skill, shipTraits, onSetStarshipTraits)}
                        onSetCrewNPCCommandPoints={(cp: number) => onSetCrewNPCCommandPoints(cp, shipTraits, onSetStarshipTraits)}
                        onSetCrewCurrent={(crew: number) => onSetCrewCurrent(crew, shipTraits, onSetStarshipTraits)}
                        onSetPassengersCurrent={(passengers: number) => onSetPassengersCurrent(passengers, shipTraits, onSetStarshipTraits)}
                    />

                    <ShipDesignStep10Stats
                        shipTraits={shipTraits}
                    />

                    <ShipDesignStep11Validation
                        shipTraits={shipTraits}
                    />

                    <ShipDesignStep12Notes
                        shipTraits={shipTraits}
                        readOnly={props.readOnly}
                        onSetNotes={(notes: string) => onSetNotes(notes, shipTraits, onSetStarshipTraits)}
                    />

                    {/* <div className="mb-3"><h4>Ship</h4><pre>{JSON.stringify(props.starshipTraits, null, 2)}</pre></div> */}


                </>
            }

            {/* <div className="mb-3"><h4>Local Storage: </h4>{localStorage.getItem("tryShipTraits")}</div> */}

        </>
    );
}

export default StarshipDesignMain;

