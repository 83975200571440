import React, { useState } from 'react';
import uniqid from 'uniqid';

import { faEye, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { FocusLevel } from '../../interfaces/FocusLevel';
import { FocusLevelPick } from "../../interfaces/FocusLevelPick";
import { SkillData } from '../../interfaces/SkillData';
import { SkillLevel } from '../../interfaces/SkillLevel';
import { SkillLevelPick } from '../../interfaces/SkillLevelPick';

import SkillsJSON from "../../lookups/SkillsAll.json";
import { Lookups } from "../../lookups/Lookups";

import SkillPickControls from './SkillPickControls';
import DescriptionAlert from "../DescriptionAlert";

import { convertAnySkillToSkillListDesc } from '../../utilities/SkillUtilities';
import { SkillPointsPick } from '../../interfaces/SkillPointsPick';
import UniqueGift from './UniqueGift';

interface IProps {
    className: string;
    focusType: string; // Combat/NonCombat
    focusLevelPicksToChooseFrom: FocusLevelPick[];
    focusLevelAlreadyPicked: FocusLevelPick;
    skillLevelsAlreadyPicked: SkillLevelPick[]; // bonus skill level from level 1 focus
    skillPointsAlreadyPicked: SkillPointsPick[]; // bonus skill points from level 2+ foci
    charTraits: CharacterTraits;
    charDerivedSkills: SkillLevel[];
    // charDerivedSkillsAtPreviousStep: SkillLevel[];
    charDerivedFocuses: FocusLevel[];
    traitToUpdate_Focuses: string;
    traitToUpdate_Skills: string;
    includeLeadingIcon: boolean;
    onSelectFocusLevel: (focusName: string, focusLevel: number, traitToUpate: string) => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onManageCustomSkills: () => void;
    onSetUniqueGift: (focusLevel: number, text: string) => void;
}

const FocusPickControlsLevelling: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const [showDescription, setShowDescription] = useState(false);

    const lookups = Lookups.getInstance();

    const skillsData: SkillData[] = SkillsJSON;

    let focusTypeDescription = props.focusType;
    if (props.focusType === "Any") { focusTypeDescription = "any"; }
    if (props.focusType === "Combat") { focusTypeDescription = "a combat-related"; }
    if (props.focusType === "NonCombat") { focusTypeDescription = "a non-combat"; }

    const onChangeFocusLevelPick = (e: any) => {
        const val = e.target.value;
        if (val !== "") {
            const focusNameAndLevel = val.split("/");
            const focusName = focusNameAndLevel[0];
            const focusLevel = parseInt(focusNameAndLevel[1]) - 1;
            props.onSelectFocusLevel(focusName, focusLevel + 1, props.traitToUpdate_Focuses);
        } else {
            props.onSelectFocusLevel("", 0, props.traitToUpdate_Focuses);
        }
    }

    const getFocusDropdown = () => {
        let options: any;
        if (props.focusType === "Any") {
            options = props.focusLevelPicksToChooseFrom.sort().map((flp) => <option key={uniqid()} value={flp.focus + "/" + flp.level}>{flp.focus} {flp.level}</option>);
        } else {
            options = props.focusLevelPicksToChooseFrom.filter((o) => o.type === props.focusType).sort().map((flp) => <option key={uniqid()} value={flp.focus + "/" + flp.level}>{flp.focus} {flp.level}</option>);
        }

        return (
            <select onChange={onChangeFocusLevelPick} value={props.focusLevelAlreadyPicked?.focus + "/" + props.focusLevelAlreadyPicked?.level}>
                <option value="">-- select --</option>
                {options}
            </select>
        );
    }

    const focusDropdown = getFocusDropdown();

    const selectedFocusData = lookups.focuses.find((fd) => fd.focus === props.focusLevelAlreadyPicked?.focus);

    const changeGainALevelToGain3SkillPoints = (txt: string | undefined, skill: string | undefined) => {
        if (txt && skill) {
            // Check if skill is a comma-delimited list
            let skillList = "";
            const skills = skill.split(",").map((s) => s = s.trim());
            if (skills.length > 1) {
                skillList = skills.join(" or ");
            } else {
                skillList = skill.trim();
            }
            if (txt.indexOf("any combat skill") !== -1) {
                return txt.replace("Gain any combat skill as a bonus skill", "Gain +3 skill points in any combat skill (see Skill Points below)");
            }
            if (txt.indexOf("any psychic skill") !== -1) {
                return txt.replace("Gain any psychic skill as a bonus", "Gain +3 skill points in any psychic skill (see Skill Points below)");
            }
            return txt.replace("Gain " + skillList + " as a bonus skill", "Gain +3 skill points in " + skillList + " (see Skill Points below)");
        }
    }

    const focusName = selectedFocusData?.focus;
    const focusDescription = selectedFocusData?.desc;
    let focusLevel1Description = selectedFocusData?.levels[0].desc;
    let focusLevel2Description = "";
    if (selectedFocusData?.levels[1]) { focusLevel2Description = selectedFocusData?.levels[1].desc; }

    let bonusSkillName = "";

    const focusData = lookups.focuses.find((fd) => fd.focus === props.focusLevelAlreadyPicked?.focus);
    if (focusData) {
        const bonusSkillLevel = focusData.levels[0].bonuses.find((b) => b.type === "bonusSkill");
        if (bonusSkillLevel && bonusSkillLevel.skill) {
            bonusSkillName = convertAnySkillToSkillListDesc(bonusSkillLevel.skill, skillsData, true);
            focusLevel1Description = changeGainALevelToGain3SkillPoints(selectedFocusData?.levels[0].desc, bonusSkillName);
        }
    }

    const getSkillNamesToChooseFrom = (focusName: string): string[] => {

        let bonusSkillName = "";

        const focusData = lookups.focuses.find((fd) => fd.focus === focusName);
        if (focusData) {
            const bonusSkillLevel = focusData.levels[0].bonuses.find((b) => b.type === "bonusSkill");
            if (bonusSkillLevel && bonusSkillLevel.skill) {
                bonusSkillName = convertAnySkillToSkillListDesc(bonusSkillLevel.skill, skillsData, true);
            }
        }

        return [bonusSkillName];
    }

    const skillNamesToChooseFrom = getSkillNamesToChooseFrom(props.focusLevelAlreadyPicked?.focus);

    let focusHistory = "";
    const theFocusesDerived = props.charDerivedFocuses.filter((fl) => fl.focus === props.focusLevelAlreadyPicked.focus);
    if (theFocusesDerived.length > 0) {
        focusHistory = theFocusesDerived.map((f) => f.history).join("; ");
    }

    const addParagraphs = (text: string | undefined) => {
        if (text === undefined) { return ""; }
        const p = text.split("|");
        return p.map((para) => <p key={uniqid()}>{para}</p>);
    }

    const onChangeUniqueGift1 = (theText: string) => {
        const focusLevel = 1;
        props.onSetUniqueGift(focusLevel, theText);
    }

    const onChangeUniqueGift2 = (theText: string) => {
        const focusLevel = 2;
        props.onSetUniqueGift(focusLevel, theText);
    }

    const isUniqueGift = () => {
        return props.focusLevelAlreadyPicked.focus === "Unique Gift";
    }

    return (
        <>
            <div className="mb-2">
                {props.includeLeadingIcon &&
                    <>
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    </>
                }
                Select a level in {focusTypeDescription} Focus: {focusDropdown}
            </div>
            {(props.focusLevelAlreadyPicked.level !== 0) &&
                <div>

                    {props.focusLevelAlreadyPicked.level === 1 &&

                        <div className="mb-1">
                            <b>{focusName} - Level 1:</b> {focusLevel1Description}
                            <button className="ml-2 btn btn-outline-info btn-tiny" type="button" title="Show Description" onClick={(e) => setShowDescription(!showDescription)}>
                                <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                            </button>
                            {bonusSkillName &&
                                <div className="mt-1">

                                    {/* {pickAnotherSkillMsg !== "" &&
                                        <div className="mb-2">{pickAnotherSkillMsg}</div>
                                    } */}

                                    {(skillNamesToChooseFrom.length > 0) &&
                                        <>
                                            <SkillPickControls
                                                lockedSkillName={bonusSkillName}
                                                maxLevel={1}
                                                singleSkillOnly={true}
                                                skillNamesToChooseFrom={skillNamesToChooseFrom}
                                                skillLevelsAlreadyPicked={props.skillLevelsAlreadyPicked}
                                                skillPointsAlreadyPicked={props.skillPointsAlreadyPicked}
                                                traitToUpdate={props.traitToUpdate_Skills}
                                                charTraits={char}
                                                charDerivedSkills={props.charDerivedSkills}
                                                isLevelFocus={true}
                                                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                                                onManageCustomSkills={props.onManageCustomSkills}
                                            />
                                            {/* <div><pre>{JSON.stringify(props.charDerivedSkills.filter((s) => s.history.length > 0), null, 2)}</pre></div> */}
                                            {/* <div><pre>{JSON.stringify(props.charDerivedSkillsAtPreviousStep.filter((s) => s.history.length > 0), null, 2)}</pre></div>  */}
                                            {/* <div><pre>{JSON.stringify(props.charDerivedFocuses, null, 2)}</pre></div> */}

                                        </>

                                    }

                                </div>
                            }

                            {isUniqueGift() &&
                                <UniqueGift fieldName={"uniqueGift1_level1"} uniqueGift={char.basicTraits.uniqueGift1} onChangeText={onChangeUniqueGift1} />
                            }
                        </div>
                    }

                    {props.focusLevelAlreadyPicked.level === 2 &&
                        <>
                            <div className="mb-0">
                                <b>{focusName} - Level 2:</b> {focusLevel2Description}
                                <button className="ml-2 btn btn-outline-info btn-tiny" type="button" title="Show Description" onClick={(e) => setShowDescription(!showDescription)}>
                                    <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                                </button>
                            </div>
                            {isUniqueGift() &&
                                <UniqueGift fieldName={"uniqueGift2_level1"} uniqueGift={char.basicTraits.uniqueGift2} onChangeText={onChangeUniqueGift2} />
                            }
                        </>
                    }

                    <DescriptionAlert display={showDescription}>
                        <>
                            <div className="mb-1"><b>{focusName}:</b> {addParagraphs(focusDescription)}</div>
                            <div className="mb-1"><b>Level 1:</b> {focusLevel1Description}</div>
                            <div><b>Level 2:</b> {focusLevel2Description}</div>
                            {focusHistory !== "" &&
                                <div className="mt-1"><i>History:</i> {focusHistory}</div>
                            }
                        </>
                    </DescriptionAlert>

                </div>
            }
        </>
    )

}

export default FocusPickControlsLevelling;
