import React from "react";

import CharacterDesignMain from "../components/CharacterDesignMain";

const CharacterDesigner: React.FunctionComponent<{}> = (props: {}) => {

    return (
        <div className="pageBody">
            <CharacterDesignMain />
        </div>
    );
}

export default CharacterDesigner;
