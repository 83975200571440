import React from "react";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterTraits } from "../../interfaces/CharacterTraits";

import { getAttributeModifierPlusMinus } from "../../utilities/Utilities";


export interface IProps {
    attributeLongNames: string[];
    attributeAbbrev: string[];
    charTraits: CharacterTraits;
    onSetAttributeTo14: (attributeNumber: number, attributeValue: number) => void;
    onRerollAttributes: () => void;
}

const AttributesRoll: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const onSetAttributeTo14 = (e: any) => {
        const attributeNumber: number = parseInt(e.target.getAttribute("data-attributenumber"));
        const oldValue: number = parseInt(e.target.getAttribute("data-oldvalue"));
        props.onSetAttributeTo14(attributeNumber, oldValue);
    }

    const getAttributeValues = () => {
        const attributeDropdowns: any[] = [];
        let attributeNumber = 0;
        char.attributeTraits.attributeScores.forEach((a) => {
            const isChecked = char.attributeTraits.attributeScores[attributeNumber] !== char.attributeTraits.originalScores[attributeNumber];
            attributeDropdowns.push(
                <tr key={"attddl" + attributeNumber}>
                    <td>
                        <div className="d-sm-none">{props.attributeAbbrev[attributeNumber]}</div>
                        <div className="d-none d-sm-block">{props.attributeAbbrev[attributeNumber]}</div>     
                    </td>
                    <td>
                        {char.attributeTraits.attributeScores[attributeNumber]}
                    </td>
                    <td>{getAttributeModifierPlusMinus(props.attributeLongNames[attributeNumber], char.attributeTraits.attributeScores[attributeNumber], null)}</td>
                    <td>
                        <input type="radio" value={props.attributeLongNames[attributeNumber]} checked={isChecked} data-attributenumber={attributeNumber} data-oldvalue={char.attributeTraits.attributeScores[attributeNumber]} name="setTo14" onChange={onSetAttributeTo14} />
                        {char.attributeTraits.attributeScores[attributeNumber] !== char.attributeTraits.originalScores[attributeNumber] &&
                            <span className="ml-2">was {char.attributeTraits.originalScores[attributeNumber]}</span>
                        }
                    </td>
                </tr>
            )
            attributeNumber += 1;
        })
        return attributeDropdowns;
    }

    const attributeValues = getAttributeValues();

    return (
        <div>
            <div className="mb-2">
                <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                Attributes were rolled randomly (3d6 in order). You may set one attribute to a score of 14.
            </div>
            <table className="mb-0 attributeTable">
                <thead>
                    <tr>
                        <th>Attribute</th>
                        <th>Score</th>
                        <th>
                            <div className="d-sm-none">Mod.</div>
                            <div className="d-none d-sm-block">Modifier</div>
                        </th>
                        <th>Set to 14</th>
                    </tr>
                </thead>
                <tbody>
                    {attributeValues}
                </tbody>
            </table>
            <button className="btn btn-secondary btn-tiny" onClick={props.onRerollAttributes}>Reroll</button>&nbsp;{props.charTraits.attributeTraits.rerolls} reroll{props.charTraits.attributeTraits.rerolls === 1 ? "" : "s"}
        </div>
    )

}

export default AttributesRoll;