import { StarshipTraits } from "../../interfaces/StarshipTraits";

export const applyValidation = (shipTraits: StarshipTraits): StarshipTraits => {

    // Clear old validation:
    shipTraits.validationCodes = [];
    shipTraits.validationErrors = []; 


    // Get traits at each step:

    // const charDerivedStats = new CharacterDerivedStats(characterTraits);

    // charDerivedStats.calculateSkillLevels(CreationStep.FreeFocus);
    // const skillsAtFreeFocusSkillsStep = charDerivedStats.skillLevels;

    // charDerivedStats.calculateSkillLevels(CreationStep.FreeSkill);
    // const skillsAtFreeSkillsStep = charDerivedStats.skillLevels;

    // charDerivedStats.calculateAttributeLevels(CreationStep.Classes);
    // const attributesAtBackground = charDerivedStats.attributeLevels;

    // // Step 1 
    // const v1_0 = val1_0CheckHasCharacterName(characterTraits.basicTraits.name);
    // if (!v1_0) { characterTraits.basicTraits.validationCodes.push("noName") };

    // // Step 2
    // const v2_0 = val2_0CheckAttributeMethodIsSet(characterTraits.attributeTraits.method);
    // if (!v2_0) { characterTraits.attributeTraits.validationCodes.push("attributeAssignNotSelected") };

    // const v2_1 = val2_1CheckAllAttributesAreSet(characterTraits.attributeTraits.attributeScores);
    // if (!v2_1) { characterTraits.attributeTraits.validationCodes.push("notAllAttributes") };

    // // Step 3
    // const v3_0 = val3_0CheckHasBackground(characterTraits.background.backgroundName);
    // if (!v3_0) { characterTraits.background.validationCodes.push("bgNotSelected") };

    // if (v3_0) {
    //     const v3_1 = val3_1CheckHasBackgroundMethod(characterTraits.background.method);
    //     if (!v3_1) { characterTraits.background.validationCodes.push("backgroundMethodNotSelected") };

    //     if (characterTraits.background.method === "assign") {
    //         const v3_2 = val3_2CheckHasPickedThreeBackgroundSkills(characterTraits.background.backgroundSkillLevelPicks);
    //         if (!v3_2) { characterTraits.background.validationCodes.push("notThreeBgSkillLevels") };

    //         const v3_3 = val3_3CheckHasPickedRequiredBackgroundCombatSkill(characterTraits.background.backgroundName, backgroundsData, skillsData, characterTraits.background.backgroundSkillLevelPicks);
    //         if (!v3_3) { characterTraits.background.validationCodes.push("notPickedBgCombatSkill") };
    //     }

    //     if (characterTraits.background.method === "roll") {
    //         const v3_4 = val3_4CheckHasPickedFreeSkill(characterTraits.background.backgroundSkillLevelPicks);
    //         if (!v3_4) { characterTraits.background.validationCodes.push("notPickedFreeBackgroundSkill") };

    //         characterTraits.background.tableRolls.forEach((tr, rollIndex) => {
    //             if (tr.result.indexOf("+") === -1) {
    //                 // Check each rolled skill has been selected....
    //                 const v3_5 = val3_5CheckHasPickedRolledSkill(tr.result, tr.skillLevelPicks);
    //                 if (!v3_5) { characterTraits.background.validationCodes.push("notPickedRolledBackgroundSkill-" + rollIndex) };
    //             } else {
    //                 // Check each rolled attribute bonus has been selected....
    //                 const v3_5 = val3_5CheckHasPickedRolledAttribute(tr.result, tr.attributeScoreBonuses);
    //                 if (!v3_5) { characterTraits.background.validationCodes.push("notPickedRolledBackgroundAttribute-" + rollIndex) };
    //             }
    //         });

    //         // Check all attributes are 18 or less
    //         const v3_6 = val3_6CheckAttributesUnder18(attributesAtBackground);
    //         if (!v3_6) { characterTraits.background.validationCodes.push("scoreOver18") };
    //     }
    // }

    // // Step 4

    // const v4_0 = val4_0CheckHasPickedClasses(characterTraits.levelOne.classes);
    // if (!v4_0) { characterTraits.levelOne.validationCodes.push("classNotSelected") };

    // // Need to switch this to a loop
    // characterTraits.levelOne.classes.forEach((c, classIndex) => {

    //     charDerivedStats.calculateSkillLevels(CreationStep.Classes, classIndex);
    //     const skillsAtClassesFocusSkillsStep = charDerivedStats.skillLevels;

    //     const v4_1 = val4_1CheckHasPickedAllClassSkills(c.className, characterTraits, classesData);
    //     if (!v4_1) { c.validationCodes.push("classSkillPicksNotAllSelected") };

    //     const v4_2 = val4_2CheckHasPickedClassesFocuses(c.className, characterTraits, classesData);
    //     if (!v4_2) { c.validationCodes.push("classFocusNotSelected") };

    //     const v4_3 = val4_3CheckHasPickedClassesFocusesSkillLevels(c.className, characterTraits, focusesData);
    //     if (!v4_3) { c.validationCodes.push("classFocusSkillsNotAllSelected") };

    //     const v4_4 = val4_4CheckClassFocusSkillLevelsHaveNoSkillLevelOverOne(c.className, characterTraits, skillsAtClassesFocusSkillsStep, focusesData);
    //     if (!v4_4) { c.validationCodes.push("classFocusSkillAboveLimit") };

    //     const v4_5 = val4_5CheckPsychicTrainingFocusOnlyAvailableToPsychic(c.className, characterTraits);
    //     if (!v4_5) { c.validationCodes.push("classFocusPsychicTrainingOnlyForPsychic") };

    //     const v4_6 = val4_6CheckWildPsychicFocusNotAvailableToPsychic(c.className, characterTraits);
    //     if (!v4_6) { c.validationCodes.push("classFocusWildPsychicNotForPsychic") };

    //     const v4_7 = val4_7CheckPsychicTrainingFocusForPartialPsychicHasSameSkill(c.className, characterTraits);
    //     if (!v4_7) { c.validationCodes.push("classFocusPsychicTrainingForPartialPsychicMustHaveSameSkill") };

    // })

    // // Step 5
    // const v5_0 = val5_0CheckHasPickedFreeFocus(characterTraits.levelOne.freeFocusLevelPick);
    // if (!v5_0) { characterTraits.levelOne.validationCodes.push("freeFocusNotSelected") };

    // const v5_0a = val5_01aCheckHasPickedFreeFocusAttributeModifier(characterTraits.levelOne.freeFocusLevelPick);
    // if (!v5_0a) { characterTraits.levelOne.validationCodes.push("freeFocusAttributeModifierNotSelected") };

    // const v5_1 = val5_1CheckHasPickedFreeFocusSkillLevel(characterTraits.levelOne.freeFocusLevelPick);
    // if (!v5_1) { characterTraits.levelOne.validationCodes.push("freeFocusSkillLevelNotSelected") };

    // const v5_2 = val5_2CheckFreeFocusSkillLevelsHaveNoSkillLevelOverOne(characterTraits.levelOne.freeFocusLevelPick, skillsAtFreeFocusSkillsStep, focusesData);
    // if (!v5_2) { characterTraits.levelOne.validationCodes.push("freeFocusSkillLevelAboveLimit") };

    // const v5_2_1 = val5_8CheckIfVIRobotIsPsychic(characterTraits.levelOne.freeFocusLevelPick, characterTraits);
    // if (v5_2_1) { characterTraits.levelOne.validationCodes.push("viRobotCannotBePsychic") };

    // const v5_3 = val5_3CheckHasPickedFreeSkillLevel(characterTraits.levelOne.freeSkillLevelPicks);
    // if (!v5_3) { characterTraits.levelOne.validationCodes.push("freeSkillLevelNotSelected") };

    // const v5_4 = val5_4CheckFreeSkillLevelHasNoSkillLevelOverOne(characterTraits.levelOne.freeSkillLevelPicks, skillsAtFreeSkillsStep);
    // if (!v5_4) { characterTraits.levelOne.validationCodes.push("freeSkillLevelAboveLimit") };

    // const v5_5 = val5_5CheckPsychicTrainingFocusOnlyAvailableToPsychic(characterTraits.levelOne.freeFocusLevelPick, characterTraits);
    // if (!v5_5) { characterTraits.levelOne.validationCodes.push("freeFocusPsychicTrainingOnlyForPsychic") };

    // const v5_6 = val5_6CheckWildPsychicFocusNotAvailableToPsychic(characterTraits.levelOne.freeFocusLevelPick, characterTraits);
    // if (!v5_6) { characterTraits.levelOne.validationCodes.push("freeFocusWildPsychicNotForPsychic") };

    // const v5_7 = val5_7CheckPsychicTrainingFocusForPartialPsychicHasSameSkill(characterTraits.levelOne.freeFocusLevelPick, characterTraits);
    // if (!v5_7) { characterTraits.levelOne.validationCodes.push("freeFocusPsychicTrainingForPartialPsychicMustHaveSameSkill") };

    // const v5_8 = val5_8CheckAlienWithPsychicAptitudeIsPsychic(characterTraits.levelOne.freeFocusLevelPick, characterTraits);
    // if (!v5_8) { characterTraits.levelOne.validationCodes.push("freeFocusAlienMustBePsychic") };


    // // Step 6

    // // Get all of character's psychic skills that are level-1
    // // Check if each skill has a level-1 technique selected. Return val code with index. 
    // const getSkillsAtFreeSkillStep = () => {
    //     const charDerivedStatsAtThisClass = new CharacterDerivedStats(characterTraits);
    //     charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.FreeSkill);
    //     return charDerivedStatsAtThisClass.skillLevels;
    // }

    // const allCharactersSkills = getSkillsAtFreeSkillStep();
    // const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
    // const allCharactersPsychicSkills = allCharactersSkills.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));
    // allCharactersPsychicSkills.forEach((ps, index) => {
    //     const v6_0 = val6_0CheckHasPickedLevelOneTechniqueForEachPsychicSkillAtLevelOne(ps, characterTraits.levelOne.psychicTechniquePicks);
    //     if (!v6_0) { characterTraits.levelOne.validationCodes.push("levelOnePsychicTechniqueNotSelected-" + index) };

    //     const v6_1 = val6_1CheckSyntheticAdaptationHasFixOrProgramSkill(characterTraits.levelOne.psychicTechniquePicks, characterTraits);
    //     if (!v6_1) { characterTraits.levelOne.validationCodes.push("levelOneSytheticAdaptationReqProgramOrFix-" + index) };

    // })

    // charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);
    // const focusLevelsAtAllSteps = charDerivedStats.focusLevels;

    // const v6_2 = val6_2CheckWildTalentHasSelectedPsychicDiscipline(characterTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline, focusLevelsAtAllSteps, characterTraits);
    // if (!v6_2) { characterTraits.levelOne.validationCodes.push("levelOneWildTalentMustSelectDiscipline") };

    // const v6_3 = val6_3CheckWildTalentHasSelectedTechnique1(characterTraits.levelOne.wildTalentPicks, focusLevelsAtAllSteps, characterTraits);
    // if (!v6_3) { characterTraits.levelOne.validationCodes.push("levelOneWildTalentMustSelectTechnique") };

    // const v6_4 = val6_4CheckWildTalentHasSelectedTechnique2(characterTraits.levelOne.wildTalentPicks, focusLevelsAtAllSteps, characterTraits);
    // if (!v6_4) { characterTraits.levelOne.validationCodes.push("levelTwoWildTalentMustSelectTechnique") };

    // // Step 7
    // const v7_0 = val7_0CheckEquipmentMethodSelected(characterTraits.gear.method, characterTraits);
    // if (!v7_0) { characterTraits.levelOne.validationCodes.push("equipmentMethodNotSelected") };

    // const v7_1 = val7_1CheckStartingCreditsRolled(characterTraits.gear.startingCredits, characterTraits);
    // if (!v7_1) { characterTraits.levelOne.validationCodes.push("levelOneCreditsNotRolled") };

    // if (characterTraits.gear.method === "pack") {
    //     const v7_2 = val7_2CheckEquipmentPackSelected(characterTraits.gear.pack, characterTraits);
    //     if (!v7_2) { characterTraits.levelOne.validationCodes.push("equipmentPackNotSelected") };
    // }

    // const v7_3 = val7_3CheckVIDoesNotHaveCyberware(charDerivedStats, characterTraits);
    // if (v7_3) { characterTraits.levelOne.validationCodes.push("viCannotPossessCyber") };

    // // Step 8
    // const v8_0 = val8_0CheckHitPointsRolled(characterTraits.levelOne.rolledHitPoints, characterTraits);
    // if (!v8_0) { characterTraits.levelOne.validationCodes.push("levelOneHitPointsNotRolled") };

    // // Levels validation 
    // characterTraits.levels.forEach((lev) => {

    //     // Check hit points for level have been rolled. 
    //     if (lev.rolledHitPoints && lev.rolledHitPoints.length === 0) {
    //         lev.validationCodes.push("levelHitPointsNotRolled");
    //     }

    //     let attrBoostWarning_3 = false;
    //     let attrBoostWarning_4 = false;
    //     let attrBoostWarning_5 = false;

    //     if ([2, 5, 7, 10].indexOf(lev.level) !== -1) {
    //         // Check has picked a focus
    //         const levelFocus = lev.focusLevelPick;
    //         if (levelFocus) {
    //             if (levelFocus.focus === "") {
    //                 lev.validationCodes.push("levelFocusNotSelected");
    //             }
    //         } else {
    //             lev.validationCodes.push("levelFocusNotSelected");
    //         }
    //         // If focus grants skill points, ensure user has selected a skill
    //         if (levelFocus?.grantsSkill && levelFocus?.skillPointsPicks && levelFocus?.skillPointsPicks.length === 0) {
    //             lev.validationCodes.push("levelFocusMustPickSkill");
    //         }

    //         if (levelFocus) {

    //             // Check if the character has the Psychic Training Focus; if so, must be a Psychic or Partial Psychic
    //             if (levelFocus.focus === "Psychic Training") {
    //                 const isPsychic = characterTraits.levelOne.classes.find((cls) => cls.className === "Psychic" || cls.className === "Partial Psychic");
    //                 if (!isPsychic) {
    //                     lev.validationCodes.push("levelFocusPsychicTrainingOnlyForPsychic");
    //                 }
    //             }

    //             // Check if the character has the Wild Psychic Focus; if so, must NOT be a Psychic or Partial Psychic
    //             if (levelFocus.focus === "Wild Psychic Talent") {
    //                 const isPsychic = characterTraits.levelOne.classes.find((cls) => cls.className === "Psychic" || cls.className === "Partial Psychic");
    //                 if (isPsychic) {
    //                     lev.validationCodes.push("levelFocusWildPsychicNotForPsychic");
    //                 }
    //             }

    //             // Check if the character has the Psychic Training focus; if so, if is a Partial Psychic, must select the same skill for the 
    //             // focus as weas selected for the Partial Psychic class. 
    //             if (levelFocus.focus === "Psychic Training" && levelFocus.level === 1) {
    //                 const isPartialPsychic = characterTraits.levelOne.classes.find((cls) => cls.className === "Partial Psychic");
    //                 if (isPartialPsychic) {
    //                     if (levelFocus.skillLevelPicks) {
    //                         const focusSkill = levelFocus.skillPointsPicks[0];
    //                         if (focusSkill && focusSkill.skill) {
    //                             // Get the partial psychic' skill:
    //                             let partialPsychicSkill: SkillLevelPick | null = null;
    //                             characterTraits.levelOne.classes.forEach((cl) => {
    //                                 if (cl.className === "Partial Psychic") {
    //                                     if (cl.classSkillPicks.length > 0) {
    //                                         partialPsychicSkill = cl.classSkillPicks[0];
    //                                         if (focusSkill && partialPsychicSkill) {
    //                                             if (focusSkill.skill !== partialPsychicSkill.skill) {
    //                                                 lev.validationCodes.push("levelFocusPsychicTrainingForPartialPsychicMustHaveSameSkill");
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                             })
    //                         }

    //                     }

    //                 }
    //             }

    //         }

    //     }

    //     lev.skillPointSpends.forEach((sps, index) => {

    //         const charDerivedStats_atSkillPick = new CharacterDerivedStats(characterTraits);
    //         charDerivedStats_atSkillPick.calculateIsPsychic();
    //         charDerivedStats_atSkillPick.calculateFocusLevels(CreationStep.AllSteps, lev.level);
    //         charDerivedStats_atSkillPick.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, lev.level);
    //         charDerivedStats_atSkillPick.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, lev.level, index);
    //         charDerivedStats_atSkillPick.calculateSkillPointsLeftSoFar();
    //         charDerivedStats_atSkillPick.calculateSkillLevels(CreationStep.AllSteps, 100, 100, lev.level, index, false, false, false); // last param excludes bonus skill points provoded by foci. 

    //         const charDerivedStats_atSkillPick_WithoutFocusSkillPoints = new CharacterDerivedStats(characterTraits);
    //         charDerivedStats_atSkillPick_WithoutFocusSkillPoints.calculateIsPsychic();
    //         charDerivedStats_atSkillPick_WithoutFocusSkillPoints.calculateFocusLevels(CreationStep.AllSteps, lev.level);
    //         charDerivedStats_atSkillPick_WithoutFocusSkillPoints.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, lev.level);
    //         charDerivedStats_atSkillPick_WithoutFocusSkillPoints.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, lev.level, index);
    //         charDerivedStats_atSkillPick_WithoutFocusSkillPoints.calculateSkillPointsLeftSoFar();
    //         charDerivedStats_atSkillPick_WithoutFocusSkillPoints.calculateSkillLevels(CreationStep.AllSteps, 100, 100, lev.level, index, false, true, false); // last param excludes bonus skill points provoded by foci. 


    //         if (sps.spendType === "improveSkill") {

    //             const thisSkill = charDerivedStats_atSkillPick.skillLevels.find((sl) => sl.skill === sps.skillName);
    //             if (thisSkill && thisSkill.level) {

    //                 const actualSkillLevel = thisSkill.level - 1; // 1pt = level-0, 2pts = level-1, etc.

    //                 // This is for allowing for right to exceed skill level limits if only using skill points granted by a focus. 
    //                 let actualSkillLevelWithoutFocusPoints = 0;
    //                 const thisSkillWithoutFocusPoints = charDerivedStats_atSkillPick_WithoutFocusSkillPoints.skillLevels.find((sl) => sl.skill === sps.skillName);
    //                 if (thisSkillWithoutFocusPoints && thisSkillWithoutFocusPoints.level) {
    //                     actualSkillLevelWithoutFocusPoints = thisSkillWithoutFocusPoints.level - 1;
    //                 }

    //                 // Check skill points spent on skill improvements do not exceed level limits. 
    //                 if (actualSkillLevel === 2 && lev.level < 3) {
    //                     if (actualSkillLevelWithoutFocusPoints === 2 && lev.level < 3) {
    //                         sps.validationCodes.push("mustBeAtLeastLevel3ToBuyLevel2skill");
    //                     }
    //                 }
    //                 if (actualSkillLevel === 3 && lev.level < 6) {
    //                     if (actualSkillLevelWithoutFocusPoints === 3 && lev.level < 6) {
    //                         sps.validationCodes.push("mustBeAtLeastLevel6ToBuyLevel3skill");
    //                     }
    //                 }
    //                 if (actualSkillLevel === 4 && lev.level < 9) {
    //                     if (actualSkillLevelWithoutFocusPoints === 4 && lev.level < 9) {
    //                         sps.validationCodes.push("mustBeAtLeastLevel9ToBuyLevel4skill");

    //                     }
    //                 }

    //             }
    //         }

    //         // Check skill points spent on attribute improvements do not exceed level limits. 
    //         if (sps.spendType === "improveAttribute") {

    //             charDerivedStats_atSkillPick.calculateAttributeLevels(CreationStep.AllSteps, 100, lev.level, index);

    //             if (charDerivedStats_atSkillPick.totalAttributeBoosts >= 3 && lev.level < 3) {
    //                 if (!attrBoostWarning_3) {
    //                     sps.validationCodes.push("mustBeAtLeastLevel3ToBuy3rdAttributeBoost");
    //                     attrBoostWarning_3 = true;
    //                 }
    //             }
    //             if (charDerivedStats_atSkillPick.totalAttributeBoosts >= 4 && lev.level < 6) {
    //                 if (!attrBoostWarning_4) {
    //                     sps.validationCodes.push("mustBeAtLeastLevel6ToBuy4thAttributeBoost");
    //                     attrBoostWarning_4 = true;
    //                 }
    //             }
    //             if (charDerivedStats_atSkillPick.totalAttributeBoosts >= 5 && lev.level < 9) {
    //                 if (!attrBoostWarning_5) {
    //                     sps.validationCodes.push("mustBeAtLeastLevel9ToBuy5thAttributeBoost");
    //                     attrBoostWarning_5 = true;
    //                 }
    //             }

    //             // check attribute has not pased 18
    //             const thisAttribute = charDerivedStats_atSkillPick.attributeLevels.find((al) => al.attributeName === sps.attributeName);
    //             if (thisAttribute) {
    //                 if (thisAttribute && thisAttribute.level && thisAttribute.level > 18) {
    //                     sps.validationCodes.push("attributeCannotExceed18");
    //                 }
    //             }

    //         }

    //     })

    // })

    // // Is all valid?
    // const allValidationErrors = [
    //     ...characterTraits.basicTraits.validationCodes,
    //     ...characterTraits.attributeTraits.validationCodes,
    //     ...characterTraits.background.validationCodes,
    //     ...characterTraits.levelOne.validationCodes,
    // ];

    // characterTraits.levelOne.classes.forEach((c) => {
    //     allValidationErrors.push(...c.validationCodes);
    // })

    // characterTraits.levels.forEach((lev) => {
    //     allValidationErrors.push(...lev.validationCodes);
    //     lev.skillPointSpends.forEach((sps) => {
    //         allValidationErrors.push(...sps.validationCodes);
    //     })
    // })

    // characterTraits.isValid = allValidationErrors.length === 0;
    // characterTraits.validationErrors = allValidationErrors;

    return shipTraits;
}