import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import app from "../firebase";

import { AuthContext } from "../Auth";

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfirmModal from "../components/ConfirmModal";
import { getAlienFocusesFromDB } from "../utilities/Utilities";
import { Lookups } from "../lookups/Lookups";

type IProps = {}

const CustomAlienFoci: React.FunctionComponent<IProps> = (props: IProps) => {

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [alienFoci, setAlienFoci] = useState<Array<any>>([]);
    const [displayConfirmDelete, setDisplayConfirmDelete] = useState(false);
    const [alienFocusIdToDelete, setAlienFocusIdToDelete] = useState("");
    const [alienFocusNameToDelete, setAlienFocusNameToDelete] = useState("");
    const [reloadFoci, setReloadFoci] = useState(0);

    const lookups = Lookups.getInstance();

    useEffect(() => {
        // Create an scoped async function in the hook.
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {

                    const db = app.firestore();
                    const data = await db.collection("alienFoci")
                        .where("user_id", "==", user.uid)
                        .orderBy("alienFocus_lower", "asc")
                        .get();

                    const alienFoci: any[] = [];
                    data.forEach((doc) => {
                        alienFoci.push({ ...doc.data(), id: doc.id });
                    });
                    setAlienFoci(alienFoci);
                    setLoading(false);
                }
            }
            catch (error: any) {
                console.log(error);
                setLoading(false);
            }
        }
        // Execute the created function directly
        asyncUseEffect();
    }, [user, reloadFoci]); // Will load characters after rendering     

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const onDelete = (alienFocusId: string, alienFocusName: string): void => {
        setAlienFocusIdToDelete(alienFocusId);
        setAlienFocusNameToDelete(alienFocusName);

        setDisplayConfirmDelete(true);
    }

    const deleteAlienFocus = async () => {
        // Delete the focus from the database. 
        const db = app.firestore();
        await db.collection("alienFoci").doc(alienFocusIdToDelete).delete();

        if (user) {
            // reload alien foci data so deleted focus is no longer available. 
            const alienFocusesFromDB = await getAlienFocusesFromDB(user, app);
            lookups.addFoci("alienFoci", alienFocusesFromDB);

            // set the 'origin' field to 'Unknown' for all characters that had the focus.
            await db.collection("character")
                .where("user_id", "==", user.uid)
                .where("origin", "==", alienFocusNameToDelete)
                .get().then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        doc.ref.update({
                            origin: "Human"
                        });
                    });
                });
        }

        // Close the modal and reload the foci. 
        setDisplayConfirmDelete(false);
        setReloadFoci(reloadFoci + 1);
    }

    const alienFociRows = alienFoci.map((c: any) =>
        <tr key={c.id}>
            <td className="" ><Link to={"/customAlienFociDesigner/" + c.id}>{isBlank(c.alienFocus, "(No name)")}</Link></td>
            <td style={{ width: "10%" }} className="text-center">
                <button className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); onDelete(c.id, c.alienFocus) }}><FontAwesomeIcon icon={faTrash} title="Delete"></FontAwesomeIcon ></button>
            </td>
        </tr>
    )
    return (
        <div className="pageBody">
            <h1 className="pageHeading">Alien Foci</h1>

            <ConfirmModal
                displayModal={displayConfirmDelete}
                title="Confirm Delete Alien Origin Focus"
                message={"Are sure you want to delete the " + alienFocusNameToDelete + " Alien Origin Focus? The focus will be removed from all characters."}
                confirmButtonText="Delete"
                closeButtonText="Cancel"
                onClose={() => setDisplayConfirmDelete(false)}
                onConfirm={() => deleteAlienFocus()}
            />

            <div className="mt-2 mb-2"><Link className="btn btn-primary" to="/customAlienFociDesigner">Design an Alien Focus</Link></div>

            {!loading && alienFoci.length > 0 &&

                <table className="table table-striped table-sm w-auto">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" className="">Alien Species</th>
                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alienFociRows}
                    </tbody>
                </table>
            }

            {!loading && alienFoci.length === 0 &&
                <p>You currently have no Alien Foci.</p>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}

export default CustomAlienFoci;
