import { StarshipTraits } from './../interfaces/StarshipTraits';
import { FAQData } from './../interfaces/FAQData';
import { BackgroundData } from "../interfaces/BackgroundData";
import { ClassData } from "../interfaces/ClassData";
import { FocusData } from "../interfaces/FocusData";
import { PsychicTechniquesData } from './../interfaces/PsychicTechniquesData';
import { SkillData } from "../interfaces/SkillData";
import { GearData } from "../interfaces/GearData";
import { GearModData } from "../interfaces/GearModData";
import { GearCategoryData } from "../interfaces/GearCategoryData";
import { GearPackData } from "../interfaces/GearPackData";
import { AlienBenefitData } from './../interfaces/AlienBenefit';
import { EquipmentTag } from "../interfaces/EquipmentTagData";
import { ShipHullData } from '../interfaces/ShipHull';

import BackgroundsJSON from "../lookups/Backgrounds.json";
import ClassesJSON from "../lookups/Classes.json";
import FocusesJSON from "../lookups/Focuses.json";
import PsychicTechniquesJSON from "../lookups/PsychicTechniques.json";
import SkillsJSON from "../lookups/SkillsAll.json";
import GearJSON from "../lookups/Gear.json";
import GearModsJSON from "../lookups/GearMods.json";
import GearCategoryJSON from "../lookups/GearCategories.json";
import GearPacksJSON from "../lookups/GearPacks.json";
import EquipmentTagsJSON from "../lookups/EquipmentTags.json";
import FAQJSON from "../lookups/FAQ.json";
import AlienBenefitsJSON from "../lookups/AlienBenefits.json";
import ShipHullsJSON from "../lookups/ShipHulls.json";
import ShipFittingsJSON from "../lookups/ShipFittings.json";
import ShipModsJSON from "../lookups/ShipMods.json";
import ShipExamplesJSON from '../lookups/StarshipExamples.json';


import { convertAnySkillToSkillListDesc } from "../utilities/SkillUtilities";
import deepcopy from "deepcopy";
import { ShipFittingData } from '../interfaces/ShipFitting';
import { ShipModData } from '../interfaces/ShipMod';

export class Lookups {
    private static instance: Lookups;

    public backgrounds: BackgroundData[] = [];
    public classes: ClassData[] = [];
    public focuses: FocusData[] = [];
    public psychicTechniques: PsychicTechniquesData[] = [];
    public skills: SkillData[] = [];
    public gear: GearData[] = [];
    public gearMods: GearModData[] = [];
    public gearCategories: GearCategoryData[] = [];
    public gearPacks: GearPackData[] = [];
    public equipmentTags: EquipmentTag[] = [];
    public FAQ: FAQData[] = [];
    public alienBenefits: AlienBenefitData[] = [];
    public shipHulls: ShipHullData[] = [];
    public shipFittings: ShipFittingData[] = [];
    public shipMods: ShipModData[] = [];
    public shipExamples: StarshipTraits[] = [];

    public constructor() {
        this.getSkills();
        this.getFocuses();
        this.getBackgrounds();
        this.getClasses();
        this.getPsychicTechniques();
        this.getGear();
        this.getGearMods();
        this.getGearCategories();
        this.getGearPacks();
        this.getEquipmentTags();
        this.getFAQ();
        this.getAlienBenefits();
        this.getShipHulls();
        this.getShipFittings();
        this.getShipMods();
        this.getShipExamples();
    }

    public static getInstance(): Lookups {
        if (!Lookups.instance) {
            Lookups.instance = new Lookups();
        }
        return Lookups.instance;
    }

    private getSkills = () => {
        this.skills = SkillsJSON;
    }

    private getFocuses() {
        let focusesData: FocusData[] = FocusesJSON;

        let newFocuses: FocusData[] = [];

        // Convert single Specialist Focuses into one Specialist Focus per nonCombat/npnPsychic skill.
        focusesData.forEach((thisFocus) => {
            if (thisFocus.focus === "Specialist") {
                const skills = convertAnySkillToSkillListDesc("NonCombatNonPsychicSkill", this.skills, true).split(", ")
                skills.forEach((thisSkill) => {
                    const specialist: FocusData = deepcopy(thisFocus);
                    specialist.focus = specialist.focus + " (" + thisSkill + ")";
                    specialist.levels[0].bonuses[0].skill = thisSkill;
                    newFocuses.push(specialist);
                })
            } else {
                newFocuses.push(deepcopy(thisFocus));
            }
        })

        // // Remove default Specialist focus
        // newFocuses = newFocuses.filter((f) => f.focus !== "Specialist");

        // Return focuses, sorted alphabetically
        this.focuses = newFocuses.sort((a, b) => a.focus > b.focus ? 1 : -1);
    }

    private getBackgrounds = () => {
        this.backgrounds = BackgroundsJSON;
    }

    private getClasses = () => {
        this.classes = ClassesJSON;
    }

    private getPsychicTechniques = () => {
        this.psychicTechniques = PsychicTechniquesJSON;
    }

    private getGear = () => {
        this.gear = GearJSON;
        // convert 0.33 to 1/3
        this.gear.forEach((g) => {
            if (g.enc === 0.33) { g.enc = 1 / 3; }
        })
    }

    private getGearMods = () => {
        this.gearMods = GearModsJSON;
    }

    private getGearCategories = () => {
        this.gearCategories = GearCategoryJSON;
    }

    private getGearPacks = () => {
        this.gearPacks = GearPacksJSON;
    }

    private getEquipmentTags = () => {
        this.equipmentTags = EquipmentTagsJSON;
    }

    private getFAQ = () => {
        this.FAQ = FAQJSON;;
    }

    private getAlienBenefits = () => {
        this.alienBenefits = AlienBenefitsJSON;
    }

    public addFoci = (source: string, newfoci: FocusData[]) => {
        // delete any existing foci from the source
        if (source !== "") {
            this.focuses = this.focuses.filter((f) => f.source !== source || !f.source);
        }
        // add the new foci. 
        this.focuses = [...this.focuses, ...newfoci];
        this.focuses.sort((a, b) => a.focus > b.focus ? 1 : -1);
    }

    private getShipHulls = () => {
        this.shipHulls = ShipHullsJSON;
    }

    private getShipFittings = () => {
        this.shipFittings = ShipFittingsJSON;
    }

    private getShipMods = () => {
        this.shipMods = ShipModsJSON;
    }

    private getShipExamples = () => {
        this.shipExamples = ShipExamplesJSON;
    }

}
