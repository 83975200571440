import { SkillLevelPick } from '../interfaces/SkillLevelPick';
import { SkillData } from '../interfaces/SkillData';
import { AttributeScoreBonus } from '../interfaces/CharacterTraits';

const nameSort = (a: SkillData, b: SkillData) => a.skill < b.skill ? -1 : 1;

export const getAllSkills = (skillList: SkillData[]) => {
    return skillList.sort(nameSort);
}

export const getSkillsByType = (type: string, skillList: SkillData[]) => {
    const skills = skillList.filter((s) => {
        if (s.hasOwnProperty("type")) {
            return s.type?.indexOf(type) !== -1;
        } else {
            return false;
        }
    });
    return skills.sort(nameSort);
}

export const getSkillsByTypes = (types: string[], skillList: SkillData[]) => {
    const skills = skillList.filter((s) => {
        if (s.hasOwnProperty("type")) {
            return s.type?.some(item => types.includes(item));
        } else {
            return false;
        }
    });
    return skills.sort(nameSort);
}

export const getSkillsByNotType = (type: string, skillList: SkillData[]) => {
    const untypedSkills = skillList.filter((s) => !s.hasOwnProperty("type")); // skills that do not have the 'types' property. 
    const notTypeSkills = skillList.filter((s) => {
        if (s.hasOwnProperty("type")) {
            return s.type?.indexOf(type) === -1;
        } else {
            return false;
        }
    });
    const finalSkills = [...untypedSkills, ...notTypeSkills];
    return finalSkills.sort(nameSort);
}

export const getSkillsByNotTypes = (types: string[], skillList: SkillData[]) => {

    // Will always include all non-typed skills
    let untypedSkills = skillList.filter((s) => !s.hasOwnProperty("type"));

    // Then add any typed skills that are not of any the types specified. 
    const typedSkills = skillList.filter((s) => s.hasOwnProperty("type"));
    const notOfTypesSkills: SkillData[] = [];
    typedSkills.forEach((s) => {
        let include = true;
        types.forEach((t) => {
            if (s.type?.indexOf(t) === -1) { include = false; }
        })
        if (include) { notOfTypesSkills.push(s); }
    })

    const finalSkills = [...untypedSkills, ...notOfTypesSkills];
    return finalSkills.sort(nameSort);
}

export const convertAnySkillToSkillListDesc = (skillsListString: string, skillList: SkillData[], includeAnySkill: boolean): string => {

    const skillArray = skillsListString.split(",").map((s) => s.trim());

    if (includeAnySkill && skillArray.indexOf("AnyNonPsychicSkill") !== -1) {
        return getSkillsByNotType("Psychic", skillList).map((s) => s.skill).join(", ");
    }

    let elaboratedSkills: string[] = [];
    skillArray.forEach((s) => {
        if (s.trim() === "AnyCombatSkill") {
            const combatSkills = getSkillsByType("Combat", skillList);
            combatSkills.forEach((cs) => elaboratedSkills.push(cs.skill));
        }
        else if (s.trim() === "AnyPsychicSkill") {
            const psychicSkills = getSkillsByType("Psychic", skillList);
            psychicSkills.forEach((s) => elaboratedSkills.push(s.skill));
        }
        else if (s.trim() === "NonCombatNonPsychicSkill") {
            const skills = getSkillsByNotTypes(["Combat", "Psychic"], skillList);
            skills.forEach((s) => elaboratedSkills.push(s.skill));
        }
        else if (s.trim().indexOf("|") !== -1) {
            const skills = s.trim().split("|");
            skills.forEach((s) => elaboratedSkills.push(s))
        } else {
            if (s !== "AnyNonPsychicSkill") {
                elaboratedSkills.push(s.trim());
            }
        }
    })
    const dedupedSkills = elaboratedSkills.filter((skill, index) => elaboratedSkills.indexOf(skill) === index);
    return dedupedSkills.sort().join(", ");
}

export const convertQuickSkillsToSkillListDesc = (skillsListString: string, capitalise: boolean, skillList: SkillData[]): string => {
    const skillArray = skillsListString.split(",").map((s) => s.trim());
    const any = capitalise ? "Any" : "any";
    const combatSkills = getSkillsByType("Combat", skillList).map((s) => s.skill).join("/");
    return skillArray.map((s) => s === "AnyCombatSkill" ? any + " Combat Skill (" + combatSkills + ")" : s).sort().join(", ");

}

export const getTotalSkillLevelPicks = (slp: SkillLevelPick[]): number => {
    if (slp && slp.length > 0) {
        const reducer = (accum: number, levels: number) => accum + levels;
        return slp.map((slp) => slp.levels).reduce(reducer);
    }
    return 0;
}

export const getTotalAttributeScorePicks = (asp: AttributeScoreBonus[]): number => {
    if (asp && asp.length > 0) {
        const reducer = (accum: number, levels: number) => accum + levels;
        return asp.map((asp) => asp.bonus).reduce(reducer);
    }
    return 0;
}

export const displayBGTable = (skillNames: string): string => {
    const arrSkillnames = skillNames.split(", ");
    let results: string[] = [];
    for (let i = 0; i < arrSkillnames.length; i++) {
        let thisResult = "(" + (i + 1) + ") ";
        thisResult += convertSkillToFriendlyName(arrSkillnames[i], null);
        results.push(thisResult);
    }
    return results.join("; ") + ".";
}

export const convertSkillToFriendlyName = (skill: string, level: number | null): string => {
    let friendlySkill = "";
    switch (skill) {
        case "AnyCombatSkill": friendlySkill = "Any Combat"; break;
        case "AnyNonPsychicSkill": friendlySkill = "Any Skill"; break;
        case "+1AnyStat": friendlySkill = "+1 Any Stat"; break;
        case "+1Physical": friendlySkill = "+1 Physical"; break;
        case "+1Mental": friendlySkill = "+1 Mental"; break;
        case "+2Physical": friendlySkill = "+2 Physical"; break;
        case "+2Mental": friendlySkill = "+2 Mental"; break;
        default: friendlySkill = skill;
    }
    friendlySkill = friendlySkill.replace("|", " or ");
    if (level !== null) { friendlySkill += "-" + level; }
    return friendlySkill;
}
