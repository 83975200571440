import cloneDeep from 'lodash.clonedeep';
import { Mod, SkillPointSpend } from './../interfaces/CharacterTraits';
import { SkillPointsPick } from './../interfaces/SkillPointsPick';
import { PsychicTechniquePick } from './../interfaces/PsychicTechniquePick';
import { AttributeScoreBonus, CharacterTraits, ClassTrait, EquipmentItem, Level } from "../interfaces/CharacterTraits";
import { FocusData, LevelBonus } from "../interfaces/FocusData";
import { FocusLevel } from "../interfaces/FocusLevel";
import { FocusLevelPick } from "../interfaces/FocusLevelPick";
import { SkillData } from "../interfaces/SkillData";
import { SkillLevelPick } from "../interfaces/SkillLevelPick";

import BackgroundsJSON from "../lookups/Backgrounds.json";
import SkillsJSON from "../lookups/SkillsAll.json";

import { getAllSkills, getSkillsByType } from "./SkillUtilities";
import { Lookups } from '../lookups/Lookups';
import { applyValidation } from '../components/CharacterDesignerValidation';
import { nameLength } from "../lookups/Enums";

import { formatAttack, formatDieRoll, PlusMinus } from "../utilities/Utilities";

import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";
import uniqid from 'uniqid';
import { WeaponStat } from '../interfaces/WeaponStat';
import { GearPackData } from '../interfaces/GearPackData';
import { VehicleStat } from '../interfaces/VehicleStat';
import { AttributeModifierPick } from '../interfaces/AttributeModifierPick';
import { DroneStat } from '../interfaces/DroneStat';
import { GearModData } from '../interfaces/GearModData';
import { RobotStat } from '../interfaces/RobotStat';

const lookups = Lookups.getInstance();

export const checkAndValidateCharacterTraits = (charTraits: CharacterTraits): CharacterTraits => {
    charTraits = ensureDefaultBackgroundSkillLevelIsAlwaysSelected(charTraits);
    charTraits = ensureDefaultClassFocusSkillLevelsAreAlwaysSelected(charTraits, lookups.focuses, lookups.skills);
    charTraits = ensureDefaultFreeFocusSkillLevelsAreAlwaysSelected(charTraits, lookups.focuses, lookups.skills);
    charTraits = ensureDefaultFreeFocusAttributeModifierIsAlwaysSelected(charTraits, lookups.focuses, lookups.skills);
    charTraits = ensureDefaultLevelFocusSkillLevelsAreAlwaysSelected(charTraits, lookups.focuses, lookups.skills);
    charTraits = ensureNoDuplicateFocuses(charTraits);
    charTraits = ensureAllLevelTwoFocusesHaveLevelOnePreqrequisite(charTraits);
    charTraits = ensureAllLevelOnePsychicTechniquesHaveLevelOneSkillPrerequisite(charTraits);
    charTraits = ensurePsychicSkillsHaveCoreTechnique(charTraits);
    charTraits = ensureWildTalentDataHasPrerequistiteFocus(charTraits);
    charTraits = ensurePsychicTechniquePicksHaveAtLeastLevel1PsychicSkills(charTraits);
    charTraits = removeTelekineticArmoryItemsIfDoesNotKnowTelekineticArmory(charTraits);
    charTraits = removeFreeVehicleIfNotAVIVehicleBot(charTraits);
    charTraits = removeBuiltInModIfNotAnVIRobot(charTraits);
    charTraits = removeBodyArsenalItemsIfDoesNotHaveBodyArsenalArray(charTraits);
    charTraits = removeNaturalDefensesWeaponsItemsIfDoesNotHaveNaturalDefensesWeapons(charTraits);
    charTraits = setOrigin(charTraits);
    charTraits = removeUniqueGiftsIfDoesNotHaveFoci(charTraits);
    charTraits = removeAnyAlienFocusThatNoLongerExists(charTraits);
    charTraits = applyValidation(charTraits, lookups.backgrounds, lookups.classes, lookups.skills, lookups.focuses);

    return charTraits;
}

export const ensureDefaultBackgroundSkillLevelIsAlwaysSelected = (charTraits: CharacterTraits): CharacterTraits => {
    // Ensure that the default skill level pick for the selected background is always selected (if any)
    const backgroundsData = BackgroundsJSON;
    const skillsData = SkillsJSON;
    let theBackground = backgroundsData.find((bg) => bg.background === charTraits.background.backgroundName);
    if (theBackground) {
        const freeSkill = theBackground.freeSkill;
        const allSkills = getAllSkills(skillsData).map((s) => s.skill);
        if (allSkills.indexOf(freeSkill) !== -1) {
            if (allSkills.indexOf(theBackground.freeSkill) !== -1) {
                const matchingSkill = charTraits.background.backgroundSkillLevelPicks.find((bslp) => bslp.skill === freeSkill);
                if (!matchingSkill) {
                    const freePick: SkillLevelPick = { skill: freeSkill, levels: 1 };
                    charTraits.background.backgroundSkillLevelPicks = [freePick];
                }
            }
        }
    }
    return charTraits;
}

export const ensureDefaultClassFocusSkillLevelsAreAlwaysSelected = (charTraits: CharacterTraits, focusesData: FocusData[], skillsData: SkillData[]): CharacterTraits => {
    // Ensure that the default skill level pick for a class focus level skill is always selected (if any)
    // if there is only one default skill choice (i.e. not 'AnyCombatSkill' or 'Punch|Stab') and the skill is not already at level-1.

    charTraits.levelOne.classes.forEach((c, classIndex) => {
        const theFocusData = focusesData.find((f) => f.focus === c.classFocusLevelPick.focus);
        if (theFocusData) {
            theFocusData.levels[0].bonuses.forEach((b: LevelBonus) => {
                if (b.type === "bonusSkill") {
                    const skillName = b.skill;
                    if (skillName) {
                        const allSkills = getAllSkills(skillsData).map((s) => s.skill);
                        if (allSkills.indexOf(skillName) !== -1) {
                            const matchingSkill = c.classFocusLevelPick.skillLevelPicks.find((slp) => slp.skill === skillName);
                            let currentSkillLevel = 0;

                            // Get derived skills at this point in chargen process:
                            const charDerivedStatsAtThisClass = new CharacterDerivedStats(charTraits);
                            charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.Classes, classIndex);

                            const currentSkill = charDerivedStatsAtThisClass.skillLevels.find((ds) => ds.skill === skillName);
                            if (currentSkill && currentSkill.level) { currentSkillLevel = currentSkill.level - 1 }

                            if (!matchingSkill && currentSkillLevel < 1) {
                                const freePick: SkillLevelPick = { skill: skillName, levels: 1 };
                                c.classFocusLevelPick.skillLevelPicks = [freePick];
                            }

                            if (matchingSkill && currentSkillLevel > 1) {
                                c.classFocusLevelPick.skillLevelPicks = c.classFocusLevelPick.skillLevelPicks.filter((slp) => slp.skill !== skillName);
                            }
                        }
                    }
                }
            })
        }
    })

    return charTraits;
}

export const ensureDefaultFreeFocusSkillLevelsAreAlwaysSelected = (charTraits: CharacterTraits, focusesData: FocusData[], skillsData: SkillData[]): CharacterTraits => {
    // Ensure that the default skill level pick for the free focus level skill is always selected (if any)
    // if there is only one default skill choice (i.e. not 'AnyCombatSkill' or 'Punch|Stab') and the skill is not already at level-1.

    const theFocusData = focusesData.find((f) => f.focus === charTraits.levelOne.freeFocusLevelPick.focus);
    if (theFocusData) {
        theFocusData.levels[0].bonuses.forEach((b: LevelBonus) => {
            if (b.type === "bonusSkill") {
                const skillName = b.skill;
                if (skillName) {
                    const allSkills = getAllSkills(skillsData).map((s) => s.skill);
                    if (allSkills.indexOf(skillName) !== -1) {
                        const matchingSkill = charTraits.levelOne.freeFocusLevelPick.skillLevelPicks.find((slp) => slp.skill === skillName);
                        let currentSkillLevel = 0;

                        // Get derived skills at this point in chargen process:
                        const charDerivedStatsAtFreeFocus = new CharacterDerivedStats(charTraits);
                        charDerivedStatsAtFreeFocus.calculateSkillLevels(CreationStep.FreeFocus);

                        const currentSkill = charDerivedStatsAtFreeFocus.skillLevels.find((ds) => ds.skill === skillName);
                        if (currentSkill && currentSkill.level) { currentSkillLevel = currentSkill.level - 1 }

                        if (!matchingSkill && currentSkillLevel < 1) {
                            const freePick: SkillLevelPick = { skill: skillName, levels: 1 };
                            charTraits.levelOne.freeFocusLevelPick.skillLevelPicks = [freePick];
                        }
                        if (matchingSkill && currentSkillLevel > 1) {
                            charTraits.levelOne.freeFocusLevelPick.skillLevelPicks = charTraits.levelOne.freeFocusLevelPick.skillLevelPicks.filter((slp) => slp.skill !== skillName);
                        }
                    }
                }
            }
        })
    }

    return charTraits;
}

export const ensureDefaultFreeFocusAttributeModifierIsAlwaysSelected = (charTraits: CharacterTraits, focusesData: FocusData[], skillsData: SkillData[]): CharacterTraits => {
    // If an Alien orogin focus grants an attribute modifier bonus, if there is onyl one choice of attribute bonus 
    // then always set it as selected.

    const theFocusData = focusesData.find((f) => f.focus === charTraits.levelOne.freeFocusLevelPick.focus);
    if (theFocusData) {
        theFocusData.levels[0].bonuses.forEach((b: LevelBonus) => {
            if (b.type === "attributeModifierBonus") {
                const attrIndexes = b.availableAttributeIndexes;
                if (attrIndexes) {
                    if (attrIndexes.length === 1) {
                        charTraits.levelOne.freeFocusLevelPick.attributeModifierPicks = [
                            {
                                attributeIndex: attrIndexes[0],
                                modifierAdjustment: 1,
                            }
                        ]
                    }
                }
            }
        })
    }

    return charTraits;
}

export const ensureDefaultLevelFocusSkillLevelsAreAlwaysSelected = (charTraits: CharacterTraits, focusesData: FocusData[], skillsData: SkillData[]): CharacterTraits => {
    // Ensure that the default skill level pick for the focus levels gained at level 2, 5, 7 and 10 skill is always selected (if any)
    // if there is only one default skill choice (i.e. not 'AnyCombatSkill' or 'Punch|Stab') and the skill is not already at level-1.

    charTraits.levels.forEach((lev) => {

        const levelFocus = lev.focusLevelPick;
        if (levelFocus) {

            const levelFocus = lev.focusLevelPick;
            if (levelFocus) {
                const theFocusData = focusesData.find((f) => f.focus === levelFocus.focus);
                if (theFocusData) {
                    theFocusData.levels[levelFocus.level - 1].bonuses.forEach((b: LevelBonus) => {
                        if (b.type === "bonusSkill") {
                            const skillName = b.skill;
                            if (skillName) {
                                const allSkills = getAllSkills(skillsData).map((s) => s.skill);
                                if (allSkills.indexOf(skillName) !== -1) {

                                    const levelFocusSkillPointsPick: SkillPointsPick = {
                                        skill: skillName,
                                        points: 3
                                    }
                                    levelFocus.skillPointsPicks = [levelFocusSkillPointsPick];

                                }
                            }
                        }
                    })
                }
            }
        }

    })

    return charTraits;
}

export const ensureNoDuplicateFocuses = (charTraits: CharacterTraits): CharacterTraits => {
    // If the user has selected a focus, then goes back earlier in the chargen process and picks the same focus, then must delete
    // the later duplicate.

    const blankFocus: FocusLevelPick = {
        focus: "",
        level: 0,
        skillLevelPicks: [],
        skillPointsPicks: [],
        grantsSkill: false,
        type: ""
    };

    // Get all focuses:
    const derivedStats = new CharacterDerivedStats(charTraits);
    derivedStats.calculateFocusLevels(CreationStep.AllSteps);
    const allFocuses = derivedStats.focusLevels;

    let checkedFocusLevels: FocusLevel[] = [];

    let duplicateCount = 0;

    allFocuses.forEach((f) => {

        duplicateCount = 0;

        const alreadyChecked = checkedFocusLevels.find((cfl) => cfl.focus === f.focus && cfl.level === f.level);
        if (!alreadyChecked) {

            // Check the class focuses:
            charTraits.levelOne.classes.forEach((c) => {
                if (c.classFocusLevelPick) {
                    const classFLP = c.classFocusLevelPick;
                    if (classFLP.focus === f.focus && classFLP.level === f.level) {
                        duplicateCount = duplicateCount + 1;
                        if (duplicateCount > 1) {
                            c.classFocusLevelPick = { ...blankFocus };
                        }
                    }
                }
            })

            // Check the free background focus:
            const freeFocus = charTraits.levelOne.freeFocusLevelPick;
            if (freeFocus) {
                if (freeFocus.focus === f.focus && freeFocus.level === f.level) {
                    duplicateCount = duplicateCount + 1;
                    if (duplicateCount > 1) {
                        charTraits.levelOne.freeFocusLevelPick = { ...blankFocus };
                    }
                }
            }

            // Check the character's focis from levels 2, 5, 7 and 10. 
            charTraits.levels.forEach((lev) => {
                if (lev.focusLevelPick) {
                    if (lev.focusLevelPick.focus === f.focus && lev.focusLevelPick.level === f.level) {
                        duplicateCount = duplicateCount + 1;
                        if (duplicateCount > 1) {
                            lev.focusLevelPick = { ...blankFocus };
                        }
                    }
                }
            })

            // Add the focus to the list of ones that I've checked.
            const checkedFocusLevel = { focus: f.focus, level: f.level, history: [] };
            checkedFocusLevels.push(checkedFocusLevel);
        }

    })

    return charTraits;
}

export const ensureAllLevelTwoFocusesHaveLevelOnePreqrequisite = (charTraits: CharacterTraits): CharacterTraits => {

    // Check if all level 2 focuses have their level 1 preqrequisite; if not, remove them.

    const blankFocus: FocusLevelPick = {
        focus: "",
        level: 0,
        skillLevelPicks: [],
        skillPointsPicks: [],
        grantsSkill: false,
        type: ""
    };

    // Get all focuses:
    const derivedStats = new CharacterDerivedStats(charTraits);
    derivedStats.calculateFocusLevels(CreationStep.AllSteps);
    const allFocuses = derivedStats.focusLevels;

    // Check the class focuses:
    charTraits.levelOne.classes.forEach((c) => {
        if (c.classFocusLevelPick) {
            if (c.classFocusLevelPick.level === 2) {
                const classFLP = c.classFocusLevelPick;
                const level1Focus = allFocuses.find((f) => f.focus === classFLP.focus && f.level === 1);
                if (!level1Focus) {
                    c.classFocusLevelPick = { ...blankFocus };
                }
            }
        }
    })

    // Check the free background focus:
    const freeFocus = charTraits.levelOne.freeFocusLevelPick;
    if (freeFocus) {
        if (freeFocus.level === 2) {
            const level1Focus = allFocuses.find((f) => f.focus === freeFocus.focus && f.level === 1);
            if (!level1Focus) {
                charTraits.levelOne.freeFocusLevelPick = { ...blankFocus };
            }
        }
    }

    // Check all the foci gained at levels 2, 5, 7, and 10.
    charTraits.levels.forEach((lev) => {
        const levelFocus = lev.focusLevelPick;
        if (levelFocus) {
            const level1Focus = allFocuses.find((f) => f.focus === levelFocus.focus && f.level === 1);
            if (!level1Focus) {
                lev.focusLevelPick = { ...blankFocus };
            }
        }
    });

    return charTraits;
}

export const ensureAllLevelOnePsychicTechniquesHaveLevelOneSkillPrerequisite = (charTraits: CharacterTraits): CharacterTraits => {
    // Check that each level-1 psychic technique has a matching level-1 psychic skill. If not, remove the technique.
    let updatedTechniques = [...charTraits.levelOne.psychicTechniquePicks];

    const getSkillsAtFreeSkillStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.FreeSkill);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const allCharactersSkills = getSkillsAtFreeSkillStep();
    const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
    const allCharactersPsychicSkills = allCharactersSkills.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));

    charTraits.levelOne.psychicTechniquePicks.forEach((pt) => {
        const matchingSkill = allCharactersPsychicSkills.find((s) => s.skill === pt.skill && s.level && s.level > 1);
        if (!matchingSkill) {
            // No prerequisite skill, so remove the techniques that require that skills
            updatedTechniques = updatedTechniques.filter((t) => t.skill !== pt.skill);
        }
    })

    charTraits.levelOne.psychicTechniquePicks = updatedTechniques;

    return charTraits;
}

export const ensurePsychicSkillsHaveCoreTechnique = (charTraits: CharacterTraits): CharacterTraits => {
    let updatedTechniques = [...charTraits.levelOne.psychicTechniquePicks];

    const getAllPsychicSkills = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.AllSteps, 100, 100, 100);
        const allCharactersSkills = charDerivedStatsAtThisClass.skillLevels;
        const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
        const allCharactersPsychicSkills = allCharactersSkills.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));
        return allCharactersPsychicSkills;
    }

    // remove all existing core techniques:
    updatedTechniques = updatedTechniques.filter((ut) => ut.level === 0);

    // add the core technique for each Psychic skill
    const allPsychicSkills = getAllPsychicSkills();
    allPsychicSkills.forEach((ps) => {
        // add the core technique for the skill
        const allTechniques = lookups.psychicTechniques;
        const theCoreTech = allTechniques.find((t) => t.skill === ps.skill);
        if (theCoreTech && theCoreTech.coreTechnique) {
            const coreTechnique: PsychicTechniquePick = {
                skill: ps.skill,
                technique: theCoreTech?.coreTechnique.name,
                level: 0,
                note: "Core technique gained when learned " + ps.skill + "-0",
                sourceType: "SkillLevel-" + ps.skill + "-0",
                pickIndex: 0
            }
            updatedTechniques.push(coreTechnique);
        }
    });

    const getSkillsAtCurrentLevel = (level: number) => {
        const charDerivedStatsAtThisLevel = new CharacterDerivedStats(charTraits);
        charDerivedStatsAtThisLevel.calculateSkillLevels(CreationStep.AllSteps, -1, -1, level, 100);
        return charDerivedStatsAtThisLevel.skillLevels;
    }

    // Check if character has the skill required for all techniques
    charTraits.levels.forEach((lev) => {
        const allCharactersSkills = getSkillsAtCurrentLevel(lev.level);
        const allCharactersPsychicSkills = allCharactersSkills.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));

        lev.skillPointSpends.forEach((sps) => {
            if (sps.psychicTechniquePicks) {
                let clonedPsychicTechniquePicks = [...sps.psychicTechniquePicks];
                sps.psychicTechniquePicks.forEach((ptp) => {
                    if (ptp) {
                        const reqSkill = allCharactersPsychicSkills.find((ps) => ps.skill === ptp.skill);
                        if (!reqSkill) {
                            clonedPsychicTechniquePicks = clonedPsychicTechniquePicks.filter((cp) => cp.skill !== ptp.skill);
                        } else {
                            if (reqSkill?.level) {
                                if ((reqSkill.level - 1) < ptp.level) {
                                    clonedPsychicTechniquePicks = clonedPsychicTechniquePicks.filter((cp) => cp.skill !== ptp.skill);
                                } else {
                                }
                            }
                        }
                    }
                })
                sps.psychicTechniquePicks = clonedPsychicTechniquePicks;
            }

        })

    });

    return charTraits;
}

export const ensureWildTalentDataHasPrerequistiteFocus = (charTraits: CharacterTraits): CharacterTraits => {

    const getFocuses = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(charTraits);
        charDerivedStatsAtThisClass.calculateFocusLevels(CreationStep.AllSteps);
        return charDerivedStatsAtThisClass.focusLevels;
    }
    const focusLevels = getFocuses();

    // Check if character has Wild Psychic Talent 1; if not, remove wild talent data
    const wildTalent1 = focusLevels.find((f) => f.focus === "Wild Psychic Talent" && f.level === 1);
    if (!wildTalent1) {
        charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline = "";
        charTraits.levelOne.wildTalentPicks.wildTalentTechnique1 = "";
        charTraits.levelOne.wildTalentPicks.wildTalentTechnique2 = "";
    }

    // Check if character has Wild Psychic Talent 2; if not, remove only levbel-2 wild talent technique
    const wildTalent2 = focusLevels.find((f) => f.focus === "Wild Psychic Talent" && f.level === 2);
    if (!wildTalent2) {
        charTraits.levelOne.wildTalentPicks.wildTalentTechnique2 = "";
    }

    return charTraits;

}

export const ensurePsychicTechniquePicksHaveAtLeastLevel1PsychicSkills = (charTraits: CharacterTraits): CharacterTraits => {
    // If character does not have any Psychic skills at least level 1 at this point, blank all their technique skill point spends

    const getAllPsychicSkills = (level: number, index: number) => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.AllSteps, 100, 100, level, index);
        const allCharactersSkills = charDerivedStatsAtThisClass.skillLevels;
        const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
        const allCharactersPsychicSkills = allCharactersSkills.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));
        return allCharactersPsychicSkills;
    }

    charTraits.levels.forEach((lev) => {

        lev.skillPointSpends.forEach((sps, index) => {

            if (sps.spendType === "learnTechnique") {

                const allPsychicSkills = getAllPsychicSkills(lev.level, index);
                const skillsOverLevel1 = allPsychicSkills.filter((ps) => ps.level && ps.level > 1);

                if (skillsOverLevel1.length === 0) {
                    sps.pointsSpent = 0;
                    sps.skillName = "";
                    sps.techniqueName = "";
                    sps.attributeName = "";
                    sps.pointType = "";
                }

            }

        })

    })

    return charTraits;
}


export const removeTelekineticArmoryItemsIfDoesNotKnowTelekineticArmory = (charTraits: CharacterTraits): CharacterTraits => {

    const charDerivedStats = new CharacterDerivedStats(charTraits);
    charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, 100, 100);

    if (!charDerivedStats.psychicTechniqueLevels.find((tl) => tl.technique === "Telekinetic Armory")) {
        // remove the TK items. 
        const packDetails = lookups.gearPacks.find((gp) => gp.name === "TK Armory Items");
        if (packDetails) {
            packDetails.items.forEach((i) => {
                charTraits.gear.equipment = [...charTraits.gear.equipment.filter((g) => g.id !== i.id)];
            })
        }
    }

    return charTraits;
}

export const removeNaturalDefensesWeaponsItemsIfDoesNotHaveNaturalDefensesWeapons = (charTraits: CharacterTraits): CharacterTraits => {

    const charDerivedStats = new CharacterDerivedStats(charTraits);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);

    let hasNaturalDefensesWeapons = false;
    const alienFocus = charDerivedStats.focusLevels.find((f) => f.focus.indexOf("Alien -") !== -1);
    if (alienFocus) {
        const lookups = Lookups.getInstance();
        const selectedFocusData = lookups.focuses.find((fd) => fd.focus === alienFocus.focus);
        if (selectedFocusData?.benefits) {
            if (selectedFocusData.benefits.find((ben) => ben.benefit === "natDefWeapons")) {
                hasNaturalDefensesWeapons = true;
            }
        }
    }

    if (!hasNaturalDefensesWeapons) {
        charTraits.gear.equipment = charTraits.gear.equipment.filter((i) => i.name.indexOf("Natural Weapons (Alien)") === -1);
    }

    return charTraits;
}

export const removeBodyArsenalItemsIfDoesNotHaveBodyArsenalArray = (charTraits: CharacterTraits): CharacterTraits => {

    const hasBodyArsenal = charTraits.gear.equipment.find((g) => g.name === "Body Arsenal Array");
    if (!hasBodyArsenal) {
        charTraits.gear.equipment = charTraits.gear.equipment.filter((i) => i.name.indexOf("Body Arsenal Weapon") === -1);
    }

    return charTraits;
}

export const removeFreeVehicleIfNotAVIVehicleBot = (charTraits: CharacterTraits): CharacterTraits => {

    const charDerivedStats = new CharacterDerivedStats(charTraits);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, 100, 100);

    if (!charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Vehicle Bot")) {
        // remove any free vehicle and set vehicleBody to blank.
        if (charTraits.levelOne.vehicleBody) {
            charTraits.gear.equipment = charTraits.gear.equipment.filter((g) => g.name[0] !== charTraits.levelOne.vehicleBody && g.cost !== 0);
            charTraits.levelOne.vehicleBody = undefined;
        }
    }

    return charTraits;
}

export const removeBuiltInModIfNotAnVIRobot = (charTraits: CharacterTraits): CharacterTraits => {
    const charDerivedStats = new CharacterDerivedStats(charTraits);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, 100, 100);

    const isVI = charDerivedStats.focusLevels.find((f) => f.focus.indexOf("VI ") !== -1);
    if (!isVI) {
        charTraits.gear.equipment.forEach((e) => {
            if (e.mods) {
                e.mods = e.mods?.filter((m) => m.id !== "BT1");
            }
        })
    }

    return charTraits;
}

export const setOrigin = (charTraits: CharacterTraits): CharacterTraits => {

    const charDerivedStats = new CharacterDerivedStats(charTraits);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, 100, 100);

    charTraits.basicTraits.origin = "Human";

    if (charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Vehicle Bot")) {
        charTraits.basicTraits.origin = "VI Vehicle Bot";
    } else if (charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Android")) {
        charTraits.basicTraits.origin = "VI Android";
    } else if (charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Worker Bot")) {
        charTraits.basicTraits.origin = "VI Worker Bot";
    }

    let isAlien = charDerivedStats.focusLevels.find((f) => f.focus.indexOf("Alien -") !== -1);
    if (isAlien) {
        charTraits.basicTraits.origin = isAlien.focus.replace("Alien - ", "");
    }

    return charTraits;
}

const removeAnyAlienFocusThatNoLongerExists = (charTraits: CharacterTraits) => {
    const charDerivedStats = new CharacterDerivedStats(charTraits);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, 100, 100);

    const alienFocus = charDerivedStats.focusLevels.find((af) => af.focus.indexOf("Alien -") !== -1);
    if (alienFocus) {
        const matchingFocus = lookups.focuses.find((f) => f.focus === alienFocus.focus);
        if (!matchingFocus) {
            charTraits.levelOne.freeFocusLevelPick = {
                focus: "",
                level: 0,
                skillLevelPicks: [],
                skillPointsPicks: [],
                grantsSkill: false,
                type: ""
            };
        }
    }

    return charTraits;
}

const removeUniqueGiftsIfDoesNotHaveFoci = (charTraits: CharacterTraits) => {
    const charDerivedStats = new CharacterDerivedStats(charTraits);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, 100, 100);

    if (!charDerivedStats.focusLevels.find((fl) => fl.focus === "Unique Gift" && fl.level === 1)) {
        charTraits.basicTraits.uniqueGift1 = "";
    }

    if (!charDerivedStats.focusLevels.find((fl) => fl.focus === "Unique Gift" && fl.level === 2)) {
        charTraits.basicTraits.uniqueGift2 = "";
    }

    return charTraits;
}

const clipToLength = (txt: string, length: number): string => {
    return txt.substring(0, length);
}

export const onSetName = (name: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.basicTraits.name = clipToLength(name, nameLength);
    onSetCharacterTraits(updatedTraits);
}

export const onSetGoal = (goal: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.basicTraits.goal = goal;
    onSetCharacterTraits(updatedTraits);
}

export const onSetIsPublic = (isPublic: boolean, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.basicTraits.isPublic = isPublic;
    onSetCharacterTraits(updatedTraits);
}

export const onSelectAttributeAssignMethod = (method: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void): void => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.attributeTraits.method = method;
    onSetCharacterTraits(updatedTraits);

    if (method === "roll") { rollRandomAttributes(characterTraits, onSetCharacterTraits); }
    if (method === "rollAndReorder") { rollRandomAttributesForRollAndAssign(characterTraits, onSetCharacterTraits); }

}

export const onSetIncludeNonstandardMethods = (include: boolean, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void): void => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.attributeTraits.includeNonstandardMethods = include;
    onSetCharacterTraits(updatedTraits);
}

const rollRandomAttributes = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    const d6 = () => Math.floor(Math.random() * 6) + 1;

    const randomAttributes: number[] = [];
    for (let a = 0; a < 6; a++) {
        const score = d6() + d6() + d6();
        randomAttributes.push(score);
    }
    let updatedTraits = { ...characterTraits };
    updatedTraits.attributeTraits.attributeScores = randomAttributes;
    updatedTraits.attributeTraits.originalScores = randomAttributes;

    onSetCharacterTraits(updatedTraits);
}

const rollRandomAttributesForRollAndAssign = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    const d6 = () => Math.floor(Math.random() * 6) + 1;

    const randomAttributes: number[] = [];
    for (let a = 0; a < 6; a++) {
        const score = d6() + d6() + d6();
        randomAttributes.push(score);
    }

    let updatedTraits = { ...characterTraits };
    updatedTraits.attributeTraits.randomScores = randomAttributes;
    updatedTraits.attributeTraits.attributeScores = [0, 0, 0, 0, 0, 0];
    updatedTraits.attributeTraits.originalScores = [0, 0, 0, 0, 0, 0];
    updatedTraits.attributeTraits.attributeNumberSetTo14 = -1;
    updatedTraits.attributeTraits.attributeValueThatWasReplacedWith14 = -1;

    onSetCharacterTraits(updatedTraits);
}

export const onSelectAttribute = (attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    let updatedTraits = { ...characterTraits };


    // Update the selected attributes:
    let updatedAttributes = [...characterTraits.attributeTraits.attributeScores];
    updatedAttributes[attributeNumber] = attributeValue;
    updatedTraits.attributeTraits.attributeScores = updatedAttributes;

    if (alsoSetAsOriginalScore) {
        updatedTraits.attributeTraits.originalScores = updatedAttributes;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetAttributeTo14 = (attributeNumber: number, attributeValue: number, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    // Get the score that is been replaced
    const scoreThatIsBeingReplacedWith14 = characterTraits.attributeTraits.attributeScores[attributeNumber];

    // Reset all scores to originals
    let updatedAttributes: number[] = [];
    if (characterTraits.attributeTraits.method === "roll") {
        updatedAttributes = [...characterTraits.attributeTraits.originalScores];
    }
    if (characterTraits.attributeTraits.method === "rollAndReorder") {
        updatedAttributes = [...characterTraits.attributeTraits.originalScores];
    }

    // Update the selected attribute to 14
    updatedAttributes[attributeNumber] = 14;

    let updatedTraits = { ...characterTraits };

    updatedTraits.attributeTraits.attributeScores = updatedAttributes;
    updatedTraits.attributeTraits.attributeNumberSetTo14 = attributeNumber;
    updatedTraits.attributeTraits.attributeValueThatWasReplacedWith14 = scoreThatIsBeingReplacedWith14;

    onSetCharacterTraits(updatedTraits);
}

export const onRerollAttributes = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.attributeTraits.rerolls = updatedTraits.attributeTraits.rerolls + 1;
    onSetCharacterTraits(updatedTraits);

    if (characterTraits.attributeTraits.method === "roll") {
        rollRandomAttributes(characterTraits, onSetCharacterTraits);
    }

    if (characterTraits.attributeTraits.method === "rollAndReorder") {
        rollRandomAttributesForRollAndAssign(characterTraits, onSetCharacterTraits);
    }

}

export const onResetScores = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };

    updatedTraits.attributeTraits.attributeScores = [...updatedTraits.attributeTraits.originalScores];
    updatedTraits.attributeTraits.attributeNumberSetTo14 = -1;
    updatedTraits.attributeTraits.attributeValueThatWasReplacedWith14 = -1;

    onSetCharacterTraits(updatedTraits);
}

export const onSelectBackground = (background: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    updatedTraits.background.backgroundName = background;
    updatedTraits.background.backgroundSkillLevelPicks = [];
    if (background === "Custom") {
        updatedTraits.background.method = "assign";
    } else {
        updatedTraits.background.method = "";
    }
    onSetCharacterTraits(updatedTraits);
}

export const onSelectBackgroundMethod = (method: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void): void => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.background.method = method;
    onSetCharacterTraits(updatedTraits);
}

export const onSelectQuickSkills = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const backgroundsData = BackgroundsJSON;
    const selectedBackground = backgroundsData.find((bg) => bg.background === characterTraits.background.backgroundName);
    if (selectedBackground) {
        const quickSkills: string[] = selectedBackground.quickSkills.split(", ")
        let skillLevelPicks: SkillLevelPick[] = [];
        quickSkills.forEach((s) => {
            let skillname = s;
            if (skillname === "AnyCombatSkill") {
                if (selectedBackground.defaultCombatSkill) {
                    skillname = selectedBackground.defaultCombatSkill;
                } else {
                    skillname = "Shoot";
                }
            }
            skillLevelPicks.push({ skill: skillname, levels: 1 } as SkillLevelPick);
        });

        let updatedTraits = { ...characterTraits };
        updatedTraits.background.backgroundSkillLevelPicks = skillLevelPicks;
        onSetCharacterTraits(updatedTraits);
    }
}

export const onSelectBackgroundRollTable = (table: string, rollNum: number, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const tableRolls = characterTraits.background.tableRolls;
    if (tableRolls[rollNum]) {
        tableRolls[rollNum].table = table;

        const backgroundsData = BackgroundsJSON;
        const skillsData = SkillsJSON;
        const selectedBackground = backgroundsData.find((bg) => bg.background === characterTraits.background.backgroundName);

        if (table === "growth" || table === "learning") {
            let options: string[] = [];
            if (selectedBackground) {
                if (table === "growth") { options = selectedBackground?.growthTable.split(", "); }
                if (table === "learning") { options = selectedBackground?.learningTable.split(", "); }
            }
            if (options) {
                const randomOptionIndex = Math.floor(Math.random() * options.length);
                const randomOption = options[randomOptionIndex];
                tableRolls[rollNum].roll = randomOptionIndex + 1;
                tableRolls[rollNum].result = randomOption;
                if (randomOption.indexOf("+") === -1) {

                    // Roll grants a skill
                    const theSkill = skillsData.find((s) => s.skill === randomOption);
                    if (theSkill) {
                        // Check if the skill is at the limit:
                        const charDerivedStatsAtThisClass = new CharacterDerivedStats(characterTraits);
                        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.Background, -1, rollNum);
                        const thisDerivedSkill = charDerivedStatsAtThisClass.skillLevels.find((s) => s.skill === randomOption)
                        if (thisDerivedSkill && (!thisDerivedSkill.level || thisDerivedSkill.level < 2)) {
                            const newSkillLevelPick: SkillLevelPick = { skill: randomOption, levels: 1 };
                            tableRolls[rollNum].skillLevelPicks = [newSkillLevelPick];
                        } else {
                            tableRolls[rollNum].skillLevelPicks = [];
                        }

                    }
                } else {
                    // Roll grants an attribute bonus
                }
            }
        }

    }

    let updatedTraits = { ...characterTraits };
    updatedTraits.background.tableRolls = tableRolls;
    onSetCharacterTraits(updatedTraits);
}

export const onSetHomeworld = (homeworld: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.background.homeworld = homeworld;
    onSetCharacterTraits(updatedTraits);
}

export const onSetLanguages = (languages: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };
    updatedTraits.background.languages = languages;
    onSetCharacterTraits(updatedTraits);
}

export const onSelectClass = (selectedClasses: string[], characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    let isAdvent = false;
    let classes: ClassTrait[] = [];

    if (selectedClasses.length > 0) {
        isAdvent = selectedClasses.length > 1;
        selectedClasses.forEach((sc: string) => {
            const newClass: ClassTrait = {
                className: sc,
                classSkillPicks: [],
                classFocusLevelPick: {
                    focus: "",
                    level: 0,
                    skillLevelPicks: [],
                    skillPointsPicks: [],
                    grantsSkill: false,
                    type: "",
                },
                validationCodes: [],
            }
            classes.push(newClass);
        });
    };

    let updatedTraits = { ...characterTraits };
    updatedTraits.levelOne.isAdventurer = isAdvent;
    updatedTraits.levelOne.classes = classes;
    updatedTraits.levels.forEach((lev) => {
        lev.validationCodes = [];
        lev.skillPointSpends = [{ spendType: "", skillName: "", attributeName: "", techniqueName: "", pointType: "", pointsSpent: 0, validationCodes: [], psychicTechniquePicks: [] }]
    })
    onSetCharacterTraits(updatedTraits);
}

export const onSetFocusLevel = (focusName: string, focusLevel: number, traitToUpdate: string, focusesData: FocusData[], characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let type = "";
    let skillLevelPicks: SkillLevelPick[] = [];
    let skillPointsPicks: SkillPointsPick[] = [];
    let grantsSkill = false;
    let bonusSkill = "";

    const lookupFocusLevel = focusLevel - 1;

    const thisFocus = focusesData.find((f) => f.focus === focusName);
    if (thisFocus) {
        if (thisFocus.levels[lookupFocusLevel] && thisFocus.levels[lookupFocusLevel].bonuses && thisFocus.levels[lookupFocusLevel].bonuses.length > 0) {
            type = thisFocus?.type;
            const hasSkillBonus = thisFocus.levels[lookupFocusLevel].bonuses.find((b) => b.type === "bonusSkill");
            if (hasSkillBonus !== undefined && hasSkillBonus.skill) {
                grantsSkill = true;
                bonusSkill = hasSkillBonus.skill;
            }
        }
    }

    const newFocusLevelPick: FocusLevelPick = { focus: focusName, level: focusLevel, type, skillLevelPicks, skillPointsPicks, grantsSkill };

    let updatedTraits = { ...characterTraits };

    if (traitToUpdate.indexOf("levelOne.classes.classFocusPick") !== -1) {
        const className = traitToUpdate.split("/")[1];
        let theClass = updatedTraits.levelOne.classes.find((c) => c.className === className);
        if (theClass) {
            theClass.classFocusLevelPick = newFocusLevelPick;
        }
    } else if (traitToUpdate === "levelOne.freeFocusPickLevel") {
        updatedTraits.levelOne.freeFocusLevelPick = newFocusLevelPick;
        addNaturalDefencesWeapons(focusName, updatedTraits);
    } else if (traitToUpdate.indexOf("level.levelFocusPick") !== -1) {
        const level = parseInt(traitToUpdate.split("/")[1]);
        const theLevel = updatedTraits.levels[level - 2];
        if (theLevel) {
            const theFocusPick = theLevel.focusLevelPick;
            if (theFocusPick) {
                // Remove any skillPointSpends of the point type granted by the focus we are about to remove.
                if (theFocusPick.focus !== focusName) {
                    const skillPointsPick = theFocusPick.skillPointsPicks;
                    if (skillPointsPick && skillPointsPick.length > 0) {
                        const skill = skillPointsPick[0].skill + "_skill";
                        updatedTraits.levels.forEach((lev) => {
                            const theSkillPointSpends = lev.skillPointSpends;
                            if (theSkillPointSpends) {
                                lev.skillPointSpends = lev.skillPointSpends.filter((sps) => sps.pointType !== skill);
                            }
                        })
                    }
                }
            }

            // update the focus level. 
            theLevel.focusLevelPick = newFocusLevelPick;

            // Add an automatic SkillPointSpend for the skill granted by the focus (if any), if not a multi-skill choice.

            if (bonusSkill !== "") {
                if (bonusSkill.indexOf("Any") === -1 && bonusSkill.indexOf("|") === -1) {
                    const skillPointSpend: SkillPointSpend = {
                        spendType: "improveSkill",
                        skillName: bonusSkill,
                        attributeName: "",
                        techniqueName: "",
                        pointType: bonusSkill + "_skill",
                        pointsSpent: 3,
                        validationCodes: [],
                        psychicTechniquePicks: []
                    };
                    theLevel.skillPointSpends.splice(-1, 0, skillPointSpend);
                }
            }

        }
    } else {
        throw new Error("traitToUpdate_Focuses not implemented");
    }

    onSetCharacterTraits(updatedTraits);
}

const addNaturalDefencesWeapons = (focusName: string, updatedTraits: CharacterTraits) => {
    // If is Alien focus and has 'Natural Defenses (Weapons)' add the natural weapon to equipment
    if (focusName.indexOf("Alien -") !== -1) {
        const lookups = Lookups.getInstance();
        const selectedFocusData = lookups.focuses.find((fd) => fd.focus === focusName);
        if (selectedFocusData?.benefits) {
            if (selectedFocusData.benefits.find((ben) => ben.benefit === "natDefWeapons")) {
                updatedTraits = addNaturalDefensesWeaponsItems(updatedTraits);
            }
        }
    }
}

export const onSelectSkillLevelPick = (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    let traitSkillLevelPicks: SkillLevelPick[] = [];
    let traitSkillPointsPicks: SkillPointsPick[] = [];
    let theSkillLevelPick: SkillLevelPick | undefined;

    // Get the skill levels trait that will be updated. 

    if (traitToUpdate === "background.backgroundSkillLevelPicks") {
        traitSkillLevelPicks = [...characterTraits.background.backgroundSkillLevelPicks];
    }
    else if (traitToUpdate.indexOf("background.tableRolls.skillLevelPicks") !== -1) {
        const rollNum: number = parseInt(traitToUpdate.split("/")[1]);
        let theTableRoll = characterTraits.background.tableRolls[rollNum];
        if (theTableRoll) {
            traitSkillLevelPicks = theTableRoll.skillLevelPicks;
        }
    }
    else if (traitToUpdate.indexOf("levelOne.classes.classSkillPicks") !== -1) {
        const className = traitToUpdate.split("/")[1];
        let theClass = characterTraits.levelOne.classes.find((c) => c.className === className);
        if (theClass) {
            traitSkillLevelPicks = [...theClass.classSkillPicks];
        }
    }
    else if (traitToUpdate.indexOf("levelOne.classes.classFocusLevelPick.skillLevelPicks") !== -1) {
        const className = traitToUpdate.split("/")[1];
        let theClass = characterTraits.levelOne.classes.find((c) => c.className === className);
        if (theClass) {
            if (theClass.classFocusLevelPick && theClass.classFocusLevelPick.grantsSkill) {
                traitSkillLevelPicks = theClass.classFocusLevelPick.skillLevelPicks;
            } else {
                traitSkillLevelPicks = [];
            }
        }
    }
    else if (traitToUpdate === "levelOne.freeFocusPickLevel.skillLevelPicks") {
        if (characterTraits.levelOne.freeFocusLevelPick.grantsSkill) {
            traitSkillLevelPicks = characterTraits.levelOne.freeFocusLevelPick.skillLevelPicks;
        } else {
            traitSkillLevelPicks = [];
        }
    }
    else if (traitToUpdate === "levelOne.freeSkillLevelPick") {
        traitSkillLevelPicks = characterTraits.levelOne.freeSkillLevelPicks;
    }
    else if (traitToUpdate.indexOf("level.levelFocusPick") !== -1) {
        const level = parseInt(traitToUpdate.split("/")[1]);
        const focusLevel = characterTraits.levels[level - 2].focusLevelPick;
        if (focusLevel) {
            traitSkillPointsPicks = focusLevel.skillPointsPicks;
        }
    }
    else {
        throw new Error("traitToUpdate not found");
    }

    // Handling for Skill Level Picks (up to level 1):
    theSkillLevelPick = traitSkillLevelPicks.find((slp) => slp.skill === skillName);

    // Set the skill level trait.

    if (selected) {
        if (theSkillLevelPick) {
            theSkillLevelPick.levels = skillLevelPicks;
        } else {
            const newPick: SkillLevelPick = { skill: skillName, levels: skillLevelPicks };
            if (!singleSkillOnly) {
                traitSkillLevelPicks.push(newPick);
            }
            else {
                traitSkillLevelPicks = [newPick];
            }
        }
    }

    if (!selected) {
        if (theSkillLevelPick) {
            if (theSkillLevelPick.levels === 2) {
                theSkillLevelPick.levels = 1;
            }
            else if (theSkillLevelPick.levels === 1) {
                traitSkillLevelPicks = traitSkillLevelPicks.filter((slp) => slp.skill !== skillName);
            }
        }
    }

    // Handling for Skill Points Picks (level 2 and up)
    const theSkillPointsPick = traitSkillPointsPicks.find((slp) => slp.skill === skillName);

    if (selected) {
        if (theSkillPointsPick) {
            theSkillPointsPick.points = skillLevelPicks;
        } else {
            const newPick: SkillPointsPick = { skill: skillName, points: 3 };
            if (!singleSkillOnly) {
                traitSkillPointsPicks.push(newPick);
            }
            else {
                traitSkillPointsPicks = [newPick];
            }
        }

        const level = parseInt(traitToUpdate.split("/")[1]);
        if (characterTraits.levels[level - 2]) {
            if (characterTraits.levels[level - 2].skillPointSpends) {
                // Remove any existing skill point spend.
                characterTraits.levels[level - 2].skillPointSpends = characterTraits.levels[level - 2].skillPointSpends.filter((sps) => sps.pointType.indexOf("_skill") === -1);

                // Add the SkillPointSpend
                const skillPointSpend: SkillPointSpend = {
                    spendType: "improveSkill",
                    skillName: skillName,
                    attributeName: "",
                    techniqueName: "",
                    pointType: skillName + "_skill",
                    pointsSpent: 3,
                    validationCodes: [],
                    psychicTechniquePicks: []
                }
                characterTraits.levels[level - 2].skillPointSpends.splice(-1, 0, skillPointSpend);
            }
        }

    }

    if (!selected) {
        if (theSkillPointsPick) {
            traitSkillPointsPicks = traitSkillPointsPicks.filter((slp) => slp.skill !== skillName);

            // Remove any existing skill point spend.
            const level = parseInt(traitToUpdate.split("/")[1]);
            characterTraits.levels[level - 2].skillPointSpends = characterTraits.levels[level - 2].skillPointSpends.filter((sps) => sps.pointType.indexOf("_skill") === -1);
        }
    }

    // Update change to skill level.

    const updatedTraits = { ...characterTraits };

    if (traitToUpdate === "background.backgroundSkillLevelPicks") {
        updatedTraits.background.backgroundSkillLevelPicks = traitSkillLevelPicks;
    }
    else if (traitToUpdate.indexOf("background.tableRolls.skillLevelPicks") !== -1) {
        const rollNum: number = parseInt(traitToUpdate.split("/")[1]);
        let theTableRoll = characterTraits.background.tableRolls[rollNum];
        if (theTableRoll) {
            theTableRoll.skillLevelPicks = traitSkillLevelPicks;
        }
    }
    else if (traitToUpdate.indexOf("levelOne.classes.classSkillPicks") !== -1) {
        const className = traitToUpdate.split("/")[1];
        let theClass = updatedTraits.levelOne.classes.find((c) => c.className === className);
        if (theClass) {
            theClass.classSkillPicks = traitSkillLevelPicks;
        }
    }
    else if (traitToUpdate.indexOf("levelOne.classes.classFocusLevelPick.skillLevelPicks") !== -1) {
        const className = traitToUpdate.split("/")[1];
        let theClass = updatedTraits.levelOne.classes.find((c) => c.className === className);
        if (theClass) {
            if (theClass.classFocusLevelPick) {
                if (theClass.classFocusLevelPick.grantsSkill) {
                    theClass.classFocusLevelPick.skillLevelPicks = traitSkillLevelPicks;
                }
            }
        }
    }
    else if (traitToUpdate === "levelOne.freeFocusPickLevel.skillLevelPicks") {
        if (updatedTraits.levelOne.freeFocusLevelPick.grantsSkill) {
            updatedTraits.levelOne.freeFocusLevelPick.skillLevelPicks = traitSkillLevelPicks;
        }
    }
    else if (traitToUpdate === "levelOne.freeSkillLevelPick") {
        updatedTraits.levelOne.freeSkillLevelPicks = traitSkillLevelPicks;
    }
    else if (traitToUpdate.indexOf("level.levelFocusPick") !== -1) {
        const level = parseInt(traitToUpdate.split("/")[1]);
        const levelFocus = updatedTraits.levels[level - 2].focusLevelPick;
        if (levelFocus) {
            levelFocus.skillPointsPicks = traitSkillPointsPicks;
        }
    }
    else {
        throw new Error("traitToUpdate not found");
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectAttributeScoreBonusPick = (attributeIndex: number, attributeScoreBonus: number, selected: boolean, traitToUpdate: string, singleAttributeOnly: boolean, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    let traitAttributeScoreBonuses: AttributeScoreBonus[] = [];
    let theAttributeScoreBonus: AttributeScoreBonus | undefined;

    // Get the attribute levels trait that will be updated. 

    if (traitToUpdate.indexOf("background.tableRolls.attributeScoreBonuses") !== -1) {
        const rollNum: number = parseInt(traitToUpdate.split("/")[1]);
        let theTableRoll = characterTraits.background.tableRolls[rollNum];
        if (theTableRoll) {
            traitAttributeScoreBonuses = theTableRoll.attributeScoreBonuses;
        }
    }
    else {
        throw new Error("traitToUpdate not found");
    }

    theAttributeScoreBonus = traitAttributeScoreBonuses.find((asb) => asb.attributeNumber === attributeIndex);

    // Set the attribute level trait.

    if (selected) {
        if (theAttributeScoreBonus) {
            theAttributeScoreBonus.bonus = attributeScoreBonus;
        } else {
            const newPick: AttributeScoreBonus = { attributeNumber: attributeIndex, bonus: attributeScoreBonus };
            if (!singleAttributeOnly) {
                traitAttributeScoreBonuses.push(newPick);
            }
            else {
                traitAttributeScoreBonuses = [newPick];
            }
        }
    }

    if (!selected) {
        if (theAttributeScoreBonus) {
            if (theAttributeScoreBonus.bonus === 2) {
                theAttributeScoreBonus.bonus = 1;
            }
            else if (theAttributeScoreBonus.bonus === 1) {
                traitAttributeScoreBonuses = traitAttributeScoreBonuses.filter((asb) => asb.attributeNumber !== attributeIndex);
            }
        }
    }

    // Update change to attribute level

    const updatedTraits = { ...characterTraits };

    if (traitToUpdate.indexOf("background.tableRolls.attributeScoreBonuses") !== -1) {
        const rollNum: number = parseInt(traitToUpdate.split("/")[1]);
        let theTableRoll = characterTraits.background.tableRolls[rollNum];
        if (theTableRoll) {
            theTableRoll.attributeScoreBonuses = traitAttributeScoreBonuses;
        }
    }
    else {
        throw new Error("traitToUpdate not found");
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectAttributeModifierBonusPick = (attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    // Currently only implemented for levelOne.freeFocusPickLevel.attributeModifierPicks, for when picking the 'VI Worker Bot' focus. 

    // Get the attribute levels trait that will be updated. 

    let attributeModifierPicks: AttributeModifierPick[] = [];
    if (traitToUpdate === "levelOne.freeFocusPickLevel.attributeModifierPicks") {
        if (characterTraits.levelOne.freeFocusLevelPick.attributeModifierPicks) {
            attributeModifierPicks = characterTraits.levelOne.freeFocusLevelPick.attributeModifierPicks;
        };
    }
    else {
        throw new Error("traitToUpdate not found");
    }

    const theAttributeModifierPick = attributeModifierPicks.find((amp) => amp.attributeIndex === attributeIndex);

    // // Set the attribute level trait.

    if (selected) {
        if (theAttributeModifierPick) {
            theAttributeModifierPick.modifierAdjustment = attributeModifierBonus;
        } else {
            const newPick: AttributeModifierPick = { attributeIndex: attributeIndex, modifierAdjustment: attributeModifierBonus };
            attributeModifierPicks = [newPick];
        }
    }

    if (!selected) {
        attributeModifierPicks = attributeModifierPicks.filter((amp) => amp.attributeIndex !== attributeIndex);
    }


    // Update change to attribute modifier level
    const updatedTraits = { ...characterTraits };

    if (traitToUpdate.indexOf("levelOne.freeFocusPickLevel.attributeModifierPicks") !== -1) {
        characterTraits.levelOne.freeFocusLevelPick.attributeModifierPicks = attributeModifierPicks;
    }
    else {
        throw new Error("traitToUpdate not found");
    }

    onSetCharacterTraits(updatedTraits);
}

export const deleteTechniqueFromPsychicTechniquePicksAtLaterLevelsAndIndexes = (characterLevel: number, skillPickNumber: number, techniquePickNumber: number, techniqueName: string, characterTraits: CharacterTraits) => {

    const updatedTraits = { ...characterTraits };

    updatedTraits.levels.forEach((lev) => {
        if (lev.level >= characterLevel) {
            lev.skillPointSpends.forEach((sps, spsIndex) => {

                if (spsIndex > skillPickNumber) {
                    const theTech = sps.psychicTechniquePicks.find((p) => p.technique === techniqueName);
                    if (theTech) {
                        sps.psychicTechniquePicks = [];
                    }
                }

                if (spsIndex === skillPickNumber) {
                    const theTech = sps.psychicTechniquePicks.find((p) => p.pickIndex > techniquePickNumber && p.technique === techniqueName);
                    if (theTech) {
                        sps.psychicTechniquePicks = [];
                    }
                }

            })
        }
    })

    return updatedTraits;
}

export const deleteTechniqueFromSkillPointSpendsAtLaterLevelsAndIndexes = (characterLevel: number, skillPickNumber: number, techniqueName: string, characterTraits: CharacterTraits) => {

    const updatedTraits = { ...characterTraits };

    updatedTraits.levels.forEach((lev) => {
        if (lev.level >= characterLevel) {
            lev.skillPointSpends.forEach((sps, spsIndex) => {

                if (spsIndex > skillPickNumber) {
                    if (sps.techniqueName === techniqueName) {
                        sps.skillName = "";
                        sps.techniqueName = "";
                        sps.pointType = "";
                        sps.pointsSpent = 0;
                    }
                }

            })
        }
    })

    return updatedTraits;
}

export const onSetPsychicTechnique = (skill: string, techniqueName: string, level: number, note: string, traitToUpdate: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    // This is used when picking techniques at level 1. 

    let updatedTraits = { ...characterTraits };

    // Remove all other techniques of same skill and level. Have to change this when picking techniques at higher levels.
    let psychicTechniquePicks: PsychicTechniquePick[] = [];
    // psychicTechniquePicks = psychicTechniquePicks.filter((t) => t.skill !== skill && t.level !== level);

    // Get the skill levels trait that will be updated. 
    if (traitToUpdate === "levelOne.psychicTechniquePicks") {
        psychicTechniquePicks = [...characterTraits.levelOne.psychicTechniquePicks];
    }

    // Remove all other techniques of same skill and level for level 1. 
    psychicTechniquePicks = psychicTechniquePicks.filter((t) => t.skill !== skill);
    updatedTraits = deleteTechniqueFromPsychicTechniquePicksAtLaterLevelsAndIndexes(1, 100, 100, techniqueName, characterTraits);
    updatedTraits = deleteTechniqueFromSkillPointSpendsAtLaterLevelsAndIndexes(1, 100, techniqueName, characterTraits);

    // Try to find if there is an existing matching technique. 
    const theTechniqueLevelPick = psychicTechniquePicks.filter((p) => p.skill === skill && p.technique === techniqueName && p.level === level);
    if (theTechniqueLevelPick.length === 0) {
        // Add the new technique
        const newPick: PsychicTechniquePick = { skill: skill, technique: techniqueName, level: level, note: note, sourceType: "SkillLevel-" + skill + "-1", pickIndex: 0 };
        psychicTechniquePicks.push(newPick);

        // If selected Telekinetic Armory, add TK weapons and armor pack to inventory:
        if (techniqueName === "Telekinetic Armory") {
            updatedTraits = addTelekineticArmoryItems(updatedTraits);
        }
    }

    if (traitToUpdate === "levelOne.psychicTechniquePicks") {
        updatedTraits.levelOne.psychicTechniquePicks = psychicTechniquePicks;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetPsychicTechniqueWhenLevelUp = (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    let psychicTechniquePicks: PsychicTechniquePick[] = [];

    let updatedTraits = { ...characterTraits };

    updatedTraits = deleteTechniqueFromPsychicTechniquePicksAtLaterLevelsAndIndexes(characterLevel, skillPickNumber, techniquePickNumber, techniqueName, characterTraits);
    updatedTraits = deleteTechniqueFromSkillPointSpendsAtLaterLevelsAndIndexes(characterLevel, skillPickNumber, techniqueName, characterTraits);

    // Add the selected technique:
    if (traitToUpdate === "level.psychicTechniquePicks") {
        const theLevel = characterTraits.levels[characterLevel - 2];
        if (theLevel) {
            if (theLevel.skillPointSpends) {
                psychicTechniquePicks = theLevel.skillPointSpends[skillPickNumber].psychicTechniquePicks;
                const newPick: PsychicTechniquePick = { skill: skill, technique: techniqueName, level: techniqueLevel, note: "Technique gained when learned " + skill + "-" + skillLevelGained, sourceType: "SkillLevel-" + skill + "-" + skillLevelGained, pickIndex: techniquePickNumber };
                psychicTechniquePicks[techniquePickNumber] = newPick;
                theLevel.skillPointSpends[skillPickNumber].psychicTechniquePicks = psychicTechniquePicks;

                // If selected Telekinetic Armory, add TK weapons and armor pack to inventory:
                if (techniqueName === "Telekinetic Armory") {
                    updatedTraits = addTelekineticArmoryItems(updatedTraits);
                }

            }
        } else {
            throw new Error("Level not found");
        }
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectTechniqueToBuy = (level: number, skillPickNumber: number, technique: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {
        theLevel.skillPointSpends[skillPickNumber].techniqueName = technique;
        theLevel.skillPointSpends[skillPickNumber].pointType = "";
        theLevel.skillPointSpends[skillPickNumber].pointsSpent = 0;
    }

    // If selected Telekinetic Armory, add TK weapons and armor pack to inventory:
    if (technique === "Telekinetic Armory") {
        updatedTraits = addTelekineticArmoryItems(updatedTraits);
    }

    onSetCharacterTraits(updatedTraits);
}

const addGearPackWithoutAffectingCredits = (packName: string, updatedTraits: CharacterTraits) => {
    const packDetails = lookups.gearPacks.find((gp) => gp.name === packName);
    if (packDetails) {

        const clonedPackDetails: GearPackData = cloneDeep(packDetails);

        clonedPackDetails.items.forEach((i) => {

            updatedTraits.gear.equipment = updatedTraits.gear.equipment.filter((e) => e.id !== i.id);

            const theItem = lookups.gear.find((g) => g.id === i.id);
            if (theItem) {
                const newItem: EquipmentItem = {
                    uniqid: uniqid(),
                    id: i.id,
                    name: i.name ? i.name : theItem.names[0],
                    cost: theItem.cost,
                    storageQuantity: i.storageQuantity,
                    type: theItem.type,
                    enc: theItem.enc,
                    techLevel: theItem.techLevel,
                    desc: theItem.desc,
                };

                if (theItem.subtype) { newItem.subtype = theItem.subtype; }
                if (theItem.baseAC) { newItem.baseAC = theItem.baseAC; }
                if (theItem.bonusToAC) { newItem.bonusToAC = theItem.bonusToAC; }
                if (theItem.damage) { newItem.damage = theItem.damage; }
                if (theItem.burst) { newItem.burst = theItem.burst; }
                if (theItem.range) { newItem.range = theItem.range; }
                if (theItem.magazine) { newItem.magazine = theItem.magazine; }
                if (theItem.magazineReload) { newItem.magazineReload = theItem.magazineReload; }
                if (theItem.attributes) { newItem.attributes = theItem.attributes; }
                if (theItem.shock) { newItem.shock = theItem.shock; }
                if (theItem.tags) { newItem.tags = theItem.tags; }

                // vehicle stats
                if (theItem.speed) { newItem.speed = theItem.speed; }
                if (theItem.armor) { newItem.armor = theItem.armor; }
                if (theItem.hp) { newItem.hp = theItem.hp; }
                if (theItem.crew) { newItem.crew = theItem.crew; }
                if (theItem.tonnage) { newItem.tonnage = theItem.tonnage; }

                // cyberware stats:
                if (theItem.isCyberware) { newItem.isCyberware = theItem.isCyberware; }
                if (theItem.strain) { newItem.strain = theItem.strain; }
                if (theItem.shortDesc) { newItem.shortDesc = theItem.shortDesc; }

                // Natural Defenses Weapon:
                if (theItem.isNaturalDefensesWeapon) { newItem.isNaturalDefensesWeapon = theItem.isNaturalDefensesWeapon; }

                updatedTraits.gear.equipment.push(newItem);
            }
        })
    }

    return updatedTraits;
}

const addTelekineticArmoryItems = (updatedTraits: CharacterTraits) => {
    updatedTraits = addGearPackWithoutAffectingCredits("TK Armory Items", updatedTraits);
    return updatedTraits;
}

const addNaturalDefensesWeaponsItems = (updatedTraits: CharacterTraits) => {
    updatedTraits = addGearPackWithoutAffectingCredits("Natural Defenses Weapons Items", updatedTraits);
    return updatedTraits;
}

export const addVehicleBodyToEquipment = (vehicle: string, charTraits: CharacterTraits) => {
    const updatedTraits = { ...charTraits };

    // remove any existing vehicle body:
    updatedTraits.gear.equipment = updatedTraits.gear.equipment.filter((g) => g.isVehicleBody !== true);

    const theItem = lookups.gear.find((g) => g.names[0] === vehicle);
    if (theItem) {
        const newItem: EquipmentItem = {
            uniqid: uniqid(),
            id: theItem.id,
            name: vehicle,
            cost: 0,
            storageQuantity: [{ "storage": "Stowed", "quantity": 1 }],
            type: theItem.type,
            enc: theItem.enc,
            techLevel: theItem.techLevel,
            desc: theItem.desc,
            isVehicleBody: true
        };

        if (theItem.subtype) { newItem.subtype = theItem.subtype; }
        if (theItem.baseAC) { newItem.baseAC = theItem.baseAC; }
        if (theItem.bonusToAC) { newItem.bonusToAC = theItem.bonusToAC; }
        if (theItem.damage) { newItem.damage = theItem.damage; }
        if (theItem.burst) { newItem.burst = theItem.burst; }
        if (theItem.range) { newItem.range = theItem.range; }
        if (theItem.magazine) { newItem.magazine = theItem.magazine; }
        if (theItem.magazineReload) { newItem.magazineReload = theItem.magazineReload; }
        if (theItem.attributes) { newItem.attributes = theItem.attributes; }
        if (theItem.shock) { newItem.shock = theItem.shock; }
        if (theItem.tags) { newItem.tags = theItem.tags; }
        // vehicle stats
        if (theItem.speed) { newItem.speed = theItem.speed; }
        if (theItem.armor) { newItem.armor = theItem.armor; }
        if (theItem.hp) { newItem.hp = theItem.hp; }
        if (theItem.crew) { newItem.crew = theItem.crew; }
        if (theItem.tonnage) { newItem.tonnage = theItem.tonnage; }

        // add the 'Vehicle Bot Body' mod to the item.
        const theMod: Mod = { id: "VB1", quantity: 1 };
        newItem.mods = [];
        newItem.mods.push(theMod)

        updatedTraits.gear.equipment.push(newItem);
    }

}

export const onSelectWildTalentPsychicDiscipline = (skill: string, traitToUpdate: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    if (traitToUpdate === "levelOne.wildTalenPicks.wildTalentPsychicDiscipline") {
        updatedTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline = skill;
        updatedTraits.levelOne.wildTalentPicks.wildTalentTechnique1 = "";
        updatedTraits.levelOne.wildTalentPicks.wildTalentTechnique2 = "";
    }
    onSetCharacterTraits(updatedTraits);
}

export const onSelectWildTalentTechnique = (technique: string, focusLevel: number, traitToUpdate: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    let updatedTraits = { ...characterTraits };
    if (traitToUpdate === "levelOne.wildTalentPicks.wildTalentTechnique") {
        if (focusLevel === 1) {
            updatedTraits.levelOne.wildTalentPicks.wildTalentTechnique1 = technique;
            updatedTraits.levelOne.wildTalentPicks.wildTalentTechnique2 = "";
        }
        if (focusLevel === 2) {
            updatedTraits.levelOne.wildTalentPicks.wildTalentTechnique2 = technique;
        }
    }

    // If selected Telekinetic Armory, add TK weapons and armor pack to inventory:
    if (technique === "Telekinetic Armory") {
        updatedTraits = addTelekineticArmoryItems(updatedTraits);
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetHitPoints = (rolledHitPoints: number, level: number, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    const updatedTraits = { ...characterTraits };

    if (level === 1) {
        if (updatedTraits.levelOne.rolledHitPoints !== 0) {
            updatedTraits.levelOne.hitPointRerolls = updatedTraits.levelOne.hitPointRerolls + 1;
        }
        updatedTraits.levelOne.rolledHitPoints = rolledHitPoints;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetNotes = (notes: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    updatedTraits.notes = notes;
    onSetCharacterTraits(updatedTraits);
}

export const onSetStartingCredits = (credits: number, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    updatedTraits.gear.method = "roll";
    updatedTraits.gear.startingCredits = credits;
    // updatedTraits.gear.credits = credits;
    onSetCharacterTraits(updatedTraits);
}

export const onSetGearMethodToPack = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    updatedTraits.gear.method = "pack";
    onSetCharacterTraits(updatedTraits);
}

export const onSelectEquipmentPack = (pack: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    let updatedTraits = { ...characterTraits };

    const packDetails = lookups.gearPacks.find((gp) => gp.name === pack);
    if (packDetails) {

        const clonedPackDetails: GearPackData = cloneDeep(packDetails);

        clonedPackDetails.items.push({ "id": "ME9", "storageQuantity": [{ "storage": "Readied", "quantity": 1 }] }); // everyone gets an Unarmed Attack weapon
        updatedTraits.gear.pack = clonedPackDetails.name;
        updatedTraits.gear.startingCredits = clonedPackDetails.credits;
        updatedTraits.gear.equipment = [];

        let totalPrice = 0;

        clonedPackDetails.items.forEach((i) => {
            const theItem = lookups.gear.find((g) => g.id === i.id);
            if (theItem) {
                const newItem: EquipmentItem = {
                    uniqid: uniqid(),
                    id: i.id,
                    name: i.name ? i.name : theItem.names[0],
                    cost: theItem.cost,
                    storageQuantity: i.storageQuantity,
                    type: theItem.type,
                    enc: theItem.enc,
                    techLevel: theItem.techLevel,
                    desc: theItem.desc,
                };

                if (theItem.subtype) { newItem.subtype = theItem.subtype; }
                if (theItem.baseAC) { newItem.baseAC = theItem.baseAC; }
                if (theItem.bonusToAC) { newItem.bonusToAC = theItem.bonusToAC; }
                if (theItem.damage) { newItem.damage = theItem.damage; }
                if (theItem.burst) { newItem.burst = theItem.burst; }
                if (theItem.range) { newItem.range = theItem.range; }
                if (theItem.magazine) { newItem.magazine = theItem.magazine; }
                if (theItem.magazineReload) { newItem.magazineReload = theItem.magazineReload; }
                if (theItem.attributes) { newItem.attributes = theItem.attributes; }
                if (theItem.shock) { newItem.shock = theItem.shock; }
                if (theItem.tags) { newItem.tags = theItem.tags; }

                updatedTraits.gear.equipment.push(newItem);

                let totalQuantity = 0;
                i.storageQuantity.forEach((sq) => totalQuantity = totalQuantity + sq.quantity);

                totalPrice = totalPrice + (theItem.cost * totalQuantity);
            }
        })


        updatedTraits.gear.startingCredits = totalPrice + clonedPackDetails.credits;
    } else {
        updatedTraits.gear.pack = "";
        updatedTraits.gear.startingCredits = -1;
        // updatedTraits.gear.credits = -1;
        updatedTraits.gear.equipment = updatedTraits.gear.equipment.filter((g) => g.isVehicleBody !== true);
    }

    // Also add the TK Armory pack if has TK Armory discipline
    const charDerivedStats = new CharacterDerivedStats(updatedTraits);
    charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, 100, 100);
    if (charDerivedStats.psychicTechniqueLevels.find((tl) => tl.technique === "Telekinetic Armory")) {
        updatedTraits = addTelekineticArmoryItems(updatedTraits);
    }

    // Also add the Vehicle Body if one selected
    if (characterTraits.levelOne.vehicleBody && characterTraits.levelOne.vehicleBody !== "") {
        addVehicleBodyToEquipment(characterTraits.levelOne.vehicleBody, updatedTraits);
    }

    onSetCharacterTraits(updatedTraits);

}

export const onBuyGear = (theUniqid: string, id: string, name: string, price: number, buy: boolean, storage: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    if (buy) {
        handlePurchaseGear(theUniqid, id, name, price, storage, characterTraits, onSetCharacterTraits)
    } else {
        handleSellGear(theUniqid, id, name, price, storage, characterTraits, onSetCharacterTraits)
    }
}

const handlePurchaseGear = (theUniqid: string, id: string, name: string, price: number, storage: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    const theItem = getItemIncludingModsAndCustomNameAndCustomNotes(updatedTraits, theUniqid, id);
    if (theItem) {
        handleBuyMoreOfAnItem(theItem, storage);
    } else {
        handleAddNewItem(id, name, price, storage, characterTraits, onSetCharacterTraits);
    }
    onSetCharacterTraits(updatedTraits);
}

const getItemIncludingModsAndCustomNameAndCustomNotes = (updatedTraits: CharacterTraits, theUniqid: string, id: string) => {
    if (theUniqid !== "") {
        return updatedTraits.gear.equipment.find((i) => i.uniqid === theUniqid);
    } else {
        // if the character owns an unmodded version of this item, return that, otherwise return nothing. 
        return updatedTraits.gear.equipment.find((i) => i.id === id && (i.mods === undefined || i.mods.length === 0) && (i.customName === undefined || i.customName.trim() === "") && (i.customNotes === undefined || i.customNotes.trim() === ""));
    }
}

const handleAddNewItem = (id: string, name: string, price: number, storage: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    const updatedTraits = { ...characterTraits };
    const theItem = lookups.gear.find((g) => g.id === id);
    const newUniqid = uniqid();

    let storageType = "Readied";
    if (storage === "Cyberware") { storageType = "Cyberware"; }

    if (theItem) {
        const newItem: EquipmentItem = {
            uniqid: newUniqid,
            id: id,
            name: name,
            cost: price,
            storageQuantity: [{ storage: storageType, quantity: 1 }],
            type: theItem.type,
            enc: theItem.enc,
            techLevel: theItem.techLevel,
            desc: theItem.desc,
        };

        if (theItem.subtype) { newItem.subtype = theItem.subtype; }
        if (theItem.baseAC) { newItem.baseAC = theItem.baseAC; }
        if (theItem.bonusToAC) { newItem.bonusToAC = theItem.bonusToAC; }
        if (theItem.damage) { newItem.damage = theItem.damage; }
        if (theItem.burst) { newItem.burst = theItem.burst; }
        if (theItem.suppress) { newItem.suppress = theItem.suppress; }
        if (theItem.range) { newItem.range = theItem.range; }
        if (theItem.magazine) { newItem.magazine = theItem.magazine; }
        if (theItem.magazineReload) { newItem.magazineReload = theItem.magazineReload; }
        if (theItem.attributes) { newItem.attributes = theItem.attributes; }
        if (theItem.shock) { newItem.shock = theItem.shock; }
        if (theItem.tags) { newItem.tags = theItem.tags; }

        // vehicle stats
        if (theItem.speed !== undefined) { newItem.speed = theItem.speed; }
        if (theItem.armor) { newItem.armor = theItem.armor; }
        if (theItem.hp) { newItem.hp = theItem.hp; }
        if (theItem.crew) { newItem.crew = theItem.crew; }
        if (theItem.tonnage) { newItem.tonnage = theItem.tonnage; }

        // cyberware stats:
        if (theItem.isCyberware) { newItem.isCyberware = theItem.isCyberware; }
        if (theItem.strain) { newItem.strain = theItem.strain; }
        if (theItem.shortDesc) { newItem.shortDesc = theItem.shortDesc; }

        // natural defenses weapons stats:
        if (theItem.isNaturalDefensesWeapon) { newItem.isNaturalDefensesWeapon = theItem.isNaturalDefensesWeapon; }

        // drones stats:
        if (theItem.fittings) { newItem.fittings = theItem.fittings; }
        if (theItem.ac) { newItem.ac = theItem.ac; }
        if (theItem.controlRange) { newItem.controlRange = theItem.controlRange; }

        // robot stats:
        if (theItem.hitDice) { newItem.hitDice = theItem.hitDice; }
        if (theItem.attackBonus !== undefined || theItem.attackBonus === 0) { newItem.attackBonus = theItem.attackBonus; }
        if (theItem.move) { newItem.move = theItem.move; }
        if (theItem.morale) { newItem.morale = theItem.morale; }
        if (theItem.skill !== undefined || theItem.skill === 0) { newItem.skill = theItem.skill; }
        if (theItem.save) { newItem.save = theItem.save; }

        // update item cost:
        const itemCost = getItemTotalCost(newItem);
        newItem.cost = itemCost;

        updatedTraits.gear.equipment.push(newItem);

        if (name === "Body Arsenal Array") {
            addGearPackWithoutAffectingCredits("Body Arsenal Array Items", characterTraits);
        }
    }
}

const handleBuyMoreOfAnItem = (theItem: EquipmentItem, storage: string) => {
    const storeQuant = theItem.storageQuantity.find((sq) => sq.storage === storage);
    if (storeQuant) {
        storeQuant.quantity = storeQuant.quantity + 1;
    }
    else {
        theItem.storageQuantity.push({ storage, quantity: 1 });
    }
}

const handleSellGear = (theUniqid: string, id: string, name: string, price: number, storage: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const theItem = updatedTraits.gear.equipment.find((i) => i.uniqid === theUniqid);
    if (theItem) {
        const storeQuant = theItem.storageQuantity.find((sq) => sq.storage === storage);
        if (storeQuant) {
            // Reduce quantity by 1.
            if (storeQuant.quantity >= 1) {
                storeQuant.quantity = storeQuant.quantity - 1
                if (storeQuant.quantity === 0) {
                    theItem.storageQuantity = theItem.storageQuantity.filter((sq) => sq.storage !== storage);
                }
            };

            // update item cost:
            const itemCost = getItemTotalCost(theItem);
            theItem.cost = itemCost;

            // If all storage types have quantity = 0, remove the item
            let totalQuantity = 0;
            theItem.storageQuantity.forEach((sq) => totalQuantity += sq.quantity);
            if (totalQuantity === 0) {
                updatedTraits.gear.equipment = updatedTraits.gear.equipment.filter((e) => e.uniqid !== theUniqid);
            }
        }
    }

    onSetCharacterTraits(updatedTraits);
}

// export const onBuyGear_Old = (theUniqid: string, id: string, name: string, price: number, buy: boolean, storage: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
//     const updatedTraits = { ...characterTraits };

//     const newUniqid = uniqid();

//     if (buy) {
//         const theItem = updatedTraits.gear.equipment.find((i) => i.id === id && i.name === name);
//         // const theItem = updatedTraits.gear.equipment.find((i) => i.uniqid === theUniqid);
//         if (theItem) {
//             const storeQuant = theItem.storageQuantity.find((sq) => sq.storage === storage);
//             if (storeQuant) {
//                 storeQuant.quantity = storeQuant.quantity + 1;
//             }
//             else {
//                 theItem.storageQuantity.push({ storage, quantity: 1 });
//             }
//         } else {
//             const theItem = lookups.gear.find((g) => g.id === id);

//             let storageType = "Readied";
//             if (storage === "Cyberware") { storageType = "Cyberware"; }

//             if (theItem) {
//                 const newItem: EquipmentItem = {
//                     uniqid: newUniqid,
//                     id: id,
//                     name: name,
//                     cost: price,
//                     storageQuantity: [{ storage: storageType, quantity: 1 }],
//                     type: theItem.type,
//                     enc: theItem.enc,
//                     techLevel: theItem.techLevel,
//                     desc: theItem.desc,
//                 };

//                 if (theItem.subtype) { newItem.subtype = theItem.subtype; }
//                 if (theItem.baseAC) { newItem.baseAC = theItem.baseAC; }
//                 if (theItem.bonusToAC) { newItem.bonusToAC = theItem.bonusToAC; }
//                 if (theItem.damage) { newItem.damage = theItem.damage; }
//                 if (theItem.burst) { newItem.burst = theItem.burst; }
//                 if (theItem.suppress) { newItem.suppress = theItem.suppress; }
//                 if (theItem.range) { newItem.range = theItem.range; }
//                 if (theItem.magazine) { newItem.magazine = theItem.magazine; }
//                 if (theItem.magazineReload) { newItem.magazineReload = theItem.magazineReload; }
//                 if (theItem.attributes) { newItem.attributes = theItem.attributes; }
//                 if (theItem.shock) { newItem.shock = theItem.shock; }
//                 if (theItem.tags) { newItem.tags = theItem.tags; }

//                 // vehicle stats
//                 if (theItem.speed !== undefined) { newItem.speed = theItem.speed; }
//                 if (theItem.armor) { newItem.armor = theItem.armor; }
//                 if (theItem.hp) { newItem.hp = theItem.hp; }
//                 if (theItem.crew) { newItem.crew = theItem.crew; }
//                 if (theItem.tonnage) { newItem.tonnage = theItem.tonnage; }

//                 // cyberware stats:
//                 if (theItem.isCyberware) { newItem.isCyberware = theItem.isCyberware; }
//                 if (theItem.strain) { newItem.strain = theItem.strain; }
//                 if (theItem.shortDesc) { newItem.shortDesc = theItem.shortDesc; }

//                 // natural defenses weapons stats:
//                 if (theItem.isNaturalDefensesWeapon) { newItem.isNaturalDefensesWeapon = theItem.isNaturalDefensesWeapon; }

//                 // drones stats:
//                 if (theItem.fittings) { newItem.fittings = theItem.fittings; }
//                 if (theItem.ac) { newItem.ac = theItem.ac; }
//                 if (theItem.controlRange) { newItem.controlRange = theItem.controlRange; }

//                 // robot stats:

//                 console.log(JSON.stringify(theItem, null, 2));

//                 if (theItem.hitDice) { newItem.hitDice = theItem.hitDice; }
//                 if (theItem.attackBonus) { newItem.attackBonus = theItem.attackBonus; }
//                 if (theItem.move) { newItem.move = theItem.move; }
//                 if (theItem.morale) { newItem.morale = theItem.morale; }
//                 if (theItem.skill) { newItem.skill = theItem.skill; }
//                 if (theItem.save) { newItem.save = theItem.save; }

//                 // update item cost:
//                 const itemCost = getItemTotalCost(newItem);
//                 newItem.cost = itemCost;

//                 updatedTraits.gear.equipment.push(newItem);

//                 if (name === "Body Arsenal Array") {
//                     addGearPackWithoutAffectingCredits("Body Arsenal Array Items", characterTraits);
//                 }
//             }
//         }
//         // updatedTraits.gear.credits = updatedTraits.gear.credits - price;
//     } else {
//         // Sell: remove item
//         const theItem = updatedTraits.gear.equipment.find((i) => i.uniqid === theUniqid);
//         if (theItem) {
//             const storeQuant = theItem.storageQuantity.find((sq) => sq.storage === storage);
//             if (storeQuant) {
//                 // Reduce quantity by 1.
//                 if (storeQuant.quantity >= 1) {
//                     storeQuant.quantity = storeQuant.quantity - 1
//                     if (storeQuant.quantity === 0) {
//                         theItem.storageQuantity = theItem.storageQuantity.filter((sq) => sq.storage !== storage);
//                     }
//                 };

//                 // update item cost:
//                 const itemCost = getItemTotalCost(theItem);
//                 theItem.cost = itemCost;

//                 // If all storage types have quantity = 0, remove the item
//                 let totalQuantity = 0;
//                 theItem.storageQuantity.forEach((sq) => totalQuantity += sq.quantity);
//                 if (totalQuantity === 0) {
//                     updatedTraits.gear.equipment = updatedTraits.gear.equipment.filter((e) => e.uniqid !== theUniqid);
//                 }
//             }
//         }
//         // updatedTraits.gear.credits = updatedTraits.gear.credits + price;
//     }

//     // amalgamate identical non-modded items

//     // const hasSameMods = (m1: Mod[] | undefined, m2: Mod[] | undefined) => {
//     //     if (m1 === undefined && m2 === undefined) { return true; }
//     //     if (m1 !== undefined && m2 === undefined) { return false; }
//     //     if (m1 === undefined && m2 !== undefined) { return false; }
//     //     if (m1 !== undefined && m2 !== undefined) {
//     //         const m1Ids = m1.map((m) => m.id).sort();
//     //         const m2Ids = m2.map((m) => m.id).sort();
//     //         return m1Ids === m2Ids;
//     //     }
//     //     return false;
//     // }

//     // let finalUpdatedEquip: EquipmentItem[] = [];
//     // const processedUniqIds: string[] = [];
//     // updatedTraits.gear.equipment.forEach((e) => {
//     //     if (!processedUniqIds.includes(e.uniqid)) {
//     //         // check for duplicates
//     //         const theDupes = updatedTraits.gear.equipment.filter((eq) => eq.id === e.id && hasSameMods(e.mods, eq.mods));
//     //         if (theDupes.length > 0) {
//     //             console.log(JSON.stringify(theDupes, null, 2));
//     //             console.log("length: " + theDupes.length);
//     //             if (theDupes.length > 1) {
//     //                 // transfer the quantities to the first dupe, skip the rest
//     //                 theDupes.forEach((d, index) => {
//     //                     if (index === 0) {
//     //                         console.log("multiple items");
//     //                         finalUpdatedEquip.push({ ...theDupes[0] });
//     //                     } else {
//     //                         processedUniqIds.push(d.uniqid);
//     //                     }
//     //                 })
//     //             } else if (theDupes.length === 1) {
//     //                 // add the single unique item.
//     //                 finalUpdatedEquip.push({ ...theDupes[0] });
//     //             }
//     //         } else {
//     //             finalUpdatedEquip.push({ ...theDupes[0] });
//     //         }
//     //     }
//     // })
//     // updatedTraits.gear.equipment = finalUpdatedEquip;


//     onSetCharacterTraits(updatedTraits);
// }

const updateModPrices = (item: EquipmentItem) => {

    const rawItem = lookups.gear.find((i) => i.id === item.id);
    if (rawItem) {
        let gearMods = [...lookups.gearMods];

        gearMods.forEach((gm) => {
            if (gm.modCostMultiplier !== undefined) {
                gm.cost = rawItem.cost * gm.modCostMultiplier;
            }
            if (gm.baseCostMultiplier !== undefined) {
                gm.cost = (rawItem.cost * (gm.baseCostMultiplier)) - rawItem.cost;
            }
        })
        return gearMods;
    }

    return [] as GearModData[];
}

const getItemEncumbrance = (item: EquipmentItem | undefined) => {
    if (item === undefined) { return 0; }

    let enc = 0;
    const rawItem = lookups.gear.find((g) => g.id === item.id);
    if (rawItem) { enc = rawItem.enc; }

    if (item.mods) {
        item.mods.forEach((mod) => {
            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
            if (theMod) {
                theMod.tags?.forEach((t) => {
                    if (t === "noEncumbrance") {
                        enc = 0;
                    }
                    if (t === "-1Enc") {
                        enc = enc - 1;
                    }
                })
            }
        })
    }

    return Math.max(enc, 0);
}

export const onAddMod = (itemUniqueId: string, modId: string, isAddMod: boolean, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    let itemQuantity = 0;

    // If mod is a 'Built from Scratch' mod, delete any other 'Built from Scratch' mod first
    const theMod = lookups.gearMods.find((m) => m.id === modId);
    if (theMod) {
        if (theMod.type === "build") {
            const theItem = updatedTraits.gear.equipment.find((eq) => eq.uniqid === itemUniqueId);
            if (theItem) {
                theItem.mods = theItem.mods?.filter((gm) => {
                    const thisMod = lookups.gearMods.find((m) => m.id === gm.id);
                    if (thisMod) {
                        if (thisMod.type === "build") {
                            return false;
                        } else {
                            return true
                        }
                    } else {
                        return true;
                    }
                })
            }
        }
    }

    // If mod is a 'Robots' mod, delete any other 'Robots' mod first
    const theRobotMod = lookups.gearMods.find((m) => m.id === modId);
    if (theRobotMod) {
        if (theRobotMod.type === "robotMod") {
            const theItem = updatedTraits.gear.equipment.find((eq) => eq.uniqid === itemUniqueId);
            if (theItem) {
                theItem.mods = theItem.mods?.filter((gm) => {
                    const thisMod = lookups.gearMods.find((m) => m.id === gm.id);
                    if (thisMod) {
                        if (thisMod.type === "robotMod") {
                            return false;
                        } else {
                            return true
                        }
                    } else {
                        return true;
                    }
                })
            }
        }
    }

    const existingEquip = updatedTraits.gear.equipment.find((g) => g.uniqid === itemUniqueId);
    if (existingEquip) {
        if (!existingEquip.mods) {
            existingEquip.mods = [];
        }
        existingEquip.storageQuantity.forEach((sq) => {
            itemQuantity = itemQuantity + sq.quantity;
        })

        if (isAddMod) {
            const alreadyHasMod = existingEquip.mods.find((m) => m.id === modId);
            if (!alreadyHasMod) {
                // add first new mod
                existingEquip.mods.push({ id: modId, quantity: 1 });
            } else {
                // increase existing mod quantity by 1
                alreadyHasMod.quantity = alreadyHasMod.quantity + 1;
            }

        } else {
            const alreadyHasMod = existingEquip.mods.find((m) => m.id === modId);
            if (alreadyHasMod) {
                if (alreadyHasMod.quantity === 1) {
                    // remove mod
                    existingEquip.mods = existingEquip.mods.filter((m) => m.id !== modId);
                } else {
                    // reduce quantity by 1
                    alreadyHasMod.quantity = alreadyHasMod.quantity - 1;
                }
            }
        }

        // figure cost
        const itemCost = getItemTotalCost(existingEquip);
        existingEquip.cost = itemCost;

        const enc = getItemEncumbrance(existingEquip);
        existingEquip.enc = enc;

        existingEquip.mods.sort((a, b) => a.id < b.id ? -1 : 1);
    }

    onSetCharacterTraits(updatedTraits);
}

const getItemTotalCost = (item: EquipmentItem | undefined) => {
    if (item === undefined) { return 0; }

    const pricedGearMods = updateModPrices(item);

    let rawItemCost = 0;

    const rawItem = lookups.gear.find((i) => i.id === item.id)
    if (rawItem) {
        rawItemCost = rawItem.cost;
    };

    let totalModCost = 0;
    if (item.mods) {
        item.mods.forEach((mod) => {
            const pricedMod = pricedGearMods.find((pgm) => pgm.id === mod.id);
            if (pricedMod) {
                const modCost = pricedMod.cost * mod.quantity;
                totalModCost = totalModCost + modCost;
            }
        })
    }

    return rawItemCost + totalModCost;
}

export const onSetCustomName = (itemUniqueId: string, customName: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const existingEquip = updatedTraits.gear.equipment.find((g) => g.uniqid === itemUniqueId);
    if (existingEquip) {
        existingEquip.customName = customName;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetCustomNotes = (itemUniqueId: string, customNotes: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const existingEquip = updatedTraits.gear.equipment.find((g) => g.uniqid === itemUniqueId);
    if (existingEquip) {
        existingEquip.customNotes = customNotes;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetItemStorage = (theUniqid: string, existingStorage: string, newStorage: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const existingEquip = characterTraits.gear.equipment.find((g) => g.uniqid === theUniqid);
    if (existingEquip) {

        const beforeStorage = existingEquip.storageQuantity.find((sq) => sq.storage === existingStorage);
        if (beforeStorage) {
            beforeStorage.quantity = beforeStorage.quantity - 1;
            if (beforeStorage.quantity === 0) {
                existingEquip.storageQuantity = existingEquip.storageQuantity.filter((sq) => sq.storage !== existingStorage);
            }
        }

        const afterStorage = existingEquip.storageQuantity.find((sq) => sq.storage === newStorage);
        if (afterStorage) {
            afterStorage.quantity = afterStorage.quantity + 1;
        } else {
            existingEquip.storageQuantity.push({ storage: newStorage, quantity: 1 });
        }

    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetAdditionalCredits = (additionalCredits: number, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    updatedTraits.gear.additionalCredits = additionalCredits;
    onSetCharacterTraits(updatedTraits);
}

export const getRangedWeaponDesc = (r: WeaponStat) => {
    let d: string[] = [];

    d.push(PlusMinus(r.toHitBonus) + " to hit");
    d.push(formatDieRoll(r.damage) + " dmg");
    d.push("rng " + r.range.join("/"));

    if (r.mag > 0) {
        const formatMag = (mag: number) => mag > 1000 ? "Unlimited" : mag;
        d.push("mag " + formatMag(r.mag));
    }

    const toHit = PlusMinus(r.toHitBonus) + " to hit (" + r.toHitBonusNote + ")";
    const damage = formatDieRoll(r.damage) + " damage (" + r.damageNote + ")";

    const notes = r.notes;
    notes.push("TL " + r.techLevel);

    const modDesc: string[] = [];
    r.mods?.forEach((mod) => {
        const theMod = lookups.gearMods.find((m) => m.id === mod.id);
        if (theMod) {
            const desc = theMod.name + "^" + theMod.desc;
            modDesc.push(desc);
        }
    })

    return {
        name: r.name,
        shortDesc: d,
        toHit,
        damage,
        notes,
        modDesc
    };
}

export const getMeleeWeaponDesc = (r: WeaponStat) => {
    let d: string[] = [];

    d.push(PlusMinus(r.toHitBonus) + " to hit");
    d.push(formatDieRoll(r.damage) + " dmg");

    if (r.shockDamage) {
        d.push("Shock " + r.shockDamage + "pts/AC" + r.shockAC);
    }

    // d.push(r.attributes);

    const toHit = PlusMinus(r.toHitBonus) + " to hit (" + r.toHitBonusNote + ")";
    const damage = formatDieRoll(r.damage) + " damage (" + r.damageNote + ")";

    const notes = r.notes;
    notes.push("TL " + r.techLevel);

    const modDesc: string[] = [];
    r.mods?.forEach((mod) => {
        const theMod = lookups.gearMods.find((m) => m.id === mod.id);
        if (theMod) {
            const desc = theMod.name + "^" + theMod.desc;
            modDesc.push(desc);
        }
    })

    return {
        name: r.name,
        shortDesc: d,
        toHit,
        damage,
        notes,
        modDesc
    };

}

export const getVehicleDesc = (v: VehicleStat) => {

    const isNull = (x: any) => {
        if (x !== undefined) { return x; }
        return "?";
    }

    const isSpecial = (armor: string) => {
        if (armor === "100") { return "Special"; }
        return armor;
    }

    const speed = "Speed " + isNull(v.speed);
    const armor = "Armor " + isNull(isSpecial(v.armor.toString()));
    const hp = "HP " + isNull(v.hp);
    const crew = "Crew " + isNull(v.crew);
    const tonnage = "Tonnage " + isNull(v.tonnage);
    const TL = "TL " + isNull(v.techLevel);
    const notes = v.notes.join(", ");

    const modDesc: string[] = [];
    v.mods?.forEach((mod) => {
        const theMod = lookups.gearMods.find((m) => m.id === mod.id);
        if (theMod) {
            const desc = theMod.name + "^" + theMod.desc;
            modDesc.push(desc);
        }
    })

    return {
        name: v.name,
        speed,
        armor,
        hp,
        crew,
        tonnage,
        TL,
        notes,
        modDesc
    };

}

export const getDroneDesc = (v: DroneStat) => {

    const isNull = (x: any) => {
        if (x !== undefined) { return x; }
        return "?";
    }

    const formatControlRange = (range: number | undefined) => {
        if (range === undefined) { return "?"; }
        if (range < 1000) {
            return range + "m";
        }
        return range / 1000 + "km";
    }

    const speed = "Fittings " + isNull(v.fittings);
    const ac = "AC " + isNull(v.ac);
    const hp = "HP " + isNull(v.hp);
    const range = "Range " + formatControlRange(v.controlRange);
    const enc = "Enc " + isNull(v.enc);
    const TL = "TL " + isNull(v.techLevel);
    const notes = v.notes.join(", ");

    const modDesc: string[] = [];
    v.mods?.forEach((mod) => {
        const theMod = lookups.gearMods.find((m) => m.id === mod.id);
        if (theMod) {
            const desc = theMod.name + "^" + theMod.desc;
            modDesc.push(desc);
        }
    })

    return {
        name: v.name,
        speed,
        ac,
        hp,
        range,
        enc,
        TL,
        notes,
        modDesc
    };

}

export const getRobotDesc = (v: RobotStat) => {

    const isNull = (x: any) => {
        if (x !== undefined) { return x; }
        return "?";
    }

    const hitDice = "HD " + isNull(v.hitDice);
    const ac = "AC " + isNull(v.ac);
    const atk = isNull(formatAttack(v.attackBonus)) + "/" + isNull(formatDieRoll(v.damage));
    const move = "Move " + isNull(v.move) + "m";
    const skill = "Skill +" + isNull(v.skill); 
    const save = "Save " + isNull(v.save) + "+";
    const TL = "TL " + isNull(v.techLevel);

    const notes = v.notes.join(", ");

    const modDesc: string[] = [];
    v.mods?.forEach((mod) => {
        const theMod = lookups.gearMods.find((m) => m.id === mod.id);
        if (theMod) {
            const desc = theMod.name + "^" + theMod.desc;
            modDesc.push(desc);
        }
    })

    return {
        name: v.name,
        hitDice,
        ac, 
        atk,
        move,
        skill, 
        save,
        TL,
        notes,
        modDesc
    };

}

export const getDroneDescText = (v: DroneStat): string[] => {
    let d: string[] = [];

    const isNull = (x: any) => {
        if (x !== undefined) { return x; }
        return "?";
    }

    const formatControlRange = (range: number | undefined) => {
        if (range === undefined) { return "?"; }
        if (range < 1000) {
            return range + "m";
        }
        return range / 1000 + "km";
    }

    d.push("AC " + isNull(v.ac));
    d.push("HP " + isNull(v.hp));
    d.push("Move " + v.move + "m/round");
    if (v.controlRange > 5000) {
        d.push("Control Range: unlimited");
    } else {
        d.push("Control Range " + formatControlRange(v.controlRange));
    }
    if (v.endurance > 1000) {
        d.push("Endurance: unlimited");
    } else {
        d.push("Endurance " + v.endurance + " mins");
    }
    d.push("Lift " + v.liftKg + "kg");
    d.push("Enc " + isNull(v.enc));
    d.push("Max. Fittings " + isNull(v.fittings));

    // v.notes.forEach((n: any) => d.push(n));
    const mods = getModList(v);
    if (mods !== "") { d.push(mods) };

    d.push("TL " + v.techLevel);

    return d;
}

export const getModList = (thing: any) => {
    let mods: string[] = [];
    if (thing.mods) {
        const lookups = Lookups.getInstance();
        thing.mods.forEach((m: any) => {
            const theMod = lookups.gearMods.find((gm) => gm.id === m.id);
            if (theMod) {
                if (m.quantity === 1) {
                    mods.push(theMod.name);
                } else {
                    mods.push(m.quantity + " x " + theMod.name);
                }
            }
        })
    }
    let modList: string = "";
    if (mods.length > 0) {
        modList = "Mods: " + mods.sort().join("; ");
    };
    return modList;
}

export const sortItems = (i1: EquipmentItem, i2: EquipmentItem) => {
    let i1Name = i1.customName !== undefined ? i1.customName : i1.name;
    let i2Name = i2.customName !== undefined ? i2.customName : i2.name;
    return i1Name < i2Name ? -1 : 1;
}


export const onLevelUp = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const currentLevel = characterTraits.levels.length + 1;

    const newLevel: Level = {
        level: currentLevel + 1,
        rolledHitPoints: [],
        totalHitPoints: 0,
        hitPointRerolls: 0,
        validationCodes: [],
        skillPointSpends: [{ spendType: "", skillName: "", attributeName: "", techniqueName: "", pointType: "", pointsSpent: 0, validationCodes: [], psychicTechniquePicks: [] }]
    };

    updatedTraits.levels.push(newLevel);
    updatedTraits.level = currentLevel + 1;

    onSetCharacterTraits(updatedTraits);
}

export const onLevelDown = (characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };
    updatedTraits.levels.pop();
    updatedTraits.level = updatedTraits.levels.length + 1;
    onSetCharacterTraits(updatedTraits);
}

export const onSetLevelHitPoints = (level: number, hitPointRolls: number[], characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    const updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {
        if (theLevel.totalHitPoints !== 0) {
            theLevel.hitPointRerolls = theLevel.hitPointRerolls + 1;
        }
        theLevel.rolledHitPoints = hitPointRolls;
        theLevel.totalHitPoints = hitPointRolls.reduce((partialSum, a) => partialSum + a, 0);
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectSkillPointSpendType = (level: number, index: number, spendType: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {

    const updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {

        theLevel.skillPointSpends[index].spendType = spendType;

        if (spendType === "") {
            theLevel.skillPointSpends[index].spendType = spendType;
            theLevel.skillPointSpends = theLevel.skillPointSpends.filter((sps) => sps.spendType !== "");
        }

        // add final blank spend option if all previous options have had spendType selected
        let allHaveSpendType = true;
        theLevel.skillPointSpends.forEach((st) => {
            if (st.spendType === "") { allHaveSpendType = false; }
        })
        if (allHaveSpendType) {
            theLevel.skillPointSpends.push({ spendType: "", skillName: "", attributeName: "", techniqueName: "", pointType: "", pointsSpent: 0, validationCodes: [], psychicTechniquePicks: [] });
        }

    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectSkillToImprove = (level: number, index: number, skillName: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {
        theLevel.skillPointSpends[index].skillName = skillName;
        theLevel.skillPointSpends[index].techniqueName = "";
        theLevel.skillPointSpends[index].pointType = "";
        theLevel.skillPointSpends[index].pointsSpent = 0;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectSkillToBuyTechniqueFrom = (level: number, index: number, skillName: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {
        theLevel.skillPointSpends[index].skillName = skillName;
        theLevel.skillPointSpends[index].techniqueName = "";
        theLevel.skillPointSpends[index].pointType = "";
        theLevel.skillPointSpends[index].pointsSpent = 0;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectAttributeToImprove = (level: number, index: number, attributeName: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {
        theLevel.skillPointSpends[index].attributeName = attributeName;
        theLevel.skillPointSpends[index].pointType = "";
        theLevel.skillPointSpends[index].pointsSpent = 0;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectPointTypeToSpend = (level: number, index: number, pointType: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {
        theLevel.skillPointSpends[index].pointType = pointType;
        theLevel.skillPointSpends[index].pointsSpent = 0;
    }

    onSetCharacterTraits(updatedTraits);
}

export const onSelectPointsSpent = (level: number, index: number, pointsSpent: number, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    const theLevel = updatedTraits.levels.find((lev) => lev.level === level);
    if (theLevel) {
        theLevel.skillPointSpends[index].pointsSpent = pointsSpent;
        const pointsType = theLevel.skillPointSpends[index].pointType;

        // clear any later skill point spends of the same point type
        const maxIndex = theLevel.skillPointSpends.length - 1

        if (index < maxIndex) {
            for (let i = index + 1; i < maxIndex; i++) {
                if (theLevel.skillPointSpends[i].pointType === pointsType) {
                    theLevel.skillPointSpends[i].pointsSpent = 0;
                }
            }
        }

    }

    onSetCharacterTraits(updatedTraits);
}

export const onSetUniqueGift = (focusLevel: number, text: string, characterTraits: CharacterTraits, onSetCharacterTraits: (updatedTraits: CharacterTraits) => void) => {
    const updatedTraits = { ...characterTraits };

    if (focusLevel === 1) {
        updatedTraits.basicTraits.uniqueGift1 = text;
    }

    if (focusLevel === 2) {
        updatedTraits.basicTraits.uniqueGift2 = text;
    }

    onSetCharacterTraits(updatedTraits);
}

export const getTechLevel = (item: EquipmentItem) => {
    // if item has an Artifact Manufacture mod, make it TL 5.
    let isArtifactMod = false;
    if (item.mods) {
        item.mods.forEach((mod) => {
            const theMod = lookups.gearMods.find((m) => m.id === mod.id);
            if (theMod) {
                if (theMod.type === "artifactMod") { isArtifactMod = true; }
            }
        })
    }
    if (isArtifactMod) {
        return 5;
    }
    return item.techLevel;
}
