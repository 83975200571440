import React from 'react';
import { CharacterTraits } from '../../interfaces/CharacterTraits';
import SpendSkillPointsToBuyTechnique from './SpendSkillPointsToBuyTechnique';
import SpendSkillPointsToImproveAttribute from './SpendSkillPointsToImproveAttribute';
import SpendSkillPointsToImproveSkill from './SpendSkillPointsToImproveSkill';

export interface IProps {
    charTraits: CharacterTraits;
    level: number;
    index: number;
    onSelectSpendType: (index: number, spendType: string) => void;
    onSelectSkillToImprove: (index: number, skillName: string) => void;
    onSelectAttributeToImprove: (index: number, attributeName: string) => void;
    onSelectPointTypeToSpend: (index: number, pointType: string) => void;
    onSelectPointsSpent: (index: number, pointsSpent: number) => void;
    onSelectSkillToBuyTechniqueFrom: (index: number, skillName: string) => void;
    onSelectTechniqueToBuy: (level: number, skillPickNumber: number, technique: string) => void;
    onSetPsychicTechniqueWhenLevelUp: (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => void;
}

const SpendSkillPoints: React.FunctionComponent<IProps> = (props: IProps) => {

    let isPsychic = false;
    const psychicClasses = props.charTraits.levelOne.classes.filter((c) => c.className === "Psychic" || c.className === "Partial Psychic");
    if (psychicClasses.length > 0) { isPsychic = true; }

    const onSelectSpendType = (e: any) => {
        props.onSelectSpendType(props.index, e.target.value);
    }

    const onSelectSkillToImprove = (index: number, skillName: string) => {
        props.onSelectSkillToImprove(index, skillName);
    }

    const onSelectAttributeToImprove = (index: number, attributeName: string) => {
        props.onSelectAttributeToImprove(index, attributeName);
    }

    const onSelectPointTypeToSpend = (index: number, pointType: string) => {
        props.onSelectPointTypeToSpend(index, pointType);
    }

    const onSelectPointsSpent = (index: number, pointsSpent: number) => {
        props.onSelectPointsSpent(index, pointsSpent);
    }

    const onSelectSkillToBuyTechniqueFrom = (index: number, skillName: string) => {
        props.onSelectSkillToBuyTechniqueFrom(index, skillName);
    }

    const onSelectTechniqueToBuy = (level: number, skillPickNumber: number, technique: string) => {
        props.onSelectTechniqueToBuy(level, skillPickNumber, technique);
    }

    const onSetPsychicTechniqueWhenLevelUp = (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => {
        props.onSetPsychicTechniqueWhenLevelUp(skill, techniqueName, techniqueLevel, characterLevel, skillLevelGained, skillPickNumber, techniquePickNumber, traitToUpdate);
    }

    let thisLevel = null;
    thisLevel = props.charTraits.levels.find((l) => l.level === props.level);

    return (
        <div className="mb-2">
            {/* <div className="mb-2"> */}
            <select onChange={onSelectSpendType} value={thisLevel?.skillPointSpends[props.index].spendType} className="mr-1">
                <option value="">--Spend Points--</option>
                <option value="improveSkill">Buy skill</option>
                <option value="improveAttribute">Buy attribute</option>
                {isPsychic &&
                    <option value="learnTechnique">Buy technique</option>
                }
            </select>
            {/* </div> */}
            {thisLevel?.skillPointSpends[props.index].spendType === "improveSkill" &&
                <SpendSkillPointsToImproveSkill
                    charTraits={props.charTraits}
                    level={props.level}
                    index={props.index}
                    onSelectSkillToImprove={(index, skillName) => onSelectSkillToImprove(index, skillName)}
                    onSelectPointTypeToSpend={(index, pointType) => onSelectPointTypeToSpend(index, pointType)}
                    onSelectPointsSpent={(index, pointsSpent) => onSelectPointsSpent(index, pointsSpent)}
                    onSetPsychicTechniqueWhenLevelUp={(skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => onSetPsychicTechniqueWhenLevelUp(skill, techniqueName, techniqueLevel, characterLevel, skillLevelGained, skillPickNumber, techniquePickNumber, traitToUpdate)}
                />
            }
            {thisLevel?.skillPointSpends[props.index].spendType === "improveAttribute" &&
                <SpendSkillPointsToImproveAttribute
                    charTraits={props.charTraits}
                    level={props.level}
                    index={props.index}
                    onSelectAttributeToImprove={(index, attributeName) => onSelectAttributeToImprove(index, attributeName)}
                    onSelectPointTypeToSpend={(index, pointType) => onSelectPointTypeToSpend(index, pointType)}
                    onSelectPointsSpent={(index, pointsSpent) => onSelectPointsSpent(index, pointsSpent)}
                />
            }
            {thisLevel?.skillPointSpends[props.index].spendType === "learnTechnique" &&
                <SpendSkillPointsToBuyTechnique
                    charTraits={props.charTraits}
                    level={props.level}
                    index={props.index}
                    onSelectSkillToBuyTechniqueFrom={(index, skillName) => onSelectSkillToBuyTechniqueFrom(index, skillName)}
                    onSelectTechniqueToBuy={(level, skillPickNumber, technique) => onSelectTechniqueToBuy(level, skillPickNumber, technique)}
                    onSelectPointTypeToSpend={(index, pointType) => onSelectPointTypeToSpend(index, pointType)}
                    onSelectPointsSpent={(index, pointsSpent) => onSelectPointsSpent(index, pointsSpent)}
                />
            }
            {/* <pre>{JSON.stringify(props.charTraits.levels, null, 2)}</pre> */}
        </div>
    );
}

export default SpendSkillPoints; 
