import React from "react";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Lookups } from "../../lookups/Lookups";

import { BackgroundData } from "../../interfaces/BackgroundData";

import { CharacterTraits } from "../../interfaces/CharacterTraits";
import { convertAnySkillToSkillListDesc, convertQuickSkillsToSkillListDesc, getTotalSkillLevelPicks } from "../../utilities/SkillUtilities";
import SkillPickControls from "./SkillPickControls";
import { CharacterDerivedStats, CreationStep } from "../../classes/CharacterDerivedStats";
import ValidationAlert from "../ValidationAlert";

interface IProps {
    charTraits: CharacterTraits;
    selectedBackground: BackgroundData;
    onSelectQuickSkills: () => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onManageCustomSkills: () => void;
}

const BackgroundAssign: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;
    const selectedBackground = props.selectedBackground;
    const lookups = Lookups.getInstance();

    // Validation: 

    const totalBackgroundSkillLevelPicks = getTotalSkillLevelPicks(char.background.backgroundSkillLevelPicks);

    // Derived skills to pass to SkillPickControls

    const charDerivedStatsAtBackground = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtBackground.calculateSkillLevels(CreationStep.Background);

    // Skills to pick from 
    const getSkillsToPickFrom = () => {
        if (selectedBackground) {
            if (selectedBackground?.background === "Custom") {
                return convertAnySkillToSkillListDesc("AnyNonPsychicSkill", lookups.skills, true).split(", ");
            } else {
                return convertAnySkillToSkillListDesc(selectedBackground.learningTable, lookups.skills, false).split(", ");
            }
        }
        return [];
    }

    const setQuickSkills = () => {
        props.onSelectQuickSkills();
    }

    return (
        <div className="mt-2 ml-2">
            {selectedBackground.background !== "Custom" &&
                <div className="mb-1">
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    You have one free skill level in {convertQuickSkillsToSkillListDesc(selectedBackground.freeSkill, false, lookups.skills)}. Pick two other skill levels:
                </div>
            }
            {selectedBackground.background === "Custom" &&
                <div className="mb-1">
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    Pick any three skill levels:
                </div>
            }

            <SkillPickControls
                lockedSkillName={selectedBackground?.freeSkill}
                maxLevel={2}
                singleSkillOnly={false}
                skillNamesToChooseFrom={getSkillsToPickFrom()}
                skillLevelsAlreadyPicked={char.background.backgroundSkillLevelPicks}
                skillPointsAlreadyPicked={[]}
                traitToUpdate="background.backgroundSkillLevelPicks"
                charTraits={char}
                charDerivedSkills={charDerivedStatsAtBackground.skillLevels}
                isLevelFocus={false}
                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                onManageCustomSkills={props.onManageCustomSkills}
            />

            {char.background.validationCodes.indexOf("notThreeBgSkillLevels") !== -1 &&
                <ValidationAlert msg={"Select three skill levels (you have selected " + totalBackgroundSkillLevelPicks + ")"} />
            }
            {char.background.validationCodes.indexOf("notPickedBgCombatSkill") !== -1 &&
                <ValidationAlert msg="Select at least one level in a Combat skill (Punch/Stab/Shoot)" />
            }
            {selectedBackground.background !== "Custom" &&
                <div className="mb-0">
                    <button className="btn btn-outline-secondary btn-tiny m-1" onClick={(e) => setQuickSkills()}>Select Quick Skills: {convertQuickSkillsToSkillListDesc(selectedBackground.quickSkills, true, lookups.skills)}</button>
                </div>
            }
        </div>

    )
}

export default BackgroundAssign;