import React from 'react';
import { CharacterDerivedStats, CreationStep } from '../../classes/CharacterDerivedStats';
import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { SkillPoints } from '../../interfaces/SkillPoints';
import ValidationAlert from '../ValidationAlert';

import { getAttributeModifierPlusMinus } from "../../utilities/Utilities";

export interface IProps {
    charTraits: CharacterTraits;
    level: number;
    index: number;
    onSelectAttributeToImprove: (index: number, attributeName: string) => void;
    onSelectPointTypeToSpend: (index: number, pointType: string) => void;
    onSelectPointsSpent: (index: number, pointsSpent: number) => void;
}

const SpendSkillPointsToImproveAttribute: React.FunctionComponent<IProps> = (props: IProps) => {

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateIsPsychic();
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, props.level, props.index);
    charDerivedStats.calculateSkillPointsLeftSoFar();
    charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, props.level, props.index);

    const charDerivedStatsAtLevel1 = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtLevel1.calculateAttributeLevels(CreationStep.AllSteps, 100);

    let thisLevel = props.charTraits.levels.find((l) => l.level === props.level);

    const onSelectAttributeToImprove = (e: any) => {
        props.onSelectAttributeToImprove(props.index, e.target.value);
    }

    const onSelectPointTypeToSpend = (e: any) => {
        props.onSelectPointTypeToSpend(props.index, e.target.value);
    }

    const onSelectPointsSpent = (e: any) => {
        props.onSelectPointsSpent(props.index, parseInt(e.target.value));
    }

    const getPointsLeftAtThisLevelAndIndex = (): SkillPoints => {
        let pointsAvailable: SkillPoints = {
            general: charDerivedStats.skillPointsAvailableSoFar.general - charDerivedStats.skillPointsSpentSoFar.general,
            nonCombatNonPsychic: charDerivedStats.skillPointsAvailableSoFar.nonCombatNonPsychic - charDerivedStats.skillPointsSpentSoFar.nonCombatNonPsychic,
            psychic: charDerivedStats.skillPointsAvailableSoFar.psychic - charDerivedStats.skillPointsSpentSoFar.psychic
        }
        return pointsAvailable;
    }

    const getAttributeDropdown = () => {
        let options: any = [];

        let selectedAttribute = "";
        if (thisLevel) {
            selectedAttribute = thisLevel.skillPointSpends[props.index].attributeName;
        }

        const defaultOption = <option value="">--Attribute--</option>;
        charDerivedStats.attributeLevels.forEach((al, index) => {
            options.push(<option key={index} value={al.attributeName} onChange={onSelectAttributeToImprove}>{al.attributeName} {al.level} ({getAttributeModifierPlusMinus(al.attributeName, al.level !== null ? al.level : 0, charDerivedStatsAtLevel1)})</option>);
        })

        return <select value={selectedAttribute} onChange={onSelectAttributeToImprove} className="mr-1">{defaultOption}{options}</select>;
    }

    const getPointsTypeDropdown = () => {
        let selectedAttribute = "";
        let selectedPointType = "";
        let selectedPointsSpent = 0;
        if (thisLevel) {
            selectedAttribute = thisLevel.skillPointSpends[props.index].attributeName;
            selectedPointType = thisLevel.skillPointSpends[props.index].pointType;
            selectedPointsSpent = thisLevel.skillPointSpends[props.index].pointsSpent;
        }

        const pointsAvailable = getPointsLeftAtThisLevelAndIndex();
        const generalPointsAvailable = pointsAvailable.general > 0 || (selectedPointsSpent > 0 && selectedPointType === "general");
        const nonCnonPPointsAvailable = pointsAvailable.nonCombatNonPsychic > 0 || (selectedPointsSpent > 0 && selectedPointType === "nonCombatNonPsychic");
        const anyPointsAvailable = generalPointsAvailable || nonCnonPPointsAvailable;
        const defaultOption = anyPointsAvailable ? <option value="">--Points Type--</option> : <option value="">--No Suitable Points--</option>;

        if (selectedAttribute !== "") {
            return (
                <select value={selectedPointType} onChange={onSelectPointTypeToSpend} className="mr-1">
                    {defaultOption}
                    {generalPointsAvailable &&
                        <option value="general">General</option>
                    }
                    {nonCnonPPointsAvailable &&
                        <option value="nonCombatNonPsychic">Non-com/Non-psy</option>
                    }
                </select>
            )
        }
        return null;
    }

    const getPointsSpentDropdown = () => {
        let selectedPointType = "";
        let selectedPointsSpent = 0;
        if (thisLevel) {
            selectedPointType = thisLevel.skillPointSpends[props.index].pointType;
            selectedPointsSpent = thisLevel.skillPointSpends[props.index].pointsSpent;
        }

        let maxPointsAvailable = 0;

        const pointsLeft = getPointsLeftAtThisLevelAndIndex();
        switch (selectedPointType) {
            case "general": maxPointsAvailable = pointsLeft.general + selectedPointsSpent; break;
            case "nonCombatNonPsychic": maxPointsAvailable = pointsLeft.nonCombatNonPsychic + selectedPointsSpent; break;
            case "psychic": maxPointsAvailable = pointsLeft.psychic + selectedPointsSpent; break;
        }

        const getDefaultOption = (maxNum: number) => {
            if (maxNum <= 0) {
                return <option value="0">--No Points Left--</option>
            } else {
                return <option value="0">--Points--</option>
            };
        }

        const getOptions = (maxNum: number) => {
            if (maxNum === 0) { return null; }
            const pluralise = (points: number) => { return points > 1 ? "s" : "" };
            const output: any[] = [];
            for (let i = 0; i <= maxNum - 1; i++) {
                output.push(<option key={i} value={i + 1}>{i + 1} point{pluralise(i + 1)}</option>)
            }
            return output;
        }

        if (selectedPointType !== "") {
            return (
                <select value={selectedPointsSpent} onChange={onSelectPointsSpent}>
                    {getDefaultOption(maxPointsAvailable)}
                    {getOptions(maxPointsAvailable)}
                </select>
            )
        }
        return null;
    }

    const getImprovementNote = () => {

        let selectedAttribute = "";
        if (thisLevel) {
            selectedAttribute = thisLevel.skillPointSpends[props.index].attributeName;
        }

        if (selectedAttribute !== "") {
            const attSkillTracking: any = charDerivedStats.attributeSkillPointsTracking;
            const pointsSpentOnTheAttribute = attSkillTracking[selectedAttribute].pointsSpent;
            const pointsInvestedOnTheAttribute = attSkillTracking[selectedAttribute].pointsInvested;

            // get attribute level from level one (inc.background growth rolls)
            let attributeLevelAtLevel1 = null;
            const thisAttributeAtLevel1 = charDerivedStatsAtLevel1.attributeLevels.find((al) => al.attributeName === selectedAttribute);
            if (thisAttributeAtLevel1) {
                attributeLevelAtLevel1 = thisAttributeAtLevel1.level;
            }

            // Create the note text
            const thisAttribute = charDerivedStats.attributeLevels.find((al) => al.attributeName === selectedAttribute);
            if (thisAttribute) {

                const attributeLevel = thisAttribute.level ? thisAttribute.level : 0;

                let pointsToAdvance = charDerivedStats.totalAttributeBoosts + 1;
                pointsToAdvance = pointsToAdvance + pointsInvestedOnTheAttribute;

                const pluralise = (points: number) => { return points !== 1 ? "s" : "" };

                let comments = [];
                if (attributeLevel !== null && attributeLevel >= 18) {
                    comments.push(selectedAttribute + " has reached the maximum score of 18");
                }
                if (charDerivedStats.totalAttributeBoosts < 5 && attributeLevel !== null && attributeLevel < 18) {
                    comments.push("at +" + pointsToAdvance + " skill point" + pluralise(pointsToAdvance) + " will to improve to " + selectedAttribute + "-" + (attributeLevel + 1));
                }
                if (charDerivedStats.totalAttributeBoosts >= 5) {
                    comments.push("you have reached the limit of 5 attribute bonuses");
                }

                return (
                    <div className="small">
                        {selectedAttribute}-{attributeLevelAtLevel1} + {pointsSpentOnTheAttribute} skill point{pluralise(pointsSpentOnTheAttribute)} gives {selectedAttribute}-{attributeLevel};&nbsp;
                        {comments.join("; ")}
                    </div>
                )

            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    return (

        <>
            {getAttributeDropdown()}{getPointsTypeDropdown()}{getPointsSpentDropdown()}<br />
            {getImprovementNote()}
            <div>
                {thisLevel && thisLevel.skillPointSpends[props.index].validationCodes.indexOf("mustBeAtLeastLevel3ToBuy3rdAttributeBoost") !== -1 &&
                    <ValidationAlert msg="Must be at least level 3 to buy 3rd attribute boost" />
                }
                {thisLevel && thisLevel.skillPointSpends[props.index].validationCodes.indexOf("mustBeAtLeastLevel6ToBuy4thAttributeBoost") !== -1 &&
                    <ValidationAlert msg="Must be at least level 6 to buy 4th attribute boost" />
                }
                {thisLevel && thisLevel.skillPointSpends[props.index].validationCodes.indexOf("mustBeAtLeastLevel9ToBuy5thAttributeBoost") !== -1 &&
                    <ValidationAlert msg="Must be at least level 9 to buy 5th attribute boost" />
                }
                {thisLevel && thisLevel.skillPointSpends[props.index].validationCodes.indexOf("attributeCannotExceed18") !== -1 &&
                    <ValidationAlert msg="No attribute score can exceed 18" />
                }
            </div>
        </>
    );
}

export default SpendSkillPointsToImproveAttribute; 
