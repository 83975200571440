import React from "react";
import { useTranslation } from 'react-i18next';

const PageNotFound: React.FunctionComponent<unknown> = () => {

    const { t } = useTranslation();

    return (
        <div className="pageBody">
            <h1>{t("pageNotFound.pageNotFound")}</h1>
        </div>
    );
}

export default PageNotFound;
