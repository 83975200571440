import React from 'react';
import { Link } from 'react-router-dom';

const HomeNote: React.FunctionComponent<{}> = () => {

    // return null; 

    return (
        <div className="homeNote">
            <h1 className="pb-2">All Stations Alert!</h1>
            {/* <p className='small'>August 29 2022</p> */}
            <p>The <b>Starship Designer</b> is now live!</p>
            <p>This is a <b>Beta Release</b>, so please keep an eye out for any bugs and report them to <Link to="/contact">SWooNe</Link>.</p>
            <p>The 'shakedown cruise' will last a couple of weeks so we can find any bugs in the basic Ship Designer. The remaining sections will then be released, including ship mods and printing the ship's stats.</p>
        </div>
    )
}

export default HomeNote; 