import { Level, PrintTraits } from './../interfaces/CharacterTraits';
import { useEffect } from "react";
import app from "../firebase";
import * as toastr from "toastr";

import { CharacterTraits, getInitialCharacterTraits, WildTalentTraits } from "../interfaces/CharacterTraits";
import { BackgroundData } from "../interfaces/BackgroundData";
import { ClassData } from "../interfaces/ClassData";
import { SkillData } from "../interfaces/SkillData";
import { FocusData } from "../interfaces/FocusData";
import { checkAndValidateCharacterTraits } from "../utilities/charMainUtilities";

export const useEffectHookLoadCharacter = (IdInQuerystring: string, currentUser: firebase.User | null, setCharacterId: any, setCharacterTraits: any, setIsLoading: any, backgroundsData: BackgroundData[], classesData: ClassData[], skillsData: SkillData[], focusesData: FocusData[]) => {

    useEffect(() => {
        // This loads the character for the ID set in the URL when the component has first finished rendering.

        const isNullToString = (x: any) => x === null || x === undefined ? "" : x;
        const isNullToHuman = (x: any) => x === null || x === undefined ? "Human" : x;
        const isNullToBoolean = (x: any) => x === null || x === undefined ? false : x;
        const isNullToArray = (x: any) => x === null || x === undefined ? [] : x;
        const isNullToFocusPick = (x: any) => x === null || x === undefined ? { focus: "", level: 0, type: "", skillLevelPicks: [], grantsSkill: false } : x;
        const isNullToNumber = (x: any, defaultNum: number) => x === null || x === undefined ? defaultNum : x;
        const isNullToGear = (x: any) => x === null || x === undefined ? { items: [], method: "", pack: "", startingCredits: -1, additionalCredits: 0, credits: -1 } : handleAdditionalCredits(x);
        const isNullToLevel = (x: any) => {
            if (x === null || x === undefined) {
                return [];
            } else {
                // add any missing skillPointSpend.psychicTechniquePicks
                let levels: Level[] = x;

                levels.forEach((lev) => {
                    lev.skillPointSpends.forEach((sps) => {
                        if (!sps.psychicTechniquePicks) {
                            sps.psychicTechniquePicks = [];
                        }
                    })
                })

                return levels;
            }
        };

        const handleAdditionalCredits = (x: any) => {
            if (!x.additionalCredits) {
                x.additionalCredits = 0;
            }
            return x;
        }

        const isNullToWildTalentPicks = (x: any) => {
            if (x === null || x === undefined) {
                const blankWildTalent: WildTalentTraits = { wildTalentPsychicDiscipline: "", wildTalentTechnique1: "", wildTalentTechnique2: "" };
                return blankWildTalent
            } else {
                return {
                    wildTalentPsychicDiscipline: isNullToString(x.wildTalentPsychicDiscipline),
                    wildTalentTechnique1: isNullToString(x.wildTalentTechnique1),
                    wildTalentTechnique2: isNullToString(x.wildTalentTechnique2),
                }
            }
        }

        const isNullToPrint = (x: any): PrintTraits => {
            if (x === null || x === undefined) {
                const blankPrint: PrintTraits = {
                    foci: true,
                    psychicDisciplines: true,
                    psychicDisciplinesDesc: true,
                    psychicTechniquesDesc: true,
                    equipment: true,
                    skills: true,
                    includeUntrainedSkills: true,
                    notes: true
                };
                return blankPrint
            } else {
                return {
                    foci: isNullToBoolean(x.foci),
                    psychicDisciplines: isNullToBoolean(x.psychicDisciplines),
                    psychicDisciplinesDesc: isNullToBoolean(x.psychicDisciplinesDesc),
                    psychicTechniquesDesc: isNullToBoolean(x.psychicTechniquesDesc),
                    equipment: isNullToBoolean(x.equipment),
                    skills: isNullToBoolean(x.skills),
                    includeUntrainedSkills: isNullToBoolean(x.includeUntrainedSkills),
                    notes: isNullToBoolean(x.notes)
                }
            }
        }

        const cleanCharacterTraitsData = (data: any, id: string): CharacterTraits => {
            const traits: CharacterTraits = {
                id: isNullToString(id),
                basicTraits: {
                    name: isNullToString(data.basicTraits.name),
                    goal: isNullToString(data.basicTraits.goal),
                    origin: isNullToHuman(data.basicTraits.origin),
                    isPublic: isNullToBoolean(data.basicTraits.isPublic),
                    validationCodes: isNullToArray(data.basicTraits.validationMsgs),
                    collapsedSections: isNullToArray(data.basicTraits.collapsedSections),
                    uniqueGift1: isNullToString(data.basicTraits.uniqueGift1),
                    uniqueGift2: isNullToString(data.basicTraits.uniqueGift2)
                },
                attributeTraits: {
                    method: isNullToString(data.attributeTraits.method),
                    includeNonstandardMethods: isNullToBoolean(data.attributeTraits.includeNonstandardMethods),
                    attributeScores: isNullToArray(data.attributeTraits.attributeScores),
                    originalScores: isNullToArray(data.attributeTraits.originalScores),
                    randomScores: isNullToArray(data.attributeTraits.randomScores),
                    validationCodes: isNullToArray(data.attributeTraits.validationMsgs),
                    attributeNumberSetTo14: isNullToNumber(data.attributeTraits.attributeNumberSetTo14, -1),
                    attributeValueThatWasReplacedWith14: isNullToNumber(data.attributeTraits.attributeValueThatWasReplacedWith14, -1),
                    rerolls: isNullToNumber(data.attributeTraits.rerolls, 0)
                },
                background: {
                    method: isNullToString(data.background.method),
                    backgroundName: isNullToString(data.background.backgroundName),
                    backgroundSkillLevelPicks: isNullToArray(data.background.backgroundSkillLevelPicks),
                    tableRolls: isNullToArray(data.background.tableRolls),
                    validationCodes: isNullToArray(data.background.validationMsgs),
                    languages: isNullToString(data.background.languages),
                    homeworld: isNullToString(data.background.homeworld),
                },
                level: isNullToNumber(data.level, 1),
                levelOne: {
                    classes: isNullToArray(data.levelOne.classes),
                    freeFocusLevelPick: isNullToFocusPick(data.levelOne.freeFocusLevelPick),
                    freeSkillLevelPicks: isNullToArray(data.levelOne.freeSkillLevelPicks),
                    psychicTechniquePicks: isNullToArray(data.levelOne.psychicTechniquePicks),
                    wildTalentPicks: isNullToWildTalentPicks(data.levelOne.wildTalentPicks),
                    isAdventurer: isNullToBoolean(data.levelOne.isAdventurer),
                    rolledHitPoints: isNullToNumber(data.levelOne.rolledHitPoints, 0),
                    hitPointRerolls: isNullToNumber(data.levelOne.hitPointRerolls, 0),
                    validationCodes: isNullToArray(data.levelOne.validationMsgs),
                    vehicleBody: isNullToString(data.levelOne.vehicleBody),
                },
                levels: isNullToLevel(data.levels),
                gear: isNullToGear(data.gear),
                print: isNullToPrint(data.print),
                notes: isNullToString(data.notes),
                isValid: isNullToBoolean(data.isValid),
                validationErrors: [],
                showValidation: isNullToBoolean(data.showValidation)
            }

            return traits;
        }

        const asyncUseEffect = async () => {
            try {
                setIsLoading(true);
                if (currentUser) {
                    if (IdInQuerystring !== undefined && IdInQuerystring !== "") {
                        const db = app.firestore();
                        const docRefDetail = db.collection("characterTraits").doc(IdInQuerystring);
                        docRefDetail.get().then((doc) => {
                            if (doc.exists) {
                                const data = doc.data();
                                if (data) {
                                    setCharacterId(IdInQuerystring);
                                    let traits = cleanCharacterTraitsData(data.traits, IdInQuerystring);
                                    traits = checkAndValidateCharacterTraits(traits);
                                    setCharacterTraits(traits);
                                    setIsLoading(false);
                                }
                            }
                        });
                    } else {
                        let traits = getInitialCharacterTraits(backgroundsData, classesData, skillsData, focusesData);
                        traits = checkAndValidateCharacterTraits(traits);
                        setCharacterTraits(traits);
                        setIsLoading(false);
                    }
                }
            }
            catch (error: any) {
                toastr.error(error);
                setIsLoading(false);
            }
        }
        // Execute the created function directly
        asyncUseEffect();

    }, [currentUser, IdInQuerystring, setCharacterId, setCharacterTraits, setIsLoading, backgroundsData, classesData, skillsData, focusesData])
}