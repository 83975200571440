import React, { useState } from "react";

import { faCaretRight, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CharacterDerivedStats, CreationStep } from '../../classes/CharacterDerivedStats';
import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { Lookups } from '../../lookups/Lookups';
import { getSkillsByType } from '../../utilities/SkillUtilities';
import ValidationAlert from '../ValidationAlert';
import { PsychicTechniquePick } from '../../interfaces/PsychicTechniquePick';
import { TechniqueData } from '../../interfaces/PsychicTechniquesData';
import uniqid from "uniqid";
import DescriptionAlert from "../DescriptionAlert";

export interface IProps {
    charTraits: CharacterTraits;
    level: number;
    skillPickNumber: number;
    onSetPsychicTechniqueWhenLevelUp: (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => void;
}

const SelectPsychicTechniqueWhenSkillLevelIncreases: React.FunctionComponent<IProps> = (props: IProps) => {

    const getInitialDisplayToggles = () => {
        let initialShowTechniqueMap = new Map();
        for (let x = 0; x < 50; x++) {
            initialShowTechniqueMap.set(x, false);
        }
        return initialShowTechniqueMap;
    }

    const [showTechniqueDesc, setShowTechniqueDesc] = useState(getInitialDisplayToggles);

    let charDerivedStatsAtStartOfIndex = new CharacterDerivedStats(props.charTraits);
    if (props.skillPickNumber === 0) {
        // get the skill levels at the end of the previous level
        charDerivedStatsAtStartOfIndex.calculateSkillLevels(CreationStep.AllSteps, 100, 100, props.level - 1, 100, false, false, false);

    } else {
        // get the skill levels at this index. 
        charDerivedStatsAtStartOfIndex.calculateSkillLevels(CreationStep.AllSteps, 100, 100, props.level, props.skillPickNumber - 1, false, false, false);

    }

    let charDerivedStatsAtEndOfIndex = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtEndOfIndex.calculateSkillLevels(CreationStep.AllSteps, 100, 100, props.level, props.skillPickNumber, false, false, false);

    const lookups = Lookups.getInstance();
    const psychicSkills = getSkillsByType("Psychic", lookups.skills);

    interface PsySkillLevel {
        skill: string;
        level: number;
    }

    interface PsySkillLevelChange {
        skill: string;
        level: number;
        levelsGained: number;
    }

    let psySkillsBefore: PsySkillLevel[] = [];
    let psySkillsAfter: PsySkillLevel[] = [];
    let psySkillsLevelChanges: PsySkillLevelChange[] = [];

    charDerivedStatsAtStartOfIndex.skillLevels.forEach((sl) => {
        const isPsychicSkill = psychicSkills.find((s) => s.skill === sl.skill) ? true : false;
        if (isPsychicSkill) {
            if (sl.level !== null && sl.level !== 0) {
                const psySkillLevel: PsySkillLevel = { skill: sl.skill, level: sl.level };
                psySkillsBefore.push(psySkillLevel);
            }

        }
    })

    charDerivedStatsAtEndOfIndex.skillLevels.forEach((sl) => {
        const isPsychicSkill = psychicSkills.find((s) => s.skill === sl.skill) ? true : false;
        if (isPsychicSkill) {
            if (sl.level !== null && sl.level !== 0) {
                const psySkillLevel: PsySkillLevel = { skill: sl.skill, level: sl.level };
                psySkillsAfter.push(psySkillLevel);
            }
        }
    })

    psySkillsAfter.forEach((ps) => {

        const psySkillBefore = psySkillsBefore.find((psb) => psb.skill === ps.skill);
        if (psySkillBefore) {
            if (psySkillBefore.level !== ps.level) {
                const psySkillLevelChange: PsySkillLevelChange = { skill: ps.skill, level: ps.level, levelsGained: ps.level - psySkillBefore.level };
                psySkillsLevelChanges.push(psySkillLevelChange);

            }
        } else {
            const psySkillLevelChange: PsySkillLevelChange = { skill: ps.skill, level: ps.level, levelsGained: ps.level };
            psySkillsLevelChanges.push(psySkillLevelChange);
        }

    })

    const psyTechSorter = (a: TechniqueData, b: TechniqueData) => {
        return a.level > b.level ? -1 : a.name < b.name ? -1 : 1;
    }

    const getTechniqueIsPicked = (skill: string, techniqueName: string, level: number, skillPickNumber: number, techniquePickNumber: number): boolean => {
        if (props.charTraits.levels[props.level - 2]) {
            if (props.charTraits.levels[props.level - 2].skillPointSpends) {
                if (props.charTraits.levels[props.level - 2].skillPointSpends[skillPickNumber]) {
                    if (props.charTraits.levels[props.level - 2].skillPointSpends[skillPickNumber].psychicTechniquePicks[techniquePickNumber]) {
                        const techniqueAtThisPick = props.charTraits.levels[props.level - 2].skillPointSpends[skillPickNumber].psychicTechniquePicks[techniquePickNumber];
                        if (techniqueAtThisPick) {
                            if (techniqueAtThisPick.level === level && techniqueAtThisPick.skill === skill && techniqueAtThisPick.technique === techniqueName) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    const onSetShowDescription = (index: number) => {
        let newShowTechniqueDesc = new Map(showTechniqueDesc);
        const currentValue = newShowTechniqueDesc.get(index);
        newShowTechniqueDesc.set(index, !currentValue);
        setShowTechniqueDesc(newShowTechniqueDesc);
    }

    const getNonCoreTechniqueDescription = (tec: TechniqueData) => {

        const nonCoreDesc: any = [];

        const paragraphs = tec.paragraphs.map((p) => <div className="mb-1" key={uniqid()}>{p}</div>);
        nonCoreDesc.push(<div className="mb-2" key={uniqid()}>{paragraphs}</div>)

        return nonCoreDesc;
    }

    const pickTechnique = (e: any) => {
        const skill = e.target.getAttribute("data-skill");
        const technique = e.target.getAttribute("data-technique");
        const techniqueLevel = parseInt(e.target.getAttribute("data-technique-level"));
        const skillLevelGained = parseInt(e.target.getAttribute("data-skill-level-gained"));
        const skillPickNumber = parseInt(e.target.getAttribute("data-skill-pick-number"));
        const techniquePickNumber = parseInt(e.target.getAttribute("data-technique-pick-number"));
        props.onSetPsychicTechniqueWhenLevelUp(skill, technique, techniqueLevel, props.level, skillLevelGained, skillPickNumber, techniquePickNumber, "level.psychicTechniquePicks");
    }

    const getPointsSpentOnTechnique = (technique: string, level: number, index: number) => {
        let pointsSpentOnTheTechnique = 0;
        props.charTraits.levels.forEach((lev) => {
            if (lev.level <= level) {

                // Get skill points assigned as level up
                lev.skillPointSpends.forEach((slp, thisIndex) => {
                    let maxIndex = 100;
                    if (lev.level === level) { maxIndex = index; }
                    if (thisIndex <= maxIndex) {
                        if (slp.techniqueName === technique) {
                            pointsSpentOnTheTechnique = pointsSpentOnTheTechnique + slp.pointsSpent;
                        }
                    }
                })
            }
        })
        return pointsSpentOnTheTechnique;
    }

    const getTechniquesBoughtWithSkillPoints = (level: number, skillPickNumber: number, lookups: Lookups) => {
        const techniquesAdded: PsychicTechniquePick[] = [];

        props.charTraits.levels.forEach((lev) => {
            if (lev.level <= level) {
                lev.skillPointSpends.forEach((ps, index) => {

                    let indexMax = 100;
                    if (level === lev.level) { indexMax = skillPickNumber; }

                    if (index <= indexMax) {
                        if (ps.spendType === "learnTechnique" && ps.techniqueName !== "") {

                            // Check user has paid total cost of the technique (possibly over multiple skill spends).
                            let techniqueCost = 0;
                            const allTechniques = lookups.psychicTechniques;
                            const techniquesForSkill = allTechniques.find((t) => t.skill === ps.skillName);
                            const theTechnique = techniquesForSkill?.techniques.find((t) => t.name === ps.techniqueName);
                            if (theTechnique) { techniqueCost = theTechnique.level; }

                            let hasPaidForTechnique = false;

                            const pointsSpentOnTechnique = getPointsSpentOnTechnique(ps.techniqueName, lev.level, index);
 
                            if (pointsSpentOnTechnique >= techniqueCost) { hasPaidForTechnique = true; }

                            if (hasPaidForTechnique) {
                                const pick: PsychicTechniquePick = {
                                    skill: "",
                                    technique: ps.techniqueName,
                                    level: 0,
                                    note: "",
                                    sourceType: "",
                                    pickIndex: 0
                                }
                                techniquesAdded.push(pick);
                            }

                        }
                    }

                })
            }
        });
        return techniquesAdded;

    }

    const getTechniqueGainChoices = (psyLevelChanges: PsySkillLevelChange[]) => {
        let output: any[] = [];

        const numTechniquesAlreadyChosenAtThisLevel = props.charTraits.levels[props.level - 2].skillPointSpends[props.skillPickNumber].psychicTechniquePicks.length;

        let techniquePickNumber = 0;
        const skillPickNumber = props.skillPickNumber;
        psyLevelChanges.forEach((pslc) => {

            let techniqueIndex = 0;

            for (let levelGained = pslc.level - pslc.levelsGained; levelGained < pslc.level; levelGained++) {
                if (levelGained > 0) {

                    if (techniquePickNumber <= numTechniquesAlreadyChosenAtThisLevel) {

                        let techniqueHasBeenPicked = false;

                        output.push(<div key={"select" + techniquePickNumber} className="mt-2 mb-2"><FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;Select a {pslc.skill} technique up to level-{levelGained}:</div>)

                        const psySkill = lookups.psychicTechniques.find((pt) => pt.skill === pslc.skill);
                        if (psySkill) {

                            let psySkillTechniqueChoices = psySkill.techniques.filter((t) => t.level <= levelGained);

                            const getAllPreviousPsychicTechniquePicks = (level: number, skillPickNumber: number, techniquePickNumber: number) => {
                                let picks: PsychicTechniquePick[] = [];

                                // Techniques gained by learning psychic skill levels:

                                // level one: 
                                picks = [...props.charTraits.levelOne.psychicTechniquePicks];
                                // subsequent levels:
                                props.charTraits.levels.forEach((lev) => {
                                    if (lev.level < level) {
                                        lev.skillPointSpends.forEach((sps) => {
                                            picks = [...picks, ...sps.psychicTechniquePicks];
                                        })
                                    }
                                    if (lev.level === level) {
                                        lev.skillPointSpends.forEach((sps, spsIndex) => {

                                            if (spsIndex < skillPickNumber) {
                                                picks = [...picks, ...sps.psychicTechniquePicks];
                                            }

                                            if (spsIndex === skillPickNumber) {
                                                sps.psychicTechniquePicks.forEach((ptp, ptpIndex) => {
                                                    if (ptpIndex < techniquePickNumber) {
                                                        picks.push(ptp);
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })

                                // add techniques gained by spending skill points directly on technique:
                                const techniquesBought = getTechniquesBoughtWithSkillPoints(level, skillPickNumber, lookups);
                                picks = [...picks, ...techniquesBought]; 
                                return picks;
                            }

                            // remove any techniques already gained unless they were picked at this level + index
                            psySkillTechniqueChoices = psySkillTechniqueChoices.filter((t) => {
                                const picks = getAllPreviousPsychicTechniquePicks(props.level, props.skillPickNumber, techniquePickNumber);
                                const alreadyPickedTechnique = picks.find((ptp) => {
                                    if (ptp) {
                                        return ptp.technique === t.name;
                                    }
                                    return null;
                                })
                                return !alreadyPickedTechnique;
                            });

                            psySkillTechniqueChoices.sort(psyTechSorter);

                            const getButton = (index: number) => {
                                return <button className="ml-2 btn btn-outline-info btn-tiny" type="button" data-index={techniqueIndex} onClick={(e) => onSetShowDescription(index)} title="Show level one technique description">
                                    <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                                </button>
                            }

                            if (psySkillTechniqueChoices) {
                                psySkillTechniqueChoices.forEach((pc, index) => {
                                    const techniqueChecked = getTechniqueIsPicked(psySkill.skill, pc.name, pc.level, props.skillPickNumber, techniquePickNumber);
                                    if (techniqueChecked) { techniqueHasBeenPicked = true; }
                                    const select = <label><input type="radio" value={pc.name} key={uniqid()} checked={techniqueChecked} data-skill={psySkill.skill} data-technique={pc.name} data-technique-level={pc.level} data-skill-level-gained={levelGained} data-skill-pick-number={skillPickNumber} data-technique-pick-number={techniquePickNumber} onChange={pickTechnique}></input> <b>{pc.name} (Level-{pc.level})</b></label>;

                                    output.push(
                                        <div key={uniqid()}>
                                            <div className="mb-1">
                                                {select}&nbsp;
                                                {getButton(techniqueIndex)}
                                            </div>
                                            {showTechniqueDesc.get(techniqueIndex) === true &&
                                                <DescriptionAlert display={true}>
                                                    {getNonCoreTechniqueDescription(pc)}
                                                </DescriptionAlert>
                                            }
                                        </div>);
                                    techniqueIndex = techniqueIndex + 1;
                                })
                            }

                            if (props.charTraits.showValidation && !techniqueHasBeenPicked) {
                                output.push(
                                    <div key={uniqid()}><ValidationAlert msg="You must select a technique" /></div>

                                )
                            }
                        }

                        techniquePickNumber = techniquePickNumber + 1;

                    }

                }
            }

        })

        return output;
    }


    return (
        <>
            {/* <div>{props.index}</div> */}
            {/* <div><pre>{JSON.stringify(psySkillsBefore, null, 2)}</pre></div>
            <div><pre>{JSON.stringify(psySkillsAfter, null, 2)}</pre></div>
            <div><pre>{JSON.stringify(psySkillsLevelChanges, null, 2)}</pre></div> */}

            {getTechniqueGainChoices(psySkillsLevelChanges)}
        </>
    );
}

export default SelectPsychicTechniqueWhenSkillLevelIncreases; 
