import React from 'react';
import { version } from "../version";

const HomeIntro: React.FunctionComponent<{}> = () => {
    return (
        <>
            <div className="homeSplash">
                <div>
                    Stars Without Number
                </div>
                <div className="homeSplashSubtitle">
                    Character & Starship Designer
                </div>
                <div className="homeVersionNumber">
                    ver {version}
                </div>
            </div>
            {/* <p className="homeIntro"><span className="freebooter">Freebooter</span> is a character designer for the popular <span className="swn"><a href="https://www.drivethrurpg.com/product/230009/Stars-Without-Number-Revised-Edition-Free-Version" target="_blank" rel="noopener noreferrer">Stars Without Number (Revised Edition)</a></span> tabletop roleplaying game, created by Kevin Crawford of Sine Nomine Games. </p> */}
        </>
    )
}

export default HomeIntro;