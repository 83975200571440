import React from "react";
import { Link } from "react-router-dom";

type IProps = {}

const CustomHome: React.FunctionComponent<IProps> = (props: IProps) => {


    return (
        <div className="pageBody">
            <h1 className="pageHeading">Customisation</h1>

            <p><Link className="btn btn-primary mr-2" to="/customAlienFoci">Alien Foci</Link>Create foci for Alien species</p>

        </div>
    );
}

export default CustomHome;
