import React, { useState } from "react";

import { faEye, faCaretRight, faShoppingCart, faRecycle, faShoppingBag, faHandPaper, faArchive, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterTraits, EquipmentItem } from "../../interfaces/CharacterTraits";
import { GearData } from "../../interfaces/GearData";
import { Lookups } from "../../lookups/Lookups";
import { formatArmor, formatAttack, formatAttributes, formatCredits, formatDamage, formatMagazine, formatRange, formatShock } from "../../utilities/Utilities";
import DescriptionAlert from "../DescriptionAlert";
import ValidationAlert from "../ValidationAlert";

import { CharacterDerivedStats, CreationStep } from "../../classes/CharacterDerivedStats";
import uniqid from "uniqid";
import CustomiseGearModal from "./CustomizeGearModal";
import { sortItems, getTechLevel } from "../../utilities/charMainUtilities";

export interface IProps {
    charTraits: CharacterTraits;
    onBuyGear: (uniqid: string, id: string, name: string, price: number, buy: boolean, storage: string) => void;
    onSetItemStorage: (uniqid: string, existingStorage: string, newStorage: string) => void;
    onSetAdditionalCredits: (additionalCredits: number) => void;
    onAddMod: (itemUniqueId: string, modId: string, isAddMod: boolean) => void;
    onSetCustomName: (itemUniqueId: string, customName: string) => void;
    onSetCustomNotes: (itemUniqueId: string, customNotes: string) => void;
}

const BuyEquipment: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;
    const lookups = Lookups.getInstance();

    const [addCreds, setAddCreds] = useState(props.charTraits.gear.additionalCredits.toString());

    const gearCategories = ["Armor", "Ranged Weapons", "Melee Weapons", "Heavy Weapons", "General Equipment", "Vehicles", "Drones", "Cyberware", "Robots"];

    const initialDisplayGear: string[] = [];

    const [onlyIncludeAffordableItems, setOnlyIncludeAffordableItems] = useState(true);
    const [allowDebt, setAllowDebt] = useState(false);
    const [includeArtifacts, setIncludeArtifacts] = useState(false);
    const [gearCategory, setGearCategory] = useState("");
    const [displayGearDesc, setDisplayGearDesc] = useState(initialDisplayGear);
    const [openCustomizeModal, setOpenCustomiseModal] = useState(false);
    const [itemUniqueId, setItemUniqueId] = useState("");

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateIsPsychic();
    charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, 100, 100);
    charDerivedStats.calculateEncumbrance(CreationStep.AllSteps);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);
    charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps);
    charDerivedStats.calculateTotalGearValue();

    const onBuyGear = (uniqid: string, id: string, name: string, price: number, storage: string) => {
        const buy = true;
        props.onBuyGear(uniqid, id, name, price, buy, storage);
    }

    const onSellGear = (uniqid: string, id: string, name: string, price: number, storage: string) => {
        const buy = false;
        props.onBuyGear(uniqid, id, name, price, buy, storage);
    }

    const onSetStorage = (uniqid: string, currentStorage: string, newStorage: string) => {
        props.onSetItemStorage(uniqid, currentStorage, newStorage);
    }

    const formatEnc = (enc: number, quantity: number = 1, item?: EquipmentItem) => {
        if (enc === -1) { return "~"; } // -1 is items with neglible encumbrance
        if (enc === 100) { return "*"; } // 100 is non-portable items.
        if (enc % 1 !== 0) {
            // Fractional .33 items count as 1 per 3 items (in a pack)
            return Math.ceil((enc * quantity)) + "#";
        }
        return enc * quantity;
    }

    const formatControlRange = (range: number | undefined) => {
        if (range === undefined) { return "?"; }
        if (range < 1000) {
            return range + "m";
        }
        return range / 1000 + "km";
    }

    const effectiveEnc = (enc: number, quantity: number = 1) => {
        if (enc === -1) { return 0; } // -1 is items with neglible encumbrance
        if (enc % 1 !== 0) {
            // Fractional .33 items count as 1 per 3 items (in a pack)
            return Math.ceil((enc * quantity));
        }
        return enc * quantity;
    }

    const getEquipmentDescription = (item: EquipmentItem) => {
        if (item.type === "Armor") {
            return <div key={uniqid()}>See 'Armor Class' below</div>
        }
        if (item.type === "Ranged Weapons" || item.type === "Heavy Weapons") {
            return <div key={uniqid()}>See 'Ranged Weapons' below</div>
        }
        if (item.type === "Melee Weapons") {
            return <div key={uniqid()}>See 'Melee Weapons' below</div>
        }
        if (item.type === "Vehicles") {
            return <div key={uniqid()}>See 'Vehicles' below</div>
        }
        if (item.type === "Drones" && item.subtype === "Drones") {
            return <div key={uniqid()}>See 'Drones' below</div>
        }
        if (item.type === "Cyberware") {
            return <div key={uniqid()}>Strain&nbsp;{item.strain}, {item.shortDesc}</div>
        }
        if (item.type === "Robots") {
            return <div key={uniqid()}>See 'Robots' below</div>
        }
    }

    const disableBuy1 = (g: GearData, charDerivedStats: CharacterDerivedStats): boolean => {
        if (!allowDebt) {
            return g.cost > charDerivedStats.creditsAvailable
        } else {
            return false;
        }
    }

    const disableBuy2a = (e: EquipmentItem, charDerivedStats: CharacterDerivedStats): boolean => {
        if (!allowDebt) {
            return e.cost > charDerivedStats.creditsAvailable;
        } else {
            return false;
        }
    }

    const onSetShowDescription = (id: string, name: string) => {
        let updatedDisplayGearDesc = [...displayGearDesc];
        if (displayGearDesc.indexOf(id + "_" + name) !== -1) {
            updatedDisplayGearDesc = updatedDisplayGearDesc.filter((g) => g !== id + "_" + name);
        } else {
            updatedDisplayGearDesc.push(id + "_" + name)
        }
        setDisplayGearDesc(updatedDisplayGearDesc);
    }

    const getItemsToBuy = (theType: string, subtype: string) => {
        let items = lookups.gear.filter((g) => g.type === theType && (g.subtype ? g.subtype === subtype : false));

        // Only include Body Arsenal items if character has the Body Arsenal cyberware implant.
        const hasBodyArsenal = props.charTraits.gear.equipment.find((g) => g.name === "Body Arsenal Array");
        if (!hasBodyArsenal) {
            items = items.filter((i) => i.names[0].indexOf("Body Arsenal Weapon") === -1);
        }

        let itemsControls = null;

        const getNumberOwned = (id: string, name: string) => {
            const thisItems = char.gear.equipment.filter((i) => i.id === id && i.name === name);
            if (thisItems) {
                let totalQuantity = 0;
                thisItems.forEach((item) => {
                    item.storageQuantity.forEach((i) => { totalQuantity += i.quantity; })
                })
                if (totalQuantity > 0) {
                    return <span>&nbsp;(own {totalQuantity})</span>;
                }
                return null;
            }
            return null;
        }

        const affordableItems = items.filter((g) => onlyIncludeAffordableItems && !allowDebt ? g.cost <= charDerivedStats.creditsAvailable : true);

        if (theType === "Armor") {
            itemsControls = affordableItems.map((g) =>

                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_gb"} >
                    <div className="col-12 col-sm-8 col-md-3 col-lg-3 col-xl-2 small">
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-12 col-sm-4 text-sm-right col-md-2 col-lg-2 col-xl-1 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Readied")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-primary btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                    </div>
                    <div className="col-4 col-sm-3 col-md-2 col-lg-1 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-4 col-sm-3 col-md-2 col-lg-1 col-xl-1 small">Enc.&nbsp;{formatEnc(g.enc)}</div>
                    <div className="col-4 col-sm-3 col-md-1 col-lg-1 col-xl-1 small">TL&nbsp;{g.techLevel}</div>
                    <div className="col-4 col-sm-3 text-sm-right col-md-2 col-lg-4 col-xl-1 small">AC {g.baseAC ? g.baseAC : "?"}<wbr></wbr>{g.bonusToAC ? "/+" + g.bonusToAC : null}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "Ranged Weapons") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_gb"} >
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Readied")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Dmg {formatDamage(g.damage, g.burst, g.suppress)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Rng {formatRange(g.range)}</div>
                    <div className="col-3 col-sm-3 col-md-4 col-lg-3 col-xl-1 small">Mag {formatMagazine(g.magazine, g.magazineReload)}</div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatAttributes(g.attributes)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">Enc. {formatEnc(g.enc)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">TL {g.techLevel}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "Heavy Weapons") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_gb"} >
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Readied")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Dmg {formatDamage(g.damage, g.burst, g.suppress)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Rng {formatRange(g.range)}</div>
                    <div className="col-3 col-sm-3 col-md-4 col-lg-3 col-xl-1 small">Mag {formatMagazine(g.magazine, g.magazineReload)}</div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatAttributes(g.attributes)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">Enc. {formatEnc(g.enc)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">TL {g.techLevel}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            )
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "Melee Weapons") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_gb"} >
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Readied")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Dmg&nbsp;{formatDamage(g.damage, g.burst)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Shock&nbsp;{formatShock(g.shock)}</div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatAttributes(g.attributes)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">Enc.&nbsp;{formatEnc(g.enc)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">TL&nbsp;{g.techLevel}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "General Equipment") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_gb"} >
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Readied")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">Enc.&nbsp;{formatEnc(g.enc)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">TL&nbsp;{g.techLevel}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "Vehicles") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_gb"} >
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Readied")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Spd {g.speed}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Armor {formatArmor(g.armor)}</div>
                    <div className="col-3 col-sm-3 col-md-4 col-lg-3 col-xl-1 small">HP {g.hp}</div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">Crew {g.crew}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">Tons {g.tonnage}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">TL {g.techLevel}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "Drones" && subtype === "Drones") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_gb"} >
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Readied")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 small">Fittings&nbsp;{g.fittings}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 small">Rng&nbsp;{formatControlRange(g.controlRange)}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">AC&nbsp;{g.ac}</div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-1 small">HP&nbsp;{g.hp}</div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">Enc.&nbsp;{formatEnc(g.enc)}</div>
                    <div className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-1 small">TL&nbsp;{g.techLevel}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "Cyberware") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_cy"} >
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Cyberware")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">Strain&nbsp;{g.strain}</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">TL&nbsp;{g.techLevel}</div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 small">{g.shortDesc}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }

        if (theType === "Robots") {
            itemsControls = affordableItems.map((g) =>
                <div className="row border-bottom pt-1 pb-1" key={g.id + "_" + g.names[0] + "_cy"} >
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 small">
                        <button disabled={disableBuy1(g, charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyGear("", g.id, g.names[0], g.cost, "Robots")}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.names[0])} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>
                        <span className="itemName">{g.names[0]}{getNumberOwned(g.id, g.names[0])}</span>
                    </div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">{formatCredits(g.cost)}</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">HD&nbsp;{g.hitDice}</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">AC&nbsp;{g.ac}</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">Atk&nbsp;{formatAttack(g.attackBonus)}/{formatDamage(g.damage, false)}</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">Move&nbsp;{g.move}m</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">Skill&nbsp;+{g.skill}</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">Save&nbsp;{g.save}+</div>
                    <div className="col-3 col-sm-4 col-md-2 col-lg-1 col-xl-1 small">TL&nbsp;{g.techLevel}</div>
                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 small">{g.shortDesc}</div> */}
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.names[0]) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            );
            addItemNotes(itemsControls, affordableItems);
        }


        if (itemsControls && itemsControls.length > 0) {
            return (
                <div className="container-fluid" key={theType + "_" + subtype}>
                    {itemsControls}
                </div>);
        }
        return null;
    }

    const addItemNotes = (itemsControls: any, affordableItems: any[], storageType: string = "") => {
        const anyCanFireInBurstMode = affordableItems.filter((i) => i.burst && i.burst === true);
        const anyCanFireToSuppress = affordableItems.filter((i) => i.suppress && i.suppress === true);
        const anyNegligibleEnc = affordableItems.filter((i) => i.enc === -1);
        const anyNonPortable = affordableItems.filter((i) => i.enc === 100);
        const anyPackOfThree = affordableItems.filter((i) => i.enc % 1 !== 0);

        const notes: string[] = [];
        if (anyCanFireInBurstMode.length > 0) { notes.push("*: Can fire in burst mode"); }
        if (anyCanFireToSuppress.length > 0) { notes.push("%: Can fire to suppress"); }
        if (anyNegligibleEnc.length > 0) { notes.push("~: Negligible encumbrance"); }
        if (anyNonPortable.length > 0) { notes.push("*: Not portable"); }
        if (anyPackOfThree.length > 0) { notes.push("#: Up to three of these items make a pack equal to one item"); }

        if (notes.length > 0) {
            itemsControls.push(
                <div className="row pt-1 pb-1" key={uniqid()} >
                    <div className="col-12 small">
                        {notes.join("; ")}
                    </div>
                </div>
            )
        }

        return itemsControls;
    }

    const getArmorToBuy = () => {
        let results: any[] = [];
        const armorSubcategories = ["Primitive Armor", "Street Armor", "Combat Armor", "Powered Armor"];

        if (includeArtifacts) { armorSubcategories.push("Artifact Armor"); }

        if (charDerivedStats.psychicTechniqueLevels.find((tl) => tl.technique === "Telekinetic Armory")) {
            armorSubcategories.push("Telekinetic Armory Armor");
        }

        const isVIRobotVehicle = charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Vehicle Bot");
        if (isVIRobotVehicle) {
            armorSubcategories.push("VI Vehicle Bot Armor");
        }

        armorSubcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Armor", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Armor" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("armor", subcategory)} title="Show armour category description">
                            <FontAwesomeIcon icon={faEye} title="Show armor category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("armor_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getRangedWeaponsToBuy = () => {
        let results: any[] = [];
        const subcategories = ["Projectile Weapons", "Energy Weapons"];

        if (charDerivedStats.psychicTechniqueLevels.find((tl) => tl.technique === "Telekinetic Armory")) {
            subcategories.push("Telekinetic Armory Weapons");
        }

        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Ranged Weapons", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Ranged Weapons" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("rangedweapons", subcategory)} title="Show ranged weapon category description">
                            <FontAwesomeIcon icon={faEye} title="Show ranged weapon category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("rangedweapons_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getMeleeWeaponsToBuy = () => {
        let results: any[] = [];
        const subcategories = ["Primitive Weapons", "Advanced Weapons"];

        if (charDerivedStats.psychicTechniqueLevels.find((tl) => tl.technique === "Telekinetic Armory")) {
            subcategories.push("Telekinetic Armory Weapons");
        }

        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Melee Weapons", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Melee Weapons" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("meleeweapons", subcategory)} title="Show melee weapon category description">
                            <FontAwesomeIcon icon={faEye} title="Show melee weapon category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("meleeweapons_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getHeavyWeaponsToBuy = () => {
        let results: any[] = [];
        const subcategories = ["Man-Portable", "Mounted"];

        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Heavy Weapons", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Heavy Weapons" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("heavyweapons", subcategory)} title="Show ranged weapon category description">
                            <FontAwesomeIcon icon={faEye} title="Show heavy weapon category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("heavyweapons_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getGeneralEquipmentToBuy = () => {
        let results: any[] = [];
        const subcategories = lookups.gearCategories.filter((gc) => gc.category === "General Equipment" && gc.subcategory !== "" && gc.subcategory !== "Artifacts").map((gc) => gc.subcategory);
        if (includeArtifacts) { subcategories.push("Artifacts"); }

        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("General Equipment", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "General Equipment" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("generalequipment", subcategory)} title="Show general equipment category description">
                            <FontAwesomeIcon icon={faEye} title="Show general equipment category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("generalequipment_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getVehiclesToBuy = () => {
        let results: any[] = [];
        const subcategories = lookups.gearCategories.filter((gc) => gc.category === "Vehicles" && gc.subcategory !== "").map((gc) => gc.subcategory);
        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Vehicles", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Vehicles" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("vehicles", subcategory)} title="Show vehicles category description">
                            <FontAwesomeIcon icon={faEye} title="Show vehicles category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("vehicles_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getDronesToBuy = () => {
        let results: any[] = [];
        const subcategories = lookups.gearCategories.filter((gc) => gc.category === "Drones" && gc.subcategory !== "").map((gc) => gc.subcategory);
        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Drones", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Drones" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("drones", subcategory)} title="Show drones category description">
                            <FontAwesomeIcon icon={faEye} title="Show drones category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("drones_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getCyberwareToBuy = () => {
        let results: any[] = [];
        const subcategories = lookups.gearCategories.filter((gc) => gc.category === "Cyberware" && gc.subcategory !== "").map((gc) => gc.subcategory);
        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Cyberware", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Cyberware" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("cyberware", subcategory)} title="Show cyberware category description">
                            <FontAwesomeIcon icon={faEye} title="Show cyberware category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("cyberware_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getRobotsToBuy = () => {
        let results: any[] = [];
        const subcategories = lookups.gearCategories.filter((gc) => gc.category === "Robots" && gc.subcategory !== "").map((gc) => gc.subcategory);
        subcategories.forEach((subcategory) => {
            const items = getItemsToBuy("Robots", subcategory);
            let subcatDesc = "";
            const subcatDescData = lookups.gearCategories.find((gc) => gc.category === "Robots" && gc.subcategory === subcategory);
            if (subcatDescData) { subcatDesc = subcatDescData.desc; }
            results.push(
                <span key={subcategory}>
                    <h4 className="mt-2 mb-0" key={subcategory}>
                        {subcategory}&nbsp;
                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("robots", subcategory)} title="Show robots category description">
                            <FontAwesomeIcon icon={faEye} title="Show robots category description"></FontAwesomeIcon >
                        </button>
                    </h4>
                    <DescriptionAlert display={displayGearDesc.indexOf("robots_" + subcategory) !== -1}>
                        {subcatDesc}
                    </DescriptionAlert>
                </span>
            )
            if (items) {
                results.push(items);
            } else {
                results.push(
                    <div className="container-fluid" key={subcategory + "_none"}>
                        <div className="row border-bottom pt-1 pb-1 small" >
                            <div className="col">You cannot afford any of these items.</div>
                        </div>
                    </div>
                )
            }
        })
        return results;
    }

    const getItemsOwnedByStorage = (storageType: string): any => {

        const getByStorageType = (eq: EquipmentItem, storage: string) => {
            if (eq.storageQuantity.map((sq) => sq.storage).indexOf(storage) === -1) {
                return false;
            }
            return true;
        }

        let items = char.gear.equipment.filter((i) => getByStorageType(i, storageType)).sort(sortItems);
        items = items.filter((i) => i.name !== "Unarmed Attack");

        if (items.length > 0) {
            let totalEnc = 0;
            const results = items.map((i) => {
                if (i.name === "Unarmed Attack") { return null; } // Don't show unarmed attack weapon in owned equipment list. 

                let quantDesc = "";
                const storageQuantity = i.storageQuantity.find((sq) => sq.storage === storageType);
                if (storageQuantity) {
                    const quantity = storageQuantity.quantity;
                    if (quantity > 1) { quantDesc = quantity + " x "; }
                    totalEnc = totalEnc + effectiveEnc(i.enc, quantity);

                    interface ModQuanty { name: string, quantity: number };

                    // mods display
                    let hasMods = false;
                    let modList: ModQuanty[] = [];
                    if (i.mods && i.mods.length > 0) {
                        hasMods = true;
                    }
                    if (hasMods) {
                        i.mods?.forEach((mod) => {
                            const modData = lookups.gearMods.find((gm) => gm.id === mod.id);
                            if (modData) {
                                modList.push({ name: modData.name, quantity: mod.quantity });
                            }
                        })
                        modList.sort();
                    }

                    let className = "row border-bottom pt-1 pb-1";
                    if (hasMods || i.customNotes !== undefined) { className = "row pt-1 pb-1" }

                    let name = i.name;
                    if (i.customName && i.customName?.trim() !== "") { name = i.customName; }

                    const getModDesc = () => {
                        let output: any[] = [];
                        if (i.mods && i.mods.length > 0) {
                            let totalMaint = 0;
                            const orderedMods = i.mods.sort((m1, m2) => {
                                const theMod1 = lookups.gearMods.find((mod1) => mod1.id === m1.id);
                                const theMod2 = lookups.gearMods.find((mod2) => mod2.id === m1.id);
                                if (theMod1 && theMod2) {
                                    return theMod1.name < theMod2.name ? -1 : 1;
                                }
                                return 0;
                            })
                            orderedMods.forEach((m) => {
                                const theMod = lookups.gearMods.find((mod) => mod.id === m.id);
                                if (theMod) {
                                    output.push(<li><b>{theMod.name}:</b> {theMod.desc} (Maintenance: {theMod.maintenance})</li>);
                                    totalMaint = totalMaint + theMod.maintenance;
                                }
                            });
                            totalMaint = Math.max(totalMaint, 0);
                            const maint = <div><b>Total Maintenence:</b> {totalMaint}</div>;
                            return <div key={uniqid()} className="mt-2"><b>Mods:</b><ul>{output}</ul>{maint}</div>;
                        }
                        return null;
                    }

                    const getMaint = () => {
                        if (i.mods && i.mods.length > 0) {
                            let totalMaint = 0;
                            i.mods.forEach((m) => {
                                const theMod = lookups.gearMods.find((mod) => mod.id === m.id);
                                if (theMod) {
                                    totalMaint = totalMaint + theMod.maintenance;
                                }
                            });
                            return Math.max(totalMaint, 0);
                        }
                        return 0;
                    }

                    const getModList = (item: EquipmentItem, modList: ModQuanty[]) => {

                        if (modList.length > 0) {
                            let totalFittings = 0;
                            let list: string[] = [];
                            modList.forEach((m) => {
                                if (m.quantity === 1) {
                                    list.push(m.name);
                                } else {
                                    list.push(m.quantity + " x " + m.name);
                                }
                                totalFittings = totalFittings + m.quantity;
                            })

                            let output = <span>Mods: {list.sort().join("; ")} (Maintenance: {getMaint()})</span>;

                            const maxFittings = item.fittings !== undefined ? item.fittings : 0;
                            if (maxFittings > 0) {
                                if (totalFittings > maxFittings) {
                                    output = <>{output}<div><ValidationAlert msg={item.name + " can only have up to " + maxFittings + " fittings"} /></div></>
                                }
                            }

                            return output;
                        }
                        return null;
                    }

                    const formatCustomNotes = (customNotes: string | undefined) => {
                        if (customNotes === undefined) { return null; }
                        return <div>{customNotes}</div>
                    }

                    const formatCreditsForStorage = (cost: number, quantity: number) => {
                        return "$" + (cost * quantity).toLocaleString('en-US', { style: 'decimal', currency: 'USD' });
                    }

                    return (
                        <div key={i.uniqid} >
                            <div className={className} >

                                <div className="col-12 col-sm-7 col-md-3 col-lg-3 col-xl-3 small">
                                    <span className="itemName">{quantDesc}{name}</span>
                                </div>

                                <div className="col-12 text-left col-sm-5 text-sm-right col-md-4 col-lg-3 text-lg-left col-xl-2 small">
                                    {!i.isNaturalDefensesWeapon &&
                                        <>
                                            <div className="dropdown d-inline">
                                                <button className="btn btn-primary btn-tiny dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                    Actions
                                                </button>
                                                <div className="dropdown-menu">

                                                    {!i.isVehicleBody &&
                                                        <>
                                                            <button className="buyAction" type="button" onClick={() => onSellGear(i.uniqid, i.id, i.name, i.cost, storageType)} title="Sell">
                                                                <FontAwesomeIcon icon={faRecycle} title="Sell Item"></FontAwesomeIcon > Sell
                                                            </button>


                                                            <button type="button" disabled={disableBuy2a(i, charDerivedStats)} className="buyAction" onClick={() => onBuyGear(i.uniqid, i.id, i.name, i.cost, storageType)} title="Buy">
                                                                <FontAwesomeIcon icon={faShoppingCart} title="Buy Item"></FontAwesomeIcon > Buy
                                                            </button>
                                                        </>
                                                    }

                                                    {(!i.isCyberware && !i.isVehicleBody) &&
                                                        <>
                                                            {storageType !== "Readied" &&
                                                                <button type="button" data-uniqid={i.uniqid} data-storage={storageType} className="buyAction" onClick={() => onSetStorage(i.uniqid, storageType, "Readied")}>
                                                                    <FontAwesomeIcon icon={faHandPaper} title="Ready Item"></FontAwesomeIcon > Ready
                                                                </button>
                                                            }

                                                            {storageType !== "Stowed" &&
                                                                <button type="button" data-uniqid={i.uniqid} data-storage={storageType} className="buyAction" onClick={() => onSetStorage(i.uniqid, storageType, "Stowed")}>
                                                                    <FontAwesomeIcon icon={faShoppingBag} title="Stow Item"></FontAwesomeIcon > Stow
                                                                </button>
                                                            }

                                                            {storageType !== "Cached" &&
                                                                <button type="button" data-uniqid={i.uniqid} data-storage={storageType} className="buyAction" onClick={() => onSetStorage(i.uniqid, storageType, "Cached")}>
                                                                    <FontAwesomeIcon icon={faArchive} title="Cache Item"></FontAwesomeIcon > Cache
                                                                </button>
                                                            }

                                                        </>
                                                    }

                                                    <button type="button" data-uniqid={i.uniqid} className="buyAction" onClick={() => openTheCustomizeModal(i.uniqid)}>
                                                        <FontAwesomeIcon icon={faWrench} title="Cache Item"></FontAwesomeIcon > Customize
                                                    </button>

                                                </div>
                                            </div>

                                        </>
                                    }

                                    <button className="btn btn-primary btn-tiny d-inline" type="button" onClick={(e) => onSetShowDescription(i.uniqid, "")} title="Show item description">
                                        <FontAwesomeIcon icon={faEye} title="Show description"></FontAwesomeIcon >
                                    </button>
                                </div>

                                <div className="col-5 col-sm-5 col-md-2 col-lg-1 col-xl-1 small">{formatCreditsForStorage(i.cost, quantity)}</div>
                                <div className="col-3 col-sm-3 col-md-2 col-lg-1 col-xl-1 small">Enc.&nbsp;{formatEnc(i.enc, quantity, i)}</div>
                                <div className="col-4 text-right col-sm-4 text-sm-right col-md-1 col-lg-1 col-xl-1 small">TL&nbsp;{getTechLevel(i)}</div>
                                <div className="text-right d-none d-lg-block text-md-left col-lg-3 col-xl-4 small">{getEquipmentDescription(i)}</div>

                                {displayGearDesc.indexOf(i.uniqid + "_") !== -1 &&
                                    <div className="col-12 pb-1 pt-1" key={uniqid()} >
                                        <DescriptionAlert display={true}>
                                            <div>
                                                {i.desc}
                                            </div>
                                            {getModDesc()}
                                        </DescriptionAlert>
                                    </div>
                                }

                            </div >
                            {(hasMods || i.customNotes !== undefined) &&
                                <div key={uniqid()} className="row border-bottom pb-1 small">
                                    <div className="col-12">
                                        <span className="text-secondary">{getModList(i, modList)}{formatCustomNotes(i.customNotes)}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                }
                return null;
            })

            addItemNotes(results, items, storageType);

            // check number of built-in item mods
            let totalBuiltInItemsEnc = 0;
            char.gear.equipment.forEach((e) => {
                e.mods?.forEach((m) => {
                    const theMod = lookups.gearMods.find((mod) => mod.id === m.id);
                    if (theMod) {
                        if (theMod.name === "Built-In") {
                            let totalStorage = 0;
                            e.storageQuantity.forEach((sq) => { totalStorage = totalStorage + sq.quantity; })
                            let rawItemEnc = 0;
                            const rawItem = lookups.gear.find((g) => g.id === e.id);
                            if (rawItem) { rawItemEnc = rawItem.enc; }
                            totalBuiltInItemsEnc = totalBuiltInItemsEnc + (totalStorage * rawItemEnc);
                        }
                    }
                })
            })
            if (totalBuiltInItemsEnc > 0) {
                let CONscore = 0;
                if (charDerivedStats.attributeLevels[2] && charDerivedStats.attributeLevels[2].level) { CONscore = charDerivedStats.attributeLevels[2].level; }
                const halfCON = Math.floor(CONscore / 2);
                if (totalBuiltInItemsEnc > halfCON) {
                    const msg = "You can only have items with the 'Built-In' mod for a total of " + halfCON + " (half CON) encumbrance points. You have " + totalBuiltInItemsEnc + " enc built in."
                    results.push(<ValidationAlert theKey={"builtinValidation_" + storageType} msg={msg} />)
                }
            }

            if (storageType === "Cyberware") {
                if (char.levelOne.validationCodes.indexOf("viCannotPossessCyber") !== -1) {
                    results.push(<ValidationAlert key={"viCannotPossessCyber"} msg="VI characters cannot possess cyberware" />);
                }
            }

            return results;
        } else {
            return (
                <div className="row border-bottom pt-1 pb-2" key={storageType} >
                    <div className="col-12 small">
                        You do not have any {storageType} equipment.
                    </div>
                </div>
            );
        }
    }

    const getItemsOwned = () => {
        let results: any[] = [];
        const storageTypes = ["Readied", "Stowed", "Cached"];

        const hasCyberware = props.charTraits.gear.equipment.find((g) => g.type === "Cyberware");
        if (hasCyberware) {
            storageTypes.splice(2, 0, "Cyberware");
        }

        if (char.gear.equipment.length > 0) {
            storageTypes.forEach((storageType) => {
                results.push(
                    <div className="container-fluid" key={"items owned" + storageType}>
                        <div className="mt-2 mb-2"><h4>{storageType} Equipment</h4></div>
                        {getItemsOwnedByStorage(storageType)}
                    </div>
                );
            })
        } else {
            results.push(
                <div className="container-fluid" key="no items owned">
                    <div className="row border-bottom pt-1 pb-1" >
                        <div className="col small">You do not own any equipment.</div>
                    </div>
                </div>
            )
        }

        if (results.length === 0) { return null; }
        return results;
    }

    const onSelectGearCategory = (e: any): void => {
        const cat = e.currentTarget.value;
        setGearCategory(cat);
    }

    const getGearCategoriesDropdown = () => {
        return (
            <select onChange={onSelectGearCategory} value={gearCategory}>
                <option value="">-- select --</option>
                {gearCategories.map((gt) => <option key={gt} value={gt}>{gt}</option>)}
            </select>
        )
    }
    const gearCategoriesDropdown = getGearCategoriesDropdown();

    let armorDesc = "";
    const armorData = lookups.gearCategories.find((gc) => gc.category === "Armor" && gc.subcategory === "");
    if (armorData) { armorDesc = armorData.desc; }

    let rangedWeaponsDesc = "";
    const rangedWeaponsData = lookups.gearCategories.find((gc) => gc.category === "Ranged Weapons" && gc.subcategory === "");
    if (rangedWeaponsData) { rangedWeaponsDesc = rangedWeaponsData.desc; }

    let meleeWeaponsDesc = "";
    const meleeWeaponsData = lookups.gearCategories.find((gc) => gc.category === "Melee Weapons" && gc.subcategory === "");
    if (meleeWeaponsData) { meleeWeaponsDesc = meleeWeaponsData.desc; }

    let heavyWeaponsDesc = "";
    const heavyWeaponsData = lookups.gearCategories.find((gc) => gc.category === "Heavy Weapons" && gc.subcategory === "");
    if (heavyWeaponsData) { heavyWeaponsDesc = heavyWeaponsData.desc; }

    let generalEquipmentDesc = "";
    const generalEquipmentData = lookups.gearCategories.find((gc) => gc.category === "General Equipment" && gc.subcategory === "");
    if (generalEquipmentData) { generalEquipmentDesc = generalEquipmentData.desc; }

    let vehiclesDesc = "";
    const vehiclesData = lookups.gearCategories.find((gc) => gc.category === "Vehicles" && gc.subcategory === "");
    if (vehiclesData) { vehiclesDesc = vehiclesData.desc; }

    let dronesDesc = "test";
    const dronesData = lookups.gearCategories.find((gc) => gc.category === "Drones" && gc.subcategory === "");
    if (dronesData) { dronesDesc = dronesData.desc; }

    let cyberwareDesc = "";
    const cyberwareData = lookups.gearCategories.find((gc) => gc.category === "Cyberware" && gc.subcategory === "");
    if (cyberwareData) { cyberwareDesc = cyberwareData.desc; }

    let robotsDesc = "";
    const robotsData = lookups.gearCategories.find((gc) => gc.category === "Robots" && gc.subcategory === "");
    if (robotsData) { robotsDesc = robotsData.desc; }

    const onChangeAdditionalCredits = (e: any) => {
        setAddCreds(e.target.value);
        let additionalCredits = parseInt(e.target.value);
        if (isNaN(additionalCredits)) { additionalCredits = 0; }
        props.onSetAdditionalCredits(additionalCredits);
    }

    const openTheCustomizeModal = (itemId: string) => {
        setItemUniqueId(itemId);
        setOpenCustomiseModal(true);
    }

    const closeTheCustomizeModal = () => {
        setOpenCustomiseModal(false);

    }

    return (
        <>
            <div className="mb-2">

                <CustomiseGearModal
                    displayModal={openCustomizeModal}
                    char={char}
                    charDerivedStats={charDerivedStats}
                    itemUniqueId={itemUniqueId}
                    allowDebt={allowDebt}
                    onlyIncludeAffordableItems={onlyIncludeAffordableItems}
                    includeArtifacts={includeArtifacts}
                    onAddMod={(itemUniqueId: string, modId: string, isAddMod: boolean) => props.onAddMod(itemUniqueId, modId, isAddMod)}
                    onSetCustomName={(itemUniqueId: string, customName: string) => props.onSetCustomName(itemUniqueId, customName)}
                    onSetCustomNotes={(itemUniqueId: string, customNotes: string) => props.onSetCustomNotes(itemUniqueId, customNotes)}
                    onClose={() => closeTheCustomizeModal()}
                    onSetAllowDebt={(allowDebt: boolean) => setAllowDebt(allowDebt)}
                    onSetOnlyIncludeAffordableItems={(onlyIncludeAffordableItems: boolean) => setOnlyIncludeAffordableItems(onlyIncludeAffordableItems)}
                    onSetIncludeArtifacts={(includeArtifacts: boolean) => setIncludeArtifacts(includeArtifacts)}
                />

                <div className="mb-2">
                    <b>Starting Credits:</b> {formatCredits(char.gear.startingCredits)}
                </div>

                <div className="mb-2">
                    <b>Additional Credits Earned:</b> <input type="text" value={addCreds} onChange={(e) => onChangeAdditionalCredits(e)} style={{ width: "100px" }} />
                </div>

                <div className="mb-2">
                    <b>Total Equipment Value:</b> {formatCredits(charDerivedStats.totalGearCost)}
                </div>

                <div className="mb-2">
                    <b>Credits Remaining:</b> {formatCredits(charDerivedStats.creditsAvailable)}
                </div>

                <div className="mb-1">
                    <label><input type="checkbox" checked={allowDebt} onChange={() => setAllowDebt(!allowDebt)}></input> Allow going into debt</label>
                </div>

                {!allowDebt &&
                    <div className="mb-2">
                        <label><input type="checkbox" checked={onlyIncludeAffordableItems} onChange={() => setOnlyIncludeAffordableItems(!onlyIncludeAffordableItems)}></input> Only display items you have enough credits to buy</label>
                    </div>
                }

                <div className="mb-1">
                    <label><input type="checkbox" checked={includeArtifacts} onChange={() => setIncludeArtifacts(!includeArtifacts)}></input> Include Artifact items and mods</label>
                    {includeArtifacts &&
                        <div className="small ml-3">You'll find artifacts under Armor, General Equipment and when customizing items with mods</div>
                    }
                </div>

                <div className="mb-1">
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    Select equipment to buy: {gearCategoriesDropdown}
                </div>

                {gearCategory === "Armor" &&
                    <div className="mt-2">
                        <h3>
                            Armor&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("armor", "")} title="Show armour description">
                                <FontAwesomeIcon icon={faEye} title="Show armor description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("armor_") !== -1}>
                            {armorDesc}
                        </DescriptionAlert>
                        {getArmorToBuy()}
                    </div>
                }

                {gearCategory === "Ranged Weapons" &&
                    <div className="mt-2">
                        <h3>
                            Ranged Weapons&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("rangedweapons", "")} title="Show ranged weapons description">
                                <FontAwesomeIcon icon={faEye} title="Show ranged weapons description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("rangedweapons_") !== -1}>
                            {rangedWeaponsDesc}
                        </DescriptionAlert>
                        {getRangedWeaponsToBuy()}
                    </div>
                }

                {gearCategory === "Heavy Weapons" &&
                    <div className="mt-2">
                        <h3>
                            Heavy Weapons&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("heavyweapons", "")} title="Show heavy weapons description">
                                <FontAwesomeIcon icon={faEye} title="Show heavy weapons description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("heavyweapons_") !== -1}>
                            {heavyWeaponsDesc}
                        </DescriptionAlert>
                        {getHeavyWeaponsToBuy()}
                    </div>
                }

                {gearCategory === "Melee Weapons" &&
                    <div className="mt-2">
                        <h3>
                            Melee Weapons&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("meleeweapons", "")} title="Show melee weapons description">
                                <FontAwesomeIcon icon={faEye} title="Show melee weapons description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("meleeweapons_") !== -1}>
                            {meleeWeaponsDesc}
                        </DescriptionAlert>
                        {getMeleeWeaponsToBuy()}
                    </div>
                }

                {gearCategory === "General Equipment" &&
                    <div className="mt-2">
                        <h3>
                            General Equipment&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("generalequipment", "")} title="Show general equipment description">
                                <FontAwesomeIcon icon={faEye} title="Show general equipment description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("generalequipment_") !== -1}>
                            {generalEquipmentDesc}
                        </DescriptionAlert>
                        {getGeneralEquipmentToBuy()}
                    </div>
                }

                {gearCategory === "Vehicles" &&
                    <div className="mt-2">
                        <h3>
                            Vehicles&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("vehicles", "")} title="Show vehicles description">
                                <FontAwesomeIcon icon={faEye} title="Show vehicles description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("vehicles_") !== -1}>
                            {vehiclesDesc}
                        </DescriptionAlert>
                        {getVehiclesToBuy()}
                    </div>
                }

                {gearCategory === "Drones" &&
                    <div className="mt-2">
                        <h3>
                            Drones&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("drones", "")} title="Show drones description">
                                <FontAwesomeIcon icon={faEye} title="Show drones description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("drones_") !== -1}>
                            {dronesDesc}
                        </DescriptionAlert>
                        {getDronesToBuy()}
                    </div>
                }

                {gearCategory === "Cyberware" &&
                    <div className="mt-2">
                        <h3>
                            Cyberware&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("cyberware", "")} title="Show cyberware description">
                                <FontAwesomeIcon icon={faEye} title="Show cyberware description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("cyberware_") !== -1}>
                            {cyberwareDesc}
                        </DescriptionAlert>
                        {getCyberwareToBuy()}
                    </div>
                }

                {gearCategory === "Robots" &&
                    <div className="mt-2">
                        <h3>
                            Robots&nbsp;
                            <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription("robots", "")} title="Show robots description">
                                <FontAwesomeIcon icon={faEye} title="Show robots description"></FontAwesomeIcon >
                            </button>
                        </h3>
                        <DescriptionAlert display={displayGearDesc.indexOf("robots_") !== -1}>
                            {robotsDesc}
                        </DescriptionAlert>
                        {getRobotsToBuy()}
                    </div>
                }

                {["Armor", "Ranged Weapons", "Melee Weapons", "Heavy Weapons", "General Equipment", "Vehicles", "Drones", "Cyberware", "Robots"].indexOf(gearCategory) === -1 && gearCategory !== "" &&
                    <ul><li className="border-top pt-1 pb-1">Coming soon...</li></ul>
                }

            </div>

            <div className="mb-2 mt-3">
                <h3>Equipment Owned</h3>
                {getItemsOwned()}
            </div>

            <div className="mb-2 mt-3">
                <h3>Encumbrance</h3>
                <ul>
                    <li>{charDerivedStats.encumbranceLevel} (base move {charDerivedStats.encumbranceLevelBaseMove} meters per round)</li>
                    <li>Readied Items: {charDerivedStats.readiedEquipmentEncumbrance} (Unencumbered: up to {charDerivedStats.readiedUnencumberedLimit}, Light Enc: up to {charDerivedStats.readiedLightlyEncumberedLimit}, Heavy Enc.: up to {charDerivedStats.readiedHeavilyEncumberedLimit})</li>
                    <li>Stowed Items: {charDerivedStats.stowedEquipmentEncumbrance} (Unencumbered: up to {charDerivedStats.stowedUnencumberedLimit}, Light Enc: up to {charDerivedStats.stowedLightlyEncumberedLimit}, Heavy Enc.: up to {charDerivedStats.stowedHeavilyEncumberedLimit})</li>
                </ul>
            </div>
        </>
    )

}

export default BuyEquipment;