import React from "react";
import { CharacterTraits } from "../interfaces/CharacterTraits";

import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";

interface IProps {
    charTraits: CharacterTraits;
    onSetNotes: (notes: string) => void;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep11Notes: React.FunctionComponent<IProps> = (props: IProps) => {

    const sectionName = "notes";
    const switchDisplay = () => { props.onSwitchCollapsed(sectionName); }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }

    const onSetNotes = (value: string) => {
        props.onSetNotes(value);
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            // ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'link'
    ];

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2>Notes {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                <div style={{ height: "280px" }}>
                    <ReactQuill value={props.charTraits.notes} onChange={onSetNotes} modules={modules} formats={formats} style={{ height: "230px" }} />
                </div>

            </div>
            
        </div>
    );

}

export default CharacterDesignStep11Notes;