import React from "react";
import { CharacterTraits } from "../interfaces/CharacterTraits";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faChevronCircleUp, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import Level from "./charGen/Level";

interface IProps {
    charTraits: CharacterTraits;
    onLevelUp: () => void;
    onLevelDown: () => void;
    onSetLevelHitPoints: (level: number, hitPointRolls: number[]) => void;
    onSelectSkillPointSpendType: (level: number, index: number, spendType: string) => void;
    onSelectSkillToImprove: (level: number, index: number, skillName: string) => void;
    onSelectAttributeToImprove: (level: number, index: number, attributeName: string) => void;
    onSelectPointTypeToSpend: (level: number, index: number, pointType: string) => void;
    onSelectPointsSpent: (level: number, index: number, pointsSpent: number) => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onSelectFocusLevel: (focusName: string, focusLevel: number, traitToUpdate: string) => void;
    onSetPsychicTechniqueWhenLevelUp: (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => void;
    onSelectWildTalentPsychicDiscipline: (skill: string, traitToUpdate: string) => void;
    onSelectWildTalentTechnique: (technique: string, focusLevel: number, traitToUpdate: string) => void;
    onSelectSkillToBuyTechniqueFrom: (level: number, index: number, skillName: string) => void;
    onSelectTechniqueToBuy: (level: number, skillPickNumber: number, technique: string) => void;
    onSwitchCollapsed: (sectionName: string) => void;
    onSetUniqueGift: (focusLevel: number, text: string) => void;
}

const CharacterDesignStep9Leveling: React.FunctionComponent<IProps> = (props: IProps) => {

    const sectionName = "leveling";
    const switchDisplay = () => { props.onSwitchCollapsed(sectionName); }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }

    const nextLevel = props.charTraits.levels.length + 2;

    const getLevels = () => {
        const output: any = [];
        props.charTraits.levels.forEach((lev, index) => {
            output.push(
                <Level
                    key={"lev_" + index}
                    charTraits={props.charTraits}
                    level={lev.level}
                    onLevelUp={props.onLevelUp}
                    onLevelDown={props.onLevelDown}
                    onSetLevelHitPoints={props.onSetLevelHitPoints}
                    onSelectSkillPointSpendType={props.onSelectSkillPointSpendType}
                    onSelectSkillToImprove={props.onSelectSkillToImprove}
                    onSelectAttributeToImprove={props.onSelectAttributeToImprove}
                    onSelectPointTypeToSpend={props.onSelectPointTypeToSpend}
                    onSelectPointsSpent={props.onSelectPointsSpent}
                    onSelectSkillLevelPick={props.onSelectSkillLevelPick}
                    onSelectFocusLevel={props.onSelectFocusLevel}
                    onSetPsychicTechniqueWhenLevelUp={props.onSetPsychicTechniqueWhenLevelUp}
                    onSelectWildTalentPsychicDiscipline={props.onSelectWildTalentPsychicDiscipline}
                    onSelectWildTalentTechnique={props.onSelectWildTalentTechnique}
                    onSelectSkillToBuyTechniqueFrom={props.onSelectSkillToBuyTechniqueFrom}
                    onSelectTechniqueToBuy={props.onSelectTechniqueToBuy}
                    onSwitchCollapsed={(sectionName: string) => props.onSwitchCollapsed(sectionName)}
                    onSetUniqueGift={(focusLevel: number, text: string) => props.onSetUniqueGift(focusLevel, text)}
                />
            );
        })
        return output;
    }

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2>Advancement {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                {props.charTraits.levels.length === 0 &&
                    <div className="mb-2">
                        <button className="btn btn-primary btn-tiny d-inline btn-level" onClick={() => props.onLevelUp()}>
                            <FontAwesomeIcon icon={faArrowUp} title="text-info" className="mr-1"></FontAwesomeIcon >
                            Level Up
                        </button> Gain level {nextLevel}
                    </div>
                }

                {getLevels()}

            </div>

        </div>
    );

}

export default CharacterDesignStep9Leveling;