import React from "react";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";

interface IProps {
    shipTraits: StarshipTraits;
}

const ShipDesignStep11Validation: React.FunctionComponent<IProps> = (props: IProps) => {

    const shipDerivedStats = new ShipDerivedStats(props.shipTraits);

    let output: string[] = [];

    if (shipDerivedStats.totals.totalPowerUsed > shipDerivedStats.powerAvailable) {
        output.push("Power Used exceeds Power Available.")
    }
    if (shipDerivedStats.totals.totalMassUsed > shipDerivedStats.massAvailable) {
        output.push("Mass Used exceeds Mass Available.")
    }
    if (shipDerivedStats.totals.totalHardPointsUsed > shipDerivedStats.hardPointsAvailable) {
        output.push("Hard Points Used exceeds Hard Points Available.")
    }
    if (props.shipTraits.currentFuelLoads > shipDerivedStats.fuelLoadsMax) {
        output.push("Fuel Loads exceeds Maximum Fuel Loads.")
    }
    if (shipDerivedStats.lifeSupportLoad > shipDerivedStats.lifeSupportMax) {
        output.push("Life Support Load (crew and passengers) exceeds Maxumum Life Support.")
    }
    if (shipDerivedStats.cargoCurrentTons > shipDerivedStats.cargoCapacityTons) {
        output.push("Cargo Tonnage exceeds Maximum Cargo Tonnage.")
    }
    if (shipDerivedStats.crewTotal < shipDerivedStats.crewMin) {
        output.push("Crew is less than Minimum Crew.")
    }
    
    const hasAutomationSupport = props.shipTraits.fittings.find((f) => f.id === "SF7");
    if(hasAutomationSupport) {
        if(props.shipTraits.crewData.crewAutomationSupportBots < 1) {
            output.push("Ship has Automation Support fitting but has no Automation Support Bots (see Cargo and Consumables section).")
        }
    }

    if(output.length === 0) {
        output.push("No validation issues.");
    }

    return (
        <div className="chargenSection">
            <h2>Validation</h2>

            <ul>
                {output.map((o, index) =>
                    <li key={index}>{o}</li>
                )}
            </ul>

        </div>
    );

}

export default ShipDesignStep11Validation;