import React, { useState } from "react";
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface IProps {
    uniqueGift: string;
    fieldName: string;
    onChangeText: (theText: string) => void;
}

const UniqueGift: React.FunctionComponent<IProps> = (props: IProps) => {

    const [ug, setUg] = useState(props.uniqueGift);

    const onChangeTheText = (e: any) => {
        setUg(e.currentTarget.value);
    }

    const onBlur = (e: any) => {
        props.onChangeText(ug);
    }

    return (
        <div key={props.fieldName + "div"} className="form-group form-row mb-1">
            <label htmlFor="name" className="col-12 col-sm-3 col-md-2 col-form-label">
                <FontAwesomeIcon icon={faCaretRight} title=""></FontAwesomeIcon >&nbsp;
                Unique Gift
            </label>
            <div className="col-12 col-sm-9 col-md-10">
                <textarea className="form-control" id={props.fieldName} value={ug} onBlur={onBlur} onChange={onChangeTheText} rows={5} />
            </div>
        </div>
    )

}

export default UniqueGift;

