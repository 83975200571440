import React, { useState } from 'react';
import uniqid from 'uniqid';

import { faEye, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { FocusLevel } from '../../interfaces/FocusLevel';
import { FocusLevelPick } from "../../interfaces/FocusLevelPick";
import { SkillData } from '../../interfaces/SkillData';
import { SkillLevel } from '../../interfaces/SkillLevel';
import { SkillLevelPick } from '../../interfaces/SkillLevelPick';

import SkillsJSON from "../../lookups/SkillsAll.json";
import { Lookups } from "../../lookups/Lookups";

import SkillPickControls from './SkillPickControls';
import DescriptionAlert from "../DescriptionAlert";

import { convertAnySkillToSkillListDesc } from '../../utilities/SkillUtilities';
import { SkillPointsPick } from '../../interfaces/SkillPointsPick';
import AttributeModifierPickControl from './AttributeModifierPickControls';
import { AttributeModifierPick } from '../../interfaces/AttributeModifierPick';
import UniqueGift from './UniqueGift';
import { attributeLongNames } from '../../lookups/Enums';

interface IProps {
    className: string;
    focusType: string; // Combat/NonCombat
    focusLevelPicksToChooseFrom: FocusLevelPick[];
    focusLevelAlreadyPicked: FocusLevelPick;
    skillLevelsAlreadyPicked: SkillLevelPick[]; // bonus skill level from level 1 focus
    skillPointsAlreadyPicked: SkillPointsPick[]; // bonus skill points from level 2+ foci/
    attributeModifiersAlreadyPicked: AttributeModifierPick[];
    charTraits: CharacterTraits;
    charDerivedSkills: SkillLevel[];
    charDerivedSkillsAtPreviousStep: SkillLevel[];
    charDerivedFocuses: FocusLevel[];
    traitToUpdate_Focuses: string;
    traitToUpdate_Skills: string;
    traitToUpdate_AttributeModifiers: string;
    includeLeadingIcon: boolean;
    onSelectFocusLevel: (focusName: string, focusLevel: number, traitToUpate: string) => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onSelectAttributeModifierBonusPick: (attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => void;
    onSelectVehicleBody: (vehicleName: string) => void;
    onManageCustomSkills: () => void;
    onSetUniqueGift: (focusLevel: number, text: string) => void;
}

const FocusPickControls: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const [showDescription, setShowDescription] = useState(false);

    const lookups = Lookups.getInstance();

    const skillsData: SkillData[] = SkillsJSON;

    let focusTypeDescription = props.focusType;
    if (props.focusType === "Any") { focusTypeDescription = "any"; }
    if (props.focusType === "Combat") { focusTypeDescription = "a combat-related"; }
    if (props.focusType === "NonCombat") { focusTypeDescription = "a non-combat"; }

    const onChangeFocusLevelPick = (e: any) => {
        const val = e.target.value;
        if (val !== "") {
            const focusNameAndLevel = val.split("/");
            const focusName = focusNameAndLevel[0];
            const focusLevel = parseInt(focusNameAndLevel[1]) - 1;

            props.onSelectFocusLevel(focusName, focusLevel + 1, props.traitToUpdate_Focuses);
        } else {
            props.onSelectFocusLevel("", 0, props.traitToUpdate_Focuses);
        }

    }

    const getFocusDropdown = () => {
        let options: any;
        if (props.focusType === "Any") {
            options = props.focusLevelPicksToChooseFrom.sort().map((flp) => <option key={uniqid()} value={flp.focus + "/" + flp.level}>{flp.focus} {flp.level}</option>);
        } else {
            options = props.focusLevelPicksToChooseFrom.filter((o) => o.type === props.focusType).sort().map((flp) => <option key={uniqid()} value={flp.focus + "/" + flp.level}>{flp.focus} {flp.level}</option>);
        }

        return (
            <select onChange={onChangeFocusLevelPick} value={props.focusLevelAlreadyPicked?.focus + "/" + props.focusLevelAlreadyPicked?.level}>
                <option value="">-- select --</option>
                {options}
            </select>
        );
    }

    const focusDropdown = getFocusDropdown();

    const selectedFocusData = lookups.focuses.find((fd) => fd.focus === props.focusLevelAlreadyPicked?.focus);

    const focusName = selectedFocusData?.focus;
    const focusDescription = selectedFocusData?.desc;
    const focusFlaws = selectedFocusData?.flaws;
    const focusLevel1Description = selectedFocusData?.levels[0].desc;
    let focusLevel2Description = "";
    if (selectedFocusData?.levels[1]) { focusLevel2Description = selectedFocusData?.levels[1].desc; }

    let bonusSkillName = "";

    const focusData = lookups.focuses.find((fd) => fd.focus === props.focusLevelAlreadyPicked?.focus);
    if (focusData) {
        const bonusSkillLevel = focusData.levels[0].bonuses.find((b) => b.type === "bonusSkill");
        if (bonusSkillLevel && bonusSkillLevel.skill) {
            bonusSkillName = convertAnySkillToSkillListDesc(bonusSkillLevel.skill, skillsData, true);
        }
    }

    let pickAnotherSkillMsg = "";
    let passedBonusSkillLimit = false;

    let bonusDerivedSkill = props.charDerivedSkillsAtPreviousStep.find((s) => s.skill === bonusSkillName);
    if (bonusDerivedSkill && bonusDerivedSkill.level && bonusDerivedSkill.level > 1) {
        pickAnotherSkillMsg = "You have already reached the maximum of " + bonusSkillName + " level-1. Pick an alternative skill level:";
        passedBonusSkillLimit = true;
    }

    const getSkillNamesToChooseFrom = (focusName: string, passedBonusSkillLevel: boolean): string[] => {

        let bonusSkillName = "";
        let rawBonusSkillName = "";

        const focusData = lookups.focuses.find((fd) => fd.focus === focusName);
        if (focusData) {
            const bonusSkillLevel = focusData.levels[0].bonuses.find((b) => b.type === "bonusSkill");
            if (bonusSkillLevel && bonusSkillLevel.skill) {
                rawBonusSkillName = bonusSkillLevel.skill;
                bonusSkillName = convertAnySkillToSkillListDesc(bonusSkillLevel.skill, skillsData, true);
            }
        }

        if (!passedBonusSkillLimit) {
            return [bonusSkillName];
        }

        if (rawBonusSkillName) {

            const thisSkillData = skillsData.find((sd) => sd.skill === rawBonusSkillName);
            let skills = [];
            if (thisSkillData) {
                skills = convertAnySkillToSkillListDesc("AnyNonPsychicSkill", skillsData, true).split(", ");
            } else {
                skills = convertAnySkillToSkillListDesc(rawBonusSkillName, skillsData, false).split(", ");
            }
            skills = skills.filter((s) => s !== bonusSkillName);
            return skills;
        }

        return [];
    }

    const skillNamesToChooseFrom = getSkillNamesToChooseFrom(props.focusLevelAlreadyPicked?.focus, passedBonusSkillLimit);

    let focusHistory = "";
    const theFocusesDerived = props.charDerivedFocuses.filter((fl) => fl.focus === props.focusLevelAlreadyPicked.focus);
    if (theFocusesDerived.length > 0) {
        focusHistory = theFocusesDerived.map((f) => f.history).join("; ");
    }

    const getLockedBonusSkillName = () => {
        if (!passedBonusSkillLimit) { return bonusSkillName; }
        return "";
    }

    const addParagraphs = (text: string | undefined) => {
        if (text === undefined) { return ""; }
        const p = text.split("|");
        return p.map((para) => <p key={uniqid()}>{para}</p>);
    }

    const grantsAttributeModifierBonus = () => {
        const selectedFocusData = lookups.focuses.find((fd) => fd.focus === props.focusLevelAlreadyPicked?.focus);
        const focusName = selectedFocusData?.focus;
        const focusData = lookups.focuses.find((fd) => fd.focus === focusName);
        if (focusData) {
            const attributeModifierBonus = focusData.levels[0].bonuses.find((b) => b.type === "attributeModifierBonus");
            if (attributeModifierBonus) { return true; }
        }
        return false;
    }

    const grantsVehicleBody = () => {
        const selectedFocusData = lookups.focuses.find((fd) => fd.focus === props.focusLevelAlreadyPicked?.focus);
        const focusName = selectedFocusData?.focus;
        const focusData = lookups.focuses.find((fd) => fd.focus === focusName);
        if (focusData) {
            const vehicleBodyBonus = focusData.levels[0].bonuses.find((b) => b.type === "vehicleBody");
            if (vehicleBodyBonus) { return true; }
        }
        return false;
    }

    const isUniqueGift = () => {
        return props.focusLevelAlreadyPicked.focus === "Unique Gift";
    }

    const onSelectVehicle = (e: any): void => {
        const vehicleBody = e.target.value;
        props.onSelectVehicleBody(vehicleBody);
    }

    const getVehicleOptions = () => {
        let output: any[] = [];

        let vehicleBody = "";
        if (props.charTraits.levelOne.vehicleBody) {
            vehicleBody = props.charTraits.levelOne.vehicleBody;
        }

        const vehicles = lookups.gear.filter((g) => g.type === "Vehicles");

        output.push(<option key={"selectVehicleBlank"} value="">--- Select ---</option>);

        vehicles.forEach((v) => {
            output.push(<option key={v.names[0]} value={v.names[0]}>{v.names[0]}</option>)
        })

        return <select key={"selectVehicle"} onChange={onSelectVehicle} value={vehicleBody}>{output}</select>;
    }

    const onChangeUniqueGift1 = (theText: string) => {
        const focusLevel = 1;
        props.onSetUniqueGift(focusLevel, theText);
    }

    const onChangeUniqueGift2 = (theText: string) => {
        const focusLevel = 2;
        props.onSetUniqueGift(focusLevel, theText);
    }

    const getAlienBenefits = () => {
        let output: any[] = [];

        const convertSkillName = (skill: string | undefined) => {
            return skill === "AnyCombatSkill" ? "Punch/Shoot/Stab" : skill;
        }

        if (selectedFocusData?.benefits) {
            selectedFocusData?.benefits.forEach((b) => {
                const theBenefit = lookups.alienBenefits.find((ben) => ben.id === b.benefit);
                if (theBenefit) {
                    const comments: string[] = [];
                    if (theBenefit?.benefitMechanicDesc !== "") {
                        if (theBenefit?.isOriginSkill) {
                            comments.push("Gains a free skill level in " + convertSkillName(b.originSkill));
                        } else if (theBenefit.isStrongAttribute) {
                            if (b.attributeModifierBonusIndex) {
                                comments.push("Gains +1 to " + attributeLongNames[b.attributeModifierBonusIndex] + " modifier");
                            }
                        } else {
                            comments.push(theBenefit?.benefitMechanicDesc);
                        }
                    }
                    if (b.note) (comments.push(b.note));

                    let majorMinor = b.isMajor ? "Major" : "Minor";

                    output.push(<li key={b.benefit} className="mr-1"><i>{theBenefit.benefitTitle} ({majorMinor}):</i> {comments.join("; ")}</li>)
                }
            })
        }

        return <ul>{output}</ul>;
    }

    const getAttributeIndexesToChooseFrom = () => {
        let output: number[] = [];

        const selectedFocusData = lookups.focuses.find((fd) => fd.focus === props.focusLevelAlreadyPicked?.focus);
        const focusName = selectedFocusData?.focus;
        const focusData = lookups.focuses.find((fd) => fd.focus === focusName);
        if (focusData) {
            const attributeModifierBonus = focusData.levels[0].bonuses.find((b) => b.type === "attributeModifierBonus");
            if (attributeModifierBonus) {
                if (focusName === "VI - Worker Bot") {
                    output = [0, 1, 2, 3, 4, 5];
                }
                const strongAttribute = focusData.benefits?.find((ben) => ben.benefit === "strongAttribute");
                if (strongAttribute && strongAttribute.attributeModifierBonusIndex !== undefined) {
                    output = [strongAttribute.attributeModifierBonusIndex];
                }
            }
        }

        return output;
    }

    return (
        <>
            <div className="mb-2">
                {props.includeLeadingIcon &&
                    <>
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    </>
                }
                Select a level in {focusTypeDescription} Focus: {focusDropdown}
            </div>
            {(props.focusLevelAlreadyPicked.level !== 0) &&
                <div>

                    {props.focusLevelAlreadyPicked.level === 1 &&

                        <div className="mb-1">
                            {focusName?.indexOf("Alien -") !== -1 &&
                                <div>{focusDescription}</div>
                            }
                            <b>{focusName} - Level 1:</b> {focusLevel1Description}
                            <button className="ml-2 btn btn-outline-info btn-tiny" type="button" title="Show Description" onClick={(e) => setShowDescription(!showDescription)}>
                                <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                            </button>
                            {(focusName?.indexOf("Alien -") !== -1) &&
                                <div>
                                    <b>Benefits:</b> {getAlienBenefits()}
                                </div>
                            }
                            {(focusName?.indexOf("Alien -") !== -1 && focusFlaws) &&
                                <div><b>Flaws:</b> <ul><li>{focusFlaws}</li></ul></div>
                            }
                            {bonusSkillName &&
                                <div className="mt-1">

                                    {skillNamesToChooseFrom[0].split(",").length > 1 &&
                                        <div className="mb-1">
                                            <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                                            Pick a level in a skill:
                                        </div>
                                    }

                                    {pickAnotherSkillMsg !== "" &&
                                        <div className="mb-2">{pickAnotherSkillMsg}</div>
                                    }

                                    {skillNamesToChooseFrom.length > 0 &&
                                        <>
                                            <SkillPickControls
                                                lockedSkillName={getLockedBonusSkillName()}
                                                maxLevel={1}
                                                singleSkillOnly={true}
                                                skillNamesToChooseFrom={skillNamesToChooseFrom}
                                                skillLevelsAlreadyPicked={props.skillLevelsAlreadyPicked}
                                                skillPointsAlreadyPicked={props.skillPointsAlreadyPicked}
                                                traitToUpdate={props.traitToUpdate_Skills}
                                                charTraits={char}
                                                charDerivedSkills={props.charDerivedSkills}
                                                isLevelFocus={false}
                                                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                                                onManageCustomSkills={props.onManageCustomSkills}
                                            />
                                            {/* <div><pre>{JSON.stringify(props.charDerivedSkills.filter((s) => s.history.length > 0), null, 2)}</pre></div> */}
                                            {/* <div><pre>{JSON.stringify(props.charDerivedSkillsAtPreviousStep.filter((s) => s.history.length > 0), null, 2)}</pre></div>  */}
                                            {/* <div><pre>{JSON.stringify(props.charDerivedFocuses, null, 2)}</pre></div> */}

                                        </>

                                    }

                                </div>
                            }
                            {grantsAttributeModifierBonus() &&
                                <>
                                    <div className="mb-1">
                                        {getAttributeIndexesToChooseFrom().length > 1 &&
                                            <>
                                                <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                                                <span>Pick an attribute and gain a +1 bonus to its <b>modifier</b> (up to max. +2):</span>
                                            </>
                                        }
                                    </div>
                                    <AttributeModifierPickControl
                                        charTraits={props.charTraits}
                                        attributeIndexesToChooseFrom={getAttributeIndexesToChooseFrom()}
                                        attributeModifiersAlreadyPicked={props.attributeModifiersAlreadyPicked}
                                        traitToUpdate={props.traitToUpdate_AttributeModifiers}
                                        onSelectAttributeModifierBonusPick={(attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => props.onSelectAttributeModifierBonusPick(attributeIndex, attributeModifierBonus, selected, traitToUpdate)}
                                    />
                                </>
                            }

                            {grantsVehicleBody() &&
                                <>
                                    <div className="mb-1">
                                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                                        Pick a vehicle as your body: {getVehicleOptions()}
                                    </div>
                                    {props.charTraits.levelOne.vehicleBody && props.charTraits.levelOne.vehicleBody !== "" &&
                                        <div className="small">A free {props.charTraits.levelOne.vehicleBody} will be added to your Equipment.</div>
                                    }
                                </>
                            }

                            {isUniqueGift() &&
                                <UniqueGift fieldName={"uniqueGift1_level1"} uniqueGift={char.basicTraits.uniqueGift1} onChangeText={onChangeUniqueGift1} />
                            }

                        </div>
                    }

                    {props.focusLevelAlreadyPicked.level === 2 &&
                        <>
                            <div className="mb-0">
                                <b>{focusName} - Level 2:</b> {focusLevel2Description}
                                <button className="ml-2 btn btn-outline-info btn-tiny" type="button" title="Show Description" onClick={(e) => setShowDescription(!showDescription)}>
                                    <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                                </button>
                            </div>
                            {isUniqueGift() &&
                                <UniqueGift fieldName={"uniqueGift2_level1"} uniqueGift={char.basicTraits.uniqueGift2} onChangeText={onChangeUniqueGift2} />
                            }
                        </>
                    }

                    <DescriptionAlert display={showDescription}>
                        <>
                            <div className="mb-1"><b>{focusName}:</b> {addParagraphs(focusDescription)}</div>
                            <div className="mb-1"><b>Level 1:</b> {focusLevel1Description}</div>
                            {focusLevel2Description !== "" &&
                                <div><b>Level 2:</b> {focusLevel2Description}</div>
                            }
                            {focusHistory !== "" &&
                                <div className="mt-1"><i>History:</i> {focusHistory}</div>
                            }
                        </>
                    </DescriptionAlert>

                </div>
            }
        </>
    )

}

export default FocusPickControls;
