import app from "../firebase";

import * as toastr from "toastr";
import { StarshipTraits } from '../interfaces/StarshipTraits';

const getBasicDataToSave =  (s: StarshipTraits, currentUser: firebase.User) => {
    return {
        hullType: s.hullType, 
        isPublic: s.isPublic,
        name: s.name,
        name_lower: s.name.toLowerCase(),
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}

const getTraitsDataToSave =  (s: StarshipTraits, currentUser: firebase.User) => {
    return {
        name: s.name,
        isPublic: s.isPublic,
        traits: s,
        user_id: currentUser.uid,
        user_email: currentUser.email
    };
}


export const SaveNewStarship = async (
    currentUser: firebase.User,
    starshipTraits: StarshipTraits,
    setStarshipId: any,
) => {

    localStorage.removeItem("tryShipTraits");

    const s = starshipTraits;

    if (currentUser) {

        let starshipId = "";
        
        // Save basic details of ship into 'starship' collection, used for listing starships.
        const basicDataToSave = getBasicDataToSave(s, currentUser);

        const db = app.firestore();
        await db.collection("starship")
            .add(basicDataToSave)
            .then((docRef) => {
                starshipId = docRef.id;
                setStarshipId(starshipId);
            })
            .catch(function (error) {
                toastr.error(error);
            });

        // Save details of character into 'characterTraits' collection, used for editing a character.

        const shipTraitsDataToSave = getTraitsDataToSave(s, currentUser);
        starshipTraits.id = starshipId;

        await db.collection("starshipTraits")
            .doc(starshipId)
            .set(shipTraitsDataToSave)
            .catch(function (error) {
                toastr.error(error);
            });
    }
}

export const UpdateStarship = async (
    currentUser: firebase.User | null,
    starshipTraits: StarshipTraits,
    starshipId: string,

) => {

    localStorage.removeItem("tryShipTraits");
    
    const s = starshipTraits;

    if (currentUser) {

        // Update basic details of character into 'character' collection, used for listing characters.
        const basicDataToSave = getBasicDataToSave(s, currentUser);

        const db = app.firestore();
        await db.collection("starship")
            .doc(starshipId)
            .set(basicDataToSave)
            .catch(function (error) {
                toastr.error(error);
            });

        // Update details of character into 'characterTraits' collection, used for editing a character.
        const starshipTraitsDataToSave = getTraitsDataToSave(s, currentUser);

        await db.collection("starshipTraits")
            .doc(starshipId)
            .set(starshipTraitsDataToSave)
            .catch(function (error) {
                toastr.error(error);
            });
    }
}