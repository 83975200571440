import React, { useState } from 'react';
import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { getSkillsByType } from '../../utilities/SkillUtilities';
import ValidationAlert from '../ValidationAlert';
import { Lookups } from "../../lookups/Lookups";
import { CharacterDerivedStats, CreationStep } from '../../classes/CharacterDerivedStats';

import { faCaretRight, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import uniqid from 'uniqid';
import { CoreTechniqueData, TechniqueData } from '../../interfaces/PsychicTechniquesData';
import DescriptionAlert from '../DescriptionAlert';

interface IProps {
    charTraits: CharacterTraits;
    level: number;
    onSelectWildTalentPsychicDiscipline: (skill: string, traitToUpdate: string) => void;
    onSelectWildTalentTechnique: (technique: string, focusLevel: number, traitToUpdate: string) => void;
}

const WildPsychicTalent: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;
    const lookups = Lookups.getInstance();

    const availableTechniques = lookups.psychicTechniques.filter((pt) => {
        return pt.skill === props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline;
    });

    const getInitialWildTalent1TechniqueDisplayToggles = (skill: string) => {

        const theTechniques = lookups.psychicTechniques.filter((pt) => {
            return pt.skill === skill;
        });

        let initialShowTechniqueMap = new Map();
        theTechniques.forEach((at) => {
            initialShowTechniqueMap.set(at.coreTechnique.name, false);
            at.techniques.forEach((att) => {
                if (att.augments.length === 0 && att.level <= 1) {
                    initialShowTechniqueMap.set(att.name, false);
                }
            })
        })
        return initialShowTechniqueMap;
    }


    const [showWildTalent1TechniqueDesc, setShowWildTalent1TechniqueDesc] = useState(getInitialWildTalent1TechniqueDisplayToggles(props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline));
    const [showWildTalent2TechniqueDesc, setShowWildTalent2TechniqueDesc] = useState(getInitialWildTalent1TechniqueDisplayToggles(props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline));

    let isPsychic = false;
    const psychicClasses = props.charTraits.levelOne.classes.filter((c) => c.className === "Psychic" || c.className === "Partial Psychic");
    if (psychicClasses.length > 0) { isPsychic = true; }
    if (isPsychic) { return null; }

    const onSelectWildTalent1PsychicDiscipline = (e: any): void => {
        const discipline = e.currentTarget.value;
        setShowWildTalent1TechniqueDesc(getInitialWildTalent1TechniqueDisplayToggles(discipline));
        props.onSelectWildTalentPsychicDiscipline(discipline, "levelOne.wildTalenPicks.wildTalentPsychicDiscipline");
    }

    const getWildTalentPsychicDisciplineDropdown = () => {
        const psychicSkills = getSkillsByType("Psychic", lookups.skills);
        return (
            <select key={uniqid()} onChange={onSelectWildTalent1PsychicDiscipline} value={char.levelOne.wildTalentPicks.wildTalentPsychicDiscipline}>
                <option value="" key={uniqid()}>-- select --</option>
                {psychicSkills.map((ps) => <option key={uniqid()} value={ps.skill}>{ps.skill}</option>)}
            </select>
        )
    }
    const wildTalentPsychicDisciplineDropdown = getWildTalentPsychicDisciplineDropdown();

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, -1, props.level);
    const focusLevelsAtThisLevel = charDerivedStats.focusLevels;

    let hasWildTalentLevel1 = false;
    let hasWildTalentLevel2 = false;
    const wildTalentFocuses = focusLevelsAtThisLevel.filter((fl) => fl.focus === "Wild Psychic Talent");
    wildTalentFocuses.forEach((wtf) => {
        if (wtf.level) {
            if (wtf.level === 1) { hasWildTalentLevel1 = true; }
            if (wtf.level === 2) { hasWildTalentLevel2 = true; }
        }
    })

    if (props.level > 1) {
        if (hasWildTalentLevel2) { hasWildTalentLevel1 = false; }
    }

    const pickTechnique = (e: any) => {
        const technique = e.target.getAttribute("data-technique");
        const focusLevel = parseInt(e.target.getAttribute("data-focuslevel"));
        props.onSelectWildTalentTechnique(technique, focusLevel, "levelOne.wildTalentPicks.wildTalentTechnique");
    }

    const getCoreTechniqueDescription = (coreTech: CoreTechniqueData) => {

        const coreDesc: any = [];

        coreTech.description.forEach((desc, index) => {
            coreDesc.push(<p className="mb-1" key={uniqid()}>{desc}</p>)
        })

        const lev = coreTech.levels[0];
        coreDesc.push(<p className="mb-1 noIndent" key={uniqid()}><b>Level-{lev.level} (gained): </b>{lev.description}</p>)

        return coreDesc;
    }

    const getNonCoreTechniqueDescription = (tec: TechniqueData) => {

        const nonCoreDesc: any = [];

        const paragraphs = tec.paragraphs.map((p) => <div className="mb-1" key={uniqid()}>{p}</div>);
        nonCoreDesc.push(<div className="mb-2" key={uniqid()}>{paragraphs}</div>)

        return nonCoreDesc;
    }

    const onSetShowWildTalent1TechniqueDesc = (techniqueName: string) => {
        let newShowWildTalent1TechniqueDesc = new Map(showWildTalent1TechniqueDesc);
        const currentValue = newShowWildTalent1TechniqueDesc.get(techniqueName);
        newShowWildTalent1TechniqueDesc.set(techniqueName, !currentValue);
        setShowWildTalent1TechniqueDesc(newShowWildTalent1TechniqueDesc);
    }

    const onSetShowWildTalent2TechniqueDesc = (techniqueName: string) => {
        let newShowWildTalent2TechniqueDesc = new Map(showWildTalent2TechniqueDesc);
        const currentValue = newShowWildTalent2TechniqueDesc.get(techniqueName);
        newShowWildTalent2TechniqueDesc.set(techniqueName, !currentValue);
        setShowWildTalent2TechniqueDesc(newShowWildTalent2TechniqueDesc);
    }

    return (
        <>
            {/* <div className="mb-2">You are have the Wild Psychic Talent focus.</div> */}
            <div className="mb-1"><b>Wild Talent:</b></div>

            {hasWildTalentLevel1 &&
                <>
                    <div className="mb-3">Select a Psychic Discipline: {wildTalentPsychicDisciplineDropdown}</div>
                    {/* <div>{JSON.stringify(Array.from(showWildTalent1TechniqueDesc.entries()), null, 2)}</div>
                    <div>{JSON.stringify(Array.from(showWildTalent2TechniqueDesc.entries()), null, 2)}</div> */}
                    {
                        char.levelOne.validationCodes.indexOf("levelOneWildTalentMustSelectDiscipline") !== -1 &&
                        <ValidationAlert msg={"Psychic discipline must be selected"} />
                    }
                </>
            }

            {hasWildTalentLevel1 && props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline !== "" &&
                <div className="mb-2 pb-2 border-bottom">
                    <h6>Wild Psychic Talent Focus (Level-1)</h6>
                    <div className="mb-1"><FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;Select the core level-0 technique, or a level-1 technique that does not augment another technique: </div>
                    {availableTechniques.map((at, index) =>
                        <div key={uniqid()}>
                            <div className="mb-1">
                                <input type="radio" value={at.coreTechnique.name} key={uniqid()} checked={char.levelOne.wildTalentPicks.wildTalentTechnique1 === at.coreTechnique.name} data-technique={at.coreTechnique.name} data-focuslevel={1} id={at.coreTechnique.name} onChange={pickTechnique} />&nbsp;<label htmlFor={at.coreTechnique.name}><b>Core Technique: {at.coreTechnique.name} (Level-0)</b></label>
                                <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowWildTalent1TechniqueDesc(at.coreTechnique.name)} title="Show level one technique description">
                                    <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                                </button>
                            </div>
                            {showWildTalent1TechniqueDesc.get(at.coreTechnique.name) &&
                                <DescriptionAlert display={true}>
                                    {getCoreTechniqueDescription(at.coreTechnique)}
                                </DescriptionAlert>
                            }
                            {at.techniques.filter((t) => t.augments.length === 0).filter((t) => t.level <= 1).map((t =>
                                <div key={uniqid()}>
                                    <div className="mb-1">
                                        <input type="radio" value={t.name} key={uniqid()} checked={char.levelOne.wildTalentPicks.wildTalentTechnique1 === t.name} data-technique={t.name} data-focuslevel={1} id={t.name} onChange={pickTechnique} />&nbsp;<label htmlFor={t.name}><b>{t.name} (Level-{t.level})</b></label>
                                        <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowWildTalent1TechniqueDesc(t.name)} title="Show level one technique description">
                                            <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                                        </button>
                                    </div>
                                    {showWildTalent1TechniqueDesc.get(t.name) &&
                                        <DescriptionAlert display={true}>
                                            {getNonCoreTechniqueDescription(t)}
                                        </DescriptionAlert>
                                    }
                                </div>
                            ))}
                        </div>
                    )}
                    {char.levelOne.validationCodes.indexOf("levelOneWildTalentMustSelectTechnique") !== -1 &&
                        <ValidationAlert msg={"Psychic technique must be selected"} />
                    }
                </div>
            }

            {hasWildTalentLevel2 && props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline !== "" && props.charTraits.levelOne.wildTalentPicks.wildTalentTechnique1 !== "" &&
                <div className="mb-2 pb-2 border-bottom">
                    <h6>Wild Psychic Talent Focus (Level-2)</h6>
                    <div className="mb-1"><FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;Select the core level-0 technique, or a level-1 technique that does not augment another technique (or which augments the technique chosen for the 1st level of Wild Psychic Talent): </div>
                    {availableTechniques.map((at) =>
                        <div key={uniqid()}>
                            {props.charTraits.levelOne.wildTalentPicks.wildTalentTechnique1 !== at.coreTechnique.name &&
                                <div key={uniqid()}>
                                    <div className="mb-1">
                                        <input type="radio" value={at.coreTechnique.name} key={uniqid()} checked={char.levelOne.wildTalentPicks.wildTalentTechnique2 === at.coreTechnique.name} data-technique={at.coreTechnique.name} data-focuslevel={2} onChange={pickTechnique} id={at.coreTechnique.name + "_2"} />&nbsp;<label htmlFor={at.coreTechnique.name + "_2"}><b>Core Technique: {at.coreTechnique.name} (Level-0)</b></label>
                                        <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowWildTalent2TechniqueDesc(at.coreTechnique.name)} title="Show level one technique description">
                                            <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                                        </button>
                                    </div>
                                    {showWildTalent2TechniqueDesc.get(at.coreTechnique.name) &&
                                        <DescriptionAlert display={true}>
                                            {getCoreTechniqueDescription(at.coreTechnique)}
                                        </DescriptionAlert>
                                    }
                                </div>
                            }
                            {at.techniques.filter((t) => (t.augments.indexOf(props.charTraits.levelOne.wildTalentPicks.wildTalentTechnique1) !== -1 || t.augments.length === 0) && t.name !== props.charTraits.levelOne.wildTalentPicks.wildTalentTechnique1).filter((t) => t.level <= 1).map((t =>
                                <div key={uniqid()}>
                                    <div className="mb-1">
                                        <input type="radio" value={t.name} key={uniqid()} checked={char.levelOne.wildTalentPicks.wildTalentTechnique2 === t.name} data-technique={t.name} data-focuslevel={2} id={t.name + "-2"} onChange={pickTechnique} />&nbsp;<label htmlFor={t.name + "-2"}><b>{t.name} (Level-{t.level})</b></label>
                                        <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowWildTalent2TechniqueDesc(t.name)} title="Show level one technique description">
                                            <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                                        </button>
                                    </div>
                                    {showWildTalent2TechniqueDesc.get(t.name) &&
                                        <DescriptionAlert display={true}>
                                            {getNonCoreTechniqueDescription(t)}
                                        </DescriptionAlert>
                                    }
                                </div>
                            ))}
                        </div>
                    )}
                    {char.levelOne.validationCodes.indexOf("levelTwoWildTalentMustSelectTechnique") !== -1 &&
                        <ValidationAlert msg={"Psychic technique must be selected"} />
                    }
                </div>
            }
        </>
    )

}

export default WildPsychicTalent;