import React from "react";
import uniqid from "uniqid";

import { faEye, faCaretRight, faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Lookups } from "../lookups/Lookups";

import { getSkillsByType } from "../utilities/SkillUtilities";

import { CharacterTraits } from "../interfaces/CharacterTraits";
import { ClassAbility } from "../interfaces/ClassAbility";
import { ClassData } from "../interfaces/ClassData";
import { FocusLevelPick } from "../interfaces/FocusLevelPick";
import { SkillLevel } from "../interfaces/SkillLevel";
import { SkillLevelPick } from "../interfaces/SkillLevelPick";

import SkillPickControls from "./charGen/SkillPickControls";
import FocusPickControls from "./charGen/FocusPickControls";
import ValidationAlert from "./ValidationAlert";

import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";

interface IProps {
    charTraits: CharacterTraits;
    onSelectClass: (classes: string[]) => void;
    onSelectFocusLevel: (focusName: string, focusLevel: number, traitToUpdate: string) => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onSelectAttributeModifierBonusPick: (attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => void;
    onManageCustomSkills: () => void;
    onSwitchCollapsed: (sectionName: string) => void;
    onSelectVehicleBody: (vehicleBody: string) => void;
    onSetUniqueGift: (focusLevel: number, text: string) => void;
}

const CharacterDesignStep4Class: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "class";
    const switchDisplay = () => { props.onSwitchCollapsed(sectionName); }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }

    const lookups = Lookups.getInstance();

    const selectedClassNames = char.levelOne.classes.map((c) => c.className);
    const selectedClassesData = lookups.classes.filter((c) => selectedClassNames.indexOf(c.class) !== -1);

    const charDerivedStatsAtBackground = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtBackground.calculateSkillLevels(CreationStep.Background);

    const getSkillsAtThisStep = (classIndex: number) => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.Classes, classIndex);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const getSkillsAtPreviousStep = (classIndex: number) => {
        let skillsAtPreviousStep: SkillLevel[] = [];
        if (classIndex === -1) {
            skillsAtPreviousStep = charDerivedStatsAtBackground.skillLevels;
        } else {
            const charDerivedStatsAtPreviousClass = new CharacterDerivedStats(props.charTraits);
            charDerivedStatsAtPreviousClass.calculateSkillLevels(CreationStep.Classes, classIndex - 1);
            skillsAtPreviousStep = charDerivedStatsAtPreviousClass.skillLevels;
        };
        return skillsAtPreviousStep;
    }

    const getFocusesAtThisStep = (classIndex: number) => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateFocusLevels(CreationStep.Classes, classIndex);
        return charDerivedStatsAtThisClass.focusLevels;
    }

    const getTotalSkillLevelPicks = (slp: SkillLevelPick[]): number => {
        if (slp && slp.length > 0) {
            const reducer = (accum: number, levels: number) => accum + levels;
            return slp.map((slp) => slp.levels).reduce(reducer);
        }
        return 0;
    }

    const displayedClasses = selectedClassesData.sort().map((cd, classIndex) => {
        const collapseId = "collapse" + cd.class.replace(/ /ig, "");

        let classSkillPicks: SkillLevelPick[] = [];

        const thisClass = char.levelOne.classes.find((c) => c.className === cd.class);

        const sp = char.levelOne.classes.find((c) => c.className === cd.class)?.classSkillPicks;
        if (sp) { classSkillPicks = sp; }

        let classFocusLevelPick: FocusLevelPick;
        let fp = char.levelOne.classes.find((c) => c.className === cd.class)?.classFocusLevelPick;
        if (fp) {
            classFocusLevelPick = fp;
            if (!classFocusLevelPick.skillLevelPicks) {
                classFocusLevelPick.skillLevelPicks = [];
            }
        };

        const getFocusLevelPicksToChooseFrom = () => {
            const allFocusesAtLevelOne = lookups.focuses.filter((f) => f.focus.indexOf("VI") === -1).map((f) => {
                let grantsSkill = false;
                const hasSkillBonus = f.levels[0].bonuses.find((b) => b.type === "bonusSkill");
                if (hasSkillBonus) {
                    grantsSkill = true;
                }
                const flp: FocusLevelPick = { focus: f.focus, level: 1, type: f.type, skillLevelPicks: [], skillPointsPicks: [], grantsSkill: grantsSkill };
                return flp;
            });

            // Replace any foci that have already been picked (except those that can be picked multiple times) with
            // level 2 version.

            let finalFocuses: FocusLevelPick[] = [];

            allFocusesAtLevelOne.forEach((l1f) => {
                const existingFocusLevelOne = getFocusesAtThisStep(classIndex).find((f) => f.focus === l1f.focus && f.level === l1f.level);
                if (classFocusLevelPick) {
                    const isCurrentFocus = l1f.focus === classFocusLevelPick.focus && l1f.level === classFocusLevelPick.level;
                    const level2FocusExists = lookups.focuses.find((f) => f.focus === l1f.focus && f.levels.length > 1);
                    if (existingFocusLevelOne && !isCurrentFocus && level2FocusExists) {
                        const secondLevelFocus: FocusLevelPick = { ...l1f, level: 2 };
                        finalFocuses.push(secondLevelFocus);
                    } else {
                        finalFocuses.push(l1f);
                    }
                }
            });


            return finalFocuses;
        }

        const totalSkillPicks = getTotalSkillLevelPicks(classSkillPicks);

        const focusLevelPicksToChooseFrom = getFocusLevelPicksToChooseFrom();

        return (
            <div key={cd.class} className="mt-3">
                <h6 className="m-0 p-0">
                    {cd.class}
                    <button className="ml-2 btn btn-outline-info btn-tiny" type="button" data-toggle="collapse" data-target={"#" + collapseId} aria-expanded="false" aria-controls={collapseId} title="Show Description">
                        <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                    </button>
                </h6>

                <div className="collapse mb-2 alert alert-info" id={collapseId}>
                    {cd.desc.map((desc) =>
                        <div className="mb-1" key={uniqid()}>{desc}</div>
                    )}
                </div>

                {cd.abilities.length > 0 &&
                    <div className="ml-2">
                        {cd.abilities.map((ability: ClassAbility) =>
                            <div className="border-bottom pt-1 pb-1" key={uniqid()}>
                                <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                                {ability.desc}
                                {ability.bonusSkillLevels.length > 0 &&
                                    <>
                                        <SkillPickControls
                                            lockedSkillName=""
                                            maxLevel={ability.bonusSkillLevels.length}
                                            singleSkillOnly={ability.bonusSkillLevels.length === 1}
                                            skillNamesToChooseFrom={getSkillsByType("Psychic", lookups.skills).map((s) => s.skill)}
                                            skillLevelsAlreadyPicked={classSkillPicks}
                                            skillPointsAlreadyPicked={[]}
                                            traitToUpdate={"levelOne.classes.classSkillPicks/" + cd.class}
                                            charTraits={char}
                                            charDerivedSkills={getSkillsAtThisStep(classIndex)}
                                            isLevelFocus={false}
                                            onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                                            onManageCustomSkills={props.onManageCustomSkills}
                                        />

                                        {thisClass && thisClass.validationCodes.indexOf("classSkillPicksNotAllSelected") !== -1 &&
                                            <ValidationAlert msg={"Select " + ability.bonusSkillLevels.length + " skill levels (you have selected " + totalSkillPicks + ")"} />
                                        }
                                        {/* <div><pre>{JSON.stringify(getSkillsAtThisStep(classIndex).filter((s) => s.history.length > 0), null, 2)}</pre></div> */}

                                    </>
                                }
                                {ability.freeFocusLevels.length > 0 &&
                                    <div className="pt-2">
                                        {ability.freeFocusLevels.map((ffl: any) =>
                                            <FocusPickControls
                                                className={cd.class}
                                                key={ffl}
                                                focusType={ffl}
                                                focusLevelPicksToChooseFrom={focusLevelPicksToChooseFrom}
                                                focusLevelAlreadyPicked={classFocusLevelPick}
                                                skillLevelsAlreadyPicked={classFocusLevelPick.skillLevelPicks}
                                                skillPointsAlreadyPicked={[]}
                                                attributeModifiersAlreadyPicked={[]}
                                                traitToUpdate_Focuses={"levelOne.classes.classFocusPick/" + cd.class}
                                                traitToUpdate_Skills={"levelOne.classes.classFocusLevelPick.skillLevelPicks/" + cd.class}
                                                traitToUpdate_AttributeModifiers={"levelOne.classes.classFocusLevelPick.attributeModifierPicks/" + cd.class}
                                                charTraits={char}
                                                charDerivedSkills={getSkillsAtThisStep(classIndex)}
                                                charDerivedSkillsAtPreviousStep={getSkillsAtPreviousStep(classIndex)}
                                                charDerivedFocuses={getFocusesAtThisStep(classIndex)}
                                                includeLeadingIcon={false}
                                                onSelectFocusLevel={(focusName: string, focusLevel: number) => props.onSelectFocusLevel(focusName, focusLevel, "levelOne.classes.classFocusPick/" + cd.class)}
                                                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                                                onSelectAttributeModifierBonusPick={(attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => props.onSelectAttributeModifierBonusPick(attributeIndex, attributeModifierBonus, selected, traitToUpdate)}
                                                onSelectVehicleBody={(vehicleBody: string) => props.onSelectVehicleBody(vehicleBody)}
                                                onManageCustomSkills={props.onManageCustomSkills}
                                                onSetUniqueGift={props.onSetUniqueGift}
                                            />
                                        )}
                                        {thisClass && props.charTraits.showValidation && thisClass.validationCodes.indexOf("classFocusNotSelected") !== -1 &&
                                            <ValidationAlert msg="Focus must be selected" />
                                        }
                                        {thisClass && thisClass.validationCodes.indexOf("classFocusSkillsNotAllSelected") !== -1 &&
                                            <ValidationAlert msg={"Select one level of a skill (you have selected " + classFocusLevelPick.skillLevelPicks.length + ")"} />
                                        }
                                        {thisClass && thisClass.validationCodes.indexOf("classFocusSkillAboveLimit") !== -1 &&
                                            <ValidationAlert msg="You cannot have any skill higher than level-1 at 1st level" />
                                        }
                                        {thisClass && thisClass.validationCodes.indexOf("classFocusPsychicTrainingOnlyForPsychic") !== -1 &&
                                            <ValidationAlert msg="Only a character with the Psychic or Partial Psychic class can take the Psychic Training focus" />
                                        }
                                        {thisClass && thisClass.validationCodes.indexOf("classFocusWildPsychicNotForPsychic") !== -1 &&
                                            <ValidationAlert msg="Only a character that does not have the Psychic or Partial Psychic class can take the Wild Psychic focus" />
                                        }
                                        {thisClass && thisClass.validationCodes.indexOf("classFocusPsychicTrainingForPartialPsychicMustHaveSameSkill") !== -1 &&
                                            <ValidationAlert msg="You must select the same skill for the Psychic Training focus as you select for Partial Psychic class." />
                                        }
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                }

            </div>
        )
    });

    const onSelectClass = (e: any): void => {
        const selectedClasses = e.target.value.split(",");
        props.onSelectClass(selectedClasses);
    }

    const d6 = () => Math.floor(Math.random() * 6) + 1;

    const setRandomClass = () => {
        const classes = ["Expert", "Psychic", "Warrior", "Partial Expert,Partial Psychic", "Partial Expert,Partial Warrior", "Partial Psychic,Partial Warrior"];
        const selectedClasses = classes[d6() - 1].split(",");
        props.onSelectClass(selectedClasses);
    }

    const pairwise = (list: any[]) => {

        var pairs = new Array((list.length * (list.length - 1)) / 2),
            pos = 0;

        for (var i = 0; i < list.length; i++) {
            for (var j = i + 1; j < list.length; j++) {
                pairs[pos++] = [list[i], list[j]];
            }
        }
        return pairs;
    }

    const getClassDropdown = () => {

        interface ClassOption {
            name: string;
            value: string;
        }

        let wholeClassOptions: ClassOption[] = lookups.classes.filter((cd) => cd.classWeight === 1).sort().map((cd) => {
            return { name: cd.class, value: cd.class };
        });

        const partialClassCombinations: ClassData[][] = pairwise(lookups.classes.filter((cd) => cd.classWeight === 0.5));

        let partialClassOptions = partialClassCombinations.map((cd) => {
            const name = "Adventurer (" + cd.map((c) => c.partialClassName).join("/") + ")";
            const value = cd.map((c) => c.class);
            return { name, value };
        });

        const allOptions = [...wholeClassOptions, ...partialClassOptions];

        return (
            <select onChange={onSelectClass} value={selectedClassNames.join(",")}>
                <option value="">-- select --</option>
                {allOptions.map((co) => <option key={uniqid()} value={co.value}>{co.name}</option>)}
            </select>
        )
    }

    const classDropdown = getClassDropdown();

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Class {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                <div>
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    Select a class: {classDropdown} <button className="btn btn-secondary btn-tiny" onClick={setRandomClass}>Random</button>
                </div>

                {char.levelOne.validationCodes.indexOf("classNotSelected") === -1 &&
                    <>
                        {displayedClasses}
                    </>
                }
                {char.showValidation && char.levelOne.validationCodes.indexOf("classNotSelected") !== -1 &&
                    <ValidationAlert msg="Class must be selected" />
                }

            </div>

        </div>
    );

}

export default CharacterDesignStep4Class;