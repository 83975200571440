import React, { useState } from "react";
import { withRouter } from "react-router";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import app from "../firebase";

import { History } from 'history';

import { isEmail } from "../utilities/emailValidation";

type IProps = {
    history: History,
}

const ForgotPassword: React.FunctionComponent<IProps> = (props: IProps) => {

    const { t } = useTranslation();

    const [sendEmailFailed, setSendEmailFailed] = useState(false);
    const [sendEmailSuccess, setSendEmailSuccess] = useState(false);

    const [error, setError] = useState("");

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (values: any) => {
        try {
            await app
                .auth()
                .sendPasswordResetEmail(values.email);
            setSendEmailSuccess(true);
        } catch (error) {
            setSendEmailFailed(true);
            if (error.message) {
                if (error.code) {
                    switch (error.code) {
                        case "auth/invalid-email":
                            setError(t("forgotPassword.invalidEmail"));
                            break;
                        case "auth/user-not-found":
                            setError(t("forgotPassword.noUserExists") + " '" + values.email + "'");
                            break;
                        default:
                            setError(error.message);
                            break;
                    }
                } else {
                    setError(error.message);
                }
            }
        }
    }

    return (
        <div className="pageBody">
            <h1 className="pageHeading">{t("forgotPassword.forgotYourPassword")}</h1>

            <div className="card" style={{ maxWidth: "400px" }}>
                <div className="card-body">

                    {sendEmailSuccess &&
                        <div className="alert alert-primary" role="alert">
                            <div className="pb-2"><b>{t("forgotPassword.emailSent")}</b></div>
                            <div className="pb-2">{t("forgotPassword.followLink")}</div>
                            <div><Link className="btn btn-primary" to="/login">{t("forgotPassword.log_in")}</Link></div>
                        </div>
                    }

                    {!sendEmailSuccess &&
                        <form action="#!" method="get" onSubmit={handleSubmit(onSubmit)}>

                            <div className="pb-2">{t("forgotPassword.enterEmail")}</div>

                            {sendEmailFailed &&
                                <div className="alert alert-danger">{error}</div>
                            }
                            <div className="form-group">
                                <label htmlFor="email">{t("login.email")}</label>
                                <input type="email" placeholder={t("forgotPassword.email")} className="form-control textMid" ref={register({ required: true, validate: isEmail })} id="email" name="email" />
                                {(errors.email && errors.email.type === "required") &&
                                    <div className="text-danger">{t("forgotPassword.fieldReq")}</div>
                                }
                                {(errors.email && errors.email.type === "validate") &&
                                    <div className="text-danger">{t("forgotPassword.invalidEmail")}</div>
                                }
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">{t("forgotPassword.sendResetEmail")}</button>
                            </div>
                        </form>
                    }

                </div>
            </div>

        </div>

    )
};

export default withRouter(ForgotPassword);
