import React, { useState } from "react";
import $ from "jquery";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { Cargo } from "../../interfaces/Cargo";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";
import uniqid from "uniqid";
import { formatLargeCredits } from "../../utilities/Utilities";

interface IProps {
    shipTraits: StarshipTraits;
    displayModal: boolean;
    onSetCrewAutomationSupportBots: (bots: number) => void;
    onSetCurrentFuelLoads: (fuelLoads: number) => void;
    onAddCargo: (cargo: Cargo) => void;
    onDeleteCargo: (uniqid: string) => void;
    onClose: () => void;
}

const SelectCargoModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const modalId = "cargo_modal";

    const [autoSupBots, setAutoSupBots] = useState(props.shipTraits.crewData.crewAutomationSupportBots);
    const [currentFuelLoads, setCurrentFuelLoads] = useState(props.shipTraits.currentFuelLoads);
    const [showAddCargoForm, setShowAddCargoForm] = useState(false);
    const [cargoUniqid, setCargoUniqid] = useState("");
    const [cargoName, setCargoName] = useState("");
    const [cargoTons, setCargoTons] = useState("");
    const [cargoPrice, setCargoPrice] = useState("");
    const [cargoIsVehicles, setCargoIsVehicles] = useState(false);
    const [cargoDesc, setCargoDesc] = useState("");
    const [isEdit, setIsEdit] = useState(false);

    const shipDerivedStats = new ShipDerivedStats(props.shipTraits);

    if (props.displayModal) {
        ($("#" + modalId) as any).modal('show');
    }

    if (!props.displayModal) {
        ($("#" + modalId) as any).modal('hide');
    }

    const onCloseModal = () => {
        props.onClose();
    }

    let hasAutomationSupport = false;
    const autoSts = props.shipTraits.fittings.find((f) => f.id === "SF7");
    if (autoSts) {
        hasAutomationSupport = true;
    }

    const onSetAutoSupBots = (e: any) => {
        e.preventDefault();
        setAutoSupBots(e.currentTarget.value);

        let val = parseInt(e.currentTarget.value);
        if (!isNaN(val) && val > -1) {
            props.onSetCrewAutomationSupportBots(val);
        }
    }

    const onSetCurrentFuelLoads = (e: any) => {
        e.preventDefault();
        setCurrentFuelLoads(e.currentTarget.value);

        let val = parseInt(e.currentTarget.value);
        if (!isNaN(val) && val > -1 && val <= shipDerivedStats.fuelLoadsMax) {
            props.onSetCurrentFuelLoads(val);
        }
    }

    const openAddCargoForm = () => {
        setShowAddCargoForm(true);
        setIsEdit(false);
        setCargoUniqid(uniqid());
        setCargoName("");
        setCargoTons("");
        setCargoPrice("");
        setCargoDesc("");
        setCargoIsVehicles(false);
    }

    const openEditCargoForm = (uniqid: string) => {
        setShowAddCargoForm(true);
        setIsEdit(true);

        const theCargo = props.shipTraits.cargo.find((c) => c.uniqid === uniqid);
        if (theCargo) {
            setCargoUniqid(theCargo.uniqid);
            setCargoName(theCargo.name);
            setCargoTons(theCargo.tons.toString());
            setCargoPrice(theCargo.pricePerTon.toString());
            setCargoIsVehicles(theCargo.isVehicles);
            setCargoDesc(theCargo.desc);
        } else {
            throw new Error("Cargo not found");
        }
    }

    const onSetCargoTons = (e: any) => {
        if (isNaN(parseInt(e.currentTarget.value))) {
            setCargoTons(e.currentTarget.value);
        } else {
            let tons = parseInt(e.currentTarget.value);
            if (tons < 1) { tons = 1; }
            setCargoTons(tons.toString());
        }
    }

    const onSetCargoPrice = (e: any) => {
        if (isNaN(parseInt(e.currentTarget.value))) {
            setCargoPrice(e.currentTarget.value);
        } else {
            let price = parseInt(e.currentTarget.value);
            if (price < 1) { price = 1; }
            setCargoPrice(price.toString());
        }
    }

    const getAddIsEnabled = () => {
        const nameOk = cargoName.trim().length > 0;
        const tonsOk = !isNaN(parseInt(cargoTons));
        const priceOk = !isNaN(parseInt(cargoPrice));
        return nameOk && tonsOk && priceOk;
    }

    const deleteCargo = (uniqid: string) => {
        props.onDeleteCargo(uniqid);
    }

    const onSaveCargo = () => {
        const newCargo: Cargo = {
            uniqid: cargoUniqid,
            name: cargoName.trim(),
            tons: parseInt(cargoTons),
            pricePerTon: parseInt(cargoPrice),
            isVehicles: cargoIsVehicles,
            desc: cargoDesc.trim()
        }

        props.onAddCargo(newCargo);
        setShowAddCargoForm(false);
    }

    const addEditBtnName = isEdit ? "Save" : "Add";

    let hasVehicleTransportFittings = false;
    const vehTrans = props.shipTraits.fittings.find((f) => f.id === "SF44");
    if (vehTrans) {
        hasVehicleTransportFittings = true;
    }

    let hasVehiclesAsCargo = false;
    const vehicles = props.shipTraits.cargo.find((c) => c.isVehicles);
    if (vehicles) {
        hasVehiclesAsCargo = true;
    }



    return (
        <div id={modalId} className="modal" tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Select Cargo</h5>
                        <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <div className="mb-2"><b>Ship Support Materials</b></div>

                        <div className="row mb-2">
                            <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                <label htmlFor="crew">Fuel loads</label>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-8 col-md-2 col-lg-2 col-xl-2 small">
                                <input id="crew" type="number" className="ml-1 shortNum" value={currentFuelLoads} onChange={(e) => onSetCurrentFuelLoads(e)} onBlur={() => setCurrentFuelLoads(props.shipTraits.currentFuelLoads)} /> {shipDerivedStats.fuelLoadsMax} max
                            </div>
                        </div>

                        {hasAutomationSupport &&
                            <div className="row mb-2">
                                <div className="col-6 col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 small">
                                    <label htmlFor="crew">Automation Support Bots</label>
                                </div>
                                <div className="col-6 col-xs-6 col-sm-8 col-md-2 col-lg-2 col-xl-2 small">
                                    <input id="crew" type="number" className="ml-1 shortNum" value={autoSupBots} onChange={(e) => onSetAutoSupBots(e)} onBlur={() => setAutoSupBots(props.shipTraits.crewData.crewAutomationSupportBots)} />
                                </div>
                            </div>
                        }

                        <hr />

                        <div className="mb-2"><b>Cargo</b></div>

                        <div className="mt-2">
                            <button className="btn btn-primary btn-tiny" onClick={() => openAddCargoForm()}>Add Cargo</button>
                        </div>

                        {props.shipTraits.cargo.length === 0 && !showAddCargoForm &&
                            <div className="row border-bottom pt-1 pb-1">
                                <div className={"col-12  small"}>
                                    No cargo
                                </div>
                            </div>
                        }

                        {showAddCargoForm &&
                            <>
                                <div className="form-row mb-2">
                                    <div className="col-12 col-md4">
                                        <input type="text" className="form-control" placeholder="Cargo type" value={cargoName} onChange={(e) => setCargoName(e.currentTarget.value)} />
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <input type="number" className="form-control" placeholder="Tons" value={cargoTons} onChange={onSetCargoTons} />
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <input type="number" className="form-control" placeholder="Cost per ton" value={cargoPrice} onChange={onSetCargoPrice} />
                                    </div>
                                </div>
                                <div className="form-row mb-2">
                                    <div className="col-12 col-md-8">
                                        <input type="text" className="form-control" placeholder="Cargo description" value={cargoDesc} onChange={(e) => setCargoDesc(e.currentTarget.value)} />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" checked={cargoIsVehicles} onChange={(e) => setCargoIsVehicles(!cargoIsVehicles)} />
                                            <label className="custom-control-label" htmlFor="customCheck1">Is vehicles</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12">
                                        <button className="btn btn-primary mr-1" onClick={() => onSaveCargo()} disabled={!getAddIsEnabled()}>{addEditBtnName}</button>
                                        <button className="btn btn-secondary" onClick={() => setShowAddCargoForm(false)}>Cancel</button>
                                    </div>
                                </div>
                            </>
                        }

                        {!showAddCargoForm &&
                            <>
                                {props.shipTraits.cargo.map((c) =>
                                    <div className="row border-bottom pt-1 pb-1" key={uniqid()} >
                                        <div className={"col-8 col-sm-5 col-md-3 col-lg-3 col-xl-3 small"}>
                                            <b>
                                                {c.name}
                                                {c.isVehicles && hasVehicleTransportFittings &&
                                                    <sup>V</sup>
                                                }
                                            </b>
                                        </div>
                                        <div className={"col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 small"}>
                                            <button className="btn btn-primary btn-tiny" onClick={() => openEditCargoForm(c.uniqid)}>Edit</button>
                                            <button className="btn btn-primary btn-tiny" onClick={() => deleteCargo(c.uniqid)}>Del</button>
                                        </div>
                                        <div className={"col-6 col-sm-2 col-md-1 col-lg-1 col-xl-1 small"}>{c.tons}&nbsp;tons</div>
                                        <div className={"col-6 col-sm-2 col-md-1 col-lg-1 col-xl-1 small"}>{formatLargeCredits(c.tons * c.pricePerTon)}</div>
                                        <div className={"col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 small"}>{c.desc}</div>
                                    </div>
                                )}

                                {hasVehicleTransportFittings && hasVehiclesAsCargo &&
                                    <div className="small"><sup>V</sup>: Vehicles. Effective cargo tonnage halved as ship has Vehicle Transport Fittings.</div>
                                }

                            </>
                        }

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>Close</button>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default SelectCargoModal;

