import React from 'react';

import Menu from "../components/Menu";
import Footer from "../components/Footer";

import "./layout.css";

// Bootstrap dependencies
import "jquery";
import "popper.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// import "./BASH_bootstrap_additional.css";

type BootstrapLayout_01Props = {
    children?: any
}

const BootstrapLayout: React.FunctionComponent<BootstrapLayout_01Props> = (props: BootstrapLayout_01Props) => {

    const children = props.children || null;

    return (
        <div className="container pt-2 pb-3">

            {/* <span className="loadingSpinner">
                <i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
            </span> */}

            <Menu />

            {/* <div className="pageBody heroShot">
                {children}
            </div> */}

            {children}

            <Footer />

        </div>
    )
}

export default BootstrapLayout;