import React, { useState } from 'react';
import uniqid from 'uniqid';

import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { useTranslation } from 'react-i18next';

import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { SkillLevel } from '../../interfaces/SkillLevel';
import { SkillLevelPick } from "../../interfaces/SkillLevelPick";

import { convertAnySkillToSkillListDesc } from "../../utilities/SkillUtilities";

import SkillsJSON from "../../lookups/SkillsAll.json";
import { SkillPointsPick } from '../../interfaces/SkillPointsPick';

interface IProps {
    maxLevel: number;
    singleSkillOnly: boolean;
    lockedSkillName: string;
    skillNamesToChooseFrom: string[];
    skillLevelsAlreadyPicked: SkillLevelPick[];
    skillPointsAlreadyPicked: SkillPointsPick[];
    charTraits: CharacterTraits;
    charDerivedSkills: SkillLevel[];
    traitToUpdate: string;
    isLevelFocus: boolean;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onManageCustomSkills: () => void;
}

const SkillPickControls: React.FunctionComponent<IProps> = (props: IProps) => {

    const skillsData = SkillsJSON;

    const id = uniqid();

    const closedClass = "collapse mb-2 alert alert-info fade";

    const [selectedSkill, setSelectedSkill] = useState("");
    const [skillDesc, setSkillDesc] = useState("");

    const [skillDescClassName, setSkillDescClassName] = useState(closedClass);

    let skillHistory = "";
    const thisSkillDerived = props.charDerivedSkills.find((sl) => sl.skill === selectedSkill);
    if (thisSkillDerived) {
        skillHistory = thisSkillDerived.history.join("; ");
    }

    const showOrHideSkillDesc = (skill: string) => {
        setSelectedSkill(skill);
        const skillDesc = skillsData.find((s) => s.skill === skill);

        const openClass = closedClass + " show";
        if (skillDescClassName === closedClass) {
            if (skillDesc) {
                if (skillDescClassName === closedClass) {
                    setSkillDescClassName(openClass);
                } else {
                    setSkillDescClassName(closedClass);
                }
                setSkillDesc(skillDesc.desc);
            }
        } else {
            if (skill === selectedSkill) {
                setSkillDescClassName(closedClass);
            } else {
                if (skillDesc) {
                    setSkillDesc(skillDesc.desc);
                }
            }
        }

    };

    let className = "";
    if (props.maxLevel === 1) {
        className = "col-lg-2 col-md-3 col-sm-4 col-6 text-nowrap align-items-center pl-0 pr-1";
    } else {
        className = "col-lg-3 col-md-4 col-sm-6 col-12 text-nowrap align-items-center pl-0 pr-1";
    }

    const skills = convertAnySkillToSkillListDesc(props.skillNamesToChooseFrom.join(","), skillsData, false).split(", ");

    const getSkillPickControls = () => {
        // let colCount = 0;
        const skillControls = skills.map((s) => {
            let checkedLevel1 = false;
            let checkedLevel2 = false;
            const matchingSkillLevelPick = props.skillLevelsAlreadyPicked.find((slp) => slp.skill === s);
            if (matchingSkillLevelPick) {
                if (matchingSkillLevelPick.levels > 0) { checkedLevel1 = true; }
                if (matchingSkillLevelPick.levels > 1) { checkedLevel2 = true; }
            }

            // for level focuses that grant skill points:
            if (props.isLevelFocus) {
                if (skills.length === 1) {
                    checkedLevel1 = true;
                } else {
                    const matchingSkillLevelPick = props.skillPointsAlreadyPicked.find((slp) => slp.skill === s);
                    if (matchingSkillLevelPick) {
                        checkedLevel1 = true;
                    }
                }
            }

            let level1disabled = false;
            let level2disabled = !checkedLevel1;

            // let className = "col text-nowrap align-items-center pl-0 pr-1";

            let displayedSkillLevel: string | null = null;
            let totalSkillLevel = 0;
            const currentSkillLevel = props.charDerivedSkills.find((sl) => sl.skill === s);
            if (currentSkillLevel && currentSkillLevel.level !== null) {
                totalSkillLevel = currentSkillLevel.level - 1;
                displayedSkillLevel = "-" + (totalSkillLevel);
            }

            let btnClassName = "btn btn-tiny inline-block btn-skill ";

            if (totalSkillLevel > 1) {
                btnClassName += "btn-danger";
            }
            else if (checkedLevel1 || checkedLevel2 || displayedSkillLevel !== null) {
                btnClassName += "btn-secondary"
            }
            else {
                btnClassName += "btn-outline-secondary";
            }

            return (
                <div className={className} key={"slp_" + s}>
                    <button className={btnClassName} onClick={(e) => showOrHideSkillDesc(s)}>
                        {s}{displayedSkillLevel} <FontAwesomeIcon className="text-info" icon={faEye} title="Show Skill details"></FontAwesomeIcon >
                    </button>
                    <input type="checkbox" className="mr-1 lgCheckbox inline-block" data-skill={s} data-level={1} onChange={pickSkillLevel} disabled={level1disabled} checked={checkedLevel1} />
                    {props.maxLevel > 1 &&
                        <input type="checkbox" className="mr-1 lgCheckbox inline-block" data-skill={s} data-level={2} onChange={pickSkillLevel} disabled={level2disabled} checked={checkedLevel2} />
                    }
                </div>
            )
        });

        return skillControls;
    }

    const pickSkillLevel = (e: any) => {
        const skillName = e.target.getAttribute("data-skill");
        const skillLevelPicks = parseInt(e.target.getAttribute("data-level"));
        const selected = e.target.checked;

        pickSkillLevel_Update(skillName, skillLevelPicks, selected);
    }

    const pickSkillLevel_Update = (skill: string, levels: number, checked: boolean): void => {
        if (!(skill === props.lockedSkillName && levels === 1 && !checked)) {
            props.onSelectSkillLevelPick(skill, levels, checked, props.traitToUpdate, props.singleSkillOnly);
        }
    }

    const skillPickControls = getSkillPickControls();

    return (
        <div>
            <div className="mb-1 d-flex flex-wrap mt-1">
                {skillPickControls}
            </div>
            <div className={skillDescClassName} id={"collapseSkillsDesc" + id}>
                <div><b>{selectedSkill}</b>: {skillDesc}</div>
                {skillHistory !== "" &&
                    <div className="mt-1"><i>History:</i> {skillHistory}</div>
                }
            </div>
        </div>
    )

}

export default SkillPickControls; 