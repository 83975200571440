import React from "react";

type IProps = {}

const Contact: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <div className="pageBody">
            <h1 className="pageHeading">Contact</h1>

            <span style={{ float: "left" }} className="pr-1">
                <img src="/SWooNe.png" width="127px" height="194px" alt="SWooNe" />
            </span>

            <p>Hi! I'm Mike, the guy who created <b>Freebooter</b>. I hope it gives you hours of fun committing shenanigans across the cosmos with your friends.</p>
            <p>I keep an eye out for posts at the <a href='https://www.reddit.com/r/SWN/' target='_blank' rel="noopener noreferrer">Reddit for SWN</a>. My account is <a href='https://www.reddit.com/user/SWooNe/' target='_blank' rel="noopener noreferrer">u/SWooNe</a>, and you can message me or tag me in a post by including u/SWooNe in the post's text.</p>
            <p>The <a href='https://www.reddit.com/r/SWN/' target='_blank' rel="noopener noreferrer">Reddit for SWN</a> has a very active and friendly community. If you ask a rules question, there's a pretty good chance that Kevin Crawford (the author of SWN) will personally respond, which is neat.</p>
            <p><a className="btn btn-primary mr-2" href="https://www.buymeacoffee.com/swoone" target="_blank" rel="noopener noreferrer"><img src="/BuyMeACoffee.svg" height="20px" width="20px" alt=""/> Buy Me A Coffee</a></p>
        </div>
    );
}

export default Contact;
