import React, { useState } from "react";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";

import { StarshipTraits } from "../../interfaces/StarshipTraits";

import BuyFittingsModal from "./BuyFittingsModal";
import ListWeaponsInstalled from "./ListWeaponsInstalled";
import SelectAmmoModal from "./SelectAmmoModal";
import SelectAutoTargetingModal from "./SelectAutoTargetingModal";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onBuyOrSellFitting: (id: string, isBuy: boolean) => void;
    onSelectAutoWeapon: (autoUniqId: string, weaponUniqId: string) => void;
    onSetAmmunition: (weaponId: string, extraFreeMass: number) => void;
    onSetAmmunitionRemaining: (weaponUniqId: string, roundsRemaining: number) => void;
}

const ShipDesignStep5Weapons: React.FunctionComponent<IProps> = (props: IProps) => {

    const [openBuyFittingsModal, setOpenBuyFittingsModal] = useState(false);
    const [openAutoTargetingModal, setOpenAutoTargetingModal] = useState(false);
    const [openSetAmmoModal, setOpenSetAmmoModal] = useState(false);

    const shipDerivedStats = new ShipDerivedStats(props.shipTraits);

    let showAutoTargetingButton = false;
    const autoTargetting = props.shipTraits.fittings.find((f) => f.id === "SF6");
    if (autoTargetting) {
        showAutoTargetingButton = true;
    }

    let hasWeaponsWithAmmo = false;
    const ammoWeapons = shipDerivedStats.weaponsInstalled_NotGrouped.find((w) => w.ammunitionPerMass > 0);
    if (ammoWeapons) {
        hasWeaponsWithAmmo = true;
    }

    const getNumAllocated = () => {
        let numAutos = 0;
        const allAutos = shipDerivedStats.fittingsInstalled.find((fi) => fi.id === "SF6");
        if (allAutos) { numAutos = allAutos.quantity; }

        const numAssigned = props.shipTraits.autoTargetingWeapons.filter((f) => f.autoTargetingUniqId !== "" && f.weaponUniqId !== "").length;

        let className = "small";
        if (numAutos !== numAssigned) { className = className + " red"; }

        return <span className={className}>{numAssigned} of {numAutos} assigned</span>;
    }

    return (
        <div className="chargenSection">

            <h2>Weapons</h2>

            {!props.readOnly &&
                <div>
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenBuyFittingsModal(true)}>Select Weapons</button>
                </div>
            }

            <ListWeaponsInstalled shipTraits={props.shipTraits} />

            {/* <ListFittingsInstalled shipTraits={props.shipTraits} type="weapons" /> */}

            {!props.readOnly && hasWeaponsWithAmmo &&
                <div className="mt-2">
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenSetAmmoModal(true)}>Select Additional Ammunition</button>
                </div>
            }

            {!props.readOnly && showAutoTargetingButton &&
                <div className="mt-2">
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenAutoTargetingModal(true)}>Select Auto-targeting</button>
                    {getNumAllocated()}
                </div>
            }

            <BuyFittingsModal
                shipTraits={props.shipTraits}
                itemType="weapons"
                displayModal={openBuyFittingsModal}
                onBuyOrSellFitting={(id, isBuy) => props.onBuyOrSellFitting(id, isBuy)}
                onClose={() => setOpenBuyFittingsModal(false)}
            />

            <SelectAutoTargetingModal
                shipTraits={props.shipTraits}
                displayModal={openAutoTargetingModal}
                onSelectAutoWeapon={props.onSelectAutoWeapon}
                onClose={() => setOpenAutoTargetingModal(false)}
            />


            <SelectAmmoModal
                shipTraits={props.shipTraits}
                displayModal={openSetAmmoModal}
                onSetAmmunition={props.onSetAmmunition}
                onSetAmmunitionRemaining={props.onSetAmmunitionRemaining}
                onClose={() => setOpenSetAmmoModal(false)}
            />

        </div >
    );

}

export default ShipDesignStep5Weapons;