import { BackgroundData } from "./BackgroundData";
import { ClassData } from "./ClassData";
import { FocusData } from "./FocusData";
import { FocusLevelPick } from "./FocusLevelPick";
import { PsychicTechniquePick } from './PsychicTechniquePick';
import { SkillData } from "./SkillData";
import { SkillLevelPick } from "./SkillLevelPick";
import { applyValidation } from "../components/CharacterDesignerValidation";

export interface BasicTraits {
    name: string;
    goal: string;
    isPublic: boolean;
    origin: string;
    uniqueGift1: string;
    uniqueGift2: string;    
    validationCodes: string[];
    collapsedSections: string[];
}

export interface AttributeTraits {
    method: string;
    includeNonstandardMethods: boolean;
    attributeScores: number[];
    originalScores: number[];
    randomScores: number[];
    validationCodes: string[];
    attributeNumberSetTo14: number;
    attributeValueThatWasReplacedWith14: number;
    rerolls: number;
}

export interface AttributeScoreBonus {
    attributeNumber: number;
    bonus: number;
}

export interface AttributeModifierBonus {
    attributeNumber: number;
    bonus: number;
}

export interface TableRoll {
    table: string; // "growth" or "learning"
    roll: number; // 1-6 for Growth, 1-8 for Learning
    result: string;
    skillLevelPicks: SkillLevelPick[];
    attributeScoreBonuses: AttributeScoreBonus[];
}

export interface BackgroundTraits {
    method: string;
    backgroundName: string;
    backgroundSkillLevelPicks: SkillLevelPick[];
    tableRolls: TableRoll[];
    validationCodes: string[];
    languages: string;
    homeworld: string;
}

export interface ClassTrait {
    className: string;
    classSkillPicks: SkillLevelPick[];     // Psychic and Partial Psychic classes get one or two free skill levels at 1st level. 
    classFocusLevelPick: FocusLevelPick;      // Expert, Warrior, Partial Expert and Partial Warrior get a free Focus level at 1st level.
    validationCodes: string[];
}

export interface WildTalentTraits {
    wildTalentPsychicDiscipline: string;
    wildTalentTechnique1: string;
    wildTalentTechnique2: string;
}

export interface LevelOneTraits {
    classes: ClassTrait[];
    freeFocusLevelPick: FocusLevelPick;
    freeSkillLevelPicks: SkillLevelPick[];
    psychicTechniquePicks: PsychicTechniquePick[];
    wildTalentPicks: WildTalentTraits;
    isAdventurer: boolean;
    rolledHitPoints: number;
    hitPointRerolls: number;
    validationCodes: string[];
    vehicleBody?: string;
}

export interface GearTraits {
    equipment: EquipmentItem[];
    method: string;
    pack: string;
    startingCredits: number;
    additionalCredits: number;
    // credits: number;
}

export interface StorageQuantity {
    storage: string;
    quantity: number;
}

export interface Mod {
    id: string;
    quantity: number;
}

export interface EquipmentItem {
    uniqid: string;
    id: string; // For items copied from standard lookup tables. 
    name: string;
    cost: number;
    // storage: string;
    // quantity: number;
    storageQuantity: StorageQuantity[];
    type: string;
    subtype?: string;
    enc: number;
    techLevel: number;
    desc: string;
    // Armour stats
    baseAC?: number;
    bonusToAC?: number;
    // Weapon stats
    damage?: number[];
    burst?: boolean;
    suppress?: boolean;
    range?: number[];
    magazine?: number;
    magazineReload?: number;
    attributes?: string[]; // STR/DEX
    shock?: number[];
    // Tags
    tags?: string[];
    // Vehicle stats
    speed?: number;
    armor?: number;
    hp?: number;
    crew?: number;
    tonnage?: number;
    isVehicleBody?: boolean;
    // cyberware:
    isCyberware?: boolean;
    strain?: number;
    shortDesc?: string;
    // natural defenses:
    isNaturalDefensesWeapon? : boolean;
    // drones:
    fittings? : number,
    ac?: number,
    controlRange?: number;  
    // mods
    mods?: Mod[];
    customName?: string;
    customNotes?: string;
    // robots:
    hitDice?: number; 
    attackBonus?: number;
    move?: number; 
    morale?: number;
    skill?: number;
    save?: number;
}

export interface PrintTraits {
    foci: boolean;
    psychicDisciplines: boolean;
    psychicDisciplinesDesc: boolean;
    psychicTechniquesDesc: boolean;
    equipment: boolean;
    skills: boolean;
    includeUntrainedSkills: boolean;
    notes: boolean;
}

export interface CharacterTraits {
    id: string;
    basicTraits: BasicTraits;
    attributeTraits: AttributeTraits;
    background: BackgroundTraits;         // All backgrounds provide three skill level picks at 1st level.
    level: number;
    levelOne: LevelOneTraits;
    levels: Level[];
    gear: GearTraits;
    print: PrintTraits;
    notes: string;
    isValid: boolean;
    validationErrors: string[];
    showValidation: boolean;
}

export interface SkillPointSpend {
    spendType: string;
    skillName: string;
    attributeName: string;
    techniqueName: string;
    pointType: string;
    pointsSpent: number;
    validationCodes: string[];
    psychicTechniquePicks: PsychicTechniquePick[];
}

export interface Level {
    level: number;
    rolledHitPoints: number[];
    totalHitPoints: number;
    hitPointRerolls: number;
    validationCodes: string[];
    skillPointSpends: SkillPointSpend[];
    focusLevelPick?: FocusLevelPick;
    // psychicTechniquePicks: PsychicTechniquePick[];
}

export const getInitialCharacterTraits = (backgroundsData: BackgroundData[], classesData: ClassData[], skillsData: SkillData[], focusesData: FocusData[]): CharacterTraits => {

    let newChar: CharacterTraits = {
        id: "",
        basicTraits: {
            name: "",
            goal: "",
            isPublic: false,
            origin: "",
            validationCodes: [],
            collapsedSections: [],
            uniqueGift1: "",
            uniqueGift2: ""
        },
        attributeTraits: {
            method: "",
            includeNonstandardMethods: false,
            attributeScores: [0, 0, 0, 0, 0, 0],
            originalScores: [0, 0, 0, 0, 0, 0],
            randomScores: [0, 0, 0, 0, 0, 0],
            validationCodes: [],
            attributeNumberSetTo14: -1,
            attributeValueThatWasReplacedWith14: -1,
            rerolls: 0
        },
        background: {
            method: "",
            backgroundName: "",
            backgroundSkillLevelPicks: [],
            tableRolls: [
                { table: "", roll: 0, result: "", skillLevelPicks: [], attributeScoreBonuses: [] },
                { table: "", roll: 0, result: "", skillLevelPicks: [], attributeScoreBonuses: [] },
                { table: "", roll: 0, result: "", skillLevelPicks: [], attributeScoreBonuses: [] }
            ],
            validationCodes: [],
            languages: "Mandate",
            homeworld: "",
        },
        level: 1,
        levelOne: {
            classes: [],
            freeFocusLevelPick: { focus: "", level: 0, skillLevelPicks: [], skillPointsPicks: [], grantsSkill: false, type: "" },
            freeSkillLevelPicks: [],
            psychicTechniquePicks: [],
            wildTalentPicks: {
                wildTalentPsychicDiscipline: "",
                wildTalentTechnique1: "",
                wildTalentTechnique2: "",
            },
            isAdventurer: false,
            rolledHitPoints: 0,
            hitPointRerolls: 0,
            validationCodes: [],
        },
        gear: {
            equipment: [],
            method: "",
            pack: "",
            startingCredits: -1,
            additionalCredits: 0,
            // credits: -1,
        },
        print: {
            foci: true,
            psychicDisciplines: true,
            psychicDisciplinesDesc: true,
            psychicTechniquesDesc: true,
            equipment: true,
            skills: true,
            includeUntrainedSkills: true,
            notes: true,
        },
        levels: [],
        notes: "",
        isValid: false,
        validationErrors: [],
        showValidation: false
    }

    newChar = applyValidation(newChar, backgroundsData, classesData, skillsData, focusesData);

    return newChar;

};