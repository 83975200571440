import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import app from "../firebase";

import { AuthContext } from "../Auth";

import * as toastr from "toastr";

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfirmModal from "../components/ConfirmModal";

const Starships: React.FunctionComponent<unknown> = (props: any) => {

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [starships, setStarships] = useState<Array<any>>([]);
    const [displayConfirmDelete, setDisplayConfirmDelete] = useState(false);
    const [starshipIdToDelete, setStarshipIdToDelete] = useState("");
    const [starshipNameToDelete, setStarshipNameToDelete] = useState("");
    const [reloadStarships, setReloadStarships] = useState(0);

    useEffect(() => {
        // Create an scoped async function in the hook.
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {
                    const db = app.firestore();
                    const data = await db.collection("starship")
                        .where("user_id", "==", user.uid)
                        .orderBy("name_lower", "asc")
                        .get();

                    const starships: any[] = [];
                    data.forEach((doc) => {
                        starships.push({ ...doc.data(), id: doc.id });
                    });
                    setStarships(starships);
                    setLoading(false);
                }
            }
            catch (error: any) {
                toastr.error(error);
                setLoading(false);
            }
        }
        // Execute the created function directly
        asyncUseEffect();
    }, [user, reloadStarships]); // Will load characters after rendering 

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const onDelete = (starshipId: string, starshipName: string): void => {
        setStarshipIdToDelete(starshipId);
        setStarshipNameToDelete(starshipName);
        setDisplayConfirmDelete(true);
    }

    const starshipRows = starships.map((s) =>
        <tr key={s.id}>
            <td style={{ width: "30%" }} className="" ><Link to={"/starshipDesigner/" + s.id}>{isBlank(s.name, "(No name)")}</Link></td>
            <td style={{ width: "20%" }} className="d-none d-md-table-cell">{isBlank(s.hullType, "Not Set")}</td>
            <td style={{ width: "10%" }} className="text-center">
                <button className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); onDelete(s.id, s.name) }}><FontAwesomeIcon icon={faTrash} title="Delete"></FontAwesomeIcon ></button>
            </td>
        </tr>
    )

    const deleteStarship = async () => {
        setDisplayConfirmDelete(false);

        const db = app.firestore();
        await db.collection("starship").doc(starshipIdToDelete).delete();
        await db.collection("starshipTraits").doc(starshipIdToDelete).delete();

        setReloadStarships(reloadStarships + 1);
    }

    return (
        <div className="pageBody">

            <ConfirmModal
                displayModal={displayConfirmDelete}
                title="Confirm Delete Starship"
                message={"Are sure you want to delete " + starshipNameToDelete + "?"}
                confirmButtonText="Delete"
                closeButtonText="Cancel"
                onClose={() => setDisplayConfirmDelete(false)}
                onConfirm={() => deleteStarship()}
            />

            <h1 className="pageHeading">Starships (Beta)</h1>

            <div className="mt-2 mb-2"><Link className="btn btn-primary" to="/starshipDesigner">Design a Starship</Link></div>

            {!loading && starships.length > 0 &&

                <table className="table table-striped table-sm w-auto">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{ width: "30%" }} className="">Ship Name</th>
                            <th scope="col" style={{ width: "20%" }} className="d-none d-md-table-cell">Hull Type</th>
                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {starshipRows}
                    </tbody>
                </table>
            }

            {!loading && starships.length === 0 &&
                <p>You currently have no starships.</p>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}

export default Starships;
