import React from "react";
import AlienFocusDesignerMain from "../components/alienFociDesign/AlienFocusDesignerMain";

type IProps = {}

const CustomAlienFociDesigner: React.FunctionComponent<IProps> = (props: IProps) => {

    return (
        <div className="pageBody">
            <AlienFocusDesignerMain />
        </div>
    );
}

export default CustomAlienFociDesigner;
