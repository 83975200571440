import React, { useState } from "react";
import { Redirect } from "react-router-dom";
// import { useTranslation } from 'react-i18next';

// import { AuthContext } from "../Auth";

import { getInitialCharacterTraits, PrintTraits } from "../interfaces/CharacterTraits";

import CharacterDesignMenu from "./CharacterDesignMenu";
import CharacterDesignDerivedStats from "./CharacterDesignDerivedStats";

import { Lookups } from "../lookups/Lookups";
import CharacterDesignScreen from "./charGen/CharacterDesignScreen";
import CharacterDesignSheetScreen from "./CharacterDesignSheetScreen";

interface RouteParams {
    id: string
}

const CharacterDesignMainDemo: React.FunctionComponent<{}> = () => {

    // const { t } = useTranslation();

    // const { user: currentUser } = useContext(AuthContext);
    const lookups = Lookups.getInstance();

    // Get the character Id from the URL route (if any). 
    // let { id: IdInQuerystring } = useParams<RouteParams>();

    // State hooks:

    const [screen, setScreen] = useState("design");
    const [characterTraits, setCharacterTraits] = useState(getInitialCharacterTraits(lookups.backgrounds, lookups.classes, lookups.skills, lookups.focuses));
    const [goToCharacterList, setGoToCharacterList] = useState(false);

    // // useEffect hook to load character if id in querystring. 
    // useEffectHookLoadCharacter(IdInQuerystring, currentUser, setCharacterId, setCharacterTraits, setIsLoading, lookups.backgrounds, lookups.classes, lookups.skills, lookups.focuses);

    // Save the character:

    // const saveCharacter = async () => {
    //     if (currentUser) {
    //         setIsLoading(true);
    //         if (characterId !== "") {
    //             await UpdateCharacter(currentUser, characterTraits, characterId);
    //         } else {
    //             await SaveNewCharacter(currentUser, characterTraits, setCharacterId);
    //         }
    //         setGoToCharacterList(true);
    //     }
    // }

    const setPrintTraits = (printTraits: PrintTraits) => {
        const charTraits = { ...characterTraits };
        charTraits.print = printTraits;
        setCharacterTraits(charTraits);
    }

    const cancel = () => {
        setGoToCharacterList(true);
    }

    return (
        <>
            {screen === "design" &&
                <h1>Character Designer</h1>
            }

            {screen === "audit" &&
                <h1>Character Audit</h1>
            }

            {screen === "sheet" &&
                <h1>Character Download</h1>
            }

            <CharacterDesignMenu
                demoMode={true}
                readOnly={false}
                screen={screen}
                setScreen={(screen: string) => setScreen(screen)}
                saveCharacter={() => null}
                cancel={cancel}
            />

            <p>Try out the Designer, then sign up for an account so that you can save your characters.</p>


            {screen === "design" &&
                <CharacterDesignScreen characterTraits={characterTraits} setCharacterTraits={setCharacterTraits} />}

            {screen === "audit" &&
                <CharacterDesignDerivedStats charTraits={characterTraits} />
            }

            {screen === "sheet" &&
                <CharacterDesignSheetScreen charTraits={characterTraits} onSetPrintTraits={setPrintTraits} />
            }
            <CharacterDesignMenu
                demoMode={true}
                readOnly={false}
                screen={screen}
                setScreen={(screen: string) => setScreen(screen)}
                saveCharacter={() => null}
                cancel={cancel}
            />

            <a className="btn btn-primary btn-sm mr-2" href="https://www.buymeacoffee.com/swoone" target="_blank" rel="noopener noreferrer"><img src="/BuyMeACoffee.svg" height="20px" width="20px" alt="Buy Me A Coffee" title="Buy Me A Coffee" /> Buy Me A Coffee</a>


            {goToCharacterList &&
                <Redirect to="/characters" />
            }

        </>

    );
}

export default CharacterDesignMainDemo;

