import React from "react";

import { Lookups } from "../lookups/Lookups";

type IProps = {}

const FAQ: React.FunctionComponent<IProps> = (props: IProps) => {

    const lookups = Lookups.getInstance();

    return (
        <div className="pageBody">
            <h1 className="pageHeading">FAQ</h1>

            <p>Welcome to Freebooter - a character and starship designer for the Stars Without Number roleplaying game.</p>

            <ul>
                {lookups.FAQ.map((f, index) => (
                    <li key={"1_" + index}><a href={"#" + index} dangerouslySetInnerHTML={{ __html: f.title }}></a></li>
                ))}
            </ul>

            {lookups.FAQ.map((f, index) => (
                <div key={"2_" + index}>
                    <h5 className="faqHeader" id={index.toString()} dangerouslySetInnerHTML={{ __html: f.title }}></h5>
                    {f.text.map((t, index) => (
                        <div key={"3_" + index} className="mb-2" dangerouslySetInnerHTML={{ __html: t }}></div>
                    ))}
                </div>
            ))}

        </div>
    );
}

export default FAQ;
