import React from "react";

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    msg: string;
    theKey?: string;
}

const ValidationAlert: React.FunctionComponent<IProps> = (props: IProps) => {

    let effectiveKey = "";
    if (props.theKey) {
        effectiveKey = props.theKey;
    }

    return (
        <div className="valError" tabIndex={0} key={effectiveKey}>
            <span className="pl-0 pr-0 pt-1 pb-1 small">
                <FontAwesomeIcon icon={faExclamationCircle} title="text-info"></FontAwesomeIcon >&nbsp;
                {props.msg}
            </span>
        </div>
    );
}

export default ValidationAlert;
