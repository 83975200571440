
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { CharacterDerivedStats, CreationStep } from '../../classes/CharacterDerivedStats';
import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { TechniqueData } from '../../interfaces/PsychicTechniquesData';
import { Lookups } from '../../lookups/Lookups';
import { getSkillsByType } from '../../utilities/SkillUtilities';
import { convertPointTypeToNameCapitalised } from '../../utilities/Utilities';
import DescriptionAlert from '../DescriptionAlert';
import uniqid from 'uniqid';

export interface IProps {
    charTraits: CharacterTraits;
    level: number;
    index: number;
    onSelectSkillToBuyTechniqueFrom: (index: number, skillName: string) => void;
    onSelectTechniqueToBuy: (level: number, skillPickNumber: number, technique: string) => void;
    onSelectPointTypeToSpend: (index: number, pointType: string) => void;
    onSelectPointsSpent: (index: number, pointsSpent: number) => void;
}

const SpendSkillPointsToBuyTechnique: React.FunctionComponent<IProps> = (props: IProps) => {

    const initialDesc: any[] = [];
    const [showDescription, setShowDescription] = useState(false);
    const [techniqueDescription, setTechniqueDescription] = useState(initialDesc);

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateIsPsychic();
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, props.level, props.index);
    charDerivedStats.calculateSkillPointsLeftSoFar();
    charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, -1, -1, props.level, props.index);
    charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, props.level, props.index);

    const charDerivedStatsAtPreviousIndex = new CharacterDerivedStats(props.charTraits);
    if (props.index === 0) {
        // Get stats from end of previous level
        const levelMinusOne = props.level - 1;
        charDerivedStatsAtPreviousIndex.calculateIsPsychic();
        charDerivedStatsAtPreviousIndex.calculateFocusLevels(CreationStep.AllSteps, levelMinusOne);
        charDerivedStatsAtPreviousIndex.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, levelMinusOne);
        charDerivedStatsAtPreviousIndex.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, levelMinusOne, 100);
        charDerivedStatsAtPreviousIndex.calculateSkillPointsLeftSoFar();
        charDerivedStatsAtPreviousIndex.calculateSkillLevels(CreationStep.AllSteps, -1, -1, levelMinusOne, 100);
        charDerivedStatsAtPreviousIndex.calculatePsychicTechniqueLevels(CreationStep.AllSteps, levelMinusOne, 100);
    } else {
        // Get stats from index -1
        const indexMinusOne = props.index - 1;
        charDerivedStatsAtPreviousIndex.calculateIsPsychic();
        charDerivedStatsAtPreviousIndex.calculateFocusLevels(CreationStep.AllSteps, props.level);
        charDerivedStatsAtPreviousIndex.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, props.level);
        charDerivedStatsAtPreviousIndex.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, props.level, indexMinusOne);
        charDerivedStatsAtPreviousIndex.calculateSkillPointsLeftSoFar();
        charDerivedStatsAtPreviousIndex.calculateSkillLevels(CreationStep.AllSteps, -1, -1, props.level, indexMinusOne);
        charDerivedStatsAtPreviousIndex.calculatePsychicTechniqueLevels(CreationStep.AllSteps, props.level, indexMinusOne);
    }

    let thisLevel = props.charTraits.levels.find((l) => l.level === props.level);

    const lookups = Lookups.getInstance();
    const psychicSkills = getSkillsByType("Psychic", lookups.skills);

    const allCharactersSkills = charDerivedStats.skillLevels;
    const allCharactersPsychicSkills = allCharactersSkills.filter((s) => psychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));


    const flipShowDescription = (showTheDescription: boolean, skill: string, technique: string) => {

        // show the selected technique description. 
        const allTechniques = lookups.psychicTechniques;
        const techniquesForSkill = allTechniques.find((t) => t.skill === skill);
        const theTechnique = techniquesForSkill?.techniques.find((t) => t.name === technique);
        if (theTechnique) {
            const desc = [];
            const paragraphs = theTechnique.paragraphs.map((p) => <div className="mb-1" key={uniqid()}>{p}</div>);
            desc.push(<div className="mb-2" key={uniqid()}>{paragraphs}</div>)
            setTechniqueDescription(desc);
            setShowDescription(showTheDescription);
        } else {
            setTechniqueDescription([]);
            setShowDescription(false);
        }

    }

    const onSelectSkillToBuyTechniqueFrom = (e: any) => {
        props.onSelectSkillToBuyTechniqueFrom(props.index, e.target.value);
    }

    const onSelectTechniqueToBuy = (e: any) => {

        const technique = e.target.value;
        const skillPickNumber = props.index;
        // var dataset = e.target.options[e.target.selectedIndex].dataset;
        // const skill = dataset.skill;

        // set the selected technique
        props.onSelectTechniqueToBuy(props.level, skillPickNumber, technique);
    }

    const onSelectPointTypeToSpend = (e: any) => {
        props.onSelectPointTypeToSpend(props.index, e.target.value);
    }

    const onSelectPointsSpent = (e: any) => {
        props.onSelectPointsSpent(props.index, parseInt(e.target.value));
    }

    const formatSkillLevel = (skillLevel: any) => {
        if (skillLevel) {
            return "-" + (parseInt(skillLevel) - 1);
        } else {
            return "";
        }
    }

    const getKnownPsychicSkillsDropdown = () => {

        const psychicSkillsOverLevel0 = allCharactersPsychicSkills.filter((ps) => ps.level && ps.level > 1);
        if (psychicSkillsOverLevel0.length === 0) {
            return <div className="small">You must have a Psychic skill of level 1 or greater to buy a technique</div>
        }

        let selectedSkill = "";
        if (thisLevel) {
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;
        }

        let skills: any[] = [];
        skills.push(<option key={"discipline"} value="">--Discipline--</option>);

        allCharactersPsychicSkills.filter((s) => s.level && s.level > 1).forEach((sk) => {
            skills.push(<option key={sk.skill} onChange={onSelectSkillToBuyTechniqueFrom} value={sk.skill}>{sk.skill}{formatSkillLevel(sk.level)}</option>)
        })

        return <select value={selectedSkill} onChange={onSelectSkillToBuyTechniqueFrom} className="mr-1">{skills}</select>;

    }

    const psyTechSorter = (a: TechniqueData, b: TechniqueData) => {
        return a.level > b.level ? -1 : a.name < b.name ? -1 : 1;
    }

    const getAvailableTechniques = () => {

        let selectedSkillToBuyTechniqueFrom = "";
        let selectedTechnique = "";
        if (thisLevel) {
            selectedSkillToBuyTechniqueFrom = thisLevel.skillPointSpends[props.index].skillName;
            selectedTechnique = thisLevel.skillPointSpends[props.index].techniqueName;
        }
        if (selectedSkillToBuyTechniqueFrom === "") { return null; }

        const psychicSkill = allCharactersPsychicSkills.find((s) => s.skill === selectedSkillToBuyTechniqueFrom);
        if (psychicSkill) {

            const psychicSkillLevel = psychicSkill.level ? psychicSkill.level - 1 : 0;

            let techniques: any[] = [];
            techniques.push(<option key={"technique"} value="">--Technique & Level/Cost--</option>);

            let knownTechniquesAtPreviousIndex = charDerivedStatsAtPreviousIndex.psychicTechniqueLevels.map((ptl) => ptl.technique);

            lookups.psychicTechniques.filter((pt) => pt.skill === selectedSkillToBuyTechniqueFrom).forEach((pt) => {
                pt.techniques.filter((pt) => pt.level <= psychicSkillLevel).filter((pt) => knownTechniquesAtPreviousIndex.indexOf(pt.name) === -1).sort(psyTechSorter).forEach((tech, techIndex) => {
                    techniques.push(<option key={tech.name} onChange={onSelectTechniqueToBuy} data-skill={selectedSkillToBuyTechniqueFrom} value={tech.name}>{tech.name}-{tech.level}</option>)
                })
            })

            return <select value={selectedTechnique} onChange={onSelectTechniqueToBuy} className="mr-1">{techniques}</select>
        }

        return null;

    }

    const getPointsTypeDropdown = () => {

        let selectedSkill = "";
        let selectedTechnique = "";
        let selectedPointType = "";
        let selectedPointsSpent = 0;
        if (thisLevel) {
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;
            selectedTechnique = thisLevel.skillPointSpends[props.index].techniqueName;
            selectedPointType = thisLevel.skillPointSpends[props.index].pointType;
            selectedPointsSpent = thisLevel.skillPointSpends[props.index].pointsSpent;
        }
        if (selectedTechnique === "") { return null; }

        let output: any[] = [];

        const anyPointsAvailable = true;
        const defaultOption = anyPointsAvailable ? <option key={"defaultOption"} value="">--Points Type--</option> : <option value="">--No Suitable Points--</option>;

        for (const skillName in charDerivedStats.skillPointsAvailableSoFar) {

            const pointsLeft = !charDerivedStats.skillPointsLeftSoFar[skillName] ? 0 : charDerivedStats.skillPointsLeftSoFar[skillName];

            const isPsychicSkill = psychicSkills.find((s) => s.skill === selectedSkill) ? true : false;

            let rightSortOfSkillPoint = false;
            if (skillName === "general") {
                rightSortOfSkillPoint = true;
            } else if (skillName === "nonCombatNonPsychic") {
                if (!isPsychicSkill) {
                    rightSortOfSkillPoint = false;
                }
            } else if (skillName === "psychic") {
                if (isPsychicSkill) {
                    rightSortOfSkillPoint = true;
                }
            } else {
                if (skillName === selectedSkill + "_skill") {
                    rightSortOfSkillPoint = true;
                }
            }

            const spendPointsOnThisSkillTypeInThisSkillPick = selectedPointType === skillName && selectedPointsSpent > 0;

            // Add option to dropdown.
            if (rightSortOfSkillPoint && (spendPointsOnThisSkillTypeInThisSkillPick || pointsLeft)) {
                if (skillName.indexOf("_skill") !== -1) {
                    if (skillName === selectedSkill + "_skill") {
                        output.push(<option key={skillName} value={skillName}>{convertPointTypeToNameCapitalised(skillName, true)}</option>)
                    }
                } else {
                    output.push(<option key={skillName} value={skillName}>{convertPointTypeToNameCapitalised(skillName, true)}</option>)
                }

            }
        }

        return <select value={selectedPointType} onChange={onSelectPointTypeToSpend} className="mr-1">{defaultOption}{output}</select>;
    }

    const getPointsSpentDropdown = () => {
        let selectedSkill = "";
        let selectedPointType = "";
        let selectedPointsSpent = 0;
        let selectedTechnique = "";
        if (thisLevel) {
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;
            selectedPointType = thisLevel.skillPointSpends[props.index].pointType;
            selectedPointsSpent = thisLevel.skillPointSpends[props.index].pointsSpent;
            selectedTechnique = thisLevel.skillPointSpends[props.index].techniqueName;
        }

        // Get the cost of the technique:
        let costForTechnique = 0;
        const techniquesForSkill = lookups.psychicTechniques.find((pt) => pt.skill === selectedSkill);
        if (techniquesForSkill) {
            const theTechnique = techniquesForSkill.techniques.find((t) => t.name === selectedTechnique);
            if (theTechnique) { costForTechnique = theTechnique.level; }
        }

        let maxPointsAvailable = 0;
        const pointsLeft = getPointsLeftAtThisLevelAndIndex();

        maxPointsAvailable = pointsLeft[selectedPointType] + selectedPointsSpent;
        maxPointsAvailable = Math.min(costForTechnique, maxPointsAvailable);

        const getDefaultOption = (maxNum: number) => {
            if (maxNum <= 0) {
                return <option value="0">--No Points Left--</option>
            } else {
                return <option value="0">--Points--</option>
            };
        }

        const getOptions = (maxNum: number) => {
            if (maxNum === 0) { return null; }

            const pluralise = (points: number) => { return points > 1 ? "s" : "" };

            const output: any[] = [];
            for (let i = 0; i <= maxNum - 1; i++) {
                output.push(<option key={i} value={i + 1}>{i + 1} point{pluralise(i + 1)}</option>)
            }
            return output;
        }

        if (selectedPointType !== "") {
            return (
                <select value={selectedPointsSpent} onChange={onSelectPointsSpent}>
                    {getDefaultOption(maxPointsAvailable)}
                    {getOptions(maxPointsAvailable)}
                </select>
            )
        }
        return null;
    }

    const getPointsLeftAtThisLevelAndIndex = (): any => {

        let pointsAvailable: any = {};

        for (const pointsType in charDerivedStats.skillPointsAvailableSoFar) {
            const pointsSpentSoFar = charDerivedStats.skillPointsSpentSoFar[pointsType] ? charDerivedStats.skillPointsSpentSoFar[pointsType] : 0;
            pointsAvailable[pointsType] = charDerivedStats.skillPointsAvailableSoFar[pointsType] - pointsSpentSoFar;
        }

        return pointsAvailable;
    }

    const getPointsSpentOnTechnique = (selectedTechnique: string) => {
        let pointsSpentOnTheTechnique = 0;
        props.charTraits.levels.forEach((lev) => {
            if (lev.level <= props.level) {

                // Get skill points assigned as level up
                lev.skillPointSpends.forEach((slp, thisIndex) => {
                    let maxIndex = 100;
                    if (lev.level === props.level) { maxIndex = props.index; }
                    if (thisIndex <= maxIndex) {
                        if (slp.techniqueName === selectedTechnique) {
                            pointsSpentOnTheTechnique = pointsSpentOnTheTechnique + slp.pointsSpent;
                        }
                    }
                })
            }
        })
        return pointsSpentOnTheTechnique;
    }

    const getImprovementNote = () => {

        let selectedTechnique = "";
        let selectedSkill = "";

        let costForTechnique = 0;

        if (thisLevel) {
            selectedTechnique = thisLevel.skillPointSpends[props.index].techniqueName;
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;

            // Get the cost of the technique:
            const techniquesForSkill = lookups.psychicTechniques.find((pt) => pt.skill === selectedSkill);
            if (techniquesForSkill) {
                const theTechnique = techniquesForSkill.techniques.find((t) => t.name === selectedTechnique);
                if (theTechnique) { costForTechnique = theTechnique.level; }
            }
        }

        let output: string = "";
        let pointsSpentOnTheTechnique = 0;
        if (selectedTechnique !== "") {
            // Find how many points have already been invested in the technique up to this level and index. 
            pointsSpentOnTheTechnique = getPointsSpentOnTechnique(selectedTechnique);

            let plural1 = "";
            if (costForTechnique !== 1) { plural1 = "s" }

            let plural2 = "";
            if (pointsSpentOnTheTechnique !== 1) { plural2 = "s" }

            if (pointsSpentOnTheTechnique === costForTechnique) {
                output = "Technique purchased for " + costForTechnique + " skill point" + plural1 + ".";
            } else {
                output = "Technique costs " + costForTechnique + " skill point" + plural1 + "; have spent " + pointsSpentOnTheTechnique + " skill point" + plural2 + ".";
            }
        }

        return (
            <div className="small">{output}</div>
        )
    }

    const getTechniqueIcon = () => {
        let selectedTechnique = "";
        let selectedSkill = ""; 

        if (thisLevel) {
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;
            selectedTechnique = thisLevel.skillPointSpends[props.index].techniqueName;
        }

        if (selectedTechnique !== "") {
            return (
                <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => flipShowDescription(!showDescription, selectedSkill, selectedTechnique)} title="Show Technique description">
                    <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                </button>
            )
        }

        return null;
    }

    const getTechniqueDescription = () => {
        if (showDescription) {
            return (
                <DescriptionAlert display={true}>
                    {techniqueDescription}
                </DescriptionAlert>
            )
        }
        return null;
    }

    return (
        <>
            {getKnownPsychicSkillsDropdown()}{getAvailableTechniques()}{getPointsTypeDropdown()}{getPointsSpentDropdown()}{getTechniqueIcon()}
            {getImprovementNote()}
            {getTechniqueDescription()}



            {/* <div><pre>{JSON.stringify(thisLevel?.skillPointSpends, null, 2)}</pre></div>

            <div><pre>technique spends: {JSON.stringify(charDerivedStats.skillPointsSpentSoFarOnTechniques, null, 2)}</pre></div> */}
        </>
    );
}

export default SpendSkillPointsToBuyTechnique; 
