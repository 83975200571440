import React from "react";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterTraits } from "../../interfaces/CharacterTraits";
import { Lookups } from "../../lookups/Lookups";

import ValidationAlert from "../ValidationAlert";

export interface IProps {
    charTraits: CharacterTraits;
    onSelectEquipmentPack: (pack: string) => void;
}

const PickEquipmentPack: React.FunctionComponent<IProps> = (props: IProps) => {

    const lookups = Lookups.getInstance();

    const onSelectGearPack = (e: any) => {
        const packName = e.currentTarget.value;
        props.onSelectEquipmentPack(packName);
    }

    const getPackDropdown = () => {

        let gearPacks = lookups.gearPacks.filter((gp) => gp.public !== null && gp.public !== false);

        return (
            <>
                <select onChange={onSelectGearPack} value={props.charTraits.gear.pack}>
                    <option value="">-- select --</option>
                    {gearPacks.sort((a, b) => a.name > b.name ? 1 : -1).map((gt) => <option key={gt.name} value={gt.name}>{gt.name}</option>)}
                </select>
                {props.charTraits.showValidation && props.charTraits.levelOne.validationCodes.indexOf("equipmentPackNotSelected") !== -1 &&
                    <div>
                        <ValidationAlert msg="Equipment pack must be selected" />
                    </div>
                }
            </>
        )
    }

    const packDropdown = getPackDropdown();

    return (
        <div className="mb-2">
            <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
            Select equipment pack : {packDropdown}
        </div>
    )

}

export default PickEquipmentPack;