import { useEffect } from "react";
import app from "../firebase";
import * as toastr from "toastr";

import { getInitialStarshipTraits, StarshipTraits } from '../interfaces/StarshipTraits';
import { checkAndValidateStarshipTraits } from '../utilities/starshipMainUtilities';
import { CrewData } from '../interfaces/CrewData';

export const useEffectHookLoadStarship = (isShare: boolean, IdInQuerystring: string, currentUser: firebase.User | null, setStarshipId: any, setStarshipTraits: any, setIsLoading: any) => {

    useEffect(() => {
        // This loads the starship for the ID set in the URL when the component has first finished rendering.

        const isNullToString = (x: any) => x === null || x === undefined ? "" : x;
        const isNullToBoolean = (x: any) => x === null || x === undefined ? false : x;
        const isNullToArray = (x: any) => x === null || x === undefined ? [] : x;
        const isNullToNumber = (x: any, defaultNum: number) => x === null || x === undefined ? defaultNum : x;

        const isNullToCrewData = (x: any): CrewData => {
            if (x === null || x === undefined) {
                const blankCrewData: CrewData = {
                    crewDefaultSkill: 0,
                    crewGunnerSkill: 0,
                    crewNPCCommandPoints: 0,
                    crewAutomationSupportBots: 0,
                    crewCurrent: 0,
                    passengersCurrent: 0,
                    lifeSupportMonths: 2
                };
                return blankCrewData;
            } else {
                return {
                    crewDefaultSkill: isNullToNumber(x.crewDefaultSkill, 0),
                    crewGunnerSkill: isNullToNumber(x.crewGunnerSkill, 0),
                    crewNPCCommandPoints: isNullToNumber(x.crewNPCCommandPoints, 0),
                    crewCurrent: isNullToNumber(x.crewCurrent, 0),
                    crewAutomationSupportBots: isNullToNumber(x.crewAutomationSupportBots, 0),
                    passengersCurrent: isNullToNumber(x.passengersCurrent, 0),
                    lifeSupportMonths: isNullToNumber(x.lifeSupportMonths, 0),
                }
            }
        }

        const cleanStarshipTraitsData = (data: any, id: string): StarshipTraits => {
            const traits: StarshipTraits = {
                id: isNullToString(id),
                name: isNullToString(data.name),
                isPublic: isNullToBoolean(data.isPublic),
                fittings: isNullToArray(data.fittings),
                mods: isNullToArray(data.mods),
                crewData: isNullToCrewData(data.crewData),
                autoTargetingWeapons: isNullToArray(data.autoTargetingWeapons),
                cargo: isNullToArray(data.cargo),
                ammo: isNullToArray(data.ammo),
                foxerDrones: isNullToArray(data.foxerDrones),
                currentFuelLoads: isNullToNumber(data.currentFuelLoads, 1),
                hullType: isNullToString(data.hullType),
                isValid: isNullToBoolean(data.isValid),
                validationCodes: [],
                validationErrors: [],
                showValidation: true,
                notes: isNullToString(data.notes)
            }

            return traits;
        }

        const asyncUseEffect = async () => {
            try {
                setIsLoading(true);
                if (currentUser || isShare) {
                    if (IdInQuerystring !== undefined && IdInQuerystring !== "") {
                        localStorage.removeItem("tryShipTraits");
                        const db = app.firestore();
                        const docRefDetail = db.collection("starshipTraits").doc(IdInQuerystring);
                        docRefDetail.get().then((doc) => {
                            if (doc.exists) {
                                const data = doc.data();
                                if (data) {
                                    setStarshipId(IdInQuerystring);
                                    let traits = cleanStarshipTraitsData(data.traits, IdInQuerystring);
                                    traits = checkAndValidateStarshipTraits(traits);
                                    setStarshipTraits(traits);
                                    setIsLoading(false);
                                }
                            }
                        }).catch((e) => {
                            console.log("ERR: " + e);
                        });
                    } else {
                        const savedTraits = localStorage.getItem("tryShipTraits");
                        if (savedTraits !== null) {
                            const traits: StarshipTraits = JSON.parse(savedTraits) as unknown as StarshipTraits;
                            setStarshipTraits(traits);
                            setIsLoading(false);
                        } else {
                            let traits = getInitialStarshipTraits();
                            traits = checkAndValidateStarshipTraits(traits);
                            setStarshipTraits(traits);
                            setIsLoading(false);
                        }
                    }
                }
            }
            catch (error: any) {
                // toastr.error(error);
                console.log("error: " + error);
                setIsLoading(false);
            }
        }
        // Execute the created function directly
        asyncUseEffect();

    }, [currentUser, IdInQuerystring, setStarshipId, setStarshipTraits, setIsLoading, isShare])
}