import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import { AuthContext } from "../Auth";
import app from "../firebase";

const AccountUpdate: React.FunctionComponent<{}> = () => {

    const { t } = useTranslation();

    const [displayName, setDisplayName] = useState("");
    const [detailsUpdated, setDetailsUpdated] = useState(false);

    const [error, setError] = useState("");

    const { user: currentUser } = useContext(AuthContext);

    const { handleSubmit, register } = useForm();

    let email = "";
    if (currentUser) { email = currentUser.email as string; }

    useEffect(() => {
        if (currentUser) {
            setDisplayName(currentUser.displayName as string);
        }
    }, [currentUser]);

    const onSubmitUpdateDetails = async (values: any) => {
        if (currentUser) {
            // Update the user's data in their profile:
            currentUser.updateProfile({
                displayName: values.displayName
            }).then(() => {
                setDisplayName(currentUser.displayName as string);
                setError("");
                setDetailsUpdated(true);
                setTimeout(() => { setDetailsUpdated(false) }, 5000);
            }, (error) => {
                setError(error);
            });

            // Also update user name in the user collection:
            const db = app.firestore();
            let userRef = await db.collection("users").doc(currentUser.uid);
            userRef.update({ displayName: values.displayName });
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4>{t("myAccount.updateMyAccount")}</h4>
            </div>
            <div className="card-body">

                <form action="#!" method="get" onSubmit={handleSubmit(onSubmitUpdateDetails)}>

                    {detailsUpdated &&
                        <div className="alert alert-primary" role="alert">
                            {t("myAccount.accountDetailsUpdated")}
                        </div>
                    }

                    {error &&
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    }

                    <div className="form-group">
                        <label htmlFor="displayName">Email address</label>
                        <p>{email}</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="displayName">{t("myAccount.displayName")}</label>
                        <input type="text" defaultValue={displayName} ref={register({})} className="form-control textMid" id="displayName" name="displayName" />
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">{t("myAccount.updateMyAccount")}</button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default AccountUpdate; 