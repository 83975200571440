import React from "react";

import StarshipDesignMain from "../components/starships/StarshipDesignMain";

const StarshipDesigner: React.FunctionComponent<{}> = (props: {}) => {

    return (
        <div className="pageBody">
            <StarshipDesignMain />
        </div>
    );
}

export default StarshipDesigner;
