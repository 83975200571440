import React from "react";
import { faSave, faMarker, faUserCheck, faDownload, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from "react-router-dom";

interface IProps {
    demoMode: boolean;
    readOnly: boolean;
    screen: string;
    setScreen: (screen: string) => void;
    saveCharacter: () => void;
    cancel: () => void;
}

const CharacterDesignMenu: React.FunctionComponent<IProps> = (props: IProps) => {

    const baseBtnClassName = "btn btn-primary charMenuBtn";

    let designBtnClassName = baseBtnClassName;
    if (props.screen === "design") { designBtnClassName += " active"; }

    let auditBtnClassName = baseBtnClassName;
    if (props.screen === "audit") { auditBtnClassName += " active"; }

    let sheetBtnClassName = baseBtnClassName;
    if (props.screen === "sheet") { sheetBtnClassName += " active"; }

    const onSetScreen = (e: any, screen: string) => {
        e.preventDefault();
        props.setScreen(screen);
    }

    const onSaveCharacter = (e: any) => {
        e.preventDefault();
        props.saveCharacter();
    }

    // const onCancel = (e: any) => {
    //     e.preventDefault();
    //     props.cancel();
    // }

    return (
        <div>
            <div className="btn-toolbar mb-3 mt-3" role="toolbar" aria-label="Screen toolbar">
                <div className="btn-group mr-2" role="group" aria-label="Select screen">
                    <button type="button" className={designBtnClassName} onClick={(e) => onSetScreen(e, "design")} title="Design"><FontAwesomeIcon icon={faMarker} title="Design" className="svg"></FontAwesomeIcon ><span className="menuText"> Design</span></button>
                    <button type="button" className={auditBtnClassName} onClick={(e) => onSetScreen(e, "audit")} title="Audit"><FontAwesomeIcon icon={faUserCheck} title="Audit" className="svg"></FontAwesomeIcon ><span className="menuText"> Audit</span></button>
                    <button type="button" className={sheetBtnClassName} onClick={(e) => onSetScreen(e, "sheet")} title="Download"><FontAwesomeIcon icon={faDownload} title="Download" className="svg"></FontAwesomeIcon><span className="menuText"> Download</span></button>
                    {!props.demoMode && !props.readOnly &&
                        <button type="button" className="btn btn-primary charMenuBtn" onClick={onSaveCharacter} title="Save"><FontAwesomeIcon icon={faSave} title="Save" className="svg"></FontAwesomeIcon > Save</button>
                    }
                    {props.demoMode && !props.readOnly &&
                        <Link className="btn btn-primary charMenuBtn" to="/signup"><FontAwesomeIcon icon={faSignInAlt} title="Download" className="svg"></FontAwesomeIcon> Sign Up</Link>
                    }
                </div>
                {/* {!props.demoMode &&
                <>
                    <div className="btn-group" role="group" aria-label="Select screen">
                        <button type="button" className="btn btn-primary btn-sm" onClick={onCancel} title="Cancel"><FontAwesomeIcon icon={faWindowClose} title="Cancel"></FontAwesomeIcon ></button>
                    </div>
                </>
            } */}
    
            </div>
        </div>
    )
}

export default CharacterDesignMenu;