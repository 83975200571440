import React from "react";

import StarshipDesignShare from "../components/starships/StarshipDesignShare";

const StarshipShare: React.FunctionComponent<{}> = (props: {}) => {

    return (
        <div className="pageBody">
            <StarshipDesignShare />
        </div>
    );
}

export default StarshipShare;
