import uniqid from "uniqid";
import { Ammo } from "../interfaces/Ammo";
import { AutoTargetingWeapon } from "../interfaces/AutoTargetingWeapons";
import { Cargo } from "../interfaces/Cargo";
import { ShipFittingData } from "../interfaces/ShipFitting";
import { ShipFittingOwned } from "../interfaces/ShipFittingOwned";
import { ShipHullData } from "../interfaces/ShipHull";
import { ShipModData } from "../interfaces/ShipMod";
import { StarshipTraits } from "../interfaces/StarshipTraits";
import { nameLength } from "../lookups/Enums";
import { Lookups } from "../lookups/Lookups";
import { formatDamage, formatLargeCredits } from "./Utilities";

const lookups = Lookups.getInstance();

const clipToLength = (txt: string, length: number): string => {
    return txt.substring(0, length);
}

export const onSetName = (name: string, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.name = clipToLength(name, nameLength);
    onSetStarshipTraits(updatedTraits);
}

export const onSetExampleShip = (name: string, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    const theShipTraits = lookups.shipExamples.find((s) => s.name === name);
    if (theShipTraits) {
        let updatedTraits = { ...theShipTraits };
        onSetStarshipTraits(updatedTraits);
    }
}

export const onSetIsPublic = (isPublic: boolean, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.isPublic = isPublic;
    onSetStarshipTraits(updatedTraits);
}

export const onSetHullType = (hullType: string, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.hullType = hullType;

    // set default crew based on hull type
    const theHull = lookups.shipHulls.find((f) => f.name === hullType);
    if (theHull) {
        updatedTraits.crewData.crewCurrent = theHull.defaultCrew;
        updatedTraits.crewData.crewDefaultSkill = theHull.defaultCrewSkill;
        updatedTraits.crewData.crewGunnerSkill = theHull.defaultGunnerSkill;
        updatedTraits.crewData.crewNPCCommandPoints = theHull.defaultNPCCommandPoints;

        // remove any items that the current hull cannot accomodate
        updatedTraits.fittings = updatedTraits.fittings.filter((f) => {
            const theFitting = lookups.shipFittings.find((fit) => fit.id === f.id);
            if (theFitting) {
                const isOk = theFitting.classes.indexOf(theHull.class) !== -1;
                if (!isOk) {
                    updatedTraits.ammo = starshipTraits.ammo.filter((a) => a.weaponUniqId !== f.uniqid);
                    updatedTraits.autoTargetingWeapons = starshipTraits.autoTargetingWeapons.filter((a) => a.weaponUniqId !== f.uniqid);
                }
                return isOk;
            } else {
                return false;
            }
        })
    }

    onSetStarshipTraits(updatedTraits);
}

export const onSetCrewDefaultSkill = (skill: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.crewData.crewDefaultSkill = skill;
    onSetStarshipTraits(updatedTraits);
}

export const onSetCrewGunnerSkill = (skill: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.crewData.crewGunnerSkill = skill;
    onSetStarshipTraits(updatedTraits);
}

export const onSetCrewNPCCommandPoints = (cp: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.crewData.crewNPCCommandPoints = cp;
    onSetStarshipTraits(updatedTraits);
}

export const onSetCrewCurrent = (crew: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.crewData.crewCurrent = crew;
    onSetStarshipTraits(updatedTraits);
}

export const onSetCrewAutomationSupportBots = (bots: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.crewData.crewAutomationSupportBots = bots;
    onSetStarshipTraits(updatedTraits);
}

export const onSetPassengersCurrent = (passengers: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.crewData.passengersCurrent = passengers;
    onSetStarshipTraits(updatedTraits);
}

export const onSetCurrentFuelLoads = (fuelLoads: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.currentFuelLoads = fuelLoads;
    onSetStarshipTraits(updatedTraits);
}

export const onAddCargo = (cargo: Cargo, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    let existingCargo = updatedTraits.cargo.find((c) => c.uniqid === cargo.uniqid);
    if (existingCargo) {
        existingCargo.name = cargo.name;
        existingCargo.tons = cargo.tons;
        existingCargo.pricePerTon = cargo.pricePerTon;
        existingCargo.desc = cargo.desc;
    } else {
        updatedTraits.cargo.push(cargo);
    }
    updatedTraits.cargo.sort((c1, c2) => c1.name < c2.name ? -1 : 1);
    onSetStarshipTraits(updatedTraits);
}

export const onDeleteCargo = (uniqid: string, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.cargo = updatedTraits.cargo.filter((c) => c.uniqid !== uniqid);
    onSetStarshipTraits(updatedTraits);
}

export const onSetAmmunition = (weaponUniqid: string, ammuniton: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };

    const ammo = [...updatedTraits.ammo];

    const thisAmmo = ammo.find((a) => a.weaponUniqId === weaponUniqid);
    if (thisAmmo) {
        thisAmmo.extraFreeMass = ammuniton;
        updatedTraits.ammo = ammo;

        const maxAmmo = getMaximumAmmunitionForWeapon(updatedTraits, weaponUniqid);
        thisAmmo.roundsRemaining = maxAmmo;

        updatedTraits.ammo = ammo;
    }

    onSetStarshipTraits(updatedTraits);
}

export const onSetAmmunitionRemaining = (weaponUniqid: string, ammunitonRemaining: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };

    const ammo = [...updatedTraits.ammo];

    const thisAmmo = ammo.find((a) => a.weaponUniqId === weaponUniqid);
    if (thisAmmo) { thisAmmo.roundsRemaining = ammunitonRemaining; }

    updatedTraits.ammo = ammo;

    onSetStarshipTraits(updatedTraits);
}

export const onSetFoxerDronesExtraMass = (weaponUniqid: string, extraAmmoMass: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };

    const foxerDrones = [...updatedTraits.foxerDrones];

    const thisFoxerDrone = foxerDrones.find((a) => a.weaponUniqId === weaponUniqid);
    if (thisFoxerDrone) {
        thisFoxerDrone.extraFreeMass = extraAmmoMass;
        updatedTraits.foxerDrones = foxerDrones;

        const maxDrones = (extraAmmoMass + 1) * 5;
        thisFoxerDrone.roundsRemaining = maxDrones;

        updatedTraits.foxerDrones = foxerDrones;
    }

    onSetStarshipTraits(updatedTraits);
}

export const onSetFoxerDronesRemaining = (weaponUniqid: string, roundsRemaining: number, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };

    const foxerDrones = [...updatedTraits.foxerDrones];

    const thisFoxerDrone = foxerDrones.find((a) => a.weaponUniqId === weaponUniqid);
    if (thisFoxerDrone) { thisFoxerDrone.roundsRemaining = roundsRemaining; }

    updatedTraits.foxerDrones = foxerDrones;

    onSetStarshipTraits(updatedTraits);
}

const fittingsSort = (f1: ShipFittingOwned, f2: ShipFittingOwned) => {
    if (f1.name > f2.name) {
        return 1;
    } else if (f1.name < f2.name) {
        return -1;
    } else {
        if (f1.uniqid > f2.uniqid) {
            return 1
        } else if (f1.uniqid < f2.uniqid) {
            return -1;
        } else {
            return 0;
        }
    }
}

export const onBuyOrSellFitting = (id: string, isBuy: boolean, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {

    let updatedTraits = { ...starshipTraits };

    const theFitting = lookups.shipFittings.find((f) => f.id === id);
    if (theFitting) {

        let fittings = updatedTraits.fittings;

        if (isBuy) {

            const thisFittings = starshipTraits.fittings.filter((f) => f.id === id);
            const index = thisFittings.length + 1;

            let name = theFitting.name;
            if (theFitting.nameAfterPurchase) {
                name = theFitting.nameAfterPurchase;
            }

            let namePlural = theFitting.name;
            if (theFitting.namePlural) {
                namePlural = theFitting.namePlural;
                if (theFitting.nameAfterPurchasePlural) {
                    namePlural = theFitting.nameAfterPurchasePlural;
                }
            }

            // if is a drive upgrade, remove any other drive upgrades
            const driveUpgrades = ["SF14", "SF15", "SF16", "SF17", "SF18"];
            if (driveUpgrades.indexOf(id) !== -1) {
                // remove all the drive options
                driveUpgrades.forEach((du) => {
                    fittings = fittings.filter((f) => {
                        return f.id !== du;
                    })
                });
            }

            const uniqId = uniqid();
            const newFitting: ShipFittingOwned = { id, uniqid: uniqId, name, namePlural, type: theFitting.type, index }
            fittings.push(newFitting);

            // if is a weapon, add the ammo record (if uses ammo) and set it to maximum ammo
            if (theFitting.type === "weapons" && theFitting.weaponUsesAmmo) {
                const thisWeaponAmmo = updatedTraits.ammo.find((a) => a.weaponUniqId === id);
                if (!thisWeaponAmmo) {
                    const maxAmmo = getMaximumAmmunitionForWeapon(starshipTraits, uniqId);
                    const newAmmo: Ammo = { weaponUniqId: uniqId, name: theFitting.name, extraFreeMass: 0, roundsRemaining: maxAmmo };
                    starshipTraits.ammo.push(newAmmo);
                    starshipTraits.ammo = starshipTraits.ammo.sort((a1, a2) => a1.name < a2.name ? -1 : 1);
                }
            }

            // if is a Foxer Drones fitting, add the ammo record and set it to maximum ammo
            if (theFitting.id === "SD5") {
                const thisFoxerDrone = updatedTraits.foxerDrones.find((a) => a.weaponUniqId === id);
                if (!thisFoxerDrone) {
                    const maxDrones = 5;
                    const newDrones: Ammo = { weaponUniqId: uniqId, name: theFitting.name, extraFreeMass: 0, roundsRemaining: maxDrones };
                    starshipTraits.foxerDrones.push(newDrones);
                    starshipTraits.foxerDrones = starshipTraits.foxerDrones.sort((a1, a2) => a1.name < a2.name ? -1 : 1);
                }
            }

            fittings.sort(fittingsSort);
            updatedTraits.fittings = fittings;
            onSetStarshipTraits(updatedTraits);

        } else {
            let theFittings = updatedTraits.fittings.filter((f) => f.id === id);
            if (theFittings.length > 0) {
                const lastFitting = theFittings[theFittings.length - 1];
                updatedTraits.fittings = updatedTraits.fittings.filter((f) => f.uniqid !== lastFitting.uniqid);
                // if removing an Auto-targeting System or a Weapon, remove any matching auto-weapon link. 
                updatedTraits.autoTargetingWeapons = updatedTraits.autoTargetingWeapons.filter((atw) => atw.autoTargetingUniqId !== lastFitting.uniqid);
                updatedTraits.autoTargetingWeapons = updatedTraits.autoTargetingWeapons.filter((atw) => atw.weaponUniqId !== lastFitting.uniqid);
                // if removed last of this weapon type, also remove ammo
                const theWeapon = updatedTraits.fittings.find((f) => f.id === id);
                if (!theWeapon) {
                    updatedTraits.ammo = updatedTraits.ammo.filter((a) => a.weaponUniqId !== lastFitting.uniqid);
                    starshipTraits.ammo = starshipTraits.ammo.sort((a1, a2) => a1.name < a2.name ? -1 : 1);
                    updatedTraits.foxerDrones = updatedTraits.foxerDrones.filter((a) => a.weaponUniqId !== lastFitting.uniqid);
                    starshipTraits.foxerDrones = starshipTraits.foxerDrones.sort((a1, a2) => a1.name < a2.name ? -1 : 1);
                }

                updatedTraits.fittings = updatedTraits.fittings.sort(fittingsSort);
                onSetStarshipTraits(updatedTraits);
            }
        }
    }
}



export const onBuyOrSellMod = (id: string, isBuy: boolean, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };

    const theMod = lookups.shipMods.find((m) => m.id === id);
    if (theMod) {

        let mods = updatedTraits.mods;

        if (isBuy) {
            const thisMods = starshipTraits.mods.filter((m) => m.id === id);
            const index = thisMods.length + 1;

            const newMod: ShipFittingOwned = { id, uniqid: uniqid(), name: theMod.name, namePlural: theMod.name, type: "mod", index }
            mods.push(newMod);
        } else {
            let theMods = updatedTraits.mods.filter((m) => m.id === id);
            if (theMods.length > 0) {
                updatedTraits.mods = updatedTraits.mods.filter((m) => m.uniqid !== theMods[theMods.length - 1].uniqid);
            }
        }
    }

    updatedTraits.mods = updatedTraits.mods.sort(fittingsSort);

    onSetStarshipTraits(updatedTraits);
}

export const onSelectAutoWeapon = (autoUniqId: string, weaponUniqId: string, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };

    const autoWeapon = updatedTraits.autoTargetingWeapons.find((atw) => atw.autoTargetingUniqId === autoUniqId);
    if (autoWeapon) {
        autoWeapon.weaponUniqId = weaponUniqId;
    } else {
        const newAutoWeapon: AutoTargetingWeapon = { autoTargetingUniqId: autoUniqId, weaponUniqId };
        updatedTraits.autoTargetingWeapons.push(newAutoWeapon);
    }

    updatedTraits.autoTargetingWeapons = updatedTraits.autoTargetingWeapons.sort((a1, a2) => a1.autoTargetingUniqId < a2.autoTargetingUniqId ? -1 : 1);

    onSetStarshipTraits(updatedTraits);
}

export const onSetNotes = (notes: string, starshipTraits: StarshipTraits, onSetStarshipTraits: (updatedTraits: StarshipTraits) => void) => {
    let updatedTraits = { ...starshipTraits };
    updatedTraits.notes = notes;
    onSetStarshipTraits(updatedTraits);
}

export const checkAndValidateStarshipTraits = (shipTraits: StarshipTraits): StarshipTraits => {
    // charTraits = ensureDefaultBackgroundSkillLevelIsAlwaysSelected(charTraits);

    return shipTraits;
}

export const checkFittingIsAllowed = (type: string, fitting: ShipFittingData, hull: ShipHullData, includeNonStationFittings: boolean) => {
    const isCorrectFittingForHullSize = fitting.classes.includes(hull.class);
    const isAllowedForSpaceStation = !hull.isSpaceStation || (hull.isSpaceStation && (!fitting.notForStation || includeNonStationFittings));
    return fitting.type === type && isCorrectFittingForHullSize && isAllowedForSpaceStation;
}

export const getRawCost = (fitting: ShipFittingData, hullName: string, lookups: Lookups) => {

    if (fitting.costIsSpecial) { return -1; }

    const theHull = lookups.shipHulls.find((sh: ShipHullData) => sh.name === hullName);
    if (theHull) {
        let cost = fitting.cost;
        if (fitting.costHasSizeMod) {

            let hullClass = theHull.class;
            if (fitting.fixedClass) { hullClass = fitting.fixedClass; }

            if (hullClass === "Frigate") { cost = cost * 10; }
            if (hullClass === "Cruiser") { cost = cost * 25; }
            if (hullClass === "Capital") { cost = cost * 100; }
        }

        return cost;
    }
    return -1;
}

export const getCost = (fitting: ShipFittingData, hullType: string, lookups: Lookups) => {

    if (fitting.costIsSpecial) { return "Special"; }
    if (fitting.baseHullCostReduction) { return "-" + fitting.baseHullCostReduction * 100 + "% hull cost"; }

    const theHull = lookups.shipHulls.find((sh: ShipHullData) => sh.name === hullType);
    if (theHull) {
        let cost = fitting.cost;
        if (fitting.costHasSizeMod) {

            let hullClass = theHull.class;
            if (fitting.fixedClass) { hullClass = fitting.fixedClass; }

            if (hullClass === "Frigate") { cost = cost * 10; }
            if (hullClass === "Cruiser") { cost = cost * 25; }
            if (hullClass === "Capital") { cost = cost * 100; }
        }

        let output = formatLargeCredits(cost);

        if (fitting.weaponUsesAmmo && fitting.weaponCostPerAmmo) {
            output = output + "/" + formatLargeCredits(fitting.weaponCostPerAmmo);
        }

        return output;
    }
    return null;
}

export const getRawModCost = (mod: ShipModData, hullType: string, lookups: Lookups) => {

    const theHull = lookups.shipHulls.find((sh: ShipHullData) => sh.name === hullType);
    if (theHull) {
        const baseHullPrice = theHull.cost;
        const costFractionOfHullPrice = mod.costFractionOfHullPrice;
        const creditCost = baseHullPrice * costFractionOfHullPrice;
        return creditCost;
    }
    return -1;
}

export const getModCost = (mod: ShipModData, hullType: string, lookups: Lookups) => {

    const theHull = lookups.shipHulls.find((sh: ShipHullData) => sh.name === hullType);
    if (theHull) {
        const baseHullPrice = theHull.cost;
        const costFractionOfHullPrice = mod.costFractionOfHullPrice;
        const creditCost = baseHullPrice * costFractionOfHullPrice;

        let componentCost = mod.costComponentsPerHullClass;
        let componentsPerHullClass = mod.costComponentsPerHullClass;
        let hullClass = theHull.class;
        if (hullClass === "Fighter") { componentCost = componentsPerHullClass * 1; }
        if (hullClass === "Frigate") { componentCost = componentsPerHullClass * 2; }
        if (hullClass === "Cruiser") { componentCost = componentsPerHullClass * 3; }
        if (hullClass === "Capital") { componentCost = componentsPerHullClass * 4; }

        let output: string[] = [];
        output.push(formatLargeCredits(creditCost));

        if (componentCost !== 0) {
            const plural = componentCost === 1 ? "" : "s";
            output.push(componentCost + " comp" + plural);
        }

        if (mod.costMass) {
            output.push(mod.costMass + " Mass");
        }

        if (mod.costPower) {
            output.push(mod.costPower + " Pow");
        }

        return output.join(" + ");
    }
    return null;
}

export const getRawMass = (fitting: ShipFittingData, hullName: string, lookups: Lookups) => {

    const theHull = lookups.shipHulls.find((sh: ShipHullData) => sh.name === hullName);
    if (theHull) {
        let mass = fitting.mass;
        if (fitting.massHasSizeMod) {

            let hullClass = theHull.class;
            if (fitting.fixedClass) { hullClass = fitting.fixedClass; }

            if (hullClass === "Frigate") { mass = mass * 2; }
            if (hullClass === "Cruiser") { mass = mass * 3; }
            if (hullClass === "Capital") { mass = mass * 4; }
        }
        // TO DO need to handle this...
        // if (fitting.addsMass) {
        //     return "+" + mass;
        // }
        return mass;
    }
    return -1;
}

export const getMass = (fitting: ShipFittingData, hullType: string, lookups: Lookups) => {

    const theHull = lookups.shipHulls.find((sh: ShipHullData) => sh.name === hullType);
    if (theHull) {
        let mass = fitting.mass;
        if (fitting.massHasSizeMod) {

            let hullClass = theHull.class;
            if (fitting.fixedClass) { hullClass = fitting.fixedClass; }

            if (hullClass === "Frigate") { mass = mass * 2; }
            if (hullClass === "Cruiser") { mass = mass * 3; }
            if (hullClass === "Capital") { mass = mass * 4; }
        }
        if (fitting.addsMass) {
            return "+" + mass;
        }
        return mass;
    }
    return null;
}

export const getRawPower = (fitting: ShipFittingData, hullName: string, lookups: Lookups) => {

    const theHull = lookups.shipHulls.find((sh: ShipHullData) => sh.name === hullName);
    if (theHull) {
        let power = fitting.power;
        if (fitting.powerHasSizeMod) {

            let hullClass = theHull.class;
            if (fitting.fixedClass) { hullClass = fitting.fixedClass; }

            if (hullClass === "Frigate") { power = power * 2; }
            if (hullClass === "Cruiser") { power = power * 3; }
            if (hullClass === "Capital") { power = power * 4; }
        }
        // TO DO.. need tohandle this...
        // if (fitting.addsPower) {
        //     return "+" + power;
        // }
        return power;
    }
    return -1;
}

export const getPower = (fitting: ShipFittingData, hullType: string, lookups: Lookups) => {

    const theHull = lookups.shipHulls.find((sh) => sh.name === hullType);
    if (theHull) {
        let power = fitting.power;
        if (fitting.powerHasSizeMod) {

            let hullClass = theHull.class;
            if (fitting.fixedClass) { hullClass = fitting.fixedClass; }

            if (hullClass === "Frigate") { power = power * 2; }
            if (hullClass === "Cruiser") { power = power * 3; }
            if (hullClass === "Capital") { power = power * 4; }
        }
        if (fitting.addsPower) {
            return "+" + power;
        }
        return power;
    }
    return null;
}

export const getDamage = (fitting: ShipFittingData, lookups: Lookups): any => {
    if (fitting.weaponDamage) {
        return formatDamage(fitting.weaponDamage, false, false);
    }
    return null;
}

export const convertHullClassToNUmber = (hullClass: string) => {
    switch (hullClass) {
        case "Fighter": return 1;
        case "Frigate": return 2;
        case "Cruiser": return 3;
        case "Capital": return 4;
    }
    return 0;
}

export const getMaximumAmmunitionForWeapon = (starshipTraits: StarshipTraits, weaponUniqId: string) => {
    const theWeaponInstance = starshipTraits.fittings.find((fi) => fi.uniqid === weaponUniqId);
    if (theWeaponInstance) {
        const weaponId = theWeaponInstance.id;
        const theFitting = lookups.shipFittings.find((sf) => sf.id === weaponId);
        if (theFitting) {
            let ammoPerMass = 0;

            let hullClass = "";
            const theHull = lookups.shipHulls.find((sh) => sh.name === starshipTraits.hullType);
            if (theHull) { hullClass = theHull.class; }

            if (theFitting.effects) {
                const ammoEffects = theFitting.effects.find((e) => e.indexOf("AMMO") !== -1);
                if (ammoEffects) {
                    const ammo = ammoEffects.split(":");
                    ammoPerMass = parseInt(ammo[1]);

                    const shipHullClassNumber = convertHullClassToNUmber(hullClass);

                    const minHullClassForWeapon = theFitting.classes[0];
                    const minHullClassNumber = convertHullClassToNUmber(minHullClassForWeapon);

                    if (shipHullClassNumber <= minHullClassNumber) {
                        ammoPerMass = ammoPerMass * 1; // no adjustment to amount of ammo
                    } else {
                        for (let x = minHullClassNumber; x < shipHullClassNumber; x++) {
                            ammoPerMass = ammoPerMass * 2;
                        }
                    }

                    // get how much extra extra mass of ammo for weapon has been bought:
                    let totalExtraRounds = 0;
                    let extraAmmoMass = 0;

                    const theAmmo = starshipTraits.ammo.find((a) => a.weaponUniqId === weaponUniqId);
                    if (theAmmo) {
                        extraAmmoMass = theAmmo.extraFreeMass;
                        totalExtraRounds = ammoPerMass + (extraAmmoMass * ammoPerMass);
                        return totalExtraRounds;
                    } else {
                        return ammoPerMass;
                    }
                }
            }

        }
        return 0;
    }
    return 0;
}

export const handleIndefiniteLifeSupport = (months: number) => {
    let output = "";
    if (months >= 1000) {
        output = "Indefinite";
    } else {
        output = months.toString() + " months";
    }
    return output;
}

export const handleSpeed = (speed: number) => {
    let output = "";
    if (speed <= -100) {
        output = "None";
    } else {
        output = speed.toString();
    }
    return output;
}