import React from "react";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterTraits } from "../../interfaces/CharacterTraits";

import ValidationAlert from "../ValidationAlert";

import { getAttributeModifierPlusMinus } from "../../utilities/Utilities";


export interface IProps {
    attributeLongNames: string[];
    defaultAttributeArray: number[];
    charTraits: CharacterTraits;
    onSelectAttribute: (attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean) => void;
}

const AttributesAssign: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const getUnselectedAttributeArray = () => {
        let unselectedAttributes = [...props.defaultAttributeArray];
        char.attributeTraits.attributeScores.forEach((as) => {
            if (as !== 0) {
                unselectedAttributes = unselectedAttributes.filter((a) => a !== as);
            }
        });
        return unselectedAttributes;
    }

    const unselectedAttributeArray = getUnselectedAttributeArray();

    const sortNums = (a: any, b: any): number => {
        if (a === 0) { return -1; }
        return b - a;
    }

    const onSelectAttribute = (e: any): void => {
        const attributeNumber = parseInt(e.target[e.target.selectedIndex].getAttribute("data-number"));
        const attributeValue = parseInt(e.target.value);
        props.onSelectAttribute(attributeNumber, attributeValue, false);
    }

    const getAttributeDropdownOptions = (attributeNumber: number) => {
        const zeroToDash = (attribute: number): string => attribute === 0 ? "-" : attribute.toString();

        let options: any[] = [...unselectedAttributeArray];
        if (options.indexOf(char.attributeTraits.attributeScores[attributeNumber]) === -1) { options.push(char.attributeTraits.attributeScores[attributeNumber]); }
        if (options.indexOf(0) === -1) { options.push(0); }

        const optionElements = options.sort(sortNums).map((o) => <option key={attributeNumber + "_" + o} value={o} data-number={attributeNumber}>{zeroToDash(o)}</option>);
        return optionElements;
    }

    const getAttributeDropdowns = () => {
        const attributeDropdowns: any[] = [];
        let attributeNumber = 0;
        char.attributeTraits.attributeScores.forEach((a) => {
            attributeDropdowns.push(
                <tr key={"attddl" + attributeNumber}>
                    <td>{props.attributeLongNames[attributeNumber]}</td>
                    <td>
                        <select onChange={onSelectAttribute} value={char.attributeTraits.attributeScores[attributeNumber]}>
                            {getAttributeDropdownOptions(attributeNumber)}
                        </select>
                    </td>
                    <td>{getAttributeModifierPlusMinus(props.attributeLongNames[attributeNumber], char.attributeTraits.attributeScores[attributeNumber], null)}</td>
                </tr>
            )
            attributeNumber += 1;
        })
        return attributeDropdowns;
    }

    let attributeDropdowns = getAttributeDropdowns();

    return (
        <div>

            {unselectedAttributeArray.length > 0 &&
                <p>
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    Assign the following scores to your attributes: {unselectedAttributeArray.sort(sortNums).join(", ")}
                </p>
            }

            {unselectedAttributeArray.length === 0 &&
                <p>
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    Standard array of attributes scores were assigned.
                </p>
            }

            <table className="mb-0 attributeTable">
                <thead>
                    <tr>
                        <th>Attribute</th>
                        <th>Score</th>
                        <th>Modifier</th>
                    </tr>
                </thead>
                <tbody>
                    {attributeDropdowns}
                </tbody>
            </table>
            {char.showValidation && char.attributeTraits.validationCodes.indexOf("notAllAttributes") !== -1 &&
                <ValidationAlert msg="All attributes must be set" />
            }

        </div>
    )

}

export default AttributesAssign;