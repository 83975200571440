import React from "react";

import { faCaretRight, faChevronCircleUp, faChevronCircleDown, faDice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import fakerator from "fakerator";

import { CharacterTraits } from "../interfaces/CharacterTraits";

import ValidationAlert from "./ValidationAlert";
import { nameLength } from "../lookups/Enums";
import { getCharacterDesc } from "../utilities/Utilities";

interface IProps {
    charTraits: CharacterTraits;
    onSetName: (name: string) => void;
    onSetGoal: (goal: string) => void;
    onSetIsPublic: (isPublic: boolean) => void;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep1Name: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "characterName";

    const switchDisplay = () => {
        props.onSwitchCollapsed(sectionName);
    }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }

    const onChangeName = (e: any): void => {
        const name = e.currentTarget.value;
        props.onSetName(name);
    }

    const onChangeGoal = (e: any): void => {
        const goal = e.currentTarget.value;
        props.onSetGoal(goal);
    }

    const generateRandomName = () => {
        const fake = fakerator();
        const randomName = fake.names.name();
        props.onSetName(randomName);
    }

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >{getCharacterDesc(props.charTraits)} {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                <div className="input-group form-row  mt-2 mb-1">
                    <label htmlFor="name" className="col-12 col-sm-3 col-md-2 col-form-label">
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                        Name
                    </label>
                    <div className="col-12 col-sm-9 col-md-10 input-group">
                        <input type="text" className="form-control" id="name" value={char.basicTraits.name} onChange={onChangeName} maxLength={nameLength} />
                        <button className="btn btn-primary"><FontAwesomeIcon icon={faDice} title="Random Name" onClick={(e) => generateRandomName()}></FontAwesomeIcon ></button>
                    </div>
                </div>

                <div className="form-group form-row mb-0">
                    <label htmlFor="name" className="col-12 col-sm-3 col-md-2 col-form-label">
                        <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                        Goal
                    </label>
                    <div className="col-12 col-sm-9 col-md-10">
                        <input type="text" className="form-control" id="name" value={char.basicTraits.goal} onChange={onChangeGoal} />
                    </div>
                </div>
                {char.showValidation && char.basicTraits.validationCodes.indexOf("noName") > -1 &&
                    <ValidationAlert msg="Name is required" />
                }

            </div>

        </div >
    );

}

export default CharacterDesignStep1Name;