import React, { useState } from "react";

import { attributeAbr, attributeLongNames } from "../../lookups/Enums";

import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CharacterTraits } from "../../interfaces/CharacterTraits";

import { getAttributeModifierPlusMinus } from "../../utilities/Utilities";

import { CharacterDerivedStats, CreationStep } from "../../classes/CharacterDerivedStats";
import uniqid from "uniqid";
import { AttributeLevel } from "../../interfaces/AttributeLevel";
import { AttributeModifierPick } from "../../interfaces/AttributeModifierPick";

export interface IProps {
    charTraits: CharacterTraits;
    attributeIndexesToChooseFrom: number[];
    attributeModifiersAlreadyPicked: AttributeModifierPick[];
    traitToUpdate: string;
    onSelectAttributeModifierBonusPick: (attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => void;
}

const AttributeModifierPickControl: React.FunctionComponent<IProps> = (props: IProps) => {

    const closedClass = "collapse mb-2 alert alert-info fade small";

    const [attrDescClassName, setAttrDescClassName] = useState(closedClass);
    const [selectedAttributeIndex, setSelectedAttributeIndex] = useState(-1);

    const id = uniqid();

    const showOrHideAttributeDesc = (attributeIndex: number, thisAttribute: AttributeLevel) => {
        setSelectedAttributeIndex(attributeIndex);

        const openClass = closedClass + " show";
        if (attrDescClassName === closedClass) {
            if (attrDescClassName === closedClass) {
                setAttrDescClassName(openClass);
            } else {
                setAttrDescClassName(closedClass);
            }
        } else {
            if (attributeIndex === selectedAttributeIndex) {
                setAttrDescClassName(closedClass);
            }
        }

    };

    const pickAttributeLevel = (e: any) => {
        const attributeIndex = parseInt(e.target.getAttribute("data-attributeindex"));
        const attributeModifierBonus = 1;
        const selected = e.target.checked;

        props.onSelectAttributeModifierBonusPick(attributeIndex, attributeModifierBonus, selected, props.traitToUpdate);
    }

    const getAttributeControls = (attributeIndexes: number[], bonusSize: number, attributeLevels: AttributeLevel[]) => {
        const controls: any[] = [];

        const divClassName = "col-lg-3 col-md-4 col-sm-6 col-6 text-nowrap align-items-center pl-0 pr-1";

        attributeIndexes.forEach((ai) => {

            let checkedLevel1 = false;
            const amp = props.attributeModifiersAlreadyPicked.find((amp) => amp.attributeIndex === ai);
            if (amp) {
                if(amp.modifierAdjustment > 0) { checkedLevel1 = true; }
            }

            let btnClassName = "btn btn-tiny inline-block btn-skill ";
            if (checkedLevel1) {
                btnClassName += "btn-secondary"
            }
            else {
                btnClassName += "btn-outline-secondary";
            }

            const score = attributeLevels[ai].level;
            let mod = "?";
            if (score) {
                mod = getAttributeModifierPlusMinus(attributeLevels[ai].attributeName, score, charDerivedStatsAtBackgroundRoll);
            }

            let level1disabled = false;

            controls.push(
                <div className={divClassName} key={"att_" + ai}>
                    <button className={btnClassName} onClick={(e) => showOrHideAttributeDesc(ai, attributeLevels[ai])}>
                        {attributeAbr[ai]} {score} ({mod}) <FontAwesomeIcon className="text-info" icon={faEye} title="Show Attribute details"></FontAwesomeIcon >
                    </button>
                    <input type="checkbox" className="mr-1 lgCheckbox inline-block" data-attributeindex={ai} data-attributebonus={1} onChange={pickAttributeLevel} disabled={level1disabled} checked={checkedLevel1} />
                </div>
            )
        })

        return controls;
    }

    const charDerivedStatsAtBackgroundRoll = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtBackgroundRoll.calculateAttributeLevels(CreationStep.Background, 1);
    charDerivedStatsAtBackgroundRoll.calculateFocusLevels(CreationStep.AllSteps); 
    
    const attributeControls = getAttributeControls(props.attributeIndexesToChooseFrom, 1, charDerivedStatsAtBackgroundRoll.attributeLevels);

    let attrHistory = "";
    let level = 0;
    let mod = "";
    const thisAttributeDerived = charDerivedStatsAtBackgroundRoll.attributeLevels[selectedAttributeIndex];
    if (thisAttributeDerived) {
        attrHistory = thisAttributeDerived.history.join("; ");
        level = thisAttributeDerived.level !== null ? thisAttributeDerived.level : 0;
        mod = getAttributeModifierPlusMinus(thisAttributeDerived.attributeName, level, charDerivedStatsAtBackgroundRoll);
    }

    return (
        <>
            <div className="mb-1 d-flex flex-wrap mt-1">
                {attributeControls}
            </div>
            <div className={attrDescClassName} id={"collapseAttrDesc" + id}>
                <div><b>{attributeLongNames[selectedAttributeIndex]} {level} ({mod})</b>  </div>
                {attrHistory !== "" &&
                    <div className="mt-1"><i>History:</i> {attrHistory}</div>
                }
            </div>
        </>
    )

}

export default AttributeModifierPickControl;