import React from 'react';
import { CharacterDerivedStats, CreationStep } from '../../classes/CharacterDerivedStats';
import { CharacterTraits } from '../../interfaces/CharacterTraits';
import { Lookups } from '../../lookups/Lookups';
import { getSkillsByNotType, getSkillsByType } from '../../utilities/SkillUtilities';
import { convertPointTypeToNameCapitalised } from '../../utilities/Utilities';
import ValidationAlert from '../ValidationAlert';
import SelectPsychicTechniqueWhenSkillLevelIncreases from './SelectPsychicTechniqueWhenSkillLevelIncreases';

export interface IProps {
    charTraits: CharacterTraits;
    level: number;
    index: number;
    onSelectSkillToImprove: (index: number, skillName: string) => void;
    onSelectPointTypeToSpend: (index: number, pointType: string) => void;
    onSelectPointsSpent: (index: number, pointsSpent: number) => void;
    onSetPsychicTechniqueWhenLevelUp: (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => void;
}

const SpendSkillPointsToImproveSkill: React.FunctionComponent<IProps> = (props: IProps) => {

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateIsPsychic();
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, props.level, props.index);
    charDerivedStats.calculateSkillPointsLeftSoFar();
    charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, -1, -1, props.level, props.index);


    const charDerivedStatsAtLevel1 = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtLevel1.calculateSkillLevels(CreationStep.AllSteps);

    const lookups = Lookups.getInstance();
    const nonPsychicSkills = getSkillsByNotType("Psychic", lookups.skills);
    const psychicSkills = getSkillsByType("Psychic", lookups.skills);
    const combatSkills = getSkillsByType("Combat", lookups.skills);

    let thisLevel = props.charTraits.levels.find((l) => l.level === props.level);

    const formatSkillLevel = (skillLevel: any) => {
        if (skillLevel) {
            return "-" + (parseInt(skillLevel) - 1);
        } else {
            return "";
        }
    }

    const onSelectSkillToImprove = (e: any) => {
        props.onSelectSkillToImprove(props.index, e.target.value);
    }

    const onSelectPointTypeToSpend = (e: any) => {
        props.onSelectPointTypeToSpend(props.index, e.target.value);
    }

    const onSelectPointsSpent = (e: any) => {
        props.onSelectPointsSpent(props.index, parseInt(e.target.value));
    }

    const getPointsLeftAtThisLevelAndIndex = (): any => {

        let pointsAvailable: any = {};

        for (const pointsType in charDerivedStats.skillPointsAvailableSoFar) {
            const pointsSpentSoFar = charDerivedStats.skillPointsSpentSoFar[pointsType] ? charDerivedStats.skillPointsSpentSoFar[pointsType] : 0;
            pointsAvailable[pointsType] = charDerivedStats.skillPointsAvailableSoFar[pointsType] - pointsSpentSoFar;
        }

        return pointsAvailable;
    }

    const getSkillDropdown = () => {
        let options: any = [];

        let selectedSkill = "";
        if (thisLevel) {
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;
        }

        let restrictedPsychicSkill = "";
        if (charDerivedStats.isRestrictedPsychic) {
            let theClass = props.charTraits.levelOne.classes.find((cl) => cl.className === "Partial Psychic");
            if (theClass) {
                let classSkillPick = theClass.classSkillPicks[0];
                if (classSkillPick) {
                    restrictedPsychicSkill = classSkillPick.skill;
                }
            }
        }

        const defaultOption = <option value="">--Skill--</option>;
        charDerivedStats.skillLevels.forEach((sl, index) => {

            if (!charDerivedStats.isPsychic) {
                // Only show non-psychic skills
                if (nonPsychicSkills.find((nps) => nps.skill === sl.skill)) {
                    options.push(<option key={index} value={sl.skill} onChange={onSelectSkillToImprove}>{sl.skill}{formatSkillLevel(sl.level)}</option>);
                }
            } else if (charDerivedStats.isRestrictedPsychic) {
                // Restricted psychic: only show non-psychic skills except for the character's single psychic skill
                if (nonPsychicSkills.find((nps) => nps.skill === sl.skill) || sl.skill === restrictedPsychicSkill) {
                    options.push(<option key={index} value={sl.skill} onChange={onSelectSkillToImprove}>{sl.skill}{formatSkillLevel(sl.level)}</option>);
                }
            } else {
                // Unrestricted psychic: Show all skills, psychic and non-psychic
                options.push(<option key={index} value={sl.skill} onChange={onSelectSkillToImprove}>{sl.skill}{formatSkillLevel(sl.level)}</option>);
            }

        })
        return <select value={selectedSkill} onChange={onSelectSkillToImprove} className="mr-1">{defaultOption}{options}</select>
    }

    const getPointsTypeDropdown = () => {

        let selectedSkill = "";
        let selectedPointType = "";
        let selectedPointsSpent = 0;
        if (thisLevel) {
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;
            selectedPointType = thisLevel.skillPointSpends[props.index].pointType;
            selectedPointsSpent = thisLevel.skillPointSpends[props.index].pointsSpent;
        }
        if (selectedSkill === "") { return null; }

        let output: any[] = [];

        const anyPointsAvailable = true;
        const defaultOption = anyPointsAvailable ? <option key={"defaultOption"} value="">--Points Type--</option> : <option value="">--No Suitable Points--</option>;

        for (const skillName in charDerivedStats.skillPointsAvailableSoFar) {

            const pointsLeft = !charDerivedStats.skillPointsLeftSoFar[skillName] ? 0 : charDerivedStats.skillPointsLeftSoFar[skillName];
             // Can this type of skill points be spent on this skill?

            const isCombatSkill = combatSkills.find((s) => s.skill === selectedSkill) ? true : false;
            const isPsychicSkill = psychicSkills.find((s) => s.skill === selectedSkill) ? true : false;

            let rightSortOfSkillPoint = false;
            if (skillName === "general") {
                rightSortOfSkillPoint = true;
            } else if (skillName === "nonCombatNonPsychic") {
                if (!isCombatSkill && !isPsychicSkill) {
                    rightSortOfSkillPoint = true;
                }
            } else if (skillName === "psychic") {
                if (isPsychicSkill) {
                    rightSortOfSkillPoint = true;
                }
            } else {
                if (skillName === selectedSkill + "_skill") {
                    rightSortOfSkillPoint = true;
                }
            }

            const spendPointsOnThisSkillTypeInThisSkillPick = selectedPointType === skillName && selectedPointsSpent > 0;

            // Add option to dropdown.
            if (rightSortOfSkillPoint && (spendPointsOnThisSkillTypeInThisSkillPick || pointsLeft)) {
                if (skillName.indexOf("_skill") !== -1) {
                    if (skillName === selectedSkill + "_skill") {
                        output.push(<option key={skillName} value={skillName}>{convertPointTypeToNameCapitalised(skillName, true)}</option>)
                    }
                } else {
                    output.push(<option key={skillName} value={skillName}>{convertPointTypeToNameCapitalised(skillName, true)}</option>)
                }

            }
        }

        return <select value={selectedPointType} onChange={onSelectPointTypeToSpend} className="mr-1">{defaultOption}{output}</select>;
    }

    const getPointsSpentDropdown = () => {

        let selectedPointType = "";
        let selectedPointsSpent = 0;
        if (thisLevel) {
            selectedPointType = thisLevel.skillPointSpends[props.index].pointType;
            selectedPointsSpent = thisLevel.skillPointSpends[props.index].pointsSpent;
        }

        let maxPointsAvailable = 0;
        const pointsLeft = getPointsLeftAtThisLevelAndIndex();

        maxPointsAvailable = pointsLeft[selectedPointType] + selectedPointsSpent;

        const getDefaultOption = (maxNum: number) => {
            if (maxNum <= 0) {
                return <option value="0">--No Points Left--</option>
            } else {
                return <option value="0">--Points--</option>
            };
        }

        const getOptions = (maxNum: number) => {
            if (maxNum === 0) { return null; }

            const pluralise = (points: number) => { return points > 1 ? "s" : "" };

            const output: any[] = [];
            for (let i = 0; i <= maxNum - 1; i++) {
                output.push(<option key={i} value={i + 1}>{i + 1} point{pluralise(i + 1)}</option>)
            }
            return output;
        }

        if (selectedPointType !== "") {
            return (
                <select value={selectedPointsSpent} onChange={onSelectPointsSpent}>
                    {getDefaultOption(maxPointsAvailable)}
                    {getOptions(maxPointsAvailable)}
                </select>
            )
        }
        return null;
    }

    const getPointsSpentOnSkill = (selectedSkill: string) => {
        let pointsSpentOnTheSkill = 0;
        props.charTraits.levels.forEach((lev) => {
            if (lev.level <= props.level) {

                // Get skill points assigned as level up
                lev.skillPointSpends.forEach((slp, thisIndex) => {
                    let maxIndex = 100;
                    if (lev.level === props.level) { maxIndex = props.index; }
                    if (thisIndex <= maxIndex) {
                        if (slp.skillName === selectedSkill && slp.spendType === "improveSkill") {
                            pointsSpentOnTheSkill = pointsSpentOnTheSkill + slp.pointsSpent;
                        }
                    }
                })
            }
        })
        return pointsSpentOnTheSkill;
    }

    const getImprovementNote = () => {
        let selectedSkill = "";

        if (thisLevel) {
            selectedSkill = thisLevel.skillPointSpends[props.index].skillName;
        }

        if (selectedSkill !== "") {

            // Find how many points have already been invested in the skill up to this level and index. 
            let pointsSpentOnTheSkill = getPointsSpentOnSkill(selectedSkill);

            // get skill level from level one
            let skillLevelAtLevel1 = null;
            const thisSkillAtLevel1 = charDerivedStatsAtLevel1.skillLevels.find((sl) => sl.skill === selectedSkill);
            if (thisSkillAtLevel1) {
                skillLevelAtLevel1 = thisSkillAtLevel1.level;
            }

            // Create the note text
            const thisSkill = charDerivedStats.skillLevels.find((sl) => sl.skill === selectedSkill);
            if (thisSkill) {

                const skillLevel = thisSkill.level ? thisSkill.level : 0;

                let pointsToAdvance = 0;
                switch (skillLevel) {
                    case 0: pointsToAdvance = 1; break;  // no training
                    case 1: pointsToAdvance = 2; break;     // level-0
                    case 2: pointsToAdvance = 3; break;     // level-1
                    case 3: pointsToAdvance = 4; break;     // level-2
                    case 4: pointsToAdvance = 5; break;     // level-3
                    case 5: pointsToAdvance = 1000; break;  // level-4, max
                }

                let pointsSpentToGetToThisLevel = 0;
                switch (skillLevel) {
                    case 0: pointsSpentToGetToThisLevel = 0; break;  // no training
                    case 1: pointsSpentToGetToThisLevel = 1; break;     // level-0
                    case 2: pointsSpentToGetToThisLevel = 3; break;     // level-1  
                    case 3: pointsSpentToGetToThisLevel = 6; break;     // level-2
                    case 4: pointsSpentToGetToThisLevel = 10; break;    // level-3
                    case 5: pointsSpentToGetToThisLevel = 15; break;    // level-4
                }

                const pluralise = (points: number | null) => {
                    if (points === null) { return ""; }
                    return points !== 1 ? "s" : ""
                };

                const formatLevel = (level: number) => {
                    if (level === 0) {
                        return "-(untrained)"
                    } else {
                        return "-" + (level - 1);
                    }
                }

                const finalPointsToAdvance = pointsSpentToGetToThisLevel + pointsToAdvance;

                const formatSkillLevel = (skillLevelAtLevel1: any) => {
                    if (skillLevelAtLevel1 === null) { return "No "; }
                    else return skillLevelAtLevel1;
                }

                let comments = [];
                comments.push(formatSkillLevel(skillLevelAtLevel1) + " skill level pick" + pluralise(skillLevelAtLevel1) + " +" + pointsSpentOnTheSkill + " skill point" + pluralise(pointsSpentOnTheSkill) + " gives " + selectedSkill + formatLevel(skillLevel));
                if (skillLevel !== null && skillLevel >= 5) {
                    comments.push(selectedSkill + " has reached the maximum level of 4");
                }
                if (skillLevel !== null && skillLevel < 5) {

                    let pointsFromPicksAtFirstLevel = 0;
                    if (skillLevelAtLevel1 === 1) { pointsFromPicksAtFirstLevel = 1; }
                    if (skillLevelAtLevel1 === 2) { pointsFromPicksAtFirstLevel = 3; }
                    if (skillLevelAtLevel1 === 3) { pointsFromPicksAtFirstLevel = 6; }
                    if (skillLevelAtLevel1 === 4) { pointsFromPicksAtFirstLevel = 10; }
                    if (skillLevelAtLevel1 === 5) { pointsFromPicksAtFirstLevel = 15; }

                    const pointsRequired = finalPointsToAdvance - pointsSpentOnTheSkill - pointsFromPicksAtFirstLevel;
                    comments.push(pointsRequired + " more skill point" + pluralise(pointsRequired) + " required to improve to " + selectedSkill + "-" + skillLevel);
                }

                return (
                    <div className="small">
                        {comments.join("; ")}
                    </div>
                )

            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    return (
        <>
            {getSkillDropdown()}{getPointsTypeDropdown()}{getPointsSpentDropdown()}
            {getImprovementNote()}

            <SelectPsychicTechniqueWhenSkillLevelIncreases
                charTraits={props.charTraits}
                level={props.level}
                skillPickNumber={props.index}
                onSetPsychicTechniqueWhenLevelUp={props.onSetPsychicTechniqueWhenLevelUp}
            />

            {thisLevel && thisLevel.skillPointSpends[props.index].validationCodes.indexOf("mustBeAtLeastLevel3ToBuyLevel2skill") !== -1 &&
                <ValidationAlert msg="Must be at least level 3 to buy a skill at level-2 (unless bought with skill points granted by a focus)" />
            }
            {thisLevel && thisLevel.skillPointSpends[props.index].validationCodes.indexOf("mustBeAtLeastLevel6ToBuyLevel3skill") !== -1 &&
                <ValidationAlert msg="Must be at least level 6 to buy a skill at level-3 (unless bought with skill points granted by a focus)" />
            }
            {thisLevel && thisLevel.skillPointSpends[props.index].validationCodes.indexOf("mustBeAtLeastLevel9ToBuyLevel4skill") !== -1 &&
                <ValidationAlert msg="Must be at least level 9 to buy a skill at level-4 (unless bought with skill points granted by a focus)" />
            }
        </>
    );
}

export default SpendSkillPointsToImproveSkill; 
