import React, { useState } from "react";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";
import SelectCargoModal from "./SelectCargoModal";
import { formatLargeCredits } from "../../utilities/Utilities";
import { Cargo } from "../../interfaces/Cargo";
import uniqid from "uniqid";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onSetCrewDefaultSkill: (skill: number) => void;
    onSetCrewGunnerSkill: (skill: number) => void;
    onSetCrewNPCCommandPoints: (cp: number) => void;
    onSetCrewCurrent: (crew: number) => void;
    onSetCrewAutomationSupportBots: (bots: number) => void;
    onSetCurrentFuelLoads: (fuelLoads: number) => void;
    onAddCargo: (cargo: Cargo) => void;
    onDeleteCargo: (uniqid: string) => void;
}

const ShipDesignStep8Cargo: React.FunctionComponent<IProps> = (props: IProps) => {

    const shipDerivedStats = new ShipDerivedStats(props.shipTraits);
    const [openSetCrewModal, setOpenSetCrewModal] = useState(false);

    let hasSmugglerHolds = false;
    const smugHold = shipDerivedStats.fittingsInstalled.find((fi) => fi.id === "SF39");
    if (smugHold) { hasSmugglerHolds = true; }

    let hasAutomationSupport = false;
    const autoSts = props.shipTraits.fittings.find((f) => f.id === "SF7");
    if (autoSts) {
        hasAutomationSupport = true;
    }

    let hasVehicleTransportFittings = false;
    const vehTrans = props.shipTraits.fittings.find((f) => f.id === "SF44");
    if (vehTrans) {
        hasVehicleTransportFittings = true;
    }

    let hasVehiclesAsCargo = false;
    const vehicles = props.shipTraits.cargo.find((c) => c.isVehicles);
    if (vehicles) {
        hasVehiclesAsCargo = true;
    }

    return (
        <div className="chargenSection">
            <h2>Cargo and Consumables</h2>

            <SelectCargoModal
                shipTraits={props.shipTraits}
                displayModal={openSetCrewModal}
                onSetCrewAutomationSupportBots={props.onSetCrewAutomationSupportBots}
                onSetCurrentFuelLoads={props.onSetCurrentFuelLoads}
                onAddCargo={props.onAddCargo}
                onDeleteCargo={props.onDeleteCargo}
                onClose={() => setOpenSetCrewModal(false)}
            />

            {!props.readOnly &&
                <div>
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenSetCrewModal(true)}>Select Cargo & Consumables</button>
                </div>
            }

            <div className="row border-bottom pt-1 pb-1" >
                <div className={"col-8 col-xs-8 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Cargo Capacity:</div>
                <div className={"col-4 col-xs-4 col-sm-7 col-md-8 col-lg-9 col-xl-9 small"}>{shipDerivedStats.cargoCapacityTons} tons</div>
                {hasSmugglerHolds &&
                    <>
                        <div className={"col-8 col-xs-8 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Smuggler's Holds Capacity:</div>
                        <div className={"col-4 col-xs-4 col-sm-7 col-md-8 col-lg-9 col-xl-9 small"}>{shipDerivedStats.smugglersHoldsCapacityTons} tons</div>
                    </>
                }
                <div className={"col-8 col-xs-8 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Current Total Cargo:</div>
                <div className={"col-4 col-xs-4 col-sm-7 col-md-8 col-lg-9 col-xl-9 small"}>{shipDerivedStats.cargoCurrentTons} tons, {formatLargeCredits(shipDerivedStats.cargoCurrentPrice)} (not inc. fuel or auto-sup bots)</div>
            </div>

            <div className="row border-bottom pt-1 pb-1" >
                <div className={"col-8 col-sm-8 col-md-4 col-lg-3 col-xl-3 small"}><b>Fuel loads</b></div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>x{props.shipTraits.currentFuelLoads}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>{formatLargeCredits(props.shipTraits.currentFuelLoads * 500)}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}></div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Tons&nbsp;0</div>
                <div className={"col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 small"}>Fuel for spike drills</div>
            </div>

            {hasAutomationSupport &&
                <div className="row border-bottom pt-1 pb-1" >
                    <div className={"col-8 col-sm-8 col-md-4 col-lg-3 col-xl-3 small"}><b>Automation Support Bots</b></div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>x{props.shipTraits.crewData.crewAutomationSupportBots}</div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>{formatLargeCredits(props.shipTraits.crewData.crewAutomationSupportBots * 1000)}</div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}></div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Tons&nbsp;0</div>
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 small"}>Replaces crew via Automation Support fitting</div>
                </div>
            }
            {props.shipTraits.cargo.map((c) =>
                <div className="row border-bottom pt-1 pb-1" key={uniqid()} >
                    <div className={"col-8 col-sm-8 col-md-4 col-lg-3 col-xl-3 small"}>
                        <b>
                            {c.name}
                            {c.isVehicles && hasVehicleTransportFittings && hasVehiclesAsCargo &&
                                <sup>V</sup>
                            }
                        </b>
                    </div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>x{c.tons}</div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>{formatLargeCredits(c.tons * c.pricePerTon)}</div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}></div>
                    <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Tons&nbsp;{c.tons}</div>
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 small"}>{c.desc}</div>
                </div>
            )}

            {hasVehicleTransportFittings && hasVehiclesAsCargo &&
                <div className="small"><sup>V</sup>: Vehicles. Effective cargo tonnage halved as ship has Vehicle Transport Fittings.</div>
            }

        </div>
    );

}

export default ShipDesignStep8Cargo;