import React from 'react';
import { useTranslation } from 'react-i18next';

import AccountUpdate from "../components/AccountUpdate";
import AccountChangePassword from "../components/AccountChangePassword";
import AccountDelete from "../components/AccountDelete";

const MyAccount: React.FunctionComponent<unknown> = (props: any) => {

    const { t } = useTranslation();

    // const { user: currentUser } = useContext(AuthContext);

    return (
        <div className="pageBody">
            <h1 className="pageHeading">{t("myAccount.myAccount")}</h1>

            <div className="container">

                <div className="row">

                    <div className="col-md-6 mb-3">
                        <AccountUpdate />
                    </div>

                    <div className="col-md-6 mb-3">
                        <AccountChangePassword />
                    </div>

                    <div className="col-md-6">
                        <AccountDelete />
                    </div>

                </div>

            </div>

        </div>
    );
}

export default MyAccount;
