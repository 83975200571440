import React, { useEffect } from "react";
import $ from "jquery";

interface IProps {
    newVersion: string;
}

const UpdateVersionModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const onRefreshBrowser = () => {
        window.location.reload();
    }

    useEffect(() => {
        ($('#updateVersionModal') as any).modal('show');
    }, []);

    return (
        <div id="updateVersionModal" className="modal fade" tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">New Update Available</h5>
                        {/* <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                    </div>
                    <div className="modal-body">
                        <p>Freebooter has been updated to version {props.newVersion}</p>
                        <p>Please refresh your browser to get the latest code.</p>

                        <p><button type="button" className="btn btn-primary" onClick={(e) => onRefreshBrowser()}>Refresh & Update Version</button></p>

                        <p>Latest changes are listed on the Updates page.</p>
                    </div>
                    {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>Close</button>
                        </div> */}
                </div>
            </div>
        </div>
    )

}

export default UpdateVersionModal;

