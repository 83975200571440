import React from "react";
import $ from "jquery";

import { StarshipTraits } from "../../interfaces/StarshipTraits";

interface IProps {
    shipTraits: StarshipTraits;
    displayModal: boolean;
    onSelectAutoWeapon: (autoUniqId: string, weaponUniqId: string) => void;
    onClose: () => void;
}

const SelectAutoTargetingModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const modalId = "autoTargeting_modal";

    if (props.displayModal) {
        ($("#" + modalId) as any).modal('show');
    }

    if (!props.displayModal) {
        ($("#" + modalId) as any).modal('hide');
    }

    const onCloseModal = () => {
        props.onClose();
    }

    const getSelectedWeaponIdForAutoTargeter = (autoId: string) => {
        const autoWeapon = props.shipTraits.autoTargetingWeapons.find((atw) => atw.autoTargetingUniqId === autoId);
        if (autoWeapon) {
            return autoWeapon.weaponUniqId;
        }
        return "";
    }

    const getWeaponOptions = (autoTargetingId: string) => {
        let weapons = props.shipTraits.fittings.filter((f) => f.type === "weapons");

        weapons = weapons.filter((w) => {
            const autoWeapon = props.shipTraits.autoTargetingWeapons.find((atw) => atw.weaponUniqId === w.uniqid);
            return autoWeapon === undefined;
        })

        const thisAutoWeapon = props.shipTraits.autoTargetingWeapons.find((atw) => atw.autoTargetingUniqId === autoTargetingId);
        if (thisAutoWeapon) {
            const thisWeapon = props.shipTraits.fittings.find((w) => w.uniqid === thisAutoWeapon.weaponUniqId);
            if (thisWeapon) { 
                weapons.push(thisWeapon); 
            }
        }

        return weapons.map((w) => {
            return <option key={w.uniqid} value={w.uniqid}>{w.name} #{w.index}</option>
        });
    }

    const onSelectAutoWeapon = (autoUniqId: string, weaponUniqId: string): void => {
        props.onSelectAutoWeapon(autoUniqId, weaponUniqId);
    }

    const getAutoTargeting = () => {
        let output: any = [];
        const autoTargeters = props.shipTraits.fittings.filter((f) => f.id === "SF6");
        if (autoTargeters.length > 0) {
            autoTargeters.forEach((at) => {

                const weaponOptions = getWeaponOptions(at.uniqid); 

                let prompt = "--- select weapon --";
                if(weaponOptions.length === 0) { prompt = "--- no weapons available --"}

                output.push(
                    <div key={at.uniqid}>
                        <div className={"col-4 col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 small mb-1"}>Auto&#8209;targeting&nbsp;#{at.index}</div>
                        <div className={"col-8 col-xs-8 col-sm-9 col-md-10 col-lg-10 col-xl-10 small mb-1"}>
                            <select value={getSelectedWeaponIdForAutoTargeter(at.uniqid)} onChange={(e: any) => onSelectAutoWeapon(at.uniqid, e.currentTarget.value)}>
                                <option value="">{prompt}</option>
                                {weaponOptions}
                            </select>
                        </div>
                    </div>
                );
            });
            return <div key={"autoTarg"} className="row pt-1 pb-1">{output}</div>;
        }
        return null;
    }

    return (
        <div id={modalId} className="modal" tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Select Auto-Targeting</h5>
                        <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <p className="small">Select which Auto-targeting systems will operate which weapons:</p>

                        {getAutoTargeting()}

                    </div>

                    {/* <div><pre>{JSON.stringify(props.shipTraits.autoTargetingWeapons, null, 2)}</pre></div> */}

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>Close</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SelectAutoTargetingModal;

