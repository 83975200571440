import React from "react";

import StarshipDesignMainDemo from "../components/starships/StarshipDesignMainDemo";

const StarshipDesignerDemo: React.FunctionComponent<{}> = (props: {}) => {

    return (
        <div className="pageBody">
            <StarshipDesignMainDemo />
        </div>
    );
}

export default StarshipDesignerDemo;
