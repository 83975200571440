import React from "react";

import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { StarshipTraits } from "../../interfaces/StarshipTraits";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onSetNotes: (notes: string) => void;
}

const ShipDesignStep12Notes: React.FunctionComponent<IProps> = (props: IProps) => {

    const onSetNotes = (value: string) => {
        props.onSetNotes(value);
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }]
            // ['link'],
            // ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet'
        // 'link'
    ];

    if (props.readOnly) { return null; }

    return (
        <div className="chargenSection">
            <h2>Notes</h2>

            <div style={{ height: "280px" }}>
                <ReactQuill value={props.shipTraits.notes} onChange={onSetNotes} modules={modules} formats={formats} style={{ height: "230px" }} />
            </div>

        </div>
    );

}

export default ShipDesignStep12Notes;