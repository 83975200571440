import React, { useState } from "react";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";
import SelectCrewModal from "./SelectCrewModal";
import { handleIndefiniteLifeSupport } from "../../utilities/starshipMainUtilities";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onSetCrewDefaultSkill: (skill: number) => void;
    onSetCrewGunnerSkill: (skill: number) => void;
    onSetCrewNPCCommandPoints: (cp: number) => void;
    onSetCrewCurrent: (crew: number) => void;
    onSetPassengersCurrent: (passengers: number) => void;
}

const ShipDesignStep7Crew: React.FunctionComponent<IProps> = (props: IProps) => {

    const shipDerivedStats = new ShipDerivedStats(props.shipTraits);
    const [openSetCrewModal, setOpenSetCrewModal] = useState(false);

    return (
        <div className="chargenSection">
            <h2>Crew and Life Support</h2>

            <SelectCrewModal
                shipTraits={props.shipTraits}
                displayModal={openSetCrewModal}
                onSetCrewDefaultSkill={props.onSetCrewDefaultSkill}
                onSetCrewGunnerSkill={props.onSetCrewGunnerSkill}
                onSetCrewNPCCommandPoints={props.onSetCrewNPCCommandPoints}
                onSetCrewCurrent={props.onSetCrewCurrent}
                onSetPassengersCurrent={props.onSetPassengersCurrent}
                onClose={() => setOpenSetCrewModal(false)}
            />

            {!props.readOnly &&
                <div>
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenSetCrewModal(true)}>Select Crew</button>
                </div>
            }

            <div className="row border-bottom pt-1 pb-1" >
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Minimum&nbsp;crew&nbsp;to&nbsp;operate&nbsp;ship</div>
                <div className={"col-4 col-xs-6 col-sm-7 col-md-8 col-lg-9 col-xl-9 small"}>{shipDerivedStats.crewMin}</div>
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Current&nbsp;crew</div>
                <div className={"col-4 col-xs-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 small"}>{shipDerivedStats.crewTotal}</div>
                <div className={"col-12 col-xs-12 col-sm-6 col-md-7 col-lg-8 col-xl-8 small"}>
                    {props.shipTraits.crewData.crewAutomationSupportBots > 0 &&
                        <>
                            <i>{props.shipTraits.crewData.crewCurrent} crew, {props.shipTraits.crewData.crewAutomationSupportBots} automation support bots</i>
                        </>
                    }
                </div>
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Current passengers</div>
                <div className={"col-4 col-xs-6 col-sm-7 col-md-8 col-lg-9 col-xl-9 small"}>{props.shipTraits.crewData.passengersCurrent}</div>
            </div>
            <div className="row border-bottom pt-1 pb-1" >
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Life support maximum load</div>
                <div className={"col-4 col-xs-6 col-sm-7 col-md-8 col-lg-9 col-xl-9 small"}>{shipDerivedStats.lifeSupportMax}</div>
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Life support current load</div>
                <div className={"col-4 col-xs-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 small"}>{shipDerivedStats.lifeSupportLoad}</div>
                <div className={"col-12 col-xs-12 col-sm-6 col-md-7 col-lg-8 col-xl-8 small"}><i>{props.shipTraits.crewData.crewCurrent} crew and {props.shipTraits.crewData.passengersCurrent} passengers</i></div>
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Life support duration</div>
                <div className={"col-4 col-xs-6 col-sm-7 col-md-8 col-lg-9 col-xl-9 small"}>{handleIndefiniteLifeSupport(shipDerivedStats.lifeSupportMonths)}</div>
            </div>

            <div className="row border-bottom pt-1 pb-1" >
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 small"}>Default crew skill modifier</div>
                <div className={"col-4 col-xs-6 col-sm-7 col-md-8 col-lg-1 col-xl-1 small"}>+{props.shipTraits.crewData.crewDefaultSkill}</div>
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 small"}>Gunners skill modifier</div>
                <div className={"col-4 col-xs-6 col-sm-7 col-md-8 col-lg-1 col-xl-1 small"}>+{props.shipTraits.crewData.crewGunnerSkill}</div>
                <div className={"col-8 col-xs-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 small"}>NPC command points </div>
                <div className={"col-4 col-xs-6 col-sm-7 col-md-8 col-lg-1 col-xl-3 small"}>+{props.shipTraits.crewData.crewNPCCommandPoints}</div>
            </div>

        </div>
    );

}

export default ShipDesignStep7Crew;