import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../Auth";

type IProps = {
    component: React.FunctionComponent;
    path: string;
    exact: boolean;
}

const AdminOnlyRoute: React.FC<IProps> = (props: IProps): any => {

    const { user: currentUser, isAdmin } = useContext(AuthContext);

    if (userIsAuthorized(currentUser)) {
        if(isAdmin) {
            return <Route path={props.path} exact={props.exact} component={props.component} />;
        }
        return <Redirect to="/" />;
    } else {
        return <Redirect to="/" />;
    }
};

const userIsAuthorized = (currentUser: firebase.User | null): boolean => {

    if (currentUser) {  return true; }

    return false;
}

export default AdminOnlyRoute;
