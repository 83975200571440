import React, { useState } from "react";
import { StarshipTraits } from "../../interfaces/StarshipTraits";

interface IProps {
    shipTraits: StarshipTraits;
    onSetFoxerDronesExtraMass: (weaponUniqid: string, extraFreeMass: number) => void;
    onSetFoxerDronesRemaining: (weaponUniqId: string, roundsRemaining: number) => void;
}

const SelectFoxerDrones: React.FunctionComponent<IProps> = (props: IProps) => {

    const getInitialExtraDroneMass = (weaponUniqId: string) => {
        const thisWeapon = props.shipTraits.foxerDrones.find((fd) => fd.weaponUniqId === weaponUniqId);
        if (thisWeapon) {
            return thisWeapon.extraFreeMass;
        };
        return 0;
    }

    const getInitialExtraDronesRemaining = (weaponUniqId: string) => {
        const thisWeapon = props.shipTraits.foxerDrones.find((fd) => fd.weaponUniqId === weaponUniqId);
        if (thisWeapon) {
            return thisWeapon.roundsRemaining;
        };
        return 0;
    }

    let thisWeaponUniqId = "";
    const foxerDrones = props.shipTraits.fittings.find((f) => f.id === "SD5");
    if (foxerDrones) {
        thisWeaponUniqId = foxerDrones.uniqid;
    };

    const [localExtraDronesMass, setLocalExtraDronesMass] = useState(getInitialExtraDroneMass(thisWeaponUniqId).toString());
    const [localDronesRemaining, setLocalDronesRemaining] = useState(getInitialExtraDronesRemaining(thisWeaponUniqId).toString());

    const onSetExtraDronesMass = (e: any, weaponUniqId: string) => {
        setLocalExtraDronesMass(e.currentTarget.value);

        if (!isNaN(parseInt(e.currentTarget.value))) {
            let thisExtraMass = parseInt(e.currentTarget.value);
            if (thisExtraMass < 0) { thisExtraMass = 0; }
            props.onSetFoxerDronesExtraMass(weaponUniqId, thisExtraMass);

            const newRemaining = 5 + (thisExtraMass * 5);
            props.onSetFoxerDronesRemaining(weaponUniqId, newRemaining);
            setLocalDronesRemaining(newRemaining.toString());
        }
    }

    const onSetExtraDronesMassToDefault = (e: any, weaponUniqId: string) => {
        if (isNaN(parseInt(e.currentTarget.value))) {
            props.onSetFoxerDronesExtraMass(weaponUniqId, 0);
            setLocalExtraDronesMass("0");
            props.onSetFoxerDronesRemaining(weaponUniqId, 5);
            setLocalDronesRemaining("5");
        } else {
            if (parseInt(e.currentTarget.value) < 0) {
                props.onSetFoxerDronesExtraMass(weaponUniqId, 0);
                setLocalExtraDronesMass("0");
                props.onSetFoxerDronesRemaining(weaponUniqId, 5);
                setLocalDronesRemaining("5");
            }
        }
    }

    const onSetDronesRemaining = (e: any, weaponUniqId: string) => {

        setLocalDronesRemaining(e.currentTarget.value);

        let maxRounds = 0;
        const thisWeapon = props.shipTraits.foxerDrones.find((fd) => fd.weaponUniqId === weaponUniqId);
        if (thisWeapon) {
            maxRounds = 5 + (thisWeapon.extraFreeMass * 5);
        };

        if (!isNaN(parseInt(e.currentTarget.value))) {
            let roundsRemaining = parseInt(e.currentTarget.value);
            if (roundsRemaining < 0) { roundsRemaining = 0; }
            if (roundsRemaining > maxRounds) { roundsRemaining = maxRounds; }
            props.onSetFoxerDronesRemaining(weaponUniqId, roundsRemaining);
            setLocalDronesRemaining(roundsRemaining.toString());
        }
    }

    const onSetDronesRemainingToDefault = (e: any, weaponUniqId: string) => {
        if (isNaN(parseInt(e.currentTarget.value))) {
            let maxRounds = 0;
            const thisWeapon = props.shipTraits.foxerDrones.find((fd) => fd.weaponUniqId === weaponUniqId);
            if (thisWeapon) {
                maxRounds = 5 + (thisWeapon.extraFreeMass * 5);
            };

            props.onSetFoxerDronesRemaining(weaponUniqId, maxRounds);
            setLocalDronesRemaining(maxRounds.toString());
        }
    }

    const getMaxDrones = () => {
        const thisWeapon = props.shipTraits.foxerDrones.find((fd) => fd.weaponUniqId === thisWeaponUniqId);
        if (thisWeapon) {
            return (5 + (thisWeapon.extraFreeMass * 5));
        }
        return 0;
    }

    return (
        <>
            <div className="mb-2"><b>Foxer Drones</b></div>

            <div className="row pt-1 pb-1" >
                <div className={"col-7 col-xs-7 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}><b>Foxer Drones</b></div>
                <div className={"col-5 col-xs-7 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>5 drones per Mass</div>
                <div className={"col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 small"}>The Foxer Drone fitting includes 5 Foxer Drones</div>
            </div>
            <div className="row pt-1 pb-1 border-bottom">

                <div className={"col-8 col-xs-8 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                    <label htmlFor={"ammoMass"}>Additional Mass for drones:</label>
                </div>
                <div className={"col-4 col-xs-4 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                    <input id={"ammoMass"} type="number" className="shortNum" value={localExtraDronesMass} onChange={(e) => onSetExtraDronesMass(e, thisWeaponUniqId)} onBlur={(e) => onSetExtraDronesMassToDefault(e, thisWeaponUniqId)} />
                </div>

                <div className={"col-8 col-xs-8 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                    Maximum drones capacity:
                </div>
                <div className={"col-4 col-xs-4 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                    {getMaxDrones()}
                </div>

                <div className={"col-8 col-xs-8 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                    <label htmlFor={"ammoRemain"}>Drones remaining:</label>
                </div>
                <div className={"col-4 col-xs-4 col-sm-6 col-md-3 col-lg-3 col-xl-9 small"}>
                    <input id={"ammoRemain"} type="number" className="shortNum" value={localDronesRemaining} onChange={(e) => onSetDronesRemaining(e, thisWeaponUniqId)} onBlur={(e) => onSetDronesRemainingToDefault(e, thisWeaponUniqId)} />
                </div>

            </div>

            {/* <div><pre>ammo: {JSON.stringify(props.shipTraits.foxerDrones, null, 2)}</pre></div>
            <div><pre>ammo localExtraDronesMass: {JSON.stringify(localExtraDronesMass, null, 2)}</pre></div>
            <div><pre>ammo localDronesRemaining: {JSON.stringify(localDronesRemaining, null, 2)}</pre></div> */}
        </>
    );

}

export default SelectFoxerDrones;