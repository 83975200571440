import React from "react";
import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";

interface IProps {
    shipTraits: StarshipTraits;
}

const Audit: React.FunctionComponent<IProps> = (props: IProps) => {

    const derivedStats = new ShipDerivedStats(props.shipTraits);

    const getStats = (auditStrings: string[], name: string) => {
        if (auditStrings.length > 0) {
            return auditStrings.map((a, index) => <div key={name + index}>{a}</div>);
        }
        return "None";
    }

    return (
        <div className="chargenSection">

            <h5>Cost</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_totalCost, "cost")}</div>

            <h5>Free Mass Capacity</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_massAvailable, "massCap")}</div>

            <h5>Free Mass Used</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_massUsed, "massUsed")}</div>

            <h5>Power Capacity</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_powerAvailable, "powAv")}</div>

            <h5>Power Used</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_powerUsed, "powUsed")}</div>

            <h5>Hard Points Available</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_hardPointsAvailable, "hardPAvail")}</div>

            <h5>Hard Points Used</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_hardPointsUsed, "hardPUsed")}</div>

            <h5>Hit Points</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_hp, "hp")}</div>

            <h5>Armor Class</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_ac, "ac")}</div>

            <h5>Armor</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_armor, "armor")}</div>

            <h5>Speed</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_speed, "speed")}</div>

            <h5>Life Support Capacity</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_lifeSupportMax, "lsMax")}</div>

            <h5>Life Support Duration</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_lifeSupportMonths, "lsMax")}</div>

            <h5>Fuel Load Capacity</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_fuelLoads, "fl")}</div>

            <h5>Cargo Load</h5>
            <div className="mb-3 small">{getStats(derivedStats.audit_cargo, "cargo")}</div>

        </div >
    );

}

export default Audit;