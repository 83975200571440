import { ShipFittingOwned } from './ShipFittingOwned';
import { applyValidation } from "../components/starships/StarshipValidation";
import { AutoTargetingWeapon } from './AutoTargetingWeapons';
import { Cargo } from './Cargo';
import { CrewData } from './CrewData';
import { Ammo } from './Ammo';

export interface StarshipTraits {
    id: string;
    name: string;
    hullType: string;
    isPublic: boolean;
    fittings: ShipFittingOwned[];
    mods: ShipFittingOwned[];
    crewData: CrewData;
    autoTargetingWeapons: AutoTargetingWeapon[];
    cargo: Cargo[];
    ammo: Ammo[];
    foxerDrones: Ammo[];
    currentFuelLoads: number;
    isValid: boolean;
    validationCodes: string[];
    validationErrors: string[];
    showValidation: boolean;
    notes: string;
}

export const getInitialStarshipTraits = (): StarshipTraits => {

    let newShip: StarshipTraits = {
        id: "",
        name: "",
        hullType: "",
        isPublic: false,
        fittings: [],
        mods: [],
        crewData: {
            crewDefaultSkill: 0,
            crewGunnerSkill: 0,
            crewNPCCommandPoints: 0,
            crewCurrent: 0,
            crewAutomationSupportBots: 0,
            passengersCurrent: 0,
            lifeSupportMonths: 2
        },
        autoTargetingWeapons: [],
        cargo: [],
        ammo: [],
        foxerDrones: [],
        currentFuelLoads: 1,
        isValid: false,
        validationCodes: [],
        validationErrors: [],
        showValidation: false,
        notes: ""
    }

    newShip = applyValidation(newShip);

    return newShip;

};