import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AuthContext } from "../Auth";

const Footer: React.FunctionComponent<{}> = () => {

    const { t } = useTranslation();

    const { user: currentUser } = useContext(AuthContext);

    let email = "";
    if (currentUser) { email = currentUser.email as string; }

    if (currentUser) {
        return (
            <div className="right">
                <span className="small text-right pr-2 text-white footerText">{t("menu.user")}: {email}</span>
                <Link className="small text-right pr-2 text-white footerLink" to="/privacy">Privacy</Link>
            </div>
        );

    } else {
        return (
            <div className="right">
                <Link className="small text-right pr-2 text-white footerLink" to="/privacy">Privacy</Link>
            </div>
        );
    }
}

export default Footer; 