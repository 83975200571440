import { handleIndefiniteLifeSupport, handleSpeed } from './../utilities/starshipMainUtilities';
import { formatLargeCredits } from './../utilities/Utilities';
import { ShipHullData } from './../interfaces/ShipHull';
import { ShipFittingData } from './../interfaces/ShipFitting';
import { Lookups } from '../lookups/Lookups';
import { convertHullClassToNUmber, getRawCost, getRawMass, getRawModCost, getRawPower } from '../utilities/starshipMainUtilities';
import { formatDamage } from '../utilities/Utilities';
import { StarshipTraits } from './../interfaces/StarshipTraits';

export interface FittingInstalled {
    id: string;
    fittingType: string;
    name: string;
    namePlural?: string;
    quantity: number;
    totalCost: number;
    totalPower: number;
    totalMass: number;
}

export interface WeaponInstalled {
    id: string;
    uniqid: string;
    fittingType: string;
    name: string;
    quantity: number;
    totalCost: number;
    totalPower: number;
    totalMass: number;
    totalHardPoints: number;
    ammunitionPerMass: number;
    ammunition: number;
    ammunitionMass: number;
    ammunitionCost: number;
    ammunitionRemaining: number;
}

export interface ModInstalled {
    id: string;
    name: string;
    namePlural?: string;
    quantity: number;
    totalCost: number;
}

export interface TotalStats {
    totalCost: number;
    totalPowerUsed: number;
    totalMassUsed: number;
    totalAmmoCost: number;
    totalHardPointsUsed: number;
}

export class ShipDerivedStats {

    private shipTraits: StarshipTraits;
    public fittingsInstalled: FittingInstalled[];
    // public weaponsInstalled: WeaponInstalled[];
    public weaponsInstalled_NotGrouped: WeaponInstalled[];
    public modsInstalled: ModInstalled[];
    public totals: TotalStats;

    public hullClass: string = "";
    public hp: number = 0;
    public powerAvailable: number = 0;
    public massAvailable: number = 0;
    public ac: number = 0;
    public armor: number = 0;
    public crewMin: number = 0;
    public crewTotal: number = 0;
    public lifeSupportLoad: number = 0;
    public lifeSupportMax: number = 0;
    public lifeSupportMonths: number = 2;
    public speed: number = 0;
    public cargoCapacityTons: number = 0;
    public cargoCurrentTons: number = 0;
    public cargoCurrentPrice: number = 0;
    public smugglersHoldsCapacityTons: number = 0;
    public fuelLoadsMax: number = 1;
    public hardPointsAvailable: number = 0;

    public audit_totalCost: string[] = [];
    public audit_massUsed: string[] = [];
    public audit_hp: string[] = [];
    public audit_powerAvailable: string[] = [];
    public audit_powerUsed: string[] = [];
    public audit_massAvailable: string[] = [];
    public audit_ac: string[] = [];
    public audit_armor: string[] = [];
    public audit_speed: string[] = [];
    public audit_lifeSupportMax: string[] = [];
    public audit_lifeSupportMonths: string[] = [];
    public audit_fuelLoads: string[] = [];
    public audit_cargo: string[] = [];
    public audit_hardPointsAvailable: string[] = [];
    public audit_hardPointsUsed: string[] = [];

    private lookups = Lookups.getInstance();

    constructor(shipTraits: StarshipTraits) {
        this.shipTraits = shipTraits;
        this.fittingsInstalled = [];
        // this.weaponsInstalled = [];
        this.weaponsInstalled_NotGrouped = [];
        this.modsInstalled = [];
        this.totals = { totalCost: 0, totalPowerUsed: 0, totalMassUsed: 0, totalAmmoCost: 0, totalHardPointsUsed: 0 };
        this.calculateHullClass();
        this.calculateFittingsInstalled();
        // this.calculateWeaponsInstalled();
        this.calculateWeaponsInstalled_NotGrouped();
        this.calculateModsInstalled();
        this.calculateHitPoints();
        this.calculatePowerAvailable();
        this.calculateMassAvailable();
        this.calculateTotals();

        this.calculateArmorClass();
        this.calculateArmor();
        this.calculateCrewMin();
        this.calculateCrewTotal();
        this.calculateLifeSupportLoad();
        this.calculateLifeSupportMax();
        this.calculateLifeSupportMonths();
        this.calculateSpeed();
        this.calculateCargoCapacity();
        this.calculateCargoTons();
        this.calculateCargoPrice();
        this.calculateSmugglersHoldsCapacity();
        this.calculateFuelLoads();
        this.calculateHardPointsAvailable();

    }

    private calculateHullClass = () => {
        const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
        if (theHull) {
            this.hullClass = theHull.class;
        }
    }

    private calculateFittingsInstalled = () => {
        this.shipTraits.fittings.forEach((fit) => {

            const theFitting = this.lookups.shipFittings.find((sf) => sf.id === fit.id);
            if (theFitting) {

                const costEach = getRawCost(theFitting, this.shipTraits.hullType, this.lookups);
                const powerEach = getRawPower(theFitting, this.shipTraits.hullType, this.lookups);
                const massEach = getRawMass(theFitting, this.shipTraits.hullType, this.lookups);

                const installedInstances = this.fittingsInstalled.find((fi) => fi.id === fit.id);
                if (installedInstances) {
                    installedInstances.quantity = installedInstances.quantity + 1;
                    installedInstances.totalCost = costEach * installedInstances.quantity;
                    installedInstances.totalPower = powerEach * installedInstances.quantity;
                    installedInstances.totalMass = massEach * installedInstances.quantity;
                } else {
                    const newInstance: FittingInstalled = {
                        id: fit.id,
                        name: fit.name,
                        namePlural: fit.namePlural,
                        fittingType: theFitting.type,
                        quantity: 1,
                        totalCost: costEach,
                        totalPower: powerEach,
                        totalMass: massEach,
                    }
                    this.fittingsInstalled.push(newInstance);
                }
            }
        })
    }

    // private calculateWeaponsInstalled = () => {
    //     this.shipTraits.fittings.filter((fit) => fit.type === "weapons").forEach((fit) => {

    //         let hullClass = "";
    //         const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
    //         if (theHull) { hullClass = theHull.class; }

    //         const theFitting = this.lookups.shipFittings.find((sf) => sf.id === fit.id);
    //         if (theFitting && theFitting.weaponUsesAmmo) {

    //             const costEach = getRawCost(theFitting, this.shipTraits.hullType, this.lookups);
    //             const powerEach = getRawPower(theFitting, this.shipTraits.hullType, this.lookups);
    //             const massEach = getRawMass(theFitting, this.shipTraits.hullType, this.lookups);

    //             let ammoPerMass = 0;

    //             if (theFitting.weaponUsesAmmo) {
    //                 if (theFitting.effects) {
    //                     const ammoEffects = theFitting.effects.find((e) => e.indexOf("AMMO") !== -1);
    //                     if (ammoEffects) {
    //                         const ammo = ammoEffects.split(":");
    //                         ammoPerMass = parseInt(ammo[1]);

    //                         const shipHullClassNumber = this.convertHullClassToNUmber(hullClass);

    //                         const minHullClassForWeapon = theFitting.classes[0];
    //                         const minHullClassNumber = this.convertHullClassToNUmber(minHullClassForWeapon);

    //                         if (shipHullClassNumber <= minHullClassNumber) {
    //                             ammoPerMass = ammoPerMass * 1; // no adjustment to amount of ammo
    //                         } else {
    //                             for (let x = minHullClassNumber; x < shipHullClassNumber; x++) {
    //                                 ammoPerMass = ammoPerMass * 2;
    //                             }
    //                         }
    //                     }
    //                 }
    //             }

    //             // get how much extra extra mass of ammo for weapon has been bought:
    //             let extraAmmoMass = 0;
    //             let totalExtraRounds = 0;
    //             const theAmmo = this.shipTraits.ammo.find((a) => a.weaponUniqId === fit.uniqid);
    //             if (theAmmo) {
    //                 extraAmmoMass = theAmmo.extraFreeMass;
    //                 totalExtraRounds = extraAmmoMass * ammoPerMass;
    //             }

    //             const installedInstances = this.weaponsInstalled.find((fi) => fi.id === fit.id);
    //             if (installedInstances) {
    //                 installedInstances.quantity = installedInstances.quantity + 1;
    //                 installedInstances.totalCost = costEach * installedInstances.quantity;
    //                 installedInstances.totalPower = powerEach * installedInstances.quantity;
    //                 installedInstances.totalMass = massEach * installedInstances.quantity;
    //                 installedInstances.ammunition = (ammoPerMass * installedInstances.quantity) + totalExtraRounds;
    //                 installedInstances.ammunitionMass = extraAmmoMass;
    //             } else {
    //                 const newInstance: WeaponInstalled = {
    //                     id: fit.id,
    //                     uniqid: fit.uniqid,
    //                     name: fit.name,
    //                     fittingType: theFitting.type,
    //                     quantity: 1,
    //                     totalCost: costEach,
    //                     totalPower: powerEach,
    //                     totalMass: massEach,
    //                     ammunitionPerMass: ammoPerMass,
    //                     ammunition: ammoPerMass + totalExtraRounds,
    //                     ammunitionMass: extraAmmoMass,
    //                     ammunitionCost: -1,
    //                     ammunitionRemaining: -1
    //                 }
    //                 this.weaponsInstalled.push(newInstance);
    //             }
    //         }
    //     })
    // }

    private calculateWeaponsInstalled_NotGrouped = () => {
        this.shipTraits.fittings.filter((fit) => fit.type === "weapons").forEach((fit) => {

            let hullClass = "";
            const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
            if (theHull) { hullClass = theHull.class; }

            const theFitting = this.lookups.shipFittings.find((sf) => sf.id === fit.id);
            if (theFitting) {

                const costEach = getRawCost(theFitting, this.shipTraits.hullType, this.lookups);
                const powerEach = getRawPower(theFitting, this.shipTraits.hullType, this.lookups);
                const massEach = getRawMass(theFitting, this.shipTraits.hullType, this.lookups);
                const hardPointsEach = theFitting.weaponHardpoints === undefined ? 0 : theFitting.weaponHardpoints;

                let ammoPerMass = 0;

                if (theFitting.effects) {
                    const ammoEffects = theFitting.effects.find((e) => e.indexOf("AMMO") !== -1);
                    if (ammoEffects) {
                        const ammo = ammoEffects.split(":");
                        ammoPerMass = parseInt(ammo[1]);

                        const shipHullClassNumber = convertHullClassToNUmber(hullClass);

                        const minHullClassForWeapon = theFitting.classes[0];
                        const minHullClassNumber = convertHullClassToNUmber(minHullClassForWeapon);

                        if (shipHullClassNumber <= minHullClassNumber) {
                            ammoPerMass = ammoPerMass * 1; // no adjustment to amount of ammo
                        } else {
                            for (let x = minHullClassNumber; x < shipHullClassNumber; x++) {
                                ammoPerMass = ammoPerMass * 2;
                            }
                        }
                    }
                }


                // get how much extra extra mass of ammo for weapon has been bought:
                let extraAmmoMass = 0;
                let totalExtraRounds = 0;
                let ammoCostPerRound = 0;
                let totalExtraAmmoCost = 0;
                let ammunitionRemaining = 0;
                if (theFitting.weaponCostPerAmmo) { ammoCostPerRound = theFitting.weaponCostPerAmmo; }

                const theAmmo = this.shipTraits.ammo.find((a) => a.weaponUniqId === fit.uniqid);
                if (theAmmo) {
                    extraAmmoMass = theAmmo.extraFreeMass;
                    totalExtraRounds = extraAmmoMass * ammoPerMass;
                    ammunitionRemaining = theAmmo.roundsRemaining
                    totalExtraAmmoCost = ammunitionRemaining * ammoCostPerRound;
                }

                const newInstance: WeaponInstalled = {
                    id: fit.id,
                    uniqid: fit.uniqid,
                    name: fit.name,
                    fittingType: theFitting.type,
                    quantity: 1,
                    totalCost: costEach,
                    totalPower: powerEach,
                    totalMass: massEach + extraAmmoMass,
                    totalHardPoints: hardPointsEach,
                    ammunitionPerMass: ammoPerMass,
                    ammunition: ammoPerMass + totalExtraRounds,
                    ammunitionMass: extraAmmoMass,
                    ammunitionCost: totalExtraAmmoCost,
                    ammunitionRemaining: ammunitionRemaining
                }
                this.weaponsInstalled_NotGrouped.push(newInstance);
            }
        })
    }

    private calculateModsInstalled = () => {
        this.shipTraits.mods.forEach((mod) => {

            const theMod = this.lookups.shipMods.find((sm) => sm.id === mod.id);
            if (theMod) {

                const costEach = getRawModCost(theMod, this.shipTraits.hullType, this.lookups);

                const installedInstances = this.modsInstalled.find((mi) => mod.id === mi.id);
                if (installedInstances) {
                    installedInstances.quantity = installedInstances.quantity + 1;
                    installedInstances.totalCost = costEach * installedInstances.quantity;
                } else {
                    const newInstance: ModInstalled = {
                        id: mod.id,
                        name: mod.name,
                        namePlural: mod.namePlural,
                        quantity: 1,
                        totalCost: costEach,
                    }
                    this.modsInstalled.push(newInstance);
                }
            }
        })
    }

    private calculateTotals = () => {

        const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
        if (theHull) {
            let hullCost = this.applyHullPriceReductionEffects(theHull);

            // fittings
            let fittingsCost = 0;
            this.fittingsInstalled.forEach((f) => {
                fittingsCost = fittingsCost + f.totalCost;
                if (f.totalCost > 0) {
                    this.audit_totalCost.push(f.quantity + " x " + f.name + " costs " + formatLargeCredits(f.totalCost));
                }
                if (f.totalMass > 0) {
                    this.audit_massUsed.push(f.quantity + " x " + f.name + " uses Mass " + f.totalMass);
                }
            })

            // ammunition
            let ammoCost = 0;
            this.weaponsInstalled_NotGrouped.forEach((w) => {
                if (w.ammunition > 0) {
                    if (w.ammunitionMass > 0) {
                        this.audit_massUsed.push(w.ammunitionMass + " x ammo for " + w.name + " uses Mass " + w.ammunitionMass);
                    }
                    ammoCost = ammoCost + w.ammunitionCost;
                }
            })

            // hard points used
            let totalHardPointsUsed = 0;
            this.shipTraits.fittings.filter((f => f.type === "weapons")).forEach((w) => {
                const thisWeapon = this.lookups.shipFittings.find((sf) => sf.id === w.id);
                if (thisWeapon) {
                    if (thisWeapon.weaponHardpoints) {
                        this.audit_hardPointsUsed.push(thisWeapon.name + " uses Hard Points " + thisWeapon.weaponHardpoints);
                        totalHardPointsUsed = totalHardPointsUsed + thisWeapon.weaponHardpoints;
                    }
                }
            })
            this.audit_hardPointsUsed.push("Total Hard Points used: " + totalHardPointsUsed);

            // foxer drones ammo
            if (this.shipTraits.foxerDrones.length > 0) {
                const foxerDroneAmmo = this.shipTraits.foxerDrones[0];
                if (foxerDroneAmmo) {
                    this.audit_massUsed.push(foxerDroneAmmo.extraFreeMass + " x ammo for Foxer drones uses Mass " + foxerDroneAmmo.extraFreeMass);
                }
            }

            let totalCost = hullCost + fittingsCost + ammoCost;
            this.audit_totalCost.push("Total cost: " + formatLargeCredits(totalCost));

            let totalPowerUsed = 0;
            this.fittingsInstalled.forEach((fi) => {
                totalPowerUsed = totalPowerUsed + fi.totalPower;
                if (fi.totalPower > 0) {
                    this.audit_powerUsed.push(fi.quantity + " x " + fi.name + " uses Power " + fi.totalPower);
                }
            })
            this.audit_powerUsed.push("Total Power Used: " + totalPowerUsed);

            // let totalPowerUsed = this.fittingsInstalled.reduce((accumulator, obj) => { return accumulator + obj.totalPower; }, 0);

            let totalMassUsed = this.fittingsInstalled.reduce((accumulator, obj) => { return accumulator + obj.totalMass; }, 0);

            const extraMassForAmmo = this.weaponsInstalled_NotGrouped.reduce((accumulator, obj) => { return accumulator + obj.ammunitionMass; }, 0);
            totalMassUsed = totalMassUsed + extraMassForAmmo;

            let extraMassForFoxerDrones = 0;
            if (this.shipTraits.foxerDrones.length > 0) {
                const foxerDroneAmmo = this.shipTraits.foxerDrones[0];
                if (foxerDroneAmmo) {
                    extraMassForFoxerDrones = foxerDroneAmmo.extraFreeMass;
                }
            }
            totalMassUsed = totalMassUsed + extraMassForFoxerDrones;

            this.audit_massUsed.push("Total Mass Used: " + totalMassUsed + " of " + this.massAvailable + " (" + (this.massAvailable - totalMassUsed) + " free)");

            let totalAmmoCost = 0;
            this.weaponsInstalled_NotGrouped.forEach((w) => {
                totalAmmoCost = totalAmmoCost + w.ammunitionCost;
            })

            this.totals = { totalCost, totalPowerUsed, totalMassUsed, totalAmmoCost, totalHardPointsUsed };
        }
    }

    private applyHullPriceReductionEffects = (theHull: ShipHullData) => {

        this.audit_totalCost.push(theHull.class + "-class hull costs " + formatLargeCredits(theHull.cost))

        const priceAdjustments: number[] = [];
        this.shipTraits.fittings.forEach((fit) => {
            const theFitting = this.lookups.shipFittings.find((f) => f.id === fit.id);
            if (theFitting) {
                theFitting.effects?.forEach((ef) => {
                    const splitEffect = ef.split(":");
                    if (splitEffect[0] === "HULLCOST") {
                        const baseCostMult = Number.parseFloat(splitEffect[1]);
                        const adj = theHull.cost * baseCostMult;
                        priceAdjustments.push(adj);
                        this.audit_totalCost.push(theFitting.name + " alters cost by " + baseCostMult * 100 + "% of base hull cost, or " + formatLargeCredits(adj));
                    }
                })
            }
        });

        let finalHullPrice = theHull.cost;

        priceAdjustments.forEach((pa) => finalHullPrice = finalHullPrice + pa);

        return finalHullPrice;
    }

    private applyEffects = (hullAttribute: string, effectName: string, targetAttributeDesc: string, auditMember: string[], includeTotal: boolean = true) => {
        const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
        if (theHull) {

            type ObjectKey = keyof typeof theHull;
            const theKey = hullAttribute as ObjectKey;
            let points = theHull[theKey] as number;
            if (auditMember.length === 0) {
                auditMember.push(theHull.name + " base hull has " + points + " " + targetAttributeDesc + ".");
            }

            const fittingsIds: string[] = [];

            this.shipTraits.fittings.forEach((fit) => {
                const theFitting = this.lookups.shipFittings.find((f) => f.id === fit.id);
                if (theFitting) {
                    fittingsIds.push(theFitting.id);
                    if (theFitting.effects) {
                        const matchedEffect = theFitting.effects.find((ef) => ef.indexOf(effectName + ":") !== -1);
                        if (matchedEffect) {
                            const splitEffect = matchedEffect.split(":");
                            let pointsGained = parseInt(splitEffect[1]);

                            let isJustAdd = true;

                            // handle ADDBASE, where instead of just gaining points, you gains points * some base amount.
                            const isAddBase = splitEffect[0].indexOf("ADDBASE_") !== -1;
                            if (isAddBase) {
                                isJustAdd = false;
                                // Extended Life Support: Add a base amount * the points. 
                                const isExtendLifeSupport = splitEffect[0].indexOf("LIFESUPPORTMAX") !== -1;
                                if (isExtendLifeSupport) {
                                    pointsGained = pointsGained * theHull.maximumCrew;
                                    points = points + pointsGained;
                                }
                            }

                            // handle MULTIPLYBASE, where instead of just gaining points, you multiply each gain.
                            const isMultiplyBase = splitEffect[0].indexOf("MULTIPLYBASE_") !== -1;
                            if (isMultiplyBase) {
                                isJustAdd = false;

                                // Extended Life Months Add a base amount * the points. 
                                const isExtendedStores = splitEffect[0].indexOf("LIFESUPPORTMONTHS") !== -1;
                                if (isExtendedStores) {
                                    const finalScore = points * pointsGained;
                                    pointsGained = finalScore - points;
                                    points = finalScore;
                                }

                                // Extended Life Support Max: Add a base amount * the points. 
                                const isExtendLifeSupportMax = splitEffect[0].indexOf("LIFESUPPORTMAX") !== -1;
                                if (isExtendLifeSupportMax) {

                                    // special: 1st instance of Hydroponics does not increase life support max
                                    if (theFitting.name === "Hydroponic production") {
                                        const hydroSoFar = fittingsIds.filter((f) => f === "SF27");
                                        if (hydroSoFar.length === 1) {
                                            pointsGained = pointsGained - 1;
                                        }
                                    }

                                    console.log("pointsGained " + pointsGained);

                                    // points = points + pointsGained;
                                    const finalScore = points * pointsGained;
                                    pointsGained = finalScore - points;
                                    points = finalScore;
                                }
                            }

                            // Hydroponics: Makes life support indefinite. 
                            const isHydroponics = splitEffect[0].indexOf("LIFESUPPORTHYDROPONICS") !== -1;
                            if (isHydroponics) {
                                isJustAdd = false;
                                points = 1000;
                            }

                            // handle SYSTEMDRIVE, where gain power based onm hull class.
                            const isSystemDrivePower = splitEffect[0].indexOf("SYSTEMDRIVE_POWER") !== -1;
                            if (isSystemDrivePower) {
                                isJustAdd = false;
                                // System Drive Power: Add 1 point per hull class. 
                                switch (theHull.class) {
                                    case "Fighter": pointsGained = 1; break;
                                    case "Frigate": pointsGained = 2; break;
                                    case "Cruiser": pointsGained = 3; break;
                                    case "Capital": pointsGained = 4; break;
                                    default: break;
                                }
                                points = points + pointsGained;
                            }

                            // handle SYSTEMDRIVE, where gain mass based on hull class.
                            const isSystemDriveMass = splitEffect[0].indexOf("SYSTEMDRIVE_MASS") !== -1;
                            if (isSystemDriveMass) {
                                isJustAdd = false;
                                // System Drive Mass: Add 2 points per hull class. 
                                switch (theHull.class) {
                                    case "Fighter": pointsGained = 2; break;
                                    case "Frigate": pointsGained = 4; break;
                                    case "Cruiser": pointsGained = 6; break;
                                    case "Capital": pointsGained = 8; break;
                                    default: break;
                                }
                                points = points + pointsGained;
                            }

                            // Otherwise, just add the points to the stat.
                            if (isJustAdd) {
                                points = points + pointsGained;
                            }

                            let finalPoints = pointsGained.toString();
                            if (hullAttribute === "lifeSupportMonths") {
                                finalPoints = handleIndefiniteLifeSupport(points);
                            }

                            if (points > 0) {
                                auditMember.push(theFitting.name + " adds " + finalPoints + " " + targetAttributeDesc + ".");
                            } else {
                                auditMember.push(theFitting.name + " reduces " + targetAttributeDesc + " by " + finalPoints + ".");
                            }

                        }
                    }
                }
            })

            this.shipTraits.mods.forEach((mod) => {
                const theMod = this.lookups.shipMods.find((sm) => sm.id === mod.id);
                if (theMod) {
                    if (theMod.effects) {
                        const matchedEffect = theMod.effects.find((ef) => ef.indexOf(effectName + ":") !== -1);
                        if (matchedEffect) {
                            const splitEffect = matchedEffect.split(":");
                            const pointsGained = parseInt(splitEffect[1]);
                            points = points + pointsGained;
                            if (pointsGained > 0) {
                                auditMember.push(theMod.name + " mod adds " + pointsGained + " " + targetAttributeDesc + ".");
                            } else {
                                auditMember.push(theMod.name + " mod reduces " + targetAttributeDesc + " by " + pointsGained + ".");
                            }
                        }
                    }
                }
            })

            let finalPoints = points.toString();
            if (hullAttribute === "lifeSupportMonths") {
                finalPoints = handleIndefiniteLifeSupport(points);
            }
            if (hullAttribute === "speed") {
                finalPoints = handleSpeed(points);
            }

            if (includeTotal) {
                auditMember.push("Final total " + targetAttributeDesc + " is " + finalPoints + ".");
            }

            return points;
        }
        return 0;
    }


    private calculateHitPoints = () => {
        this.hp = this.applyEffects("hp", "HP", "Hit Points", this.audit_hp);
    }

    private calculatePowerAvailable = () => {
        this.powerAvailable = this.applyEffects("power", "POWER", "Power Available", this.audit_powerAvailable);
    }

    private calculateMassAvailable = () => {
        this.massAvailable = this.applyEffects("mass", "MASS", "Mass Available", this.audit_massAvailable);
    }

    private calculateArmorClass = () => {
        this.ac = this.applyEffects("ac", "AC", "Armor Class", this.audit_ac);
    }

    private calculateArmor = () => {
        this.armor = this.applyEffects("armor", "ARMOR", "Armor", this.audit_armor);
    }

    private calculateCrewMin = () => {
        const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
        if (theHull) {
            this.crewMin = theHull.minimumCrew;
            // this.crewMax = theHull.maximumCrew;
        }
    }

    private calculateLifeSupportLoad = () => {
        this.lifeSupportLoad = this.shipTraits.crewData.crewCurrent + this.shipTraits.crewData.passengersCurrent;
    }

    private calculateLifeSupportMax = () => {
        this.lifeSupportMax = this.applyEffects("maximumCrew", "LIFESUPPORTMAX", "Life Support Maximum", this.audit_lifeSupportMax);
    }

    private calculateLifeSupportMonths = () => {
        this.lifeSupportMonths = this.applyEffects("lifeSupportMonths", "LIFESUPPORTMONTHS", "Life Support Months", this.audit_lifeSupportMonths, false);
        this.lifeSupportMonths = this.applyEffects("lifeSupportMonths", "LIFESUPPORTHYDROPONICS", "Life Support Months", this.audit_lifeSupportMonths);
    }

    private calculateCrewTotal = () => {
        this.crewTotal = this.shipTraits.crewData.crewCurrent + this.shipTraits.crewData.crewAutomationSupportBots;
    }

    private calculateSpeed = () => {
        this.speed = this.applyEffects("speed", "SPEED", "Speed", this.audit_speed);
    }

    private calculateFuelLoads = () => {
        this.fuelLoadsMax = this.applyEffects("fuelLoads", "FUELLOADS", "Fuel Loads", this.audit_fuelLoads);
    }

    private calculateHardPointsAvailable = () => {
        this.hardPointsAvailable = this.applyEffects("hardpoints", "HARDPOINTS", "Hard Points", this.audit_hardPointsAvailable);
    }

    private calculateCargoCapacity = () => {

        let numCargoSpaces = 0;
        const cargoSpaces = this.shipTraits.fittings.filter((f) => f.id === "SF10");
        numCargoSpaces = cargoSpaces.length;

        let cargoSpaceMultiplier = 1;
        let hullClass = ""
        const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
        if (theHull) {
            hullClass = theHull.class;

            if (hullClass === "Fighter") { cargoSpaceMultiplier = 2; }
            if (hullClass === "Frigate") { cargoSpaceMultiplier = 20; }
            if (hullClass === "Cruiser") { cargoSpaceMultiplier = 200; }
            if (hullClass === "Capital") { cargoSpaceMultiplier = 2000; }
        }

        const totalCargoCapacity = numCargoSpaces * cargoSpaceMultiplier;
        this.cargoCapacityTons = totalCargoCapacity;
    }

    private calculateCargoTons = () => {

        let hasVehicleTransportFittings = false;
        const vehicleTransportFittings = this.shipTraits.fittings.find((f) => f.id === "SF44");
        if (vehicleTransportFittings) { hasVehicleTransportFittings = true; }

        let tons = 0;
        this.shipTraits.cargo.forEach((c) => {
            if (c.isVehicles && hasVehicleTransportFittings) {
                tons = tons + c.tons / 2;
                this.audit_cargo.push(c.tons / 2 + " tons of " + c.name + " (full tonnage  of " + c.tons + " tons is halved as cargo as is vehicles and ship has Vehicle Transport Fittings)");
            } else {
                tons = tons + c.tons;
                this.audit_cargo.push(c.tons + " tons of " + c.name);
            }
        })
        this.cargoCurrentTons = tons;
    }

    private calculateCargoPrice = () => {
        let price = 0;
        this.shipTraits.cargo.forEach((c) => {
            price = price + (c.tons * c.pricePerTon);
        })

        this.cargoCurrentPrice = price;
    }

    private calculateSmugglersHoldsCapacity = () => {

        let numSmugHold = 0;
        const smugHold = this.shipTraits.fittings.filter((f) => f.id === "SF39");
        numSmugHold = smugHold.length;

        let cargoSpaceMultiplier = 1;
        let hullClass = ""
        const theHull = this.lookups.shipHulls.find((sh) => sh.name === this.shipTraits.hullType);
        if (theHull) {
            hullClass = theHull.class;

            if (hullClass === "Fighter") { cargoSpaceMultiplier = 0.2; }
            if (hullClass === "Frigate") { cargoSpaceMultiplier = 2; }
            if (hullClass === "Cruiser") { cargoSpaceMultiplier = 20; }
            if (hullClass === "Capital") { cargoSpaceMultiplier = 200; }
        }

        const totalSmugllerHoldsCapacity = numSmugHold * cargoSpaceMultiplier;
        this.smugglersHoldsCapacityTons = totalSmugllerHoldsCapacity;
    }

    private getNotes = (fitting: ShipFittingData) => {
        let notesArray: string[] = [];
        fitting.effects?.forEach((ef) => {
            if (ef.indexOf("AMMO") !== -1) {
                const thisWeapon = this.weaponsInstalled_NotGrouped.find((w) => w.id === fitting.id);
                if (thisWeapon) {
                    notesArray.push("Ammo " + thisWeapon.ammunition);
                } else {
                    notesArray.push("Ammo ???");
                }
            } else {
                notesArray.push(ef.replace(":", " "));
            }
        })
        return notesArray.join(", ");
    }

    public getWeaponStats = (weap: WeaponInstalled) => {

        let output: string[] = [];

        const theFitting = this.lookups.shipFittings.find((f) => f.id === weap.id);
        if (theFitting) {

            let desc = theFitting.name + " (";

            let isAutoTargetted = false;
            this.shipTraits.autoTargetingWeapons.forEach((atw) => {
                if (atw.weaponUniqId === weap.uniqid) {
                    isAutoTargetted = true;
                }
            })

            if (isAutoTargetted) {
                desc = desc + "+2/" + formatDamage(theFitting.weaponDamage) + " (auto-targeted)";
            } else {
                desc = desc + "+" + this.shipTraits.crewData.crewGunnerSkill + "/" + formatDamage(theFitting.weaponDamage);
            }
            desc = desc + " ";

            const notes = this.getNotes(theFitting);
            desc = desc + notes;
            desc = desc + ")";

            output.push(desc);

        }
        if (output.length === 0) {
            return "None"
        }
        return output.join("; ");
    }

    public getWeaponStats_OLD = (fitting: FittingInstalled) => {

        const theFitting = this.lookups.shipFittings.find((f) => f.id === fitting.id);
        if (theFitting) {

            const totalWeaponCount = fitting.quantity;

            // let autoWeapons: AutoWeapon[] = [];
            let matchingAutoWeaponCount = 0;
            this.shipTraits.autoTargetingWeapons.forEach((atw) => {
                const thisWeapon = this.shipTraits.fittings.find((f) => f.uniqid === atw.weaponUniqId);
                if (thisWeapon) {
                    if (thisWeapon.id === fitting.id) {
                        matchingAutoWeaponCount = matchingAutoWeaponCount + 1;
                    }
                }
            })

            if (matchingAutoWeaponCount > 0) {
                // Has some or all of weapon with auto-target system
                const untargetedWeaponCount = totalWeaponCount - matchingAutoWeaponCount;

                const notes = this.getNotes(theFitting);

                let desc = " (";

                // desc = desc + matchingAutoWeaponCount + " x +2/" + formatDamage(theFitting.weaponDamage) + notes + " (Auto-targeted)";
                desc = desc + matchingAutoWeaponCount + " x +2/" + formatDamage(theFitting.weaponDamage);

                if (untargetedWeaponCount > 0) {
                    desc = desc + "; ";
                    desc = desc + untargetedWeaponCount + " x +" + this.shipTraits.crewData.crewGunnerSkill + "/" + formatDamage(theFitting.weaponDamage);
                }

                desc = desc + " ";
                desc = desc + notes;

                desc = desc + ")";

                return desc;
            } else {
                // No autotargetted instances of the weapon; all operated by default Gunner skill
                // const toHit = totalWeaponCount + " x Gunner +" + this.shipTraits.crewGunnerSkill;

                const toHit = totalWeaponCount + " x +" + this.shipTraits.crewData.crewGunnerSkill;

                const damage = formatDamage(theFitting.weaponDamage);

                const notes = this.getNotes(theFitting);

                let desc = " (" + toHit + "/" + damage + " " + notes + ")";
                return desc;
            }

        }
        return "";
    }

    public getFittingsList = (type: string) => {
        let output: string[] = [];
        let fittings = [...this.fittingsInstalled.filter((f) => f.fittingType === type)];

        // add the spike drive-1 if not upgraded
        const driveFittingIds = ["SF14", "SF15", "SF16", "SF17", "SF18", "SF41"];
        if (type === "fitting") {

            // handle Spike Drive and/or System Drive first
            const systemDrive = fittings.find((f) => f.id === "SF41");
            const spikeDriveFittingIds = ["SF14", "SF15", "SF16", "SF17", "SF18"];
            const spikeDriveId = fittings.map((f) => f.id).find(value => spikeDriveFittingIds.includes(value));
            const spikeDrive = this.lookups.shipFittings.find((sf) => sf.id === spikeDriveId)

            if (systemDrive === undefined && spikeDrive === undefined) {
                output.push("Spike drive-1")
            } else if (systemDrive === undefined && spikeDrive !== undefined) {
                const spikeDriveLevel = spikeDrive.name[6];
                output.push("Spike drive-" + spikeDriveLevel);
            } if (systemDrive !== undefined && spikeDrive === undefined) {
                output.push("System drive-1");
            } else if (systemDrive !== undefined && spikeDrive !== undefined) {
                const spikeDriveLevel = spikeDrive.name[6];
                output.push("System drive-" + spikeDriveLevel);
            }
        }

        // remove drives which have already been handled. 
        fittings = fittings.filter((f) => driveFittingIds.indexOf(f.id) === -1);

        fittings.sort((f1, f2) => f1.name < f2.name ? -1 : 1);


        fittings.forEach((f) => {
            let desc = "";
            if (f.quantity === 1) {
                desc = desc + (f.name)
            } else {
                if (f.namePlural && f.namePlural !== undefined) {
                    desc = desc + (f.quantity + " " + f.namePlural);
                } else {
                    desc = desc + (f.quantity + " " + f.name);
                }
            }
            if (f.id === "SF7") { // Automation Support fitting
                desc = desc + " with " + this.shipTraits.crewData.crewAutomationSupportBots + " automation support bots";
            }

            if (f.id === "SF10") {
                desc = desc + " (" + this.cargoCapacityTons + " tons)";
            }
            if (f.id === "SF39") {
                desc = desc + " (" + this.smugglersHoldsCapacityTons + " tons)";
            }
            output.push(desc);
        })

        if (output.length === 0) { return "None"; }
        return output.join(", ");
    }

    public getWeaponsList = () => {
        let output: string[] = [];
        let weapons = this.weaponsInstalled_NotGrouped;

        weapons.sort((w1, w2) => w1.name < w2.name ? -1 : 1);

        weapons.forEach((w) => {
            output.push(this.getWeaponStats(w));
        })

        if (output.length === 0) { return "None"; }
        return output.join(", ");
    }

    public getModsList = () => {
        let output: string[] = [];
        let mods = [...this.modsInstalled];

        mods.sort((m1, m2) => m1.name < m2.name ? -1 : 1);

        mods.forEach((m) => {
            let desc = "";
            if (m.quantity === 1) {
                desc = desc + (m.name)
            } else {
                desc = desc + (m.quantity + " " + m.name);
            }
            output.push(desc);
        })
        if (output.length === 0) { return "None"; }
        return output.join(", ");
    }

    public getCargoList = () => {
        let output: string[] = [];
        let cargo = [...this.shipTraits.cargo];
        let total = 0;

        cargo.sort((c1, c2) => c1.name < c2.name ? -1 : 1);

        if (this.shipTraits.currentFuelLoads > 0) {
            let plural = "";
            if (this.shipTraits.currentFuelLoads !== 1) { plural = "s"; }
            output.push(this.shipTraits.currentFuelLoads + " fuel load" + plural + " (" + formatLargeCredits(this.shipTraits.currentFuelLoads * 500) + ")");
            total = total + this.shipTraits.currentFuelLoads * 500;
        }

        cargo.forEach((c) => {
            let plural = "";
            if (c.tons !== 1) { plural = "s"; }
            output.push(c.tons + " ton" + plural + " " + c.name + " (" + formatLargeCredits(c.tons * c.pricePerTon) + ")");
            total = total + c.tons * c.pricePerTon;
        })
        if (output.length === 0) {
            return "None";
        }
        return output.join(", ") + "; " + formatLargeCredits(total) + " total";
    }

    public getAmmoList = () => {
        let output: string[] = [];
        let total = 0;

        interface AmmoQuant {
            weaponId: string;
            name: string;
            ammo: number;
            costPerRound: number;
        }

        let ammoQuants: AmmoQuant[] = [];
        this.weaponsInstalled_NotGrouped.forEach((w) => {
            if (w.ammunitionCost) {
                const thisAQ = ammoQuants.find((aq) => aq.weaponId === w.id);
                if (thisAQ) {
                    thisAQ.ammo = thisAQ.ammo + w.ammunition;
                } else {
                    const thisWeapon = this.lookups.shipFittings.find((fi) => fi.id === w.id);
                    if (thisWeapon) {
                        const costPerRound = thisWeapon.weaponCostPerAmmo === undefined ? 0 : thisWeapon.weaponCostPerAmmo;
                        const newAQ: AmmoQuant = { weaponId: w.id, ammo: w.ammunition, name: w.name, costPerRound };
                        ammoQuants.push(newAQ);
                    }
                }
            }
        })

        // foxer drones
        if (this.shipTraits.foxerDrones.length > 0) {
            this.shipTraits.foxerDrones.forEach((fd) => {
                const newAQ: AmmoQuant = { weaponId: "", ammo: fd.roundsRemaining, name: "Foxer drone", costPerRound: 0 };
                ammoQuants.push(newAQ);
            })
        }

        ammoQuants.forEach((a) => {
            const totalAmmoCostForWeapon = a.ammo * a.costPerRound;
            output.push(a.ammo + " " + a.name + " rounds (" + formatLargeCredits(totalAmmoCostForWeapon) + ")");
            total = total + totalAmmoCostForWeapon;
        })

        ammoQuants.sort((a1, a2) => a1.name < a2.name ? -1 : 1);

        // ammoQuants = ammoQuants.filter((aq) => aq.ammo !== 0);

        if (output.length === 0) {
            return "None";
        }
        return output.join(", ") + "; " + formatLargeCredits(total) + " total";
    }

}