import React, { useState, useContext, useEffect } from 'react';

import app from "../../firebase";

import { AlienFocus, Benefit } from "../../interfaces/AlienFocus";

import { faCaretRight, faEye, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, Redirect, useParams } from 'react-router-dom';

import { AuthContext } from "../../Auth";

import ValidationAlert from '../ValidationAlert';

import { Lookups } from "../../lookups/Lookups";
import DescriptionAlert from '../DescriptionAlert';
import { attributeLongNames } from '../../lookups/Enums';
import { LevelBonus } from '../../interfaces/FocusData';
import { getAlienFocusesFromDB } from '../../utilities/Utilities';

interface RouteParams {
    id: string
}

const AlienFocusDesignerMain: React.FunctionComponent<{}> = () => {

    const { user } = useContext(AuthContext);
    const lookups = Lookups.getInstance();

    const [alienFocusId, setAlienFocusId] = useState("");
    const [loading, setLoading] = useState(false);

    const [species, setSpecies] = useState("");
    const [shortDesc, setShortDesc] = useState("");
    const [longDesc, setLongDesc] = useState("");
    const [flaws, setFlaws] = useState("");

    const initialBenefits: string[] = [];
    const [benefits, setBenefits] = useState(initialBenefits);

    const initialBenefitNotes: any = {};
    const [benefitNotes, setBenefitNotes] = useState(initialBenefitNotes);

    const [selectedOriginSkill, setSelectedOriginSkill] = useState("");
    const [selectedStrongAttributeIndex, setSelectedStrongAttributeIndex] = useState(-1);

    const initialShowDesc: any = {};
    const [showDesc, setShowDesc] = useState(initialShowDesc);

    const [isSaved, setIsSaved] = useState(false);

    const [showValidations, setShowValidations] = useState(false);
    const [isValid, setIsValid] = useState(false);

    // Get the character Id from the URL route (if any). 
    let { id: IdInQuerystring } = useParams<RouteParams>();

    const convertSavedBenefits = (data: Benefit[]) => {

        // console.log(JSON.stringify(data, null, 2));

        if (data) {
            let theBenefits: string[] = [];
            let theBenefitNotes: any = {};

            data.forEach((d) => {

                // console.log(JSON.stringify(d, null, 2));

                theBenefits.push(d.benefit);
                theBenefitNotes[d.benefit] = d.note;

                if (d.originSkill) {
                    setSelectedOriginSkill(d.originSkill);
                }

                if (d.attributeModifierBonusIndex !== undefined) {
                    setSelectedStrongAttributeIndex(d.attributeModifierBonusIndex);
                }

            })

            setBenefits(theBenefits);
            setBenefitNotes(theBenefitNotes);
        }
    }

    useEffect(() => {
        // Create an scoped async function in the hook.
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {
                    if (IdInQuerystring !== undefined && IdInQuerystring !== "") {
                        const db = app.firestore();
                        const docRefDetail = db.collection("alienFoci").doc(IdInQuerystring);
                        docRefDetail.get().then((doc: any) => {
                            if (doc.exists) {
                                const data: AlienFocus = doc.data();
                                if (data) {
                                    setAlienFocusId(IdInQuerystring);
                                    setSpecies(data.alienFocus);
                                    setShortDesc(data.shortDesc);
                                    setLongDesc(data.longDesc);
                                    setFlaws(data.flaws);
                                    convertSavedBenefits(data.benefits);
                                    setLoading(false);
                                }
                            }
                        });
                    } else {
                        setLoading(false);
                    }
                }
            }
            catch (error: any) {
                console.log(error);
                toastr.error(error);
                setLoading(false);
            }

        }
        // Execute the created function directly
        asyncUseEffect();
    }, [user, IdInQuerystring]); // Will load characters after rendering    

    const saveFocus = async () => {

        setShowValidations(true);
        const isValid = doValidation();

        if (isValid && user) {

            let benefitsToSave: Benefit[] = [];
            benefits.forEach((b) => {

                let isMajor = false;
                const theBen = lookups.alienBenefits.find((fb) => fb.id === b);
                if (theBen) {
                    isMajor = theBen.isMajorBenefit;
                }

                const thisBenefit: Benefit = { benefit: b, isMajor };

                let note = "";
                if (benefitNotes[b]) { note = benefitNotes[b]; }
                if (note !== "") { thisBenefit.note = note; }

                let originSkill = "";
                if (b === "originSkill") { originSkill = selectedOriginSkill; }
                if (originSkill !== "") { thisBenefit.originSkill = originSkill; }

                if (b === "strongAttribute") {
                    if (selectedStrongAttributeIndex !== -1) (thisBenefit.attributeModifierBonusIndex = selectedStrongAttributeIndex);
                };

                benefitsToSave.push(thisBenefit);

            })

            let bonusesToSave: LevelBonus[] = [];
            benefits.forEach((b) => {
                if (b === "originSkill") {
                    const thisLevelBonus: LevelBonus = {
                        type: "bonusSkill",
                        skill: selectedOriginSkill
                    }
                    bonusesToSave.push(thisLevelBonus);
                }
                if (b === "strongAttribute") {
                    const thisLevelBonus: LevelBonus = {
                        type: "attributeModifierBonus",
                        availableAttributeIndexes: [selectedStrongAttributeIndex]
                    }
                    bonusesToSave.push(thisLevelBonus);
                }
            })

            let basicData: any = {
                alienFocus: species,
                alienFocus_lower: species.toLowerCase(),
                shortDesc,
                longDesc,
                flaws,
                benefits: benefitsToSave,
                bonuses: bonusesToSave,
                user_id: user.uid,
                user_email: user.email
            };

            if (alienFocusId === "") {
                // Save new alien focus:
                const db = app.firestore();
                await db.collection("alienFoci")
                    .add(basicData)
                    .then((docRef) => {
                        const id = docRef.id;
                        setAlienFocusId(id);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toastr.error(error);
                    });

            } else {
                // Update existing alien focus:
                const db = app.firestore();
                await db.collection("alienFoci")
                    .doc(alienFocusId)
                    .set(basicData)
                    .catch(function (error) {
                        console.log(error);
                        toastr.error(error);
                    });
            }

            // update the foci in lookups:
            const alienFocusesFromDB = await getAlienFocusesFromDB(user, app);
            lookups.addFoci("alienFoci", alienFocusesFromDB);

            setIsSaved(true);
        }

    }

    const getMajorBenefitCount = () => {
        let count = 0;
        benefits.forEach((b) => {
            const theBenefit = lookups.alienBenefits.find((ben) => ben.id === b);
            if (theBenefit) {
                if (theBenefit.isMajorBenefit) { count = count + 1; }
            }
        })
        return count;
    }

    const getMinorBenefitCount = () => {
        let count = 0;
        benefits.forEach((b) => {
            const theBenefit = lookups.alienBenefits.find((ben) => ben.id === b);
            if (theBenefit) {
                if (!theBenefit.isMajorBenefit) { count = count + 1; }
            }
        })
        return count;
    }

    const getTotalBenefits = () => {
        return getMajorBenefitCount() + (getMinorBenefitCount() / 2);
    }

    const applyBenefit = (e: any) => {

        const benefitId = e.target.getAttribute("data-benefitid");

        let updatedBenefits = [...benefits];

        if (updatedBenefits.indexOf(benefitId) !== -1) {
            updatedBenefits = updatedBenefits.filter((b) => b !== benefitId);
            let updatedBenefitNotes = { ...benefitNotes };
            delete updatedBenefits[benefitId];
            setBenefitNotes(updatedBenefitNotes);
        } else {
            updatedBenefits.push(benefitId);
            let updatedBenefitNotes = { ...benefitNotes };
            updatedBenefitNotes[benefitId] = "";
            setBenefitNotes(updatedBenefitNotes);
        }

        updatedBenefits.sort();

        setBenefits(updatedBenefits);
    }

    const applyBenefitNote = (e: any) => {

        const benefitId: string = e.target.getAttribute("data-benefitid");
        const note: string = e.target.value;

        let updatedBenefitNotes = { ...benefitNotes };

        if (benefits.indexOf(benefitId) !== -1) {
            updatedBenefitNotes[benefitId] = note;
        } else {
            updatedBenefitNotes[benefitId] = "";
        }

        setBenefitNotes(updatedBenefitNotes);
    }

    const flipShowDesc = (benefitId: string) => {
        if (benefitId) {
            let updatedShowDesc: any = { ...showDesc };

            if (!updatedShowDesc.hasOwnProperty(benefitId)) {
                updatedShowDesc[benefitId] = true;
            } else {
                updatedShowDesc[benefitId] = !updatedShowDesc[benefitId];
            }
            setShowDesc(updatedShowDesc);
        }
    }

    const getOriginSkillSelect = () => {
        let output: any[] = [];

        output.push(<option key={"selectSkill"} value="">-- select skill --</option>);

        lookups.skills.forEach((s) => {
            output.push(<option key={s.skill}>{s.skill}</option>);
        })

        output.push(<option key={"AnyCombatSkill"} value="AnyCombatSkill">Punch/Shoot/Stab</option>);

        return (
            <div className="mt-1 mb-1">
                <label htmlFor="origSkill" className="">Select origin skill: <select name="origSkill" value={selectedOriginSkill} onChange={(e) => { setSelectedOriginSkill(e.currentTarget.value); doValidation(); }}>{output}</select></label>
                {showValidations && selectedOriginSkill === "" &&
                    <div className="mt-1 mb-1">
                        <ValidationAlert msg="Origin Skill must be selected" />
                    </div>
                }
            </div >
        );
    }

    const getStrongAttributeSelect = () => {
        let output: any[] = [];

        output.push(<option key={"selectAttribute"} value={-1}>-- select attribute --</option>);

        attributeLongNames.forEach((a, index) => {
            output.push(<option key={a} value={index}>{a}</option>);
        })

        return (
            <div className="mt-1 mb-1">
                <label htmlFor="origSkill" className="" >Select attribute: <select name="strongAtt" value={selectedStrongAttributeIndex} onChange={(e) => { setSelectedStrongAttributeIndex(parseInt(e.currentTarget.value, 10)); doValidation(); }}>{output}</select></label >
                {showValidations && selectedStrongAttributeIndex === -1 &&
                    <div className="mt-1 mb-1">
                        <ValidationAlert msg="Strong Attribute must be selected" />
                    </div>
                }
            </div>
        );
    }

    const doValidation = () => {
        let valid = true;
        if (species.trim() === "") { valid = false; }
        if (shortDesc.trim() === "") { valid = false; }
        if (longDesc.trim() === "") { valid = false; }
        if (benefits.indexOf("originSkill") !== -1 && selectedOriginSkill === "") { valid = false; }
        if (benefits.indexOf("strongAttribute") !== -1 && selectedStrongAttributeIndex === -1) { valid = false; }

        setIsValid(valid);

        return valid;
    }

    const getBenefits = () => {
        const output: any = [];

        lookups.alienBenefits.forEach((b) => {

            const hasBenefit = benefits.indexOf(b.id) !== -1;
            const hasNote = lookups.alienBenefits.find((ben) => ben.id === b.id && ben.notePrompt !== "");
            const isOriginSkillBenefit = b.isOriginSkill;
            const isStrongAttributeBenefit = b.isStrongAttribute;

            const shortenTitle = (title: string) => {
                title = title.replace("Natural", "Nat.");
                title = title.replace("Movement", "Move.");
                return title;
            }


            output.push(
                <div className="mb-2" key={b.id}>
                    <div>
                        {/* <FontAwesomeIcon icon={faCaretRight} title=""></FontAwesomeIcon >&nbsp; */}
                        <input className="cbLarge" type="checkbox" checked={benefits.indexOf(b.id) !== -1} id={b.id} data-benefitid={b.id} onChange={applyBenefit} />
                        <label htmlFor={b.id}>
                            <span className="d-none d-md-inline-block">{b.benefitTitle}</span><span className="d-sm-inline-block d-md-none">{shortenTitle(b.benefitTitle)}</span> ({b.isMajorBenefit ? "Major" : "Minor"})
                        </label>
                        <button className="btn btn-outline-info btn-tiny" type="button" title="Show Description" data-benefitid={b.id} onClick={(e) => flipShowDesc(b.id)}><FontAwesomeIcon className="m-0" icon={faEye} title="Show Description"></FontAwesomeIcon></button>
                    </div>
                    {isOriginSkillBenefit && hasBenefit &&
                        <div className="">
                            {getOriginSkillSelect()}
                        </div>
                    }
                    {isStrongAttributeBenefit && hasBenefit &&
                        <div className="">
                            {getStrongAttributeSelect()}
                        </div>
                    }
                    {hasNote && hasBenefit &&
                        <div className="">
                            <input className="form-control" type="text" id={b.id + "_note"} value={benefitNotes[b.id]} data-benefitid={b.id} onChange={applyBenefitNote} placeholder={b.notePrompt} />
                        </div>
                    }
                    <DescriptionAlert display={showDesc[b.id]}>
                        <div>{b.desc}</div>
                    </DescriptionAlert>
                </div>);
        })

        return output;
    }

    return (
        <>
            <h1>Alien Origin Focus Designer</h1>

            <div className="mb-3"></div>

            {!loading &&
                <>
                    {species.trim() !== "" &&
                        <h2>{species}</h2>
                    }

                    <form>
                        {alienFocusId === "" &&
                            <div className="form-group row">

                                <label htmlFor="name" className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 col-form-label">
                                    <FontAwesomeIcon icon={faCaretRight} title=""></FontAwesomeIcon >&nbsp;
                                    Alien Species
                                </label>
                                <div className="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                                    <input type="text" className="form-control" id="name" value={species} onChange={(e) => { setSpecies(e.currentTarget.value); doValidation(); }} />
                                    <div className="form-text small">Species name cannot be changed after saving.</div>
                                    {showValidations && species.trim() === "" &&
                                        <ValidationAlert msg="Alien Species is required" />
                                    }
                                </div>

                            </div>
                        }

                        <div className="form-group row">

                            <label htmlFor="shortDesc" className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 col-form-label">
                                <FontAwesomeIcon icon={faCaretRight} title=""></FontAwesomeIcon >&nbsp;
                                Short Description
                            </label>

                            <div className="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                                <textarea className="form-control" id="shortDesc" value={shortDesc} onChange={(e) => { setShortDesc(e.currentTarget.value); doValidation(); }} rows={2} maxLength={250} />
                                {showValidations && shortDesc.trim() === "" &&
                                    <ValidationAlert msg="Short Description is required" />
                                }
                            </div>

                        </div>

                        <div className="form-group row">

                            <label htmlFor="longDesc" className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 col-form-label">
                                <FontAwesomeIcon icon={faCaretRight} title=""></FontAwesomeIcon >&nbsp;
                                Details
                            </label>

                            <div className="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                                <textarea className="form-control" id="detailedDesc" value={longDesc} onChange={(e) => { setLongDesc(e.currentTarget.value); doValidation(); }} rows={5} maxLength={5000} />
                                {showValidations && longDesc.trim() === "" &&
                                    <ValidationAlert msg="Details are required" />
                                }
                            </div>

                        </div>

                        <div className="form-group row">

                            <label htmlFor="flaws" className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 col-form-label">
                                <FontAwesomeIcon icon={faCaretRight} title=""></FontAwesomeIcon >&nbsp;
                                Flaws/<wbr></wbr>Drawbacks
                            </label>

                            <div className="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
                                <textarea className="form-control" id="flaws" value={flaws} onChange={(e) => setFlaws(e.currentTarget.value)} rows={2} maxLength={250} />
                            </div>

                        </div>

                    </form>

                    <p className="small">See p.209 of <i>Stars Without Number</i> for guidelines on creating Foci to represent members of non-human alien species.</p>

                    <h2>Species Benefits</h2>

                    <div className="benefits">

                        {/* <div>{JSON.stringify(benefits, null, 2)}</div>
                        <div>{JSON.stringify(benefitNotes, null, 2)}</div>
                        <div>{JSON.stringify(showDesc, null, 2)}</div>
                        <div>originSkill: {selectedOriginSkill}</div>
                        <div>strong attribute index: {selectedStrongAttributeIndex}</div> */}

                        {getBenefits()}

                        <div className="mb-3"><b>Major Benefits:</b> {getMajorBenefitCount().toString()}, <b>Minor Benefits:</b> {getMinorBenefitCount().toString()}</div>

                        {getTotalBenefits() > 2 &&
                            <div className="mb-3">
                                <ValidationAlert msg="You should only pick two Major benefits or a Major benefit and two Minor Benefits" />
                            </div>
                        }
                    </div>

                    <div className="mb-2">
                        <Link type="button" className="btn btn-primary mr-1" to="/customAlienFoci">Back to Alien Foci</Link>
                        <button type="button" className="btn btn-primary" onClick={saveFocus} title="Save"><FontAwesomeIcon icon={faSave} title="Save" className="svg"></FontAwesomeIcon > Save</button>
                        <a className="btn btn-primary ml-1" href="https://www.buymeacoffee.com/swoone" target="_blank" rel="noopener noreferrer"><img src="/BuyMeACoffee.svg" height="20px" width="20px" alt="Buy Me A Coffee" title="Buy Me A Coffee" /></a>
                    </div>

                    {showValidations && !isValid &&
                        <div className="mb-3">
                            <ValidationAlert msg="Please correct the validation errors before saving" />
                        </div>
                    }

                </>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

            {isSaved &&
                <Redirect to="/customAlienFoci" />
            }

        </>
    );
}

export default AlienFocusDesignerMain; 