import React from "react";
import { faCheck, faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { attributeAbr, attributeLongNames, defaultInitialAttributeScoreArray } from "../../lookups/Enums";

import { CharacterTraits } from "../../interfaces/CharacterTraits";

import CharacterDesignStep1Name from "../../components/CharacterDesignStep1Name";
import CharacterDesignStep2Attributes from "../../components/CharacterDesignStep2Attributes";
import CharacterDesignStep3Background from "../../components/CharacterDesignStep3Background";
import CharacterDesignStep4Class from "../../components/CharacterDesignStep4Class";
import CharacterDesignStep5FreeFocusAndSkill from "../../components/CharacterDesignStep5FreeFocusAndSkill";
import CharacterDesignStep6Psychic from "../../components/CharacterDesignStep6Psychic";
import CharacterDesignStep7Gear from "./../CharacterDesignStep7Gear";
import CharacterDesignStep8HitPoints from "../CharacterDesignStep8HitPoints";
import CharacterDesignStep9Leveling from "../CharacterDesignStep9Leveling";
import CharacterDesignStep10Stats from "./../CharacterDesignStep10Stats";
import CharacterDesignStep11Notes from "./../CharacterDesignStep11Notes";

import {
    onSetName, onSetGoal, onSetIsPublic, onSelectAttributeAssignMethod, onSetIncludeNonstandardMethods, onSelectAttribute, onSetAttributeTo14,
    onSelectBackgroundMethod, onSelectBackground, onSelectQuickSkills, onSelectBackgroundRollTable, onSetHomeworld, onSetLanguages,
    onSelectClass, onSetFocusLevel, onSelectSkillLevelPick, onSelectAttributeScoreBonusPick, onSelectAttributeModifierBonusPick, onSetPsychicTechnique,
    checkAndValidateCharacterTraits,
    onSelectWildTalentPsychicDiscipline,
    onSelectWildTalentTechnique,
    onSetHitPoints,
    onSetStartingCredits, onBuyGear, onSetItemStorage, onSetGearMethodToPack, onSelectEquipmentPack, onSetNotes, onSetAdditionalCredits, onRerollAttributes, onResetScores,
    onLevelUp, onLevelDown, onSetLevelHitPoints, onSelectSkillPointSpendType, onSelectSkillToImprove, onSelectPointTypeToSpend, onSelectPointsSpent, onSelectAttributeToImprove, onSetPsychicTechniqueWhenLevelUp, onSelectSkillToBuyTechniqueFrom, onSelectTechniqueToBuy, addVehicleBodyToEquipment, onSetUniqueGift,
    onAddMod,
    onSetCustomName, onSetCustomNotes
} from "../../utilities/charMainUtilities";

import { Lookups } from "../../lookups/Lookups";
import ValidationAlert from "../ValidationAlert";

interface RouteParams {
    id: string
}

interface IProps {
    characterTraits: CharacterTraits;
    setCharacterTraits: (charTraits: CharacterTraits) => void;
}

const CharacterDesignMain: React.FunctionComponent<IProps> = (props: IProps) => {

    const characterTraits = props.characterTraits;

    const lookups = Lookups.getInstance();

    // Updating character traits in state: 

    const onSetCharacterTraits = (charTraits: CharacterTraits) => {
        const updatedTraits = { ...charTraits };
        checkAndValidateCharacterTraits(updatedTraits)
        props.setCharacterTraits(updatedTraits);
    }

    const onSwitchCollapsed = (sectionName: string, charTraits: CharacterTraits) => {
        const updatedTraits = { ...charTraits };
        if (updatedTraits.basicTraits.collapsedSections.indexOf(sectionName) === -1) {
            updatedTraits.basicTraits.collapsedSections.push(sectionName)
        } else {
            updatedTraits.basicTraits.collapsedSections = updatedTraits.basicTraits.collapsedSections.filter((c) => c !== sectionName);
        }
        props.setCharacterTraits(updatedTraits);
    }

    const onSelectVehicleBody = (vehicleBody: string, charTraits: CharacterTraits) => {
        const updatedTraits = { ...charTraits };
        updatedTraits.levelOne.vehicleBody = vehicleBody;
        props.setCharacterTraits(updatedTraits);

        addVehicleBodyToEquipment(vehicleBody, charTraits);
    }

    const openAllSections = () => {
        const updatedTraits = { ...props.characterTraits };
        updatedTraits.basicTraits.collapsedSections = [];
        props.setCharacterTraits(updatedTraits);
    }

    const closeAllSections = () => {
        const updatedTraits = { ...props.characterTraits };
        updatedTraits.basicTraits.collapsedSections = ["characterName", "attributes", "background", "class", "freeFocusAndSkill", "psychicDisciplines", "equipment", "hitPoints", "otherStats", "notes"];
        for (let l = 2; l <= props.characterTraits.level; l++) {
            updatedTraits.basicTraits.collapsedSections.push("level" + l);
        }
        if (updatedTraits.level === 1) { updatedTraits.basicTraits.collapsedSections.push("leveling"); }
        props.setCharacterTraits(updatedTraits);
    }

    const showValidationMessages = () => {
        if (!props.characterTraits.showValidation) {
            const updatedTraits = { ...props.characterTraits };
            updatedTraits.showValidation = true;
            updatedTraits.basicTraits.collapsedSections = [];
            props.setCharacterTraits(updatedTraits);
        }
        window.scrollTo(0, 0);
    }

    const onManageCustomSkills = () => {
        alert("Manage custom skills");
    }

    return (
        <>

            <div className="mb-3">
                <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => closeAllSections()} >
                    <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="mr-1"></FontAwesomeIcon >
                    Close All
                </button>
                <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => openAllSections()} >
                    <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="mr-1"></FontAwesomeIcon >
                    Open All
                </button>
            </div>

            {props.characterTraits.showValidation && !props.characterTraits.isValid &&
                <div className="mb-2">
                    <ValidationAlert msg="Character has validation errors." />
                </div>
            }

            {/* <p className="small">Click buttons with an eye icon <FontAwesomeIcon icon={faEye} title="eye" className="text-info"></FontAwesomeIcon > to show/hide more details.</p> */}

            <CharacterDesignStep1Name
                charTraits={characterTraits}
                onSetName={(name: string) => onSetName(name, characterTraits, onSetCharacterTraits)}
                onSetGoal={(goal: string) => onSetGoal(goal, characterTraits, onSetCharacterTraits)}
                onSetIsPublic={(isPublic: boolean) => onSetIsPublic(isPublic, characterTraits, onSetCharacterTraits)}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
            />

            <CharacterDesignStep2Attributes
                attributeLongNames={attributeLongNames}
                attributeAbbrev={attributeAbr}
                defaultAttributeArray={defaultInitialAttributeScoreArray}
                randomAttributeScores={props.characterTraits.attributeTraits.randomScores}
                charTraits={characterTraits}
                onSelectAttributeAssignMethod={(method: string) => onSelectAttributeAssignMethod(method, characterTraits, onSetCharacterTraits)}
                onSetIncludeNonstandardMethods={(include: boolean) => onSetIncludeNonstandardMethods(include, characterTraits, onSetCharacterTraits)}
                onSelectAttribute={(attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean) => onSelectAttribute(attributeNumber, attributeValue, alsoSetAsOriginalScore, characterTraits, onSetCharacterTraits)}
                onSetAttributeTo14={(attributeNumber: number, attributeValue: number) => onSetAttributeTo14(attributeNumber, attributeValue, characterTraits, onSetCharacterTraits)}
                onRerollAttributes={() => onRerollAttributes(characterTraits, onSetCharacterTraits)}
                onResetScores={() => onResetScores(characterTraits, onSetCharacterTraits)}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
            />

            <CharacterDesignStep3Background
                charTraits={characterTraits}
                onSelectBackgroundMethod={(method: string) => onSelectBackgroundMethod(method, characterTraits, onSetCharacterTraits)}
                onSelectBackground={(background: string) => onSelectBackground(background, characterTraits, onSetCharacterTraits)}
                onSelectQuickSkills={() => onSelectQuickSkills(characterTraits, onSetCharacterTraits)}
                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly, characterTraits, onSetCharacterTraits)}
                onSelectBackgroundRollTable={(table: string, rollNum: number) => onSelectBackgroundRollTable(table, rollNum, characterTraits, onSetCharacterTraits)}
                onSelectAttributeScoreBonusPick={(attributeIndex: number, attributeScoreBonus: number, selected: boolean, traitToUpdate: string, singleAttributeOnly: boolean) => onSelectAttributeScoreBonusPick(attributeIndex, attributeScoreBonus, selected, traitToUpdate, singleAttributeOnly, characterTraits, onSetCharacterTraits)}
                onSetHomeworld={(homeworld: string) => onSetHomeworld(homeworld, characterTraits, onSetCharacterTraits)}
                onSetLanguages={(languages: string) => onSetLanguages(languages, characterTraits, onSetCharacterTraits)}
                onManageCustomSkills={() => onManageCustomSkills()}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
            />

            <CharacterDesignStep4Class
                charTraits={characterTraits}
                onSelectClass={(selectedClasses: string[]) => onSelectClass(selectedClasses, characterTraits, onSetCharacterTraits)}
                onSelectFocusLevel={(focusName: string, focusLevel: number, traitToUpdate: string) => onSetFocusLevel(focusName, focusLevel, traitToUpdate, lookups.focuses, characterTraits, onSetCharacterTraits)}
                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly, characterTraits, onSetCharacterTraits)}
                onSelectAttributeModifierBonusPick={(attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => onSelectAttributeModifierBonusPick(attributeIndex, attributeModifierBonus, selected, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onManageCustomSkills={() => onManageCustomSkills()}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
                onSelectVehicleBody={(vehicleBody: string) => onSelectVehicleBody(vehicleBody, characterTraits)}
                onSetUniqueGift={(focusLevel: number, text: string) => onSetUniqueGift(focusLevel, text, characterTraits, onSetCharacterTraits)}
            />

            <CharacterDesignStep5FreeFocusAndSkill
                charTraits={characterTraits}
                onSelectFocusLevel={(focusName: string, focusLevel: number, traitToUpdate: string) => onSetFocusLevel(focusName, focusLevel, traitToUpdate, lookups.focuses, characterTraits, onSetCharacterTraits)}
                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly, characterTraits, onSetCharacterTraits)}
                onSelectAttributeModifierBonusPick={(attributeIndex: number, attributeModifierBonus: number, selected: boolean, traitToUpdate: string) => onSelectAttributeModifierBonusPick(attributeIndex, attributeModifierBonus, selected, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onManageCustomSkills={() => onManageCustomSkills()}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
                onSelectVehicleBody={(vehicleBody: string) => onSelectVehicleBody(vehicleBody, characterTraits)}
                onSetUniqueGift={(focusLevel: number, text: string) => onSetUniqueGift(focusLevel, text, characterTraits, onSetCharacterTraits)}
            />

            <CharacterDesignStep6Psychic
                charTraits={characterTraits}
                onSetPsychicTechnique={(skill: string, techniqueName: string, level: number, note: string, traitToUpdate: string) => onSetPsychicTechnique(skill, techniqueName, level, note, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onSelectWildTalentPsychicDiscipline={(skill: string, traitToUpdate: string) => onSelectWildTalentPsychicDiscipline(skill, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onSelectWildTalentTechnique={(technique: string, focusLevel: number, traitToUpdate: string) => onSelectWildTalentTechnique(technique, focusLevel, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
            />

            <CharacterDesignStep7Gear
                charTraits={characterTraits}
                onSetStartingCredits={(credits: number) => onSetStartingCredits(credits, characterTraits, onSetCharacterTraits)}
                onSetGearMethodToPack={() => onSetGearMethodToPack(characterTraits, onSetCharacterTraits)}
                onSelectEquipmentPack={(pack: string) => onSelectEquipmentPack(pack, characterTraits, onSetCharacterTraits)}
                onBuyGear={(uniqid: string, id: string, name: string, price: number, buy: boolean, storage: string) => onBuyGear(uniqid, id, name, price, buy, storage, characterTraits, onSetCharacterTraits)}
                onSetItemStorage={(uniqid: string, existingStorage: string, newStorage: string) => onSetItemStorage(uniqid, existingStorage, newStorage, characterTraits, onSetCharacterTraits)}
                onSetAdditionalCredits={(additionalCredits: number) => onSetAdditionalCredits(additionalCredits, characterTraits, onSetCharacterTraits)}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
                onAddMod={(itemUniqueId: string, modId: string, isAddMod: boolean) => onAddMod(itemUniqueId, modId, isAddMod, characterTraits, onSetCharacterTraits)}
                onSetCustomName={(itemUniqueId: string, customName: string) => onSetCustomName(itemUniqueId, customName, characterTraits, onSetCharacterTraits)}            
                onSetCustomNotes={(itemUniqueId: string, customNotes: string) => onSetCustomNotes(itemUniqueId, customNotes, characterTraits, onSetCharacterTraits)}            
           />

            <CharacterDesignStep8HitPoints
                charTraits={characterTraits}
                onSetHitPoints={(rolledHP: number, level: number) => onSetHitPoints(rolledHP, level, characterTraits, onSetCharacterTraits)}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
            />

            <CharacterDesignStep9Leveling
                charTraits={characterTraits}
                onLevelUp={() => onLevelUp(characterTraits, onSetCharacterTraits)}
                onLevelDown={() => onLevelDown(characterTraits, onSetCharacterTraits)}
                onSetLevelHitPoints={(level: number, hitPointRolls: number[]) => onSetLevelHitPoints(level, hitPointRolls, characterTraits, onSetCharacterTraits)}
                onSelectSkillPointSpendType={(level: number, index: number, spendType: string) => onSelectSkillPointSpendType(level, index, spendType, characterTraits, onSetCharacterTraits)}
                onSelectSkillToImprove={(level: number, index: number, skillName: string) => onSelectSkillToImprove(level, index, skillName, characterTraits, onSetCharacterTraits)}
                onSelectAttributeToImprove={(level: number, index: number, attributeName: string) => onSelectAttributeToImprove(level, index, attributeName, characterTraits, onSetCharacterTraits)}
                onSelectPointTypeToSpend={(level: number, index: number, pointType: string) => onSelectPointTypeToSpend(level, index, pointType, characterTraits, onSetCharacterTraits)}
                onSelectPointsSpent={(level: number, index: number, pointsSpent: number) => onSelectPointsSpent(level, index, pointsSpent, characterTraits, onSetCharacterTraits)}
                onSelectFocusLevel={(focusName: string, focusLevel: number, traitToUpdate: string) => onSetFocusLevel(focusName, focusLevel, traitToUpdate, lookups.focuses, characterTraits, onSetCharacterTraits)}
                onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly, characterTraits, onSetCharacterTraits)}
                onSetPsychicTechniqueWhenLevelUp={(skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => onSetPsychicTechniqueWhenLevelUp(skill, techniqueName, techniqueLevel, characterLevel, skillLevelGained, skillPickNumber, techniquePickNumber, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onSelectWildTalentPsychicDiscipline={(skill: string, traitToUpdate: string) => onSelectWildTalentPsychicDiscipline(skill, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onSelectWildTalentTechnique={(technique: string, focusLevel: number, traitToUpdate: string) => onSelectWildTalentTechnique(technique, focusLevel, traitToUpdate, characterTraits, onSetCharacterTraits)}
                onSelectSkillToBuyTechniqueFrom={(level: number, index: number, skillName: string) => onSelectSkillToBuyTechniqueFrom(level, index, skillName, characterTraits, onSetCharacterTraits)}
                onSelectTechniqueToBuy={(level: number, skillPickNumber: number, technique: string) => onSelectTechniqueToBuy(level, skillPickNumber, technique, characterTraits, onSetCharacterTraits)}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
                onSetUniqueGift={(focusLevel: number, text: string) => onSetUniqueGift(focusLevel, text, characterTraits, onSetCharacterTraits)}
            />

            <CharacterDesignStep10Stats
                charTraits={characterTraits}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
            />

            <CharacterDesignStep11Notes
                charTraits={characterTraits}
                onSetNotes={(notes: string) => onSetNotes(notes, characterTraits, onSetCharacterTraits)}
                onSwitchCollapsed={(sectionName: string) => onSwitchCollapsed(sectionName, characterTraits)}
            />

            <div className="chargenSection">

                <h2>Validation</h2>

                {!characterTraits.isValid &&
                    <div className="align-middle pt-1" style={{ display: "inline-block" }}>
                        <button className="btn btn-danger btn-sm" onClick={showValidationMessages}>View Errors</button>&nbsp;
                        Your character has validation errors.
                        {/* <div>{characterTraits.validationErrors.join("; ")}</div> */}
                    </div>
                }

                {characterTraits.isValid &&
                    <div className="align-middle pt-1" style={{ display: "inline-block" }}>
                        <FontAwesomeIcon icon={faCheck} title="thumbs up" className="text-info pr-1" size="2x"></FontAwesomeIcon >
                        Your character is valid.
                    </div>
                }

            </div>

            <div className="mb-3">
                <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => closeAllSections()} >
                    <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="mr-1"></FontAwesomeIcon >
                    Close All
                </button>
                <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => openAllSections()} >
                    <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="mr-1"></FontAwesomeIcon >
                    Open All
                </button>
            </div>

            {/* <div><pre>{JSON.stringify(characterTraits.levelOne.psychicTechniquePicks, null, 2)}</pre></div> */}



        </>
    );
}

export default CharacterDesignMain;

