import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import app from "../firebase";

import { AuthContext } from "../Auth";

import * as toastr from "toastr";

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfirmModal from "../components/ConfirmModal";

const Characters: React.FunctionComponent<unknown> = (props: any) => {

    const { t } = useTranslation();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [characters, setCharacters] = useState<Array<any>>([]);
    const [displayConfirmDelete, setDisplayConfirmDelete] = useState(false);
    const [characterIdToDelete, setCharacterIdToDelete] = useState("");
    const [characterNameToDelete, setCharacterNameToDelete] = useState("");
    const [reloadCharacters, setReloadCharacters] = useState(0);

    useEffect(() => {
        // Create an scoped async function in the hook.
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {
                    const db = app.firestore();
                    const data = await db.collection("character")
                        .where("user_id", "==", user.uid)
                        .orderBy("name_lower", "asc")
                        .get();
                    const characters: any[] = [];
                    data.forEach((doc) => {
                        characters.push({ ...doc.data(), id: doc.id });
                    });
                    setCharacters(characters);
                    setLoading(false);
                }
            }
            catch (error: any) {
                toastr.error(error);
                setLoading(false);
            }
        }
        // Execute the created function directly
        asyncUseEffect();
    }, [user, reloadCharacters]); // Will load characters after rendering 

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const onDelete = (characterId: string, characterName: string): void => {
        setCharacterIdToDelete(characterId);
        setCharacterNameToDelete(characterName);

        setDisplayConfirmDelete(true);
    }

    const getClassName = (className: string) => {
        let cn = className.replace("Adventurer", "");
        cn = cn.replace("(", "");
        cn = cn.replace(")", "");
        return cn.trim();
    }

    const getShortClassName = (className: string) => {
        let cn = className.replace("Adventurer", "");
        cn = cn.replace("(", "");
        cn = cn.replace(")", "");
        cn = cn.replace("Expert", "Exp");
        cn = cn.replace("Warrior", "War");
        cn = cn.replace("Psychic", "Psy");
        return cn.trim();
    }

    const displayLevel = (rawLevel: any) => {
        if (!rawLevel) {
            return "1"
        } else {
            return rawLevel;
        }
    }

    const characterRows = characters.map((c) =>
        <tr key={c.id}>
            <td style={{width: "30%"}} className="" ><Link to={"/characterDesigner/" + c.id}>{isBlank(c.name, "(No name)")}</Link><span className="d-inline-block d-md-none">&nbsp;({isBlank(getShortClassName(c.className), "n/a")} {displayLevel(c.level)})</span></td>
            <td style={{width: "20%"}} className="d-none d-md-table-cell">{isBlank(getClassName(c.className), "n/a")} {displayLevel(c.level)}</td>
            <td style={{width: "20%"}} className="d-none d-md-table-cell">{isBlank(c.origin, "Human")}</td>
            <td style={{width: "20%"}} className="d-none d-md-table-cell">{isBlank(c.background, "n/a")}</td>
            <td style={{width: "10%"}} className="text-center">
                <button className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); onDelete(c.id, c.name) }}><FontAwesomeIcon icon={faTrash} title={t("characters.delete")}></FontAwesomeIcon ></button>
            </td>
        </tr>
    )

    const deleteCharacter = async () => {
        setDisplayConfirmDelete(false);

        const db = app.firestore();
        await db.collection("character").doc(characterIdToDelete).delete();
        await db.collection("characterTraits").doc(characterIdToDelete).delete();

        setReloadCharacters(reloadCharacters + 1);
    }

    return (
        <div className="pageBody">

            <ConfirmModal
                displayModal={displayConfirmDelete}
                title="Confirm Delete Character"
                message={"Are sure you want to delete " + characterNameToDelete + "?"}
                confirmButtonText="Delete"
                closeButtonText="Cancel"
                onClose={() => setDisplayConfirmDelete(false)}
                onConfirm={() => deleteCharacter()}
            />

            <h1 className="pageHeading">{t("characters.characters")}</h1>

            <div className="mt-2 mb-2"><Link className="btn btn-primary" to="/characterDesigner">Design a Character</Link></div>

            {!loading && characters.length > 0 &&

                <table className="table table-striped table-sm w-auto">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col" style={{width: "30%"}} className="">Name</th>
                            <th scope="col" style={{width: "20%"}} className="d-none d-md-table-cell">Class & Level</th>
                            <th scope="col" style={{width: "20%"}} className="d-none d-md-table-cell">Origin</th>
                            <th scope="col" style={{width: "20%"}} className="d-none d-md-table-cell">Background</th>
                            <th scope="col" style={{width: "10%"}} className="text-center">Delete</th>
                        </tr> 
                    </thead>
                    <tbody>
                        {characterRows}
                    </tbody>
                </table>
            }

            {!loading && characters.length === 0 &&
                <p>{t("characters.currentlyNoCharacters")}</p>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}

export default Characters;
