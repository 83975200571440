import React from "react";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BackgroundData } from "../../interfaces/BackgroundData";
import { AttributeScoreBonus, CharacterTraits, TableRoll } from "../../interfaces/CharacterTraits";
import { SkillData } from "../../interfaces/SkillData";

import { convertAnySkillToSkillListDesc, convertSkillToFriendlyName, displayBGTable } from "../../utilities/SkillUtilities";

import AttributePickControls from "./AttributePickControls";
import SkillPickControls from "./SkillPickControls";

import { Lookups } from "../../lookups/Lookups";

import { CharacterDerivedStats, CreationStep } from "../../classes/CharacterDerivedStats";
import ValidationAlert from "../ValidationAlert";

interface IProps {
    charTraits: CharacterTraits;
    selectedBackground: BackgroundData;
    onSelectBackgroundRollTable: (table: string, rollNum: number) => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onSelectAttributeScoreBonusPick: (attributeIndex: number, attributeScoreBonus: number, selected: boolean, traitToUpdate: string, singleAttributeOnly: boolean) => void;
    onManageCustomSkills: () => void;
}

const BackgroundRoll: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;
    const lookups = Lookups.getInstance();

    const getRowControl = (tableRoll: TableRoll, rollNum: number) => {
        const controls: any[] = [];

        if (tableRoll.table === "") {
            controls.push(<div key={"rollRow" + rollNum}>Roll on <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectBackgroundRollTable("growth", rollNum)}>Growth</button> or <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectBackgroundRollTable("learning", rollNum)}>Learning</button> table?</div>);
        }

        if (tableRoll.table === "growth" || tableRoll.table === "learning") {

            const tableName = tableRoll.table === "growth" ? "Growth" : "Learning";
            controls.push(<div key={"rollRow" + rollNum}>{tableName} Table roll was {tableRoll.roll}: <b>{convertSkillToFriendlyName(tableRoll.result, null)}</b></div>);

            if (tableRoll.result.indexOf("+") === -1) {
                // Rolled a skill level bonus
                addSkillLevelControl(controls, rollNum, tableRoll);
            } else {
                // Rolled an attribute increase bonus
                addAttributeLevelControl(controls, rollNum, tableRoll);
            }

        }

        return controls;
    }

    const addSkillLevelControl = (controls: any[], rollNum: number, tableRoll: TableRoll) => {

        const getDerivedSkillsAtThisTableStep = (rollNum: number) => {
            const charDerivedStatsAtThisTableStep = new CharacterDerivedStats(props.charTraits);
            charDerivedStatsAtThisTableStep.calculateSkillLevels(CreationStep.Background, -1, rollNum);
            return charDerivedStatsAtThisTableStep.skillLevels;
        }

        const getHasReachedSkillLimit = (skillName: string) => {
            // Get derived skills at previous step:
            if (rollNum > 0) {
                const charDerivedStatsAtPreviousTableStep = new CharacterDerivedStats(props.charTraits);
                charDerivedStatsAtPreviousTableStep.calculateSkillLevels(CreationStep.Background, -1, rollNum - 1);
                const theDerivedSkill = charDerivedStatsAtPreviousTableStep.skillLevels.find((s) => s.skill === skillName);
                if (theDerivedSkill && theDerivedSkill.level && theDerivedSkill.level > 1) {
                    return true;
                }
            }
            return false;
        }

        const getSkillNamesToChooseFrom = (resultFromTable: string, skillsData: SkillData[]) => {
            // Check if skill is already at limit.
            const theSkill = skillsData.find((s) => s.skill === resultFromTable);
            if (theSkill) {
                if (!getHasReachedSkillLimit(theSkill.skill)) {
                    return [resultFromTable];
                } else {
                    let allSkills = convertAnySkillToSkillListDesc("AnyNonPsychicSkill", skillsData, true).split(", ")
                    allSkills = allSkills.filter((s) => s !== resultFromTable);
                    return allSkills;
                }
            } else {
                return convertAnySkillToSkillListDesc(resultFromTable, skillsData, true).split(", ")
            }
        }

        let lockedSkillName = "";
        const theSkill = lookups.skills.find((s) => s.skill === tableRoll.result);
        if (theSkill) {
            lockedSkillName = tableRoll.result;
        }

        controls.push(
            <div key={"slp" + rollNum}>
                {getHasReachedSkillLimit(tableRoll.result) &&
                    <div className="mb-2">You have already reached the maximum of {tableRoll.result} level-1. Pick an alternative skill level: </div>
                }
                <SkillPickControls
                    lockedSkillName={lockedSkillName}
                    maxLevel={1}
                    singleSkillOnly={true}
                    skillNamesToChooseFrom={getSkillNamesToChooseFrom(tableRoll.result, lookups.skills)}
                    skillLevelsAlreadyPicked={tableRoll.skillLevelPicks}
                    skillPointsAlreadyPicked={[]}
                    traitToUpdate={"background.tableRolls.skillLevelPicks/" + rollNum}
                    charTraits={char}
                    charDerivedSkills={getDerivedSkillsAtThisTableStep(rollNum)}
                    isLevelFocus={false}
                    onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                    onManageCustomSkills={props.onManageCustomSkills}
                />
                {char.background.validationCodes.indexOf("notPickedRolledBackgroundSkill-" + rollNum) !== -1 &&
                    <ValidationAlert msg={"Select one level of a skill (you have selected " + tableRoll.skillLevelPicks.length + ")"} />
                }
            </div>);
    }

    const addAttributeLevelControl = (controls: any[], rollNum: number, tableRoll: TableRoll) => {
        const bonusSize = parseInt(tableRoll.result.substring(0, 2).replace("+", ""));
        const plural = bonusSize === 1 ? "" : "s";

        const reducer = (total: number, abs: AttributeScoreBonus) => { return total + abs.bonus };

        controls.push(
            <div key={"apc_" + rollNum}>
                <AttributePickControls
                    charTraits={char}
                    attributeBonus={tableRoll.result}
                    rollNum={rollNum}
                    traitToUpdate={"background.tableRolls.attributeScoreBonuses/" + rollNum}
                    onSelectAttributeScoreBonusPick={(attributeIndex: number, attributeScoreBonus: number, selected: boolean, traitToUpdate: string, singleAttributeOnly: boolean) => props.onSelectAttributeScoreBonusPick(attributeIndex, attributeScoreBonus, selected, traitToUpdate, singleAttributeOnly)}
                />
                {char.background.validationCodes.indexOf("notPickedRolledBackgroundAttribute-" + rollNum) !== -1 &&
                    <ValidationAlert msg={"Select " + bonusSize + " point" + plural + " to add to your attribute scores (you have selected " + tableRoll.attributeScoreBonuses.reduce(reducer, 0) + ")"} />
                }
            </div>
        );
    }

    const getRollControls = () => {
        const controls: any[] = [];

        for (let rollNum = 0; rollNum < 3; rollNum++) {
            const thisRoll =
                <div className="mb-2 mt-2 pb-1 border-bottom" key={"roll" + rollNum}>
                    <h6>Roll {rollNum + 1}</h6>
                    {getRowControl(char.background.tableRolls[rollNum], rollNum)}
                </div>;
            controls.push(thisRoll);
        }

        return controls;
    }

    const rollControls = getRollControls();

    const charDerivedStatsAtBackground = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtBackground.calculateSkillLevels(CreationStep.Background);

    return (
        <div>
            <div className="mb-2">
                <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                You get the background's Free Skill, and may roll up to three times on the background's Growth or Learning tables:
            </div>
            <div className="border p-2">
                <div className="mb-2"><span className="title">Free Skill:</span> {convertSkillToFriendlyName(props.selectedBackground.freeSkill, null)}</div>
                <div className="mb-2"><span className="title">Growth Table (1d6):</span> {displayBGTable(props.selectedBackground.growthTable)}</div>
                <div><span className="title">Learning Table (1d8):</span> {displayBGTable(props.selectedBackground.learningTable)}</div>
            </div>
            <div className="mb-2 mt-2 pb-1 border-bottom">
                <h6>Free Skill</h6>
                <SkillPickControls
                    lockedSkillName={props.selectedBackground.freeSkill}
                    maxLevel={1}
                    singleSkillOnly={true}
                    skillNamesToChooseFrom={[props.selectedBackground.freeSkill]}
                    skillLevelsAlreadyPicked={char.background.backgroundSkillLevelPicks}
                    skillPointsAlreadyPicked={[]}
                    traitToUpdate={"background.backgroundSkillLevelPicks"}
                    charTraits={char}
                    charDerivedSkills={charDerivedStatsAtBackground.skillLevels}
                    isLevelFocus={false}
                    onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                    onManageCustomSkills={props.onManageCustomSkills}
                />
                {char.background.validationCodes.indexOf("notPickedFreeBackgroundSkill") !== -1 &&
                    <ValidationAlert msg={"Select one level of a skill (you have selected " + char.background.backgroundSkillLevelPicks.length + ")"} />
                }
            </div>
            <>{rollControls}</>
            {char.background.validationCodes.indexOf("scoreOver18") !== -1 &&
                <ValidationAlert msg={"No attribute score can be over 18"} />
            }
        </div>
    )
}

export default BackgroundRoll;