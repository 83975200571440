import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import { AuthContext } from "../Auth";

import HomeIntro from "../components/HomeIntro";
import HomeFooter from "../components/HomeFooter";
import HomeNote from "../components/HomeNote";

const Home: React.FunctionComponent<unknown> = () => {

    // const { t } = useTranslation();

    const { user: currentUser } = useContext(AuthContext);

    if (!currentUser) {
        return <Redirect to={"/"} />
    }

    if (currentUser && !currentUser.emailVerified) {
        return <Redirect to={"/emailRequiresVerification?email=" + currentUser.email} />
    }

    return (
        <div className="pageBody heroShot">

            <HomeIntro />

            <p><Link className="btn btn-primary btn-lg tryDesigner" to="/characters">Characters</Link></p>

            <p><Link className="btn btn-primary btn-lg tryDesigner" to="/starships">Starships (Beta)</Link></p>

            <p><Link className="btn btn-primary btn-lg tryDesigner" to="/custom">Customisation</Link></p>

            <p><a className="btn btn-primary btn-lg tryDesigner" href="https://www.buymeacoffee.com/swoone" target="_blank" rel="noopener noreferrer"><img src="/BuyMeACoffee.svg" height="20px" width="20px" alt="" /> Buy Me A Coffee</a></p>

            <HomeNote />

            <HomeFooter />

        </div>
    );
}

export default Home;
