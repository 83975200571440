import React from "react";

interface IProps {
    display: boolean;
    children: any; 
}

const DescriptionAlert: React.FunctionComponent<IProps> = (props: IProps) => {
    if (props.display) {
        return <div className="mt-1 mb-0 alert alert-info">{props.children}</div>;
    }
    return null;
}

export default DescriptionAlert;
