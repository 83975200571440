import React from "react";
// import { useTranslation } from "react-i18next";
import $ from "jquery";

interface IProps {
    displayModal: boolean;
    title: string;
    message: string
    confirmButtonText: string;
    closeButtonText: string;
    onConfirm: () => void;
    onClose: () => void;
}

const ConfirmModal: React.FunctionComponent<IProps> = (props: IProps) => {

    if (props.displayModal) {
        ($('#myModal') as any).modal('show');
    }

    if (!props.displayModal) {
        ($('#myModal') as any).modal('hide');
    }

    const onCloseModal = () => {
        props.onClose();
    }

    return (
        <div id="myModal" className="modal fade" tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                        <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label={props.closeButtonText}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {props.message}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>{props.closeButtonText}</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => props.onConfirm()}>{props.confirmButtonText}</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ConfirmModal;

