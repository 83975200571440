import React, { useState } from 'react';

import { faArrowDown, faArrowUp, faCheck, faDice, faCaretRight, faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CharacterTraits } from '../../interfaces/CharacterTraits';

import ConfirmModal from "../../components/ConfirmModal";
import { CharacterDerivedStats, CreationStep } from '../../classes/CharacterDerivedStats';
import ValidationAlert from '../ValidationAlert';
import SpendSkillPoints from './SpendSkillPoints';
import FocusPickControlsLevelling from './FocusPickControlsLevelling';
import { Lookups } from '../../lookups/Lookups';
import { FocusLevelPick } from '../../interfaces/FocusLevelPick';
import { convertPointTypeToNameCapitalised } from '../../utilities/Utilities';

import WildPsychicTalent from "../charGen/WildPsychicTalent";

interface IProps {
    charTraits: CharacterTraits;
    level: number;
    onLevelUp: () => void;
    onLevelDown: () => void;
    onSetLevelHitPoints: (level: number, hitPointRolls: number[]) => void;
    onSelectSkillPointSpendType: (level: number, index: number, spendType: string) => void;
    onSelectSkillToImprove: (level: number, index: number, skillName: string) => void;
    onSelectAttributeToImprove: (level: number, index: number, attributeName: string) => void;
    onSelectPointTypeToSpend: (level: number, index: number, pointType: string) => void;
    onSelectPointsSpent: (level: number, index: number, pointsSpent: number) => void;
    onSelectSkillLevelPick: (skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => void;
    onSelectFocusLevel: (focusName: string, focusLevel: number, traitToUpdate: string) => void;
    onSetPsychicTechniqueWhenLevelUp: (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNUmber: number, techniquePickNumber: number, traitToUpdate: string) => void;
    onSelectWildTalentPsychicDiscipline: (skill: string, traitToUpdate: string) => void;
    onSelectWildTalentTechnique: (technique: string, focusLevel: number, traitToUpdate: string) => void;
    onSelectSkillToBuyTechniqueFrom: (level: number, index: number, skillName: string) => void;
    onSelectTechniqueToBuy: (level: number, skillPickNumber: number, technique: string) => void;
    onSwitchCollapsed: (sectionName: string) => void;
    onSetUniqueGift: (focusLevel: number, text: string) => void;
}

const Level: React.FunctionComponent<IProps> = (props: IProps) => {

    const [displayConfirmDeleteLevel, setDisplayConfirmDeleteLevel] = useState(false);

    const sectionName = "level" + props.level;
    const switchDisplay = () => { props.onSwitchCollapsed(sectionName); }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }


    const thisLevel = props.charTraits.levels.find((l) => l.level === props.level);
    const skillPointPicks = thisLevel ? thisLevel.skillPointSpends.length : 0;

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateBaseAttack(CreationStep.AllSteps);
    charDerivedStats.calculateHitPointsLevels(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsAvailableSoFar(CreationStep.AllSteps, props.level);
    charDerivedStats.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, props.level, skillPointPicks - 1);
    charDerivedStats.calculateSkillPointsLeftSoFar();

    const deleteLevel = () => {
        setDisplayConfirmDeleteLevel(false);
        setTimeout(props.onLevelDown, 500);
        // props.onLevelDown();
    }

    const getBaseAttackIncrease = () => {
        const thisLevelBaseAttackIncreases = charDerivedStats.baseAttackLevels.history.filter((bal) => bal.level === props.level);
        if (thisLevelBaseAttackIncreases.length > 0) {
            return <div><b>Base Attack Bonus:</b> {thisLevelBaseAttackIncreases.map((i) => i.history).join("; ")}</div>
        }
        return null;
    }

    const onRollHitPoints = () => {

        // Check if has 'Tough' benefit from Alien origin focus:
        let hasTough = false;
        const alienFocus = charDerivedStats.focusLevels.find((f) => f.focus.indexOf("Alien -") !== -1);
        if (alienFocus) {
            const lookups = Lookups.getInstance();
            const selectedFocusData = lookups.focuses.find((fd) => fd.focus === props.charTraits.levelOne.freeFocusLevelPick.focus);
            if (selectedFocusData?.benefits) {
                if (selectedFocusData.benefits.find((ben) => ben.benefit === "tough")) {
                    hasTough = true;
                }
            }
        }

        const getHPRoll = (isTough: boolean, rollNumber: number) => {
            if(rollNumber === 0 && isTough) { return 6; }
            let hpRoll = Math.floor(Math.random() * 6) + 1;
            if (isTough && hpRoll === 1) {
                hpRoll = getHPRoll(isTough, rollNumber);
            }
            return hpRoll;
        }

        const hpRolls: number[] = [];
        for (let rollNumber = 0; rollNumber < props.level; rollNumber++) {
            const hpRoll = getHPRoll(hasTough, rollNumber); 
            hpRolls.push(hpRoll);
        }

        props.onSetLevelHitPoints(props.level, hpRolls);
    }

    let thisLevelHP = charDerivedStats.hitPointsLevels.find((hp) => hp.level === props.level);

    let didNotBeatLastLevelHPNote = "";
    if (thisLevelHP) {
        didNotBeatLastLevelHPNote = thisLevelHP.didNotBeatPreviousLevelHP ? "; Did not exceed previous level's HP so just increase by 1" : "";
    }

    const onSelectSpendType = (index: number, spendType: string) => {
        props.onSelectSkillPointSpendType(props.level, index, spendType);
    }

    const onSelectSkillToImprove = (index: number, skillName: string) => {
        props.onSelectSkillToImprove(props.level, index, skillName);
    }

    const onSelectSkillToBuyTechniqueFrom = (index: number, skillName: string) => {
        props.onSelectSkillToBuyTechniqueFrom(props.level, index, skillName);
    }

    const onSelectTechniqueToBuy = (level: number, skillPickNumber: number, technique: string) => {
        props.onSelectTechniqueToBuy(level, skillPickNumber, technique);
    }

    const onSelectAttributeToImprove = (index: number, attributeName: string) => {
        props.onSelectAttributeToImprove(props.level, index, attributeName);
    }

    const onSelectPointTypeToSpend = (index: number, pointType: string) => {
        props.onSelectPointTypeToSpend(props.level, index, pointType);
    }

    const onSelectPointsSpent = (index: number, pointsSpent: number) => {
        props.onSelectPointsSpent(props.level, index, pointsSpent);
    }

    const onSetPsychicTechniqueWhenLevelUp = (skill: string, techniqueName: string, techniqueLevel: number, characterLevel: number, skillLevelGained: number, skillPickNumber: number, techniquePickNumber: number, traitToUpdate: string) => {
        props.onSetPsychicTechniqueWhenLevelUp(skill, techniqueName, techniqueLevel, characterLevel, skillLevelGained, skillPickNumber, techniquePickNumber, traitToUpdate);
    }

    const hasSpentAllPoints = () => {
        let hasSpentAllPoints = true;
        for (const pointType in charDerivedStats.skillPointsAvailableSoFar) {
            const pointsSpent = charDerivedStats.skillPointsSpentSoFar[pointType] ? charDerivedStats.skillPointsSpentSoFar[pointType] : 0;
            const pointsAvailable = charDerivedStats.skillPointsAvailableSoFar[pointType] ? charDerivedStats.skillPointsAvailableSoFar[pointType] : 0;
            if (pointsSpent !== pointsAvailable) {
                hasSpentAllPoints = false;
            }
        }
        return hasSpentAllPoints;
    }

    const getSkillPointSpendControls = () => {

        const controls = thisLevel && thisLevel.skillPointSpends.map((sps, index) => {
            const hideLastItem = index === thisLevel.skillPointSpends.length - 1 && hasSpentAllPoints();
            if (!hideLastItem) {
                return <li key={index}>
                    <SpendSkillPoints
                        charTraits={props.charTraits}
                        level={props.level}
                        index={index}
                        onSelectSpendType={onSelectSpendType}
                        onSelectSkillToImprove={onSelectSkillToImprove}
                        onSelectAttributeToImprove={onSelectAttributeToImprove}
                        onSelectPointTypeToSpend={onSelectPointTypeToSpend}
                        onSelectPointsSpent={onSelectPointsSpent}
                        onSelectSkillToBuyTechniqueFrom={onSelectSkillToBuyTechniqueFrom}
                        onSelectTechniqueToBuy={onSelectTechniqueToBuy}
                        onSetPsychicTechniqueWhenLevelUp={onSetPsychicTechniqueWhenLevelUp}
                    />
                </li>
            }
            return null;
        })

        return <ol style={{ padding: "0", paddingLeft: "20px", marginBottom: "0px" }}>{controls}</ol>;
    }

    const getFocusesAtThisStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateFocusLevels(CreationStep.AllSteps, 100);
        return charDerivedStatsAtThisClass.focusLevels;
    }

    const getFocusLevelPicksToChooseFrom = () => {
        const lookups = Lookups.getInstance();

        // Get list of all level-1 foci. 
        const allFocusesAtLevelOne = lookups.focuses.filter((f) => f.focus.indexOf("VI") === -1).map((f) => {
            let grantsSkill = false;
            const hasSkillBonus = f.levels[0].bonuses.find((b) => b.type === "bonusSkill");
            if (hasSkillBonus) {
                grantsSkill = true;
            }
            const flp: FocusLevelPick = { focus: f.focus, level: 1, type: f.type, skillLevelPicks: [], skillPointsPicks: [], grantsSkill: grantsSkill };
            return flp;
        });

        // Replace any foci that have already been picked at level-1 with level 2 version.
        // Remove any that are already at level-2.

        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateFocusLevels(CreationStep.AllSteps);

        let finalFocuses: FocusLevelPick[] = [];

        // If this focus has been picked, add it to the dropdown. 
        const pickedFocus = getlevelFocusLevelAlreadyPicked();
        if (pickedFocus.focus !== "") {
            finalFocuses.push(pickedFocus);
        }

        allFocusesAtLevelOne.forEach((foc) => {
            const thisFocusLevels = charDerivedStatsAtThisClass.focusLevels.filter((fl) => fl.focus === foc.focus);
            if (thisFocusLevels.length > 0) {
                const maxFocusLevel = thisFocusLevels.reduce(function (prev, current) {
                    return (prev.level > current.level) ? prev : current
                })
                if (maxFocusLevel) {
                    if (maxFocusLevel.level === 2) {
                        // do nothing, don't add this focus to the list, it's alrady maxxed out
                    } else {
                        // Add the focus at level-2 (if it has a level-2 focus)
                        const level2FocusExists = lookups.focuses.find((f) => f.focus === foc.focus && f.levels.length > 1);
                        if (foc.focus !== pickedFocus.focus && level2FocusExists) {
                            finalFocuses.push({ ...foc, level: 2 });
                        }
                    }
                }
            } else {
                // Add the focus at level-1. 
                finalFocuses.push({ ...foc, level: 1 });
            }
        })



        return finalFocuses.sort((a, b) => a.focus > b.focus ? 1 : -1);
    }

    const getlevelFocusLevelAlreadyPicked = () => {
        if (thisLevel && thisLevel.level && thisLevel.focusLevelPick) {
            return thisLevel.focusLevelPick;
        } else {
            return { focus: "", level: 0, type: "", skillLevelPicks: [], skillPointsPicks: [], grantsSkill: false };
        }
    }

    let focusLevelPicksToChooseFrom: any;
    let levelFocusLevelPick: FocusLevelPick = { focus: "", level: 0, type: "", skillLevelPicks: [], skillPointsPicks: [], grantsSkill: false };
    if (thisLevel && thisLevel.level && [2, 5, 7, 10].includes(thisLevel.level)) {
        focusLevelPicksToChooseFrom = getFocusLevelPicksToChooseFrom();
        levelFocusLevelPick = getlevelFocusLevelAlreadyPicked();
    }

    const getSkillsAtThisStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.AllSteps, 100, 100, props.level, 0, true);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const getSkillsAtEndOfThisStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.AllSteps, 100, 100, props.level, 100);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const charDerivedStatsAtBackground = new CharacterDerivedStats(props.charTraits);
    charDerivedStatsAtBackground.calculateSkillLevels(CreationStep.Background);

    const getSkillPointsAvailableSoFarDisplay = () => {
        let output: any[] = [];
        for (const pointsType in charDerivedStats.skillPointsAvailableSoFar) {
            const pointsAvailable = charDerivedStats.skillPointsAvailableSoFar[pointsType];
            if (pointsAvailable > 0) {
                const pointsSpent = charDerivedStats.skillPointsSpentSoFar[pointsType] ? charDerivedStats.skillPointsSpentSoFar[pointsType] : 0;

                let dot: any = null;
                if (pointsAvailable === pointsSpent) {
                    dot = <FontAwesomeIcon icon={faCheck} title="Points all spent" className="mr-1 green"></FontAwesomeIcon >;
                }

                output.push(<li key={pointsType}><b><i>{convertPointTypeToNameCapitalised(pointsType)}:</i></b> {pointsAvailable} ({pointsSpent} spent)&nbsp;{dot}</li>);
            }
        }
        return <ul>{output}</ul>;
    }

    const getCurrentSkills = () => {
        const skills = getSkillsAtEndOfThisStep();
        const output = skills.filter((s) => s.level && s.level > 0).map((s) => s.skill + "-" + (s.level && s.level - 1)).join(", ");
        if (output === "") {
            return "None";
        }
        return output;
    }

    const pickedWidTalentAtThisLevel = thisLevel?.focusLevelPick?.focus === "Wild Psychic Talent";

    return (
        <div className="mb-3">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h3>Level {props.level} {getCollapseIcon()}</h3></div>

            <div className={sectionClassName}>

                {/* <div>{JSON.stringify(props.charTraits.levels[props.level - 2])}</div> */}

                {getBaseAttackIncrease()}

                <div className="mb-3"><b>Saving Throws:</b> All improve by 1</div>

                {/* <h4>Hit Points</h4> */}

                {/* Hit point rolls */}

                {thisLevel && thisLevel.rolledHitPoints.length === 0 &&
                    <div className="mb-3">
                        <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => onRollHitPoints()}>
                            <FontAwesomeIcon icon={faDice} title="text-info" className="mr-1"></FontAwesomeIcon >
                            Roll
                        </button> Roll {props.level}d6 for Hit Points

                        {props.charTraits.showValidation && thisLevel.validationCodes.indexOf("levelHitPointsNotRolled") !== -1 &&
                            <div>
                                <ValidationAlert msg={"Hit Points must be rolled"} />
                            </div>
                        }
                    </div>
                }

                {thisLevel && thisLevel.rolledHitPoints.length > 0 && thisLevelHP &&
                    <div className="mt-1">
                        <div>
                            <b>Hit Points at level {props.level}:</b>  {thisLevelHP.totalHitPointsAtLevel} HP
                        </div>
                        <div>
                            <ul>
                                <li><i>Hit Points rolls:</i> {thisLevel.rolledHitPoints.join(" + ")} = {thisLevel.totalHitPoints}</li>
                                <li><i>Hit Points rolls total:</i>  {thisLevelHP.hitPoints} HP ({thisLevelHP.history.join("; ")}{didNotBeatLastLevelHPNote})</li>
                                <li><button className="btn btn-secondary btn-tiny" onClick={onRollHitPoints}>Reroll</button>&nbsp;{thisLevel.hitPointRerolls} reroll{thisLevel.hitPointRerolls === 1 ? "" : "s"}</li>
                            </ul>
                        </div>
                    </div>
                }

                {/* Focus gained */}
                {thisLevel && thisLevel.level && [2, 5, 7, 10].includes(thisLevel.level) &&
                    <div className="mt-1 mb-3">
                        <b>Focus:</b>&nbsp;
                        <FocusPickControlsLevelling
                            className={""}
                            key={"focus_level_" + thisLevel.level}
                            focusType={"Any"}
                            focusLevelPicksToChooseFrom={focusLevelPicksToChooseFrom}
                            focusLevelAlreadyPicked={levelFocusLevelPick}
                            skillLevelsAlreadyPicked={levelFocusLevelPick.skillLevelPicks}
                            skillPointsAlreadyPicked={levelFocusLevelPick.skillPointsPicks}
                            traitToUpdate_Focuses={"level.levelFocusPick/" + props.level}
                            traitToUpdate_Skills={"level.levelFocusPick.skillLevelPicks/" + props.level}
                            charTraits={props.charTraits}
                            charDerivedSkills={getSkillsAtThisStep()}
                            // charDerivedSkillsAtPreviousStep={getSkillsAtPreviousStep()}
                            charDerivedFocuses={getFocusesAtThisStep()}
                            includeLeadingIcon={true}
                            onSelectFocusLevel={(focusName: string, focusLevel: number) => props.onSelectFocusLevel(focusName, focusLevel, "level.levelFocusPick/" + props.level)}
                            onSelectSkillLevelPick={(skillName: string, skillLevelPicks: number, selected: boolean, traitToUpdate: string, singleSkillOnly: boolean) => props.onSelectSkillLevelPick(skillName, skillLevelPicks, selected, traitToUpdate, singleSkillOnly)}
                            onManageCustomSkills={() => null}
                            onSetUniqueGift={(focusLevel: number, text: string) => { props.onSetUniqueGift(focusLevel, text) }}
                        // onManageCustomSkills={props.onManageCustomSkills}
                        />
                        {props.charTraits.showValidation && thisLevel.validationCodes.indexOf("levelFocusNotSelected") !== -1 &&
                            <div>
                                <ValidationAlert msg={"Focus must be selected"} />
                            </div>
                        }
                        {thisLevel.validationCodes.indexOf("levelFocusMustPickSkill") !== -1 &&
                            <div>
                                <ValidationAlert msg={"Select a skill to gain 3 skill points in"} />
                            </div>
                        }
                        {thisLevel.validationCodes.indexOf("levelFocusPsychicTrainingOnlyForPsychic") !== -1 &&
                            <div>
                                <ValidationAlert msg={"Only a character with the Psychic or Partial Psychic class can take the Psychic Training focus"} />
                            </div>
                        }
                        {thisLevel.validationCodes.indexOf("levelFocusWildPsychicNotForPsychic") !== -1 &&
                            <div>
                                <ValidationAlert msg={"Only a character that does not have the Psychic or Partial Psychic class can take the Wild Psychic focus"} />
                            </div>
                        }
                        {thisLevel.validationCodes.indexOf("levelFocusPsychicTrainingForPartialPsychicMustHaveSameSkill") !== -1 &&
                            <div>
                                <ValidationAlert msg={"You must select the same skill for the Psychic Training focus as you select for Partial Psychic class."} />
                            </div>
                        }
                    </div>
                }

                {pickedWidTalentAtThisLevel &&
                    <WildPsychicTalent
                        charTraits={props.charTraits}
                        level={props.level}
                        onSelectWildTalentPsychicDiscipline={props.onSelectWildTalentPsychicDiscipline}
                        onSelectWildTalentTechnique={props.onSelectWildTalentTechnique}
                    />
                }

                {/* Skill Points Gained */}
                <div className="mt-1 mb-3">
                    <b>Skill Points:</b>&nbsp;
                    {getSkillPointsAvailableSoFarDisplay()}
                    {/* <div><pre>{JSON.stringify(charDerivedStats.skillPointsAvailableSoFar, null, 2)}</pre></div> */}
                </div>

                {/* Skill Points Spent */}
                <div className="mt-1">
                    <div className="pb-2"><FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon > Spend Skill Points:</div>
                    {getSkillPointSpendControls()}
                    <div className="mb-2"><b>Skills:</b> {getCurrentSkills()}</div>
                    {!hasSpentAllPoints() &&
                        <div className="mb-3">You have unspent skill points. These can be saved to spend at a subsequent level.</div>
                    }
                    {hasSpentAllPoints() &&
                        <div className="mb-3">All skill points are spent. </div>
                    }
                </div>

                {/* <GainPsyTechniqueWhenLevel
                level={props.level}
                charTraits={props.charTraits}
                onSetPsychicTechniqueWhenLevelUp={props.onSetPsychicTechniqueWhenLevelUp}
            /> */}

                {/* Level up/down  */}
                {props.level === props.charTraits.level &&
                    <>
                        <h3>Advance a Level</h3>

                        {props.charTraits.level < 20 &&
                            <div className="mb-2 mt-2">
                                <button className="btn btn-primary btn-tiny d-inline btn-level" onClick={() => props.onLevelUp()}>
                                    <FontAwesomeIcon icon={faArrowUp} title="text-info" className="mr-1"></FontAwesomeIcon >
                                    Level Up
                                </button> Gain level {props.level + 1}
                            </div>
                        }

                        <div>
                            <button className="btn btn-primary btn-tiny d-inline btn-level" onClick={() => setDisplayConfirmDeleteLevel(true)}>
                                <FontAwesomeIcon icon={faArrowDown} title="text-info" className="mr-1"></FontAwesomeIcon >
                                Level Down
                            </button> Remove level {props.level}
                        </div>
                    </>
                }

                <ConfirmModal
                    displayModal={displayConfirmDeleteLevel}
                    title="Confirm Delete Level"
                    message={"Are sure you want to delete this level?"}
                    confirmButtonText="Delete"
                    closeButtonText="Cancel"
                    onClose={() => setDisplayConfirmDeleteLevel(false)}
                    onConfirm={() => deleteLevel()}
                />

            </div>

        </div>
    )

}

export default Level; 