import React from 'react';

import { faCaretRight, faDice, faHandPaper, faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AttributesAssign from "./charGen/AttributesAssign";
import AttributesAssignAny from "./charGen/AttributesAssignAny";
import AttributesRoll from './charGen/AttributesRoll';

import { CharacterTraits } from '../interfaces/CharacterTraits';

import ValidationAlert from "./ValidationAlert";
import AttributesRollAndAssign from './charGen/AttributesRollAndAssign';

interface IProps {
    attributeLongNames: string[];
    attributeAbbrev: string[];
    defaultAttributeArray: number[];
    randomAttributeScores: number[];
    charTraits: CharacterTraits;
    onSelectAttributeAssignMethod: (method: string) => void;
    onSetIncludeNonstandardMethods: (include: boolean) => void;
    onSelectAttribute: (attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean) => void;
    onSetAttributeTo14: (attributeNumber: number, attributeValue: number) => void;
    onRerollAttributes: () => void;
    onResetScores: () => void;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep2Attributes: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "attributes";
    const switchDisplay = () => { props.onSwitchCollapsed(sectionName); }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Attributes {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                {char.attributeTraits.method === "" &&
                    <div>
                        <div className="mb-2">
                            <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                            Pick how to set your character's attributes:
                        </div>
                        <div className="mb-2">
                            <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectAttributeAssignMethod("roll")} >
                                <FontAwesomeIcon icon={faDice} title="text-info" className="mr-1"></FontAwesomeIcon >
                                Roll
                            </button>&nbsp;
                            Roll attributes randomly (3d6 each, in order, set one to 14).
                        </div>
                        <div className="mb-2">
                            <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectAttributeAssignMethod("assign")}>
                                <FontAwesomeIcon icon={faHandPaper} title="text-info" className="mr-1"></FontAwesomeIcon >
                                Assign
                            </button>&nbsp;
                            Assign attributes from a standard array.
                        </div>
                        <div className="mb-2">
                            <label><input type="checkbox" checked={char.attributeTraits.includeNonstandardMethods} onChange={() => props.onSetIncludeNonstandardMethods(!char.attributeTraits.includeNonstandardMethods)}></input> Include non-standard methods</label>
                        </div>
                        {char.attributeTraits.includeNonstandardMethods &&
                            <>
                                <div className="mb-2">
                                    <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectAttributeAssignMethod("rollAndReorder")} >
                                        <FontAwesomeIcon icon={faDice} title="text-info" className="mr-1"></FontAwesomeIcon >
                                        Roll
                                    </button>&nbsp;
                                    Roll attributes randomly (3d6 each, assign order, set one to 14).
                                </div>
                                <div className="mb-2">
                                    <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => props.onSelectAttributeAssignMethod("assignAny")} >
                                        <FontAwesomeIcon icon={faDice} title="text-info" className="mr-1"></FontAwesomeIcon >
                                        Assign
                                    </button>&nbsp;
                                    Assign any attribute scores.
                                </div>
                            </>
                        }
                        {char.showValidation && char.attributeTraits.validationCodes.indexOf("attributeAssignNotSelected") !== -1 &&
                            <ValidationAlert msg="Attribute assignment method must be selected" />
                        }
                    </div>
                }

                {char.attributeTraits.method === "roll" &&
                    <AttributesRoll
                        attributeLongNames={props.attributeLongNames}
                        attributeAbbrev={props.attributeAbbrev}
                        charTraits={props.charTraits}
                        onSetAttributeTo14={(attributeNumber: number, attributeValue: number) => props.onSetAttributeTo14(attributeNumber, attributeValue)}
                        onRerollAttributes={() => props.onRerollAttributes()}
                    />
                }

                {char.attributeTraits.method === "assign" &&
                    <AttributesAssign
                        attributeLongNames={props.attributeLongNames}
                        defaultAttributeArray={props.defaultAttributeArray}
                        charTraits={props.charTraits}
                        onSelectAttribute={(attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean) => props.onSelectAttribute(attributeNumber, attributeValue, alsoSetAsOriginalScore)}
                    />
                }

                {char.attributeTraits.method === "rollAndReorder" &&
                    <AttributesRollAndAssign
                        attributeLongNames={props.attributeLongNames}
                        charTraits={props.charTraits}
                        onSelectAttribute={(attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean) => props.onSelectAttribute(attributeNumber, attributeValue, alsoSetAsOriginalScore)}
                        onSetAttributeTo14={(attributeNumber: number, attributeValue: number) => props.onSetAttributeTo14(attributeNumber, attributeValue)}
                        onRerollAttributes={() => props.onRerollAttributes()}
                        onResetScores={() => props.onResetScores()}
                    />
                }

                {char.attributeTraits.method === "assignAny" &&
                    <AttributesAssignAny
                        attributeLongNames={props.attributeLongNames}
                        charTraits={props.charTraits}
                        onSelectAttribute={(attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean) => props.onSelectAttribute(attributeNumber, attributeValue, alsoSetAsOriginalScore)}
                    />
                }

            </div>

        </div>
    );

}

export default CharacterDesignStep2Attributes;