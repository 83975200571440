import React, { useState, useEffect } from "react";
import app from "../firebase";

import { version } from "../version";

const AdminVersion: React.FunctionComponent<unknown> = (props: any) => {

    const [versionOnServer, setVersionOnServer] = useState("-1");
    const [versionToSet, setVersionToSet] = useState("-1");

    // On first render, check if the code version thas been updated.
    useEffect(() => {
        // Get latest version number from server:
        const db = app.firestore();
        const docRefDetail = db.collection("version").doc("1");
        docRefDetail.get().then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                if (data) {
                    const versionOnServer = data.version;
                    setVersionOnServer(versionOnServer);
                    setVersionToSet(versionOnServer);
                }
            }
        });


    }, []);

    const setVersion = async () => {
        const db = app.firestore();
        await db.collection("version")
            .doc("1")
            .set({ version: versionToSet })
            .catch(function (error) {
                alert(error);
            });
        setVersionOnServer(versionToSet);
    }

    return (
        <div className="pageBody">

            <p>Version on local code: {version}</p>

            <p>Version on server: {versionOnServer}</p>

            <div>Set version on server: <input type="text" value={versionToSet} onChange={(e) => { setVersionToSet(e.target.value.trim()) }} /><button onClick={setVersion}>Set Version</button></div>

        </div>
    );
}

export default AdminVersion;
