import React, { useState } from "react";
import { StarshipTraits } from "../../interfaces/StarshipTraits";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onBuyOrSellMod: (id: string, isBuy: boolean) => void;
}

const ShipDesignStep6Mods: React.FunctionComponent<IProps> = (props: IProps) => {

    // const [openBuyModsModal, setOpenBuyModsModal] = useState(false);

    return (
        <div className="chargenSection">

            <h2>Modifications</h2>

            <div className="small">Coming soon...</div>

            {/* <BuyModsModal
                shipTraits={props.shipTraits}
                displayModal={openBuyModsModal}
                onBuyOrSellMod={(id, isBuy) => props.onBuyOrSellMod(id, isBuy)}
                onClose={() => setOpenBuyModsModal(false)}
            /> */}

            {/* {!props.readOnly &&
                <div>
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenBuyModsModal(true)}>Select Mods</button>
                </div>
            } */}

            {/* <ListModsInstalled shipTraits={props.shipTraits} /> */}

        </div >
    );

}

export default ShipDesignStep6Mods;