const en = {
    "site": {
        "site_title": "freebooter",
    },
    "homeGuest": {
        "please_log_in": "Please Log In, or Sign Up for a new account.",
        "welcome_to_the_site": "Welcome to the Site!",
    },
    "menu": {
        "blog": "Blog",
        "characters": "Characters",
        "english": "English",
        "french": "French",
        "german": "German",
        "home": "Home",
        "log_in": "Log In",
        "login": "Login",
        "myAccount": "My Account",
        "sign_out": "Sign Out",
        "sign_up": "Sign Up",
        "todolist": "To Do List",
        "user": "user",
        "users": "Users",
    },
    "footer": {
        "copyright": "Copyright"
    },
    "emailVerify": {
        "clickToResend": "If you cannot find the email, click this button to resend the verification email to '{{email}}':",
        "emailHasLink": "An email has been sent to your email address. It contains a link that you must click to activate your account.",
        "emailReqVerification": "Your Email Requires Verification",
        "emailSent": "The verification email has been re-sent to '{{email}}'.",
        "emailWasSent": "Your login was successful, but your email address must be verified before your account will be activated.",
        "resend": "Resend Verification Email",
        "returnHereAndLogIn": "After you have activated your account, please return here and log in.",
        "unknownAddress": "Unknown Address",
    },
    "forgotPassword": {
        "email": "Email",
        "emailSent": "Password Reset Email Sent",
        "enterEmail": "Please enter your account's email address. An email with a password reset link will be sent to you.",
        "fieldReq": "This field is required",
        "followLink": "A password reset email has been sent to your email account. Follow the link in the email to reset your password, then return here to log in using your new password.",
        "forgotYourPassword": "Forgot Your Password?",
        "invalidEmail": "The email address is invalid.",
        "log_in": "Log In",
        "noUserExists": "No user exists with the email address",
        "sendFailed": "Sending Email Failed:",
        "sendResetEmail": "Send Password Reset Email",
    },
    "home": {
        "hi": "Hi",
        "home": "Home",
        "myAccount": "My Account",
        "noName": "You have not set your name. You can set it here:",
        "noNameorPhoto": "You have not set your name or a photo. You can set it here:",
        "noPhoto": "You have not set your photo. You can set it here:",
        "welcome": "Welcome to the site!",
        "yourPhoto": "Your photo",
    },
    "login": {
        "dontHaveAnAccount": "Don't have an account?",
        "email": "Email",
        "fieldReq": "This field is required",
        "forgotPassword": "Forgot your password?",
        "heading": "Welcome",
        "invalidEmail": "The email address is invalid.",
        "log_in": "Log In",
        "loginFailed": "Login Failed:",
        "password": "Password",
        "pleaseLogIn": "Please login below:",
        "signUp": "Sign Up",
        "tooManyRequests": "You have entered the wrong password too many times. Your account has been temporarily disabled. Try again later, or use the 'Forgot your password?' link to reset your password.",
        "userDisabled": "Your user account has been disabled.",
        "userNotFound": "No user exists with the email address",
        "wrongPassword": "Password is incorrect.",
    },
    "pageNotFound": {
        "pageNotFound": "Page Not Found"
    },
    "signUp": {
        "alreadyHaveAnAccount": "Already have an account?",
        "email": "Email",
        "emailSent": "An email has been sent to your email address. It contains a link that you must click to activate your account.",
        "emailUsed": "An account already exists with that email address.",
        "enterEmail": "Please enter your email address and a password to create a new account:",
        "fieldReq": "This field is required",
        "invalidEmail": "The email address is invalid.",
        "log_in": "Log In",
        "password": "Password",
        "pleaseLogIn": "After you have activated your account, please return here and log in.",
        "signUp": "Sign Up",
        "signUpFailed": "Sign Up Failed:",
        "smiley": "Smiley Face",
        "success": "You have successfully signed up for an account.",
        "weakPassword": "The password is too weak. Must be at least six characters.",
    },
    "myAccount": {
        "accountDetailsUpdated": "Account details updated",
        "changePassword": "Change Password",
        "deleteAccount": "Delete Account",
        "displayName": "Display Name",
        "myAccount": "My Account",
        "photoURL": "Photo URL",
        "updateMyAccount": "Update My Account",
        "userName": "User",
    },
    "unauthorized": {
        "pleaseLoginIn": "You do not have permission to view this page. Please log in as an authorized user.",
        "unauthorized": "Unauthorized Access",
    },
    "changePwd": {
        "changePassword": "Change Password",
        "confirmPassword": "Confirm password",
        "doNotMatch": "Passwords do not match",
        "error": "Error",
        "fieldReq": "This field is required",
        "logInAgain": "You have been logged in for too long to reset your password. Please log out, then log in again and come back here to change your password.",
        "newPassword": "New password",
        "passwordChanged": "Your password has been reset.",
        "resetMyPassword": "Reset My Password",
        "unknownError": "An unknown error has occurred.",
        "weakPassword": "The password is too weak. Must be at least six characters.",
    },
    "deleteAccount": {
        "accountDeleted": "Your account and data have been permanently deleted. You will now be logged out.",
        "areYouSure": "Are you CERTAIN that you want to delete your account and all your data? This action is permanent and cannot be undone.",
        "deleteAccount": "Delete Account",
        "error": "Error",
        "logInAgain": "You have been logged in for too long to delete your account. Please log out, then log in again and come back here to delete your account.",
        "permDeleteMyAccount": "Permanently delete my account:",
        "unknownError": "An unknown error has occurred.",
    },
    "todo": {
        "addToDo": "Add To Do",
        "done": "Done",
        "enterNewToDo": "Enter a new thing to do...",
        "loading": "Loading...",
        "nothingToDo": "You have nothing to do.",
        "toDoList": "To Do List",
        "updateToDo": "Update thing to do...",
    },
    "blog": {
        "AreYouCertainYouWantToDelete": "Are you certain you want to delete this blog entry?",
        "body": "Body",
        "cancel": "Cancel",
        "createBlogEntry": "Create Blog Entry",
        "del": "Del.",
        "edit": "Edit",
        "editBlogEntry": "Edit Blog Entry",
        "fieldReq": "Field req.",
        "loading": "Loading...",
        "my_blog": "My Blog",
        "noBlogEntries": "No blog entries",
        "save": "Save",
        "title": "Title",
    },
    "adminUsers": {
        "adminUsers": "Admin - Users",
        "email": "Email",
        "displayName": "Display Name",
        "emailVerified": "Email Verified",
    },
    "characters": {
        "background": "Background",
        "characters": "Characters",        
        "class": "Class",
        "createACharacter": "Create a Character",
        "currentlyNoCharacters": "You currently have no characters.",
        "name": "Name",
        "public": "Public",
        "delete": "Delete",
        "level": "Level"
    },
    "charDesigner": {
        "characterDesigner": "Character Designer"
    }
}

export default en;