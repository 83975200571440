import React from "react";

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterTraits } from "../../interfaces/CharacterTraits";

import ValidationAlert from "../ValidationAlert";

import { getAttributeModifierPlusMinus } from "../../utilities/Utilities";


export interface IProps {
    attributeLongNames: string[];
    charTraits: CharacterTraits;
    onSelectAttribute: (attributeNumber: number, attributeValue: number, alsoSetAsOriginalScore: boolean) => void;
    onSetAttributeTo14: (attributeNumber: number, attributeValue: number) => void;
    onRerollAttributes: () => void;
    onResetScores: () => void;
}

const AttributesRollAndAssign: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sortNums = (a: any, b: any): number => {
        if (a === -1) { return -1; }
        return b - a;
    }

    const onSetAttributeTo14 = (e: any) => {
        const attributeNumber: number = parseInt(e.target.getAttribute("data-attributenumber"));
        const oldValue: number = parseInt(e.target.getAttribute("data-oldvalue"));
        props.onSetAttributeTo14(attributeNumber, oldValue);
    }

    const getUnselectedAttributeArray = () => {
        let unselectedScores = [...props.charTraits.attributeTraits.randomScores];

        // char.attributeTraits.attributeScores.forEach((as, index) => {
        char.attributeTraits.originalScores.forEach((as, index) => {

            const indexOfScore = unselectedScores.indexOf(as);
            if (indexOfScore !== -1) {
                unselectedScores.splice(indexOfScore, 1);
            }
        });

        return unselectedScores;
    }

    const onSelectAttribute = (e: any): void => {

        const attributeNumber = parseInt(e.target[e.target.selectedIndex].getAttribute("data-number"));
        const attributeValue = parseInt(e.target.value);

        // If all are selected and one set to 14 and user unselects one, then reset to original values and clear the 14
        if (props.charTraits.attributeTraits.attributeScores.indexOf(-1) === -1 && props.charTraits.attributeTraits.attributeNumberSetTo14 !== -1 && attributeValue === -1) {
            props.onResetScores();
        } else {
            const finalAttributeValue = attributeValue === -1 ? 0 : attributeValue;
            props.onSelectAttribute(attributeNumber, finalAttributeValue, true);
        }
    }

    const getAttributeDropdownOptions = (attributeNumber: number) => {
        const zeroToDash = (attribute: number): string => attribute === -1 ? "-" : attribute.toString();

        let options: any[] = [...getUnselectedAttributeArray()];

        const thisAttributeScore = char.attributeTraits.attributeScores[attributeNumber];
        if (thisAttributeScore !== 0) {
            options.push(thisAttributeScore);
        }

        const optionElements = options.sort(sortNums).map((o, index) => <option key={attributeNumber + "_" + index + "_" + o} value={o} data-number={attributeNumber}>{zeroToDash(o)}</option>);
        optionElements.unshift(<option key={attributeNumber + "_" + options.length + 1 + "_-1"} value={-1} data-number={attributeNumber}>-</option>);
        return optionElements;
    }

    const getAttributeDropdowns = () => {
        const attributeDropdowns: any[] = [];
        let attributeNumber = 0;
        char.attributeTraits.attributeScores.forEach((a, index) => {
            const isChecked = char.attributeTraits.attributeNumberSetTo14 === index;
            attributeDropdowns.push(
                <tr key={"attddl" + attributeNumber}>
                    <td>{props.attributeLongNames[attributeNumber]}</td>
                    <td>
                        <select onChange={onSelectAttribute} value={char.attributeTraits.attributeScores[attributeNumber]}>
                            {getAttributeDropdownOptions(attributeNumber)}
                        </select>
                    </td>
                    <td>{getAttributeModifierPlusMinus(props.attributeLongNames[attributeNumber], char.attributeTraits.attributeScores[attributeNumber], null)}</td>
                    {getUnselectedAttributeArray().length === 0 &&
                        <td>
                            <input type="radio" value={props.attributeLongNames[attributeNumber]} checked={isChecked} data-attributenumber={attributeNumber} data-oldvalue={char.attributeTraits.attributeScores[attributeNumber]} name="setTo14" onChange={onSetAttributeTo14} disabled={char.attributeTraits.attributeScores[attributeNumber] === 0} />
                            {char.attributeTraits.attributeNumberSetTo14 === attributeNumber &&
                                <span className="ml-2">was {char.attributeTraits.attributeValueThatWasReplacedWith14}</span>
                            }
                        </td>
                    }
                </tr>
            )
            attributeNumber += 1;
        })
        return attributeDropdowns;
    }

    const reroll = () => {
        props.onRerollAttributes();
    }

    let attributeDropdowns = getAttributeDropdowns();

    return (
        <div>

            {getUnselectedAttributeArray().length > 0 &&
                <p>
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    Non-standard method: Assign the following scores to your attributes (3d6 each): {getUnselectedAttributeArray().sort(sortNums).join(", ")}; then may set one score to 14.
                </p>
            }

            {getUnselectedAttributeArray().length === 0 &&
                <p>
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    Non-standard method: 3d6 each, assigned as desired, set one to 14.
                </p>
            }

            <table className="mb-0 attributeTable">
                <thead>
                    <tr>
                        <th>Attribute</th>
                        <th>Score</th>
                        <th>Modifier</th>
                        {getUnselectedAttributeArray().length === 0 &&
                            <th>Set to 14</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {attributeDropdowns}
                </tbody>
            </table>
            <div>
                <button className="btn btn-secondary btn-tiny" onClick={reroll}>Reroll</button>&nbsp;{props.charTraits.attributeTraits.rerolls} reroll{props.charTraits.attributeTraits.rerolls === 1 ? "" : "s"}
            </div>

            {char.showValidation && char.attributeTraits.validationCodes.indexOf("notAllAttributes") !== -1 &&
                <ValidationAlert msg="All attributes must be set" />
            }

            {/* <div>random Rolls: {props.charTraits.attributeTraits.randomScores.join(", ")}</div>
            <div>unselectedAttributes: {getUnselectedAttributeArray().join(", ")}</div>
            <div>attributes: {props.charTraits.attributeTraits.attributeScores.join(", ")}</div>
            <div>originalScores attributes: {props.charTraits.attributeTraits.originalScores.join(", ")}</div>
            <div>setTo14: {props.charTraits.attributeTraits.attributeNumberSetTo14}</div>
            <div>score that was replaced with 14: {props.charTraits.attributeTraits.attributeValueThatWasReplacedWith14}</div> */}

        </div>
    )

}

export default AttributesRollAndAssign;