import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import app from "../firebase";

import { History } from 'history';

import { isEmail } from "../utilities/emailValidation";

type IProps = {
    history: History,
}

const Login: React.FunctionComponent<IProps> = (props: IProps) => {

    const { t } = useTranslation();

    const [loginFailed, setLoginFailed] = useState(false);
    const [error, setError] = useState("");

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (values: any) => {
        try {
            await app
                .auth()
                .signInWithEmailAndPassword(values.email, values.password)
                .then(async (user) => {
                    if (user) {
                        // Update user's email verification status.
                        const db = app.firestore();
                        let userRef = await db.collection("users").doc(user.user?.uid);
                        userRef.update({ emailVerified: user.user?.emailVerified });
                    }
                })
            props.history.push("/home");
        } catch (error) {
            setLoginFailed(true);

            if (error.message) {
                if (error.code) {
                    switch (error.code) {
                        case "auth/invalid-email":
                            setError(t("login.invalidEmail"));
                            break;
                        case "auth/user-disabled":
                            setError(t("login.userDisabled"));
                            break;
                        case "auth/user-not-found":
                            setError(t("login.userNotFound") + " '" + values.email + "'.");
                            break;
                        case "auth/wrong-password":
                            setError(t("login.wrongPassword"));
                            break;
                        case "auth/too-many-requests":
                            setError(t("login.tooManyRequests"));
                            break;
                        default:
                            setError(error.message);
                            break;
                    }
                } else {
                    setError(error.message);
                }
            }
        }
    }

    return (
        <div className="pageBody">
            <h1 className="pageHeading">{t("login.log_in")}</h1>

            <div className="card" style={{ maxWidth: "400px" }}>
                <div className="card-body">

                    {/* <p>{t("login.pleaseLogIn")}</p> */}

                    <form action="#!" method="get" onSubmit={handleSubmit(onSubmit)}>

                        {loginFailed &&
                            <div className="alert alert-danger">{error}</div>
                        }
                        <div className="form-group">
                            <label htmlFor="email">{t("login.email")}</label>
                            <input type="email" placeholder={t("login.email")} className="form-control textMid" ref={register({ required: true, validate: isEmail })} id="email" name="email" />
                            {(errors.email && errors.email.type === "required") &&
                                <div className="text-danger">{t("login.fieldReq")}</div>
                            }
                            {(errors.email && errors.email.type === "validate") &&
                                <div className="text-danger">{t("login.invalidEmail")}</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">{t("login.password")}</label>
                            <input type="password" className="form-control textMid" placeholder={t("login.password")} ref={register({ required: true })} id="password" name="password" />
                            {errors.password &&
                                <div className="text-danger">{t("login.fieldReq")}</div>
                            }
                            <Link className="form-text text-muted small" to="/forgotPassword">{t("login.forgotPassword")}</Link>
                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary">{t("login.log_in")}</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
};

export default withRouter(Login);
