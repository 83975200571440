import React from 'react';

import BootstrapLayout from "./BootstrapLayout";

type LayoutProps = {
    children?: any
};

const Layout: React.FunctionComponent<LayoutProps> = (props: LayoutProps) => {

    const children = props.children || null;

    return <BootstrapLayout>{children}</BootstrapLayout>;
}

export default Layout;