import React, { useState } from "react";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import BuyFittingsModal from "./BuyFittingsModal";
import ListFittingsInstalled from "./ListFittingsInstalled";
import SelectFoxerDronesModal from "./SelectFoxerDronesModal";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onBuyOrSellFitting: (id: string, isBuy: boolean) => void;
    onSetFoxerDronesExtraMass: (weaponUniqid: string, extraFreeMass: number) => void;
    onSetFoxerDronesRemaining: (weaponUniqId: string, roundsRemaining: number) => void;
}

const ShipDesignStep4Defenses: React.FunctionComponent<IProps> = (props: IProps) => {

    const [openBuyFittingsModal, setOpenBuyFittingsModal] = useState(false);
    const [openSetFoxerDronesModal, setOpenSetFoxerDronesModal] = useState(false);

    let hasFoxerDrones = false;
    const foxerDrones = props.shipTraits.fittings.find((f) => f.id === "SD5");
    if (foxerDrones) {
        hasFoxerDrones = true;
    }

    return (
        <div className="chargenSection">

            <h2>Defenses</h2>

            {!props.readOnly &&
                <div>
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenBuyFittingsModal(true)}>Select Defences</button>
                </div>
            }

            <ListFittingsInstalled shipTraits={props.shipTraits} type="defenses" />

            {!props.readOnly && hasFoxerDrones &&
                <div className="mt-2">
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenSetFoxerDronesModal(true)}>Select Additional Foxer Drones</button>
                </div>
            }

            <BuyFittingsModal
                shipTraits={props.shipTraits}
                itemType="defenses"
                displayModal={openBuyFittingsModal}
                onBuyOrSellFitting={(id, isBuy) => props.onBuyOrSellFitting(id, isBuy)}
                onClose={() => setOpenBuyFittingsModal(false)}
            />

            <SelectFoxerDronesModal
                shipTraits={props.shipTraits}
                displayModal={openSetFoxerDronesModal}
                onSetFoxerDronesExtraMass={props.onSetFoxerDronesExtraMass}
                onSetFoxerDronesRemaining={props.onSetFoxerDronesRemaining}
                onClose={() => setOpenSetFoxerDronesModal(false)}
            />

        </div >
    );

}

export default ShipDesignStep4Defenses;