import React, { useState } from "react";

import { Lookups } from "../../lookups/Lookups";

import { CharacterTraits, EquipmentItem } from "../../interfaces/CharacterTraits";

import $ from "jquery";

import { GearModData } from "../../interfaces/GearModData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faRecycle, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import DescriptionAlert from "../DescriptionAlert";

import { formatCredits as formatCreditsStandard } from "../../utilities/Utilities";

import uniqid from "uniqid";
import { CharacterDerivedStats } from "../../classes/CharacterDerivedStats";
import ValidationAlert from "../ValidationAlert";


interface IProps {
    displayModal: boolean;
    char: CharacterTraits;
    charDerivedStats: CharacterDerivedStats;
    itemUniqueId: string;
    allowDebt: boolean;
    onlyIncludeAffordableItems: boolean;
    includeArtifacts: boolean;
    onAddMod: (itemUniqueId: string, modId: string, isAddMod: boolean) => void;
    onSetCustomName: (itemUniqueId: string, customName: string) => void;
    onSetCustomNotes: (itemUniqueId: string, customNotes: string) => void;
    onClose: () => void;
    onSetAllowDebt: (allowDebt: boolean) => void;
    onSetOnlyIncludeAffordableItems: (onlyIncludeAffordableItems: boolean) => void;
    onSetIncludeArtifacts: (includeArtifacts: boolean) => void;
}

const CustomizeGearModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const lookups = Lookups.getInstance();

    const initialDisplayGear: string[] = [];
    const [displayGearDesc, setDisplayGearDesc] = useState(initialDisplayGear);

    if (props.displayModal) {
        ($('#myCustomizeGearModal') as any).modal('show');
    }

    if (!props.displayModal) {
        ($('#myCustomizeGearModal') as any).modal('hide');
    }

    const onCloseModal = () => {
        props.onClose();
    }

    const item = props.char.gear.equipment.find((g) => g.uniqid === props.itemUniqueId);

    const disableBuy = (hasMod: boolean, g: GearModData, charDerivedStats: CharacterDerivedStats): boolean => {

        let canAfford = true;
        if (!props.allowDebt) {
            canAfford = g.cost <= charDerivedStats.creditsAvailable;
        } else {
            canAfford = true;
        }

        const buyMultiple = g.buyMultiple === undefined ? false : g.buyMultiple;

        if (buyMultiple) {
            if (!canAfford) { return true; }
            return false;
        } else {
            if (!hasMod) {
                return !canAfford;
            } else {
                return true;
            }
        }

    }

    const disableSell = (hasMod: boolean, g: GearModData, char: CharacterTraits): boolean => {
        return !hasMod;
    }

    const onSetShowDescription = (id: string, name: string) => {
        let updatedDisplayGearDesc = [...displayGearDesc];
        if (displayGearDesc.indexOf(id + "_" + name) !== -1) {
            updatedDisplayGearDesc = updatedDisplayGearDesc.filter((g) => g !== id + "_" + name);
        } else {
            updatedDisplayGearDesc.push(id + "_" + name)
        }
        setDisplayGearDesc(updatedDisplayGearDesc);
    }

    const onBuyMod = (modId: string) => {
        props.onAddMod(props.itemUniqueId, modId, true);
    }

    const onSellMod = (modId: string) => {
        props.onAddMod(props.itemUniqueId, modId, false);
    }

    const getNumberOwned = (uniqueItemId: string, modId: string) => {
        const theItem = props.char.gear.equipment.find((g) => g.uniqid === uniqueItemId);
        if (theItem) {
            if (theItem.mods) {
                const theMod = theItem.mods.find((m) => m.id === modId);
                if (theMod) {
                    return <span> (own {theMod.quantity})</span>;
                }
            }
        }
        return null;
    }

    const getCanBuyMultiple = (gm: GearModData) => {
        if (gm.buyMultiple) { return <sup>m</sup>; }
        return null;
    }

    const formatFix = (fix: number | undefined) => {
        if (fix === undefined) { return ""; }
        return "(Fix-" + fix + ")";
    }

    const formatTL = (tl: number | undefined) => {
        if (tl === undefined) { return ""; }
        return "(TL " + tl + ")";
    }

    const formatCredits = (cost: number, salvageUnits: number | undefined, baseItemCostMultiplier: number | undefined, modCostMultiplier: number | undefined) => {

        const formatThousands = (credits: number) => {
            if (credits < 1000) {
                return "$" + credits.toLocaleString('en-US', { style: 'decimal', currency: 'USD' });
            } else {
                return "$" + (credits / 1000).toLocaleString('en-US', { style: 'decimal', currency: 'USD' }) + "k";
            }
        }

        let output = "";
        if (baseItemCostMultiplier !== undefined) {
            output = "(x" + baseItemCostMultiplier + ") " + formatThousands(cost); //  " ($" + costAfterMult.toLocaleString('en-US', { style: 'decimal', currency: 'USD' }) + ")";
        } else if (modCostMultiplier !== undefined) {
            output = "(x" + modCostMultiplier + ") " + formatThousands(cost); // " ($" + costAfterMult.toLocaleString('en-US', { style: 'decimal', currency: 'USD' }) + ")";
        } else {
            output = formatThousands(cost);
        }
        if (salvageUnits) {
            output = output + "+" + salvageUnits + "su";
        }
        return output;
    }

    const getModControls = (gearMods: GearModData[]) => {
        return gearMods.map((g) => {

            let hasMod = false;
            const gearItem = props.char.gear.equipment.find((g) => g.uniqid === props.itemUniqueId);
            if (gearItem) {
                if (gearItem.mods) {
                    const theMod = gearItem.mods.find((mod) => mod.id === g.id);
                    if (theMod) { hasMod = true; }
                }
            }

            let className = "row border-bottom pt-1 pb-1";
            if (hasMod) { className = className + " table-primary"; }

            return (
                <div className={className} key={g.id + "_" + g.name + "_gb"} >
                    <div className="col-9 col-sm-9 col-md-9 col-lg-5 col-xl-4 small">

                        <button disabled={disableBuy(hasMod, g, props.charDerivedStats)} className="btn btn-primary btn-tiny d-inline" onClick={() => onBuyMod(g.id)}>
                            <FontAwesomeIcon icon={faShoppingCart} title="Buy"></FontAwesomeIcon >
                        </button>

                        <button disabled={disableSell(hasMod, g, props.char)} className="btn btn-primary btn-tiny d-inline" onClick={() => onSellMod(g.id)}>
                            <FontAwesomeIcon icon={faRecycle} title="Sell"></FontAwesomeIcon >
                        </button>

                        <button className="btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(g.id, g.name)} title="Show item description">
                            <FontAwesomeIcon icon={faEye} title="Show item description"></FontAwesomeIcon >
                        </button>

                        <span className="itemName">{g.name}</span>{getCanBuyMultiple(g)}{getNumberOwned(props.itemUniqueId, g.id)}
                    </div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 small text-right">{formatCredits(g.cost, g.salvageUnits, g.baseCostMultiplier, g.modCostMultiplier)}</div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-6 small">{g.shortDesc} {formatFix(g.fix)} {formatTL(g.techLevel)}</div>
                    <div className="col-12">
                        <DescriptionAlert display={displayGearDesc.indexOf(g.id + "_" + g.name) !== -1}>
                            {g.desc}
                        </DescriptionAlert>
                    </div>
                </div>
            )
        }
        );
    }

    const updateModPrices = (item: EquipmentItem) => {

        const rawItem = lookups.gear.find((i) => i.id === item.id);
        if (rawItem) {
            let gearMods = [...lookups.gearMods];

            gearMods.forEach((gm) => {
                if (gm.modCostMultiplier !== undefined) {
                    gm.cost = rawItem.cost * gm.modCostMultiplier;
                }
                if (gm.baseCostMultiplier !== undefined) {
                    gm.cost = (rawItem.cost * (gm.baseCostMultiplier)) - rawItem.cost;
                }
            })
            return gearMods;
        }

        return [] as GearModData[];
    }

    const getIsArtifact = (item: EquipmentItem | undefined) => {
        if (item === undefined) { return false; }
        if (item.type === "Armor" && item.subtype === "Artifact Armor") { return true; }
        if (item.type === "General Equipment" && item.subtype === "Artifacts") { return true; }
        return false;
    }

    const getIsRobot = (item: EquipmentItem | undefined) => {
        if (item === undefined) { return false; }
        if (item.type === "Robots") { return true; }
        return false;
    }

    const isArtifact = getIsArtifact(item);
    const isRobot = getIsRobot(item);

    const getGearModsToBuy = () => {
        let itemsControls: any = [];

        if (item) {

            const filterGear = (g: GearModData) => {

                let cost = g.cost;

                if (props.onlyIncludeAffordableItems) {
                    if (props.allowDebt) {
                        return true;
                    } else {
                        const canAfford = cost <= props.charDerivedStats.creditsAvailable;

                        let alreadyHas = false;
                        const theItem = props.char.gear.equipment.find((e) => e.uniqid === props.itemUniqueId);
                        if (theItem) {
                            const theMod = theItem.mods && theItem.mods.find((m) => m.id === g.id);
                            if (theMod !== undefined) {
                                alreadyHas = true;
                            }
                        }

                        return canAfford || alreadyHas;
                    }
                } else {
                    return true;
                }
            }

            // update prices of the mods relative to this item

            // drone-specific mods (Fittings)

            const pricedGearMods = updateModPrices(item);

            if (item.type === "Drones") {

                const droneMods = pricedGearMods.filter((gm) => gm.type === "droneFitting").filter((g) => filterGear(g));
                itemsControls.push(<h3 key={uniqid()}>Drone Fittings</h3>)
                const droneControls = getModControls(droneMods);
                itemsControls = [...itemsControls, droneControls]
                if (droneMods.length === 0) {
                    itemsControls.push(<div key={uniqid()} className="small">You cannot afford any of these items</div>)
                } else {
                    itemsControls.push(<div key={uniqid()} className="small"><sup>m</sup>: Can buy multiple times</div>)
                }

                // Validate for maximum number of fittings permitted.
                const maxFittings = item.fittings !== undefined ? item.fittings : 0;
                let numDroneFittingsInstalled = 0;
                if (maxFittings !== 0) {
                    if (item.mods) {
                        item.mods.forEach((m) => {
                            const theMod = lookups.gearMods.find((gm) => gm.id === m.id);
                            if (theMod) {
                                if (theMod.type === "droneFitting") { numDroneFittingsInstalled = numDroneFittingsInstalled + m.quantity; }
                            }
                        })
                    }
                    if (numDroneFittingsInstalled > maxFittings) {
                        itemsControls.push(
                            <div key={uniqid()}>
                                <ValidationAlert msg={item.name + " can have a maximum of " + maxFittings + " fittings"} />
                            </div>
                        );
                    }
                }

            }

            // add build from scratch mods:

            if (!isArtifact) {
                const buildMods = pricedGearMods.filter((gm) => gm.type === "build").filter((g) => filterGear(g));
                let className = "";
                if (item.type === "Drones") { className = "mt-3" }
                itemsControls.push(<h3 key={uniqid()} className={className}>Built from Scratch</h3>)
                const buildControls = getModControls(buildMods);
                itemsControls = [...itemsControls, buildControls]
                if (buildMods.length === 0) {
                    itemsControls.push(<div key={uniqid()} className="small">You cannot afford any of these items</div>)
                } else {
                    itemsControls.push(<div key={uniqid()} className="small">x[N]: Base item's cost is multiplied by N</div>)
                }
            }

            // add RobotCharacter-only mods:
            let isVI = props.charDerivedStats.focusLevels.find((f) => f.focus.indexOf("VI ") !== -1);
            if (isVI) {
                // All Bots:
                if (item.type !== "Vehicles") {
                    const botMods = pricedGearMods.filter((gm) => gm.type === "bot").filter((g) => filterGear(g));
                    itemsControls.push(<h3 key={uniqid()} className="mt-3">VI Robot Mods</h3>)
                    const botControls = getModControls(botMods);
                    itemsControls = [...itemsControls, botControls]
                    if (botControls.length === 0) {
                        itemsControls.push(<div key={uniqid()} className="small">You cannot afford any of these items</div>)
                    } else {
                        itemsControls.push(<div key={uniqid()} className="small">x[N]: Base item's cost is multiplied by N</div>)
                    }
                }
                // Vehicle Bots
                const isVIRobotVehicle = props.charDerivedStats.focusLevels.find((fl) => fl.focus === "VI - Vehicle Bot");
                if (isVIRobotVehicle && item.type === "Vehicles") {
                    const vehiclebotMods = pricedGearMods.filter((gm) => gm.type === "vehicleBot").filter((g) => filterGear(g));
                    itemsControls.push(<h3 key={uniqid()} className="mt-3">VI Vehicle Bot Mods</h3>)
                    const vehiclebotControls = getModControls(vehiclebotMods);
                    itemsControls = [...itemsControls, vehiclebotControls]
                    if (vehiclebotControls.length === 0) {
                        itemsControls.push(<div key={uniqid()} className="small">You cannot afford any of these items</div>)
                    } else {
                        itemsControls.push(<div key={uniqid()} className="small">x[N]: Base item's cost is multiplied by N</div>)
                    }
                }
            }

            if (isRobot) {
                const robotMods = pricedGearMods.filter((gm) => gm.type === "robotMod").filter((g) => filterGear(g));
                itemsControls.push(<h3 key={uniqid()} className="mt-3">Robot Mods</h3>)
                const robotControls = getModControls(robotMods);
                itemsControls = [...itemsControls, robotControls]
                if (robotMods.length === 0) {
                    itemsControls.push(<div key={uniqid()} className="small">You cannot afford any of these items</div>)
                } else {
                    itemsControls.push(<div key={uniqid()} className="small">x[N]: Base item's cost is multiplied by N</div>)
                }
            }

            // add general mods:
            if (!isArtifact) {
                const generalMods = pricedGearMods.filter((gm) => gm.type === "general").filter((g) => filterGear(g));
                itemsControls.push(<h3 key={uniqid()} className="mt-3">General Mods</h3>)
                const generalControls = getModControls(generalMods);
                itemsControls = [...itemsControls, generalControls]
                if (generalMods.length === 0) {
                    itemsControls.push(<div key={uniqid()} className="small">You cannot afford any of these items</div>)
                } else {
                    itemsControls.push(<div key={uniqid()} className="small">x[N]: Mod costs N times item's base cost; +[N]su: Mod also costs N salvage units</div>)
                }
            }

            // add artifact mods:
            if (props.includeArtifacts) {
                const artifactMods = pricedGearMods.filter((gm) => gm.type === "artifactMod").filter((g) => filterGear(g));
                itemsControls.push(<h3 key={uniqid()} className="mt-3">Artifact Manufacture</h3>)
                const artifactControls = getModControls(artifactMods);
                itemsControls = [...itemsControls, artifactControls]
                if (artifactMods.length === 0) {
                    itemsControls.push(<div key={uniqid()} className="small">You cannot afford any of these items</div>)
                }
            }

        }

        return itemsControls;
    }

    const getTotalItemCost = (item: EquipmentItem | undefined) => {

        if (item === undefined) { return 0; }

        const pricedGearMods = updateModPrices(item);

        let rawItemCost = 0;

        const rawItem = lookups.gear.find((i) => i.id === item.id)
        if (rawItem) {
            rawItemCost = rawItem.cost;
        };

        let totalModCost = 0;
        if (item.mods) {
            item.mods.forEach((mod) => {
                const pricedMod = pricedGearMods.find((pgm) => pgm.id === mod.id);
                if (pricedMod) {
                    const modCost = pricedMod.cost * mod.quantity;
                    totalModCost = totalModCost + modCost;
                }
            })
        }

        return rawItemCost + totalModCost;
    }

    const getRawItemCost = (item: EquipmentItem | undefined) => {
        if (item === undefined) { return 0; }
        const rawItem = lookups.gear.find((i) => i.id === item.id)
        if (rawItem) {
            return rawItem.cost;
        };
        return 0;
    }

    const getCustomName = (item: EquipmentItem | undefined) => {
        if (item === undefined) { return ""; }
        if (item.customName) {
            return item.customName;
        };
        return "";
    }

    const getCustomNotes = (item: EquipmentItem | undefined) => {
        if (item === undefined) { return ""; }
        if (item.customNotes) {
            return item.customNotes;
        };
        return "";
    }

    return (
        <div id="myCustomizeGearModal" className="modal " tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Customize {item?.name}</h5>
                        <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="ml-2 mr-2 mb-1 mt-1 small">
                        <label><input type="checkbox" checked={props.allowDebt} onChange={() => props.onSetAllowDebt(!props.allowDebt)}></input> Allow going into debt</label>
                    </div>
                    {!props.allowDebt &&
                        <div className="ml-2 mr-2 small">
                            <label><input type="checkbox" checked={props.onlyIncludeAffordableItems} onChange={() => props.onSetOnlyIncludeAffordableItems(!props.onlyIncludeAffordableItems)}></input> Only display items you have enough credits to buy</label>
                        </div>
                    }
                    <div className="ml-2 mr-2 small">
                        <label><input type="checkbox" checked={props.includeArtifacts} onChange={() => props.onSetIncludeArtifacts(!props.includeArtifacts)}></input> Include Artifact Manufacture mods</label>
                    </div>
                    <div className="ml-2 mr-2 small">
                        <ul>
                            <li><b>Credits Remaining:</b> {formatCreditsStandard(props.charDerivedStats.creditsAvailable)}</li>
                            <li><b>Unmodded Item Price (ea.):</b> {formatCreditsStandard(getRawItemCost(item))}</li>
                            <li><b>Modded Item Price (ea.):</b> {formatCreditsStandard(getTotalItemCost(item))}</li>
                        </ul>
                    </div>
                    <div className="ml-2 mr-2">
                        <input className="form-control" type="text" placeholder="Enter custom item name (if desired)" value={getCustomName(item)} onChange={(e: any) => props.onSetCustomName(props.itemUniqueId, e.currentTarget.value)} />
                    </div>
                    <div className="ml-2 mr-2">
                        <input className="form-control" type="text" placeholder="Enter item notes (if desired)" value={getCustomNotes(item)} onChange={(e: any) => props.onSetCustomNotes(props.itemUniqueId, e.currentTarget.value)} />
                    </div>

                    {isArtifact && !props.includeArtifacts &&
                        <div className="ml-3 mr-2 mt-1">
                            Artifacts cannot generally be modded.
                        </div>
                    }

                    <div className="modal-body">
                        {/* {props.itemUniqueId}
                        {JSON.stringify(props.char.gear.equipment.find((g) => g.uniqid === props.itemUniqueId), null, 2)} */}
                        {getGearModsToBuy()}
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomizeGearModal;

