import React from "react";
import uniqid from "uniqid";
import { ShipDerivedStats, WeaponInstalled } from "../../classes/ShipDerivedStats";
import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { Lookups } from "../../lookups/Lookups";
import { formatDamage, formatLargeCredits } from "../../utilities/Utilities";

interface IProps {
    shipTraits: StarshipTraits;
}

const ListWeaponsInstalled: React.FunctionComponent<IProps> = (props: IProps) => {

    const lookups = Lookups.getInstance();

    const shipStatsDerived = new ShipDerivedStats(props.shipTraits);

    let output: any = [];
    shipStatsDerived.weaponsInstalled_NotGrouped.filter((f) => f.fittingType === "weapons").forEach((wi) => {

        let name = "?";
        let shortDesc = "?";
        const theFitting = lookups.shipFittings.find((fit) => fit.id === wi.id);
        if (theFitting) {

            //name
            name = theFitting.name;

            let output: string[] = [];

            const damage = formatDamage(theFitting.weaponDamage);
            output.push(damage);

            const thisWeapon = lookups.shipFittings.find((sf) => sf.id === theFitting.id);
            if (thisWeapon && thisWeapon.effects) {
                thisWeapon.effects.forEach((ef) => {
                    if (ef.indexOf("AMMO") !== -1) {
                        output.push("Ammo " + wi.ammunitionRemaining + " (of " + wi.ammunition + ")");
                    } else {
                        output.push(ef.replace(":", " "));
                    }
                })
            }

            // add if has any auto-target systems
            const thisWeaponTargeter = props.shipTraits.autoTargetingWeapons.find((atw) => atw.weaponUniqId === wi.uniqid);
            if (thisWeaponTargeter) { output.push("auto-targeted"); }

            shortDesc = output.join(", "); // damage + notes + autoTargeters;
        }

        const getPrice = (weapon: WeaponInstalled) => {
            if (weapon.ammunition > 0) {
                return formatLargeCredits(weapon.totalCost) + "/" + formatLargeCredits(weapon.ammunitionCost);
            }
            return formatLargeCredits(weapon.totalCost);
        }

        const getExtraAmmo = (ammunitionMass: number) => {
            if (ammunitionMass > 0) {
                return " (+" + ammunitionMass + " ammo)";
            }
            return null;
        }

        output.push(
            <div className="row border-bottom pt-1 pb-1" key={uniqid()} >
                <div className={"col-8 col-sm-8 col-md-4 col-lg-3 col-xl-3 small"}><b>{name}{getExtraAmmo(wi.ammunitionMass)}</b> </div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>x{wi.quantity}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>{getPrice(wi)}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Power&nbsp;{wi.totalPower}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Mass&nbsp;{wi.totalMass}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Hard.&nbsp;{wi.totalHardPoints}</div>
                <div className={"col-8 col-sm-8 col-md-10 col-lg-4 col-xl-4 small"}>{shortDesc}</div>
            </div>
        )
    });

    if (output.length === 0) {
        output.push(
            <div className="row border-bottom pt-1 pb-1" key={uniqid()} >
                <div className={"col-12 small"}><b>None</b></div>
            </div>
        )
    }
    return <>{output}</>;
}

export default ListWeaponsInstalled;
