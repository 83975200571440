import React from 'react';
import uniqid from 'uniqid';
import { CharacterDerivedStats, CreationStep } from '../classes/CharacterDerivedStats';
import { CharacterTraits, ClassTrait } from '../interfaces/CharacterTraits';
import { DieRoll } from '../interfaces/DieRoll';
import { FocusLevel } from '../interfaces/FocusLevel';
import { PsychicTechniqueLevel } from '../interfaces/PsychicTechniqueLevel';
import { WeaponStat } from '../interfaces/WeaponStat';
import { Lookups } from '../lookups/Lookups';
import { getSkillsByNotType, getSkillsByType } from '../utilities/SkillUtilities';
import { attributeMethod, getRerolls } from '../utilities/Utilities';

import { formatDieRoll, getAttributeModifierPlusMinus, PlusMinus } from "../utilities/Utilities";
import ValidationAlert from './ValidationAlert';

export interface IProps {
    charTraits: CharacterTraits;
}

const CharacterDesignDerivedStats: React.FunctionComponent<IProps> = (props: IProps) => {

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    const lookups = Lookups.getInstance();

    charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 100, 100);
    const attributesAtLastStep = charDerivedStats.attributeLevels;

    charDerivedStats.calculateSkillLevels(CreationStep.AllSteps, -1, -1, 100, 100);
    const skillsAtLastStep = charDerivedStats.skillLevels;

    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);
    const focusesAtLastStep = charDerivedStats.focusLevels;

    charDerivedStats.calculateSkillPointsSpentSoFar(CreationStep.AllSteps, 100, 100);

    charDerivedStats.calculatePsychicTechniqueLevels(CreationStep.AllSteps, props.charTraits.level, 100);
    const psychicTechniquesAtLastStep = charDerivedStats.psychicTechniqueLevels;

    charDerivedStats.calculateHitPointsLevels(CreationStep.AllSteps, props.charTraits.level);
    const hitPointsAtLastStep = charDerivedStats.hitPointsLevels;

    charDerivedStats.calculateBaseAttack(CreationStep.AllSteps);
    const baseAttackAtLastStep = charDerivedStats.baseAttackLevels;

    charDerivedStats.calculateSavingThrows(CreationStep.AllSteps);
    const savesAtLastStep = charDerivedStats.savingThrowLevels;

    charDerivedStats.calculateEffort(CreationStep.AllSteps);
    const effortAtLastStep = charDerivedStats.effortLevels;

    charDerivedStats.calculateArmorClassVsPrimitiveWeapons(CreationStep.AllSteps);
    charDerivedStats.calculateArmorClassVsAdvancedWeapons(CreationStep.AllSteps);
    charDerivedStats.calculateRangedWeaponStats(CreationStep.AllSteps, true);
    charDerivedStats.calculateMeleeWeaponStats(CreationStep.AllSteps, true);
    charDerivedStats.calculateDroneStats(CreationStep.AllSteps, true);

    charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps);
    charDerivedStats.calculateSystemStrain();

    const derivedAttributesDisplay = attributesAtLastStep.map((a, index) => {
        let mod = "";
        if (a.level) {
            mod = getAttributeModifierPlusMinus(a.attributeName, a.level, charDerivedStats);
        }

        return (
            <div key={uniqid()}><b>{a.attributeName} {a.level} ({mod}):</b> {a.history.join("; ")}</div>
        )
    });

    const showLevel = (s: any) => s === null ? "" : "-" + (s - 1);
    const untrained = (s: any) => s === null ? "untrained" : "";

    let isPsychic = false;
    let isWildTalent = false;

    const psychicClasses = props.charTraits.levelOne.classes.filter((c) => c.className === "Psychic" || c.className === "Partial Psychic");
    if (psychicClasses.length > 0) { isPsychic = true; }

    const wildTalentFocus = focusesAtLastStep.find((f) => f.focus === "Wild Psychic Talent");
    if (wildTalentFocus) { isWildTalent = true; }

    const allNonPsychicSkills = getSkillsByNotType("Psychic", lookups.skills);
    const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
    const allCharactersNonPsychicSkills = skillsAtLastStep.filter((s) => allNonPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));
    const allCharactersPsychicSkills = skillsAtLastStep.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));

    const derivedNonPsychicSkillsDisplay = allCharactersNonPsychicSkills.filter((s) => s.level !== null && s.level > -1).map((s) =>
        <div key={uniqid()}><b>{s.skill}{showLevel(s.level)}:</b> {untrained(s.level)}{s.history.join("; ")}</div>
    );

    const derivedPsychicSkillsDisplay = allCharactersPsychicSkills.filter((s) => s.level !== null && s.level > -1).map((s) =>
        <div key={uniqid()}><b>{s.skill}{showLevel(s.level)}:</b> {untrained(s.level)}{s.history.join("; ")}</div>
    );

    const sortTechniques = (a: PsychicTechniqueLevel, b: PsychicTechniqueLevel) => {
        // sort by skill, then level, then technique
        if (a.skill > b.skill) {
            return 1
        } else {
            if (a.level > b.level) {
                return 1
            } else {
                if (a.technique > b.technique) {
                    return 1;
                } else {
                    return -1;
                }

            }
        }
    }

    const getDerivedPsychicTechniquesDisplay = () => {

        let output: any[] = [];

        // Separate into array of skills
        function onlyUnique(value: any, index: any, self: any) {
            return self.indexOf(value) === index;
        }

        // Display techniques gained by earning psychic skill levels.
        let techNumber = 0;
        const distinctSkills = psychicTechniquesAtLastStep.map((pt) => pt.skill).filter(onlyUnique).sort();
        distinctSkills.forEach((ds) => {
            const theSkill = charDerivedStats.skillLevels.find((sl) => sl.skill === ds);
            if (theSkill && theSkill.level) {
                output.push(<li key={uniqid()}><b>{ds}-{theSkill.level - 1}</b>:</li>);
                techNumber = techNumber + 1;
                // Get techniques for this skill
                const techniques = psychicTechniquesAtLastStep.sort(sortTechniques).filter((pt) => pt.skill === ds);
                let techniqueOutput: any[] = [];
                techniques.forEach((t) => {

                    if (t.level === 0) {
                        let levelDesc = "";
                        if (theSkill.level) {
                            if (theSkill.level === 1) {
                                levelDesc = "Level-0";
                            } else {
                                levelDesc = "Levels 0-" + (theSkill.level - 1);
                            }
                        }
                        techniqueOutput.push(<li key={uniqid()}><b>{t.technique}:</b> (Core technique {levelDesc}): {t.history.join("; ")}</li>);
                        techNumber = techNumber + 1;
                    }

                    if (t.level > 0) {
                        techniqueOutput.push(<li key={uniqid()}><b>{t.technique}:</b> (Level-{t.level} technique): {t.history.join("; ")}</li>);
                        techNumber = techNumber + 1;
                    }
                })
                output.push(<ul key={uniqid()}>{techniqueOutput}</ul>)
            }

        });

        // display techniques gained through Wild Talent foci
        if (props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline !== "") {
            const effectiveSkill = props.charTraits.levelOne.wildTalentPicks.wildTalentPsychicDiscipline;
            output.push(<li><b>{effectiveSkill}</b>:</li>);
            const techniques = psychicTechniquesAtLastStep;
            let techniqueOutput: any[] = [];
            techniques.forEach((t) => {
                if (t.level === 0) {
                    techniqueOutput.push(<li key={uniqid()}><b>{t.technique}:</b> (Core technique Level-0): {t.history.join("; ")}</li>);
                }
                if (t.level > 0) {
                    techniqueOutput.push(<li key={uniqid()}><b>{t.technique}:</b> (Level-{t.level} technique): {t.history.join("; ")}</li>);
                }
            })
            output.push(<ul>{techniqueOutput}</ul>)
        }


        return <ul>{output}</ul>;
    }

    const getRangedWeaponDesc = (r: WeaponStat) => {
        let d: string[] = [];

        d.push(PlusMinus(r.toHitBonus) + " to hit");
        d.push(formatDieRoll(r.damage) + " dmg");
        d.push("rng " + r.range.join("/"));

        if (r.mag > 0) {
            const formatMag = (mag: number) => mag > 1000 ? "Unlimited" : mag;
            d.push("mag " + formatMag(r.mag));
        }

        if (r.burst) {
            const burstDamage: DieRoll = { numDice: r.damage.numDice, dieSize: r.damage.dieSize, bonus: r.damage.bonus + 2 };
            d.push("burst fire (uses 3 ammo, " + PlusMinus(r.toHitBonus + 2) + " to hit, " + formatDieRoll(burstDamage) + " dmg)");
        }

        if (r.suppress) {
            d.push("can fire to suppress");
        }

        const add1d4DamageOnShootMiss = charDerivedStats.focusLevels.find((fl) => fl.focus === "Gunslinger" && fl.level === 2);
        if (add1d4DamageOnShootMiss) { d.push("1d4 damage on a miss"); }

        const toHit = <li>{PlusMinus(r.toHitBonus)} to hit ({r.toHitBonusNote})</li>
        const damage = <li>{formatDieRoll(r.damage)} damage ({r.damageNote})</li>

        d.push("TL " + r.techLevel);

        const notes = r.notes.map((n, index) => <li key={"n" + index}>{n}</li>);

        const details = <ul>{toHit}{damage}{notes}</ul>;

        return <li key={uniqid()}><b>{r.name}:</b> {d.join(", ")}{details}</li>;

    }

    const getMeleeWeaponDesc = (r: WeaponStat) => {
        let d: string[] = [];
        const isPunchWeapon = r.name === "Unarmed Attack";

        d.push(PlusMinus(r.toHitBonus) + " to hit");
        d.push(formatDieRoll(r.damage) + " dmg");

        if (r.shockDamage) {
            d.push("Shock " + r.shockDamage + "pts/AC" + r.shockAC);
        }

        d.push(r.attributes);

        const toHit = <li>{PlusMinus(r.toHitBonus)} to hit ({r.toHitBonusNote})</li>
        const damage = <li>{formatDieRoll(r.damage)} damage ({r.damageNote})</li>

        let shock = null;
        if (r.shockNotes.length > 0) {
            shock = <li>Shock {r.shockDamage}pts/AC{r.shockAC} ({r.shockNotes.join(", ")})</li>;
        }

        if (!isPunchWeapon) {
            const Armsman2 = charDerivedStats.focusLevels.find((fl) => fl.focus === "Armsman" && fl.level === 2);
            if (Armsman2) { d.push("1d4 dmg on a miss in melee (plus any Shock dmg)"); }
        }

        const UC2 = charDerivedStats.focusLevels.find((fl) => fl.focus === "Unarmed Combatant" && fl.level === 2);
        if (UC2) { d.push("1d6 dmg on a miss"); }

        d.push("TL " + r.techLevel);

        const notes = r.notes.map((n, index) => <li key={index}>{n}</li>);

        const details = <ul>{toHit}{damage}{shock}{notes}</ul>;

        return <li key={r.name}><b>{r.name}:</b> {d.join(", ")}{details}</li>;

    }

    const derivedPsychicTechniquesDisplay = getDerivedPsychicTechniquesDisplay();

    const sortFocuses = (a: FocusLevel, b: FocusLevel) => {
        if (a.focus === b.focus) {
            return a.level - b.level;
        } else {
            if (a.focus > b.focus) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    const derivedFocusesDisplay = focusesAtLastStep.sort(sortFocuses).map((f) =>
        <div key={uniqid()}><b>{f.focus}-{f.level}</b>: {f.history.join("; ")}</div>
    );

    const hpRerollPlural = props.charTraits.levelOne.hitPointRerolls === 1 ? "" : "s";
    const hitPointRerolls = props.charTraits.levelOne.hitPointRerolls > 0 ? "; " + props.charTraits.levelOne.hitPointRerolls + " reroll" + hpRerollPlural : "";

    let derivedHitPointsDisplay: any = null;
    const finalHP = hitPointsAtLastStep.find((hp) => hp.level === props.charTraits.level);
    if (finalHP) {
        derivedHitPointsDisplay = <div className="mb-2"><b>Hit Points:</b> {finalHP.totalHitPointsAtLevel} ({finalHP.history.join("; ")}){hitPointRerolls}</div>
    }

    const derivedBaseAttackDisplay = <div className="mb-2"><b>Base Attack:</b> +{baseAttackAtLastStep.baseAttack} ({baseAttackAtLastStep.history.map((h) => h.history).join("; ")})</div>
    const derivedEffortDisplay = effortAtLastStep.effort > 0 ? <div className="mb-2"><b>Effort:</b> {effortAtLastStep.effort} ({effortAtLastStep.history.join("; ")})</div> : null;

    const getClassName = (classes: ClassTrait[]) => {
        if (classes.length === 0) { return "Not selected"; }

        if (classes.length === 1) {
            return classes[0].className;
        }

        return "Adventurer (" + classes.map((c) => c.className).join("/") + ")";
    }

    const getOrigin = (charDerivedStats: CharacterDerivedStats) => {
        let origin = "Human";

        let isVI = charDerivedStats.focusLevels.find((f) => f.focus.indexOf("VI ") !== -1);
        if (isVI) {
            origin = isVI.focus;
        }

        let isAlien = charDerivedStats.focusLevels.find((f) => f.focus.indexOf("Alien -") !== -1);
        if (isAlien) {
            origin = isAlien.focus;
        }

        return origin;
    }

    const toNotSelected = (text: string) => {
        if (text.trim() === "") { return "Not selected"; }
        return text;
    }

    const toNA = (text: string) => {
        if (text.trim() === "") { return "n/a"; }
        return text;
    }

    return (
        <div>

            {!props.charTraits.isValid &&
                <div className="mb-2">
                    <ValidationAlert msg="Character has validation errors." />
                </div>
            }

            <div className="mb-3">
                <div><b>Name:</b> {toNotSelected(props.charTraits.basicTraits.name)}</div>
                <div><b>Origin:</b> {getOrigin(charDerivedStats)}</div>
                <div><b>Class:</b> {getClassName(props.charTraits.levelOne.classes)}</div>
                <div><b>Level:</b> {props.charTraits.level}</div>
                <div><b>Background:</b> {toNotSelected(props.charTraits.background.backgroundName)}</div>

                <div><b>Homeworld:</b> {toNA(props.charTraits.background.homeworld)}</div>
                <div><b>Languages:</b> {toNA(props.charTraits.background.languages)}</div>
            </div>

            <h3>Attributes:</h3>

            <div className="mb-3">
                <div>{attributeMethod(props.charTraits.attributeTraits.method)} {getRerolls(props.charTraits)}</div>
                {derivedAttributesDisplay}
            </div>

            <h3>Skills:</h3>

            <div className="mb-3">
                {derivedNonPsychicSkillsDisplay}
                {allCharactersNonPsychicSkills.length === 0 &&
                    <>None selected</>
                }
            </div>

            <h3>Foci:</h3>

            <div className="mb-3">
                {derivedFocusesDisplay}
                {focusesAtLastStep.length === 0 &&
                    <>None selected</>
                }
            </div>

            {isPsychic &&
                <>
                    <h3>Psychic Skills:</h3>

                    <div className="mb-3">
                        {derivedPsychicSkillsDisplay}
                        {allCharactersPsychicSkills.length === 0 &&
                            <>None selected</>
                        }
                    </div>
                </>
            }

            {(isPsychic || isWildTalent) &&
                <>
                    <h3>Psychic Techniques:</h3>

                    <div className="mb-3">
                        {derivedPsychicTechniquesDisplay}
                        {psychicTechniquesAtLastStep.length === 0 &&
                            <>None selected</>
                        }
                    </div>

                    {derivedEffortDisplay}
                </>
            }

            <h3>Other Statistics:</h3>

            {derivedHitPointsDisplay}
            {derivedBaseAttackDisplay}
            <div className="mb-3">
                <b>Saving Throws:</b>
                <ul className="mb-0">
                    <li><b>Physical {savesAtLastStep.physical.score}</b> ({savesAtLastStep.physical.history.join(", ")})</li>
                    <li><b>Mental {savesAtLastStep.mental.score}</b>  ({savesAtLastStep.mental.history.join(", ")})</li>
                    <li><b>Evasion {savesAtLastStep.evasion.score}</b>  ({savesAtLastStep.evasion.history.join(", ")})</li>
                    {charDerivedStats.savingThrowLevels.notes.length > 0 &&
                        <>
                            {charDerivedStats.savingThrowLevels.notes.map((n: string) => <li key={uniqid()}>{n}</li>)}
                        </>
                    }
                </ul>
            </div>

            <div className="mb-3">
                <b>Armor Class:</b>
                <ul>
                    <li><b>AC {charDerivedStats.armorClassLevels.ac}</b> vs low-tech (TL3 or lower) weapons
                        <ul>
                            <li>{charDerivedStats.armorClassLevels.history.join("; ")}</li>
                            {charDerivedStats.armorClassLevels.notes.length > 0 &&
                                <>
                                    {charDerivedStats.armorClassLevels.notes.map((n) => <li key={uniqid()}>{n}</li>)}
                                </>
                            }
                        </ul>
                    </li>
                    <li><b>AC {charDerivedStats.armorClassLevelsVsTL4.ac}</b> vs high-tech (TL4 or higher) melee weapons and all firearms
                        <ul>
                            <li>{charDerivedStats.armorClassLevelsVsTL4.history.join("; ")}</li>
                            {charDerivedStats.armorClassLevelsVsTL4.notes.length > 0 &&
                                <>
                                    {charDerivedStats.armorClassLevelsVsTL4.notes.map((n) => <li key={uniqid()}>{n}</li>)}
                                </>
                            }
                        </ul>
                    </li>
                </ul>

            </div>

            {charDerivedStats.rangedWeaponStats.length > 0 &&
                <div className="mb-3">
                    <b>Ranged Weapons:</b>
                    <ul>
                        {
                            charDerivedStats.rangedWeaponStats.sort((a, b) => a.name < b.name ? -1 : 1).map((r) => getRangedWeaponDesc(r))
                        }
                    </ul>
                </div>
            }

            {charDerivedStats.meleeWeaponStats.length > 0 &&
                <div className="mb-3">
                    <b>Melee Weapons:</b>
                    <ul>
                        {
                            charDerivedStats.meleeWeaponStats.sort((a, b) => a.name < b.name ? -1 : 1).map((r) => getMeleeWeaponDesc(r))
                        }
                    </ul>
                </div>
            }

            <div className="mb-2">
                <b>System Strain:</b>
                <ul className="mb-0">
                    <li><b>Maximum: {charDerivedStats.systemStrainMaximum.strain}</b> ({charDerivedStats.systemStrainMaximum.history.join("; ")})</li>
                    <li><b>Permanent: {charDerivedStats.systemStrainPermanent.strain}</b> ({charDerivedStats.systemStrainPermanent.history.length > 0 ? charDerivedStats.systemStrainPermanent.history.join("; ") : "none"})</li>
                </ul>
            </div>

            {/* <h2>Character Traits:</h2>
            <div><pre>{JSON.stringify(props.charTraits.gear.equipment, null, 2)}</pre></div> */}


        </div>

    );

}

export default CharacterDesignDerivedStats;