import React, { useEffect, useState } from "react";
import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { ShipDerivedStats } from "../../classes/ShipDerivedStats";

interface IProps {
    shipTraits: StarshipTraits;
    onSetAmmunition: (weaponUniqId: string, extraFreeMass: number) => void;
    onSetAmmunitionRemaining: (weaponUniqId: string, roundsRemaining: number) => void;
}

const SelectAmmo: React.FunctionComponent<IProps> = (props: IProps) => {

    const derivedStats = new ShipDerivedStats(props.shipTraits);

    useEffect(() => {
        const initialLocalAmmoRemaining: AmmoRemaining[] = getInitialAmmoRemaining();
        setLocalAmmoRemaining(initialLocalAmmoRemaining);
    }, [props.shipTraits]);

    interface AmmoMass {
        weaponUniqId: string,
        ammoMass: string;
    }

    interface AmmoRemaining {
        weaponUniqId: string,
        ammoRemaining: string;
    }

    const getInitialAmmoMass = () => {
        let mylocalAmmo: AmmoMass[] = [];
        derivedStats.weaponsInstalled_NotGrouped.filter((wi) => wi.ammunitionPerMass !== 0).forEach((wi) => {
            let newLocalAmmo: AmmoMass = { weaponUniqId: wi.uniqid, ammoMass: "0" };
            const thisAmmo = props.shipTraits.ammo.find((a) => a.weaponUniqId === wi.uniqid);
            if (thisAmmo) {
                newLocalAmmo.ammoMass = thisAmmo.extraFreeMass.toString();
            }
            mylocalAmmo.push(newLocalAmmo);
        })
        return mylocalAmmo;
    }

    const getInitialAmmoRemaining = () => {
        let mylocalAmmo: AmmoRemaining[] = [];
        derivedStats.weaponsInstalled_NotGrouped.filter((wi) => wi.ammunitionPerMass !== 0).forEach((wi) => {
            let newLocalAmmo: AmmoRemaining = { weaponUniqId: wi.uniqid, ammoRemaining: "0" };
            const thisAmmo = props.shipTraits.ammo.find((a) => a.weaponUniqId === wi.uniqid);
            if (thisAmmo) {
                newLocalAmmo.ammoRemaining = thisAmmo.roundsRemaining.toString();
            }
            mylocalAmmo.push(newLocalAmmo);
        })
        return mylocalAmmo;
    }

    const initialLocalAmmoMass: AmmoMass[] = getInitialAmmoMass();
    const [localAmmoMass, setLocalAmmoMass] = useState(initialLocalAmmoMass);

    const initialLocalAmmoRemaining: AmmoRemaining[] = getInitialAmmoRemaining();
    const [localAmmoRemaining, setLocalAmmoRemaining] = useState(initialLocalAmmoRemaining);

    const weaponsWithAmmo = derivedStats.weaponsInstalled_NotGrouped.filter((wi) => wi.ammunitionPerMass !== 0);
    if (weaponsWithAmmo.length === 0) { return null; }

    const onSetAmmoQuantity = (e: any, weaponUniqId: string) => {
        let updatedAmmoMass = [...localAmmoMass];
        const thisUpdatedAmmoMass = updatedAmmoMass.find((ua) => ua.weaponUniqId === weaponUniqId);
        if (thisUpdatedAmmoMass) {
            thisUpdatedAmmoMass.ammoMass = e.currentTarget.value;
        }
        setLocalAmmoMass(updatedAmmoMass);

        if (!isNaN(parseInt(e.currentTarget.value))) {
            let thisAmmo = parseInt(e.currentTarget.value);
            if (thisAmmo < 0) { thisAmmo = 0; }
            props.onSetAmmunition(weaponUniqId, thisAmmo);
        }
    }

    const onSetAmmoQuantityToDefault = (e: any, weaponUniqId: string) => {
        let updatedAmmoMass = [...localAmmoMass];
        const thisUpdatedAmmo = updatedAmmoMass.find((ua) => ua.weaponUniqId === weaponUniqId);
        if (thisUpdatedAmmo) {
            const thisWeapon = derivedStats.weaponsInstalled_NotGrouped.find((wi) => wi.uniqid === weaponUniqId);
            if (thisWeapon) {
                thisUpdatedAmmo.ammoMass = thisWeapon.ammunitionMass.toString();
            }
        }
        setLocalAmmoMass(updatedAmmoMass);
    }

    const onSetAmmunitionRemaining = (e: any, weaponUniqId: string) => {
        let updatedAmmo = [...localAmmoRemaining];
        const thisUpdatedAmmo = updatedAmmo.find((ua) => ua.weaponUniqId === weaponUniqId);
        if (thisUpdatedAmmo) {
            thisUpdatedAmmo.ammoRemaining = e.currentTarget.value;
        }
        setLocalAmmoRemaining(updatedAmmo);

        if (!isNaN(parseInt(e.currentTarget.value))) {
            let roundsRemaining = parseInt(e.currentTarget.value);
            if (roundsRemaining < 0) { roundsRemaining = 0; }

            let maxRounds = 0;
            const thisWeapon = derivedStats.weaponsInstalled_NotGrouped.find((wi) => wi.uniqid === weaponUniqId);
            if (thisWeapon) {
                maxRounds = thisWeapon.ammunition;
            }
            if (roundsRemaining > maxRounds) { roundsRemaining = maxRounds; }

            props.onSetAmmunitionRemaining(weaponUniqId, roundsRemaining);
        }
    }

    const onSetAmmunitionRemainingToDefault = (e: any, weaponUniqId: string) => {
        let updatedAmmo = [...localAmmoRemaining];
        const thisUpdatedAmmo = updatedAmmo.find((ua) => ua.weaponUniqId === weaponUniqId);
        if (thisUpdatedAmmo) {
            const thisWeapon = derivedStats.weaponsInstalled_NotGrouped.find((wi) => wi.uniqid === weaponUniqId);
            if (thisWeapon) {
                thisUpdatedAmmo.ammoRemaining = thisWeapon.ammunitionRemaining.toString();
            }
        }
        setLocalAmmoRemaining(updatedAmmo);
    }

    const getLocalAmmunitionMass = (weaponUniqId: string) => {
        const thisLocalAmmo = localAmmoMass.find((a) => a.weaponUniqId === weaponUniqId);
        if (thisLocalAmmo) {
            return thisLocalAmmo.ammoMass;
        }
        return "";
    }

    const getLocalAmmunitionRemaining = (weaponUniqId: string) => {
        const thisLocalAmmo = localAmmoRemaining.find((a) => a.weaponUniqId === weaponUniqId);
        if (thisLocalAmmo) {
            return thisLocalAmmo.ammoRemaining;
        }
        return "";
    }

    return (
        <>
            <div className="mb-2"><b>Ammunition</b></div>

            {weaponsWithAmmo.map((w, index) =>
                <div key={w.uniqid}>
                    <div className="row pt-1 pb-1" >
                        <div className={"col-7 col-xs-7 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}><b>{w.name}</b></div>
                        <div className={"col-5 col-xs-7 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>{w.ammunitionPerMass} shots per Mass</div>
                        <div className={"col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 small"}>Each weapon mount includes 1 Mass of rounds ({w.ammunitionPerMass * w.quantity} total)</div>
                    </div>
                    <div className="row pt-1 pb-1 border-bottom " >

                        <div className={"col-8 col-xs-8 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                            <label htmlFor={"ammoMass_" + w.id}>Additional Mass for ammo:</label>
                        </div>
                        <div className={"col-4 col-xs-4 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                            <input id={"ammoMass_" + w.id} type="number" className="shortNum" value={getLocalAmmunitionMass(w.uniqid)} onChange={(e) => onSetAmmoQuantity(e, w.uniqid)} onBlur={(e) => onSetAmmoQuantityToDefault(e, w.uniqid)} />
                        </div>

                        <div className={"col-8 col-xs-8 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                            Maximum rounds capacity:
                        </div>
                        <div className={"col-4 col-xs-4 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                            {w.ammunition}
                        </div>

                        <div className={"col-8 col-xs-8 col-sm-6 col-md-3 col-lg-3 col-xl-3 small"}>
                            <label htmlFor={"ammoMass_" + w.id}>Rounds Remaining:</label>
                        </div>
                        <div className={"col-4 col-xs-4 col-sm-6 col-md-3 col-lg-3 col-xl-9 small"}>
                            <input id={"ammoRemaining_" + w.id} type="number" className="shortNum" value={getLocalAmmunitionRemaining(w.uniqid)} onChange={(e) => onSetAmmunitionRemaining(e, w.uniqid)} onBlur={(e) => onSetAmmunitionRemainingToDefault(e, w.uniqid)} />
                        </div>

                    </div>
                </div>
            )}

            {/* <div><pre>ammo: {JSON.stringify(props.shipTraits.ammo, null, 2)}</pre></div>
            <div><pre>ammo local: {JSON.stringify(localAmmo, null, 2)}</pre></div> */}
        </>
    );

}

export default SelectAmmo;