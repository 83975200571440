import React, { useEffect, useState } from "react";
import firebase from "./firebase";

// see https://medium.com/wesionary-team/react-firebase-authentication-with-context-api-a770975f33cf

export type ContextProps = {
    user: firebase.User | null;
    authenticated: boolean;
    setUser: any;
    isAdmin: boolean;
    loadingAuthState: boolean;
};

const initialContext: ContextProps = {
    user: null,
    authenticated: false,
    setUser: null,
    isAdmin: false,
    loadingAuthState: false,
};

export const AuthContext = React.createContext(initialContext);

export const AuthProvider = ({ children }: any): any => {

    const [user, setUser] = useState(null as firebase.User | null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user: any) => {
            setUser(user);
            setIsAdmin(false);

            // Check if user has Admin claim on user token. 
            const currentUser = firebase.auth().currentUser;
            if (currentUser) {
                currentUser.getIdTokenResult()
                    .then((idTokenResult) => {
                        if (!!idTokenResult.claims.Admin) {
                            setIsAdmin(true);
                        }
                    })
                    .catch((error) => {
                    });
            };

            setLoadingAuthState(false);
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                authenticated: user !== null,
                setUser,
                isAdmin,
                loadingAuthState
            }}>
            {children}
        </AuthContext.Provider>
    );
}