import React, { useState } from "react";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import BuyFittingsModal from "./BuyFittingsModal";
import ListFittingsInstalled from "./ListFittingsInstalled";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onBuyOrSellFitting: (id: string, isBuy: boolean) => void;
}

const ShipDesignStep3Fittings: React.FunctionComponent<IProps> = (props: IProps) => {

    const [openBuyFittingsModal, setOpenBuyFittingsModal] = useState(false);

    return (
        <div className="chargenSection">

            <h2>Fittings</h2>

            <BuyFittingsModal
                shipTraits={props.shipTraits}
                itemType="fitting"
                displayModal={openBuyFittingsModal}
                onBuyOrSellFitting={(id, isBuy) => props.onBuyOrSellFitting(id, isBuy)}
                onClose={() => setOpenBuyFittingsModal(false)}
            />

            {!props.readOnly &&
                <div>
                    <button className="btn btn-primary btn-tiny" onClick={() => setOpenBuyFittingsModal(true)}>Select Fittings</button>
                </div>
            }

            <ListFittingsInstalled shipTraits={props.shipTraits} type="fitting" />

        </div >
    );

}

export default ShipDesignStep3Fittings;