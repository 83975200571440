import React from "react";

import ValidationAlert from "./../ValidationAlert";
import { nameLength } from "../../lookups/Enums";
import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { Lookups } from "../../lookups/Lookups";

interface IProps {
    shipTraits: StarshipTraits;
    readOnly: boolean;
    onSetName: (name: string) => void;
    onSetExampleShip: (name: string) => void;
    onSetIsPublic: (isPublic: boolean) => void;
}

const ShipDesignStep1Name: React.FunctionComponent<IProps> = (props: IProps) => {

    const ship = props.shipTraits;
    const lookups = Lookups.getInstance();

    const onChangeName = (e: any): void => {
        const name = e.currentTarget.value;
        props.onSetName(name);
    }

    const onChangeIsPublic = (e: any): void => {
        let isPublic = e.currentTarget.value === "true";
        isPublic = !isPublic;
        console.log(isPublic.toString());
        props.onSetIsPublic(isPublic);
    }

    const setExample = (name: string) => {
        props.onSetExampleShip(name);
    }

    const getExampleShips = () => {
        const ships = lookups.shipExamples.map((s) => s.name);
        return ships.map((shipName) =>
            <button key={shipName} className="buyAction" type="button" onClick={() => setExample(shipName)} title={shipName}>
                {shipName}
            </button>
        )
    }

    const getExamplesDropdown = () => {
        return (
            <div className="dropdown d-inline">
                <button className="btn btn-primary btn-tiny dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                    Examples
                </button>
                <div className="dropdown-menu">
                    {getExampleShips()}
                </div>
            </div>
        )
    }

    const getIsPublic = () => {
        if (props.shipTraits.isPublic) {
            return <span><b>Sharing Link: </b> Save this ship, then <a href={"/starshipShare/" + props.shipTraits.id} target="_blank" rel="noopener noreferrer">click here</a> </span>
        } else {
            return <span><b>No Sharing Link</b></span>
        }
    }

    return (
        <div className="chargenSection">

            {!props.readOnly &&
                <div className="mb-3">{getExamplesDropdown()}</div>
            }

            <div className="">

                {!props.readOnly &&
                    <>
                        <h2>Name and Hull Type</h2>

                        <div>
                            <input type="text" className="form-control mb-0" id="name" value={ship.name} onChange={onChangeName} maxLength={nameLength} placeholder="Ship name" />
                        </div>

                        {ship.showValidation && ship.validationCodes.indexOf("noName") > -1 &&
                            <ValidationAlert msg="Name is required" />
                        }

                        {props.shipTraits.id.trim() !== "" &&
                            <div className="mt-2 small">
                                <label htmlFor="isPublic"><input type="checkbox" className="" id="isPublic" value={ship.isPublic.toString()} onChange={onChangeIsPublic} checked={ship.isPublic} /> {getIsPublic()}</label>
                            </div>
                        }
                    </>
                }

                {props.readOnly &&
                    <>
                        <div><h2>{props.shipTraits.name}</h2></div>
                        {props.shipTraits.notes.trim() !== "" &&
                            <div className="small" dangerouslySetInnerHTML={{ __html: props.shipTraits.notes }}></div>
                        }
                    </>
                }

            </div>

        </div >
    );

}

export default ShipDesignStep1Name;