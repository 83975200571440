import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import app from "../firebase";

import { AuthContext } from "../Auth";

type IProps = {}

const AdminCustomAlienFoci: React.FunctionComponent<IProps> = (props: IProps) => {

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [alienFoci, setAlienFoci] = useState<Array<any>>([]);
    const [reloadFoci, setReloadFoci] = useState(0);
    const [orderBy, setOrderBy] = useState("alienFocus");

    useEffect(() => {
        // Create an scoped async function in the hook.
        const asyncUseEffect = async () => {
            setLoading(true);
            try {
                if (user) {

                    const db = app.firestore();
                    const data = await db.collection("alienFoci")
                        .orderBy(orderBy, "asc")
                        .get();

                    const alienFoci: any[] = [];
                    data.forEach((doc) => {
                        alienFoci.push({ ...doc.data(), id: doc.id });
                    });
                    setAlienFoci(alienFoci);
                    setLoading(false);
                }
            }
            catch (error: any) {
                console.log(error);
                setLoading(false);
            }
        }
        // Execute the created function directly
        asyncUseEffect();
    }, [user, reloadFoci, orderBy]); // Will load characters after rendering     

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const orderByAlien = () => {
        setOrderBy("alienFocus");
        setReloadFoci(reloadFoci + 1);
    }

    const orderByUser = () => {
        setOrderBy("user_email");
        setReloadFoci(reloadFoci + 1);
    }


    const alienFociRows = alienFoci.map((c: any) =>
        <tr key={c.id}>
            <td className="" ><Link to={"/customAlienFociDesigner/" + c.id}>{isBlank(c.alienFocus, "(No name)")}</Link></td>
            <td style={{ width: "25%" }} className="d-none d-md-table-cell">{isBlank(c.user_email, "n/a")}</td>
        </tr>
    )

    return (
        <div className="pageBody">
            <h1 className="pageHeading">All Alien Foci</h1>

            <p>{alienFoci.length} alien species.</p>

            <div style={{ marginBottom: "10px" }}>Order by:&nbsp;
                <button onClick={orderByAlien} style={{ marginRight: "10px" }}>Alien Species</button>
                <button onClick={orderByUser}>User</button>
            </div>


            {!loading && alienFoci.length > 0 &&

                <div>
                    <table className="table table-striped table-sm" style={{ maxWidth: "50%", minWidth: "300px" }}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col" className="">Alien</th>
                                <th scope="col" className="">User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alienFociRows}
                        </tbody>
                    </table>
                </div>
            }

            {!loading && alienFoci.length === 0 &&
                <p>You currently have no Alien Foci.</p>
            }

            {loading &&
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }

        </div>
    );
}

export default AdminCustomAlienFoci;
