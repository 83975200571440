import React, { useState } from "react";

import { faEye, faCaretRight, faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";

import { CharacterTraits } from "../interfaces/CharacterTraits";

import ValidationAlert from "./ValidationAlert";

import { Lookups } from "../lookups/Lookups";
import { getSkillsByType } from "../utilities/SkillUtilities";
import { SkillLevel } from "../interfaces/SkillLevel";
import DescriptionAlert from "./DescriptionAlert";
import WildPsychicTalent from "./charGen/WildPsychicTalent";
import uniqid from "uniqid";

interface IProps {
    charTraits: CharacterTraits;
    onSetPsychicTechnique: (skill: string, techniqueName: string, level: number, noyte: string, traitToUpdate: string) => void;
    onSelectWildTalentPsychicDiscipline: (skill: string, traitToUpdate: string) => void;
    onSelectWildTalentTechnique: (technique: string, focusLevel: number, traitToUpdate: string) => void;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep6Psychic: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "psychicDisciplines";
    const switchDisplay = () => { props.onSwitchCollapsed(sectionName); }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }

    const lookups = Lookups.getInstance();

    let isPsychic = false;
    let isWildTalent = false;

    const psychicClasses = props.charTraits.levelOne.classes.filter((c) => c.className === "Psychic" || c.className === "Partial Psychic");
    if (psychicClasses.length > 0) { isPsychic = true; }

    const getFocusesAtPreviousStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateFocusLevels(CreationStep.FreeFocus);
        return charDerivedStatsAtThisClass.focusLevels;
    }

    const focuses = getFocusesAtPreviousStep();
    const wildTalentFocus = focuses.find((f) => f.focus === "Wild Psychic Talent");
    if (wildTalentFocus) { isWildTalent = true; }

    const getSkillsAtFreeSkillStep = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculateSkillLevels(CreationStep.FreeSkill);
        return charDerivedStatsAtThisClass.skillLevels;
    }

    const allCharactersSkills = getSkillsAtFreeSkillStep();
    const allPsychicSkills = getSkillsByType("Psychic", lookups.skills);
    const allCharactersPsychicSkills = allCharactersSkills.filter((s) => allPsychicSkills.map((ps) => ps.skill).find((sk) => sk === s.skill && s.level && s.level > 0));

    const psiSkillCount = allCharactersPsychicSkills.length;
    let initialShowSkillArray: boolean[] = [];
    for (let i = 0; i < psiSkillCount; i++) {
        initialShowSkillArray.push(false);
    }

    const [showSkillDesc, setShowSkillDesc] = useState(initialShowSkillArray);

    if (!isPsychic && !isWildTalent) { return null; }

    const onSetShowDescription = (skillIndex: number) => {
        let newShowSkillDesc = [...showSkillDesc];
        newShowSkillDesc[skillIndex] = !newShowSkillDesc[skillIndex];
        setShowSkillDesc(newShowSkillDesc);
    }

    const getPsychicTechniquesAtStep6 = () => {
        const charDerivedStatsAtThisClass = new CharacterDerivedStats(props.charTraits);
        charDerivedStatsAtThisClass.calculatePsychicTechniqueLevels(CreationStep.PsychicDisciplines, 0, 0);
        return charDerivedStatsAtThisClass.psychicTechniqueLevels;
    }

    const getTechniqueIsPicked = (skill: string, techniqueName: string, level: number): boolean => {
        const knownTechniques = getPsychicTechniquesAtStep6();
        const theTechnique = knownTechniques.find((t) => t.skill === skill && t.technique === techniqueName && t.level === level);
        if (theTechnique) {
            return true;
        }
        return false;
    }

    const pickTechnique = (e: any) => {
        const skill = e.target.getAttribute("data-skill");
        const technique = e.target.getAttribute("data-technique");
        const note = "Gained technique when learned " + skill + "-1";

        props.onSetPsychicTechnique(skill, technique, 1, note, "levelOne.psychicTechniquePicks");
    }

    const getPsychicSkillDisplay = (skillLevel: SkillLevel, index: number) => {
        let shortIntro = "";
        const skillDesc: any = [];
        const coreTechnique: any = [];
        const selectLevelOneTechnique: any = [];

        const thisSkillData = lookups.psychicTechniques.find((pt) => pt.skill === skillLevel.skill);
        if (thisSkillData) {

            shortIntro = thisSkillData.shortIntro;

            thisSkillData.intro.forEach((i) => {
                if (i.header !== "") {
                    skillDesc.push(<h6 className="mt-2 mb-1" key={uniqid()}>{i.header}</h6>)
                }
                i.paragraphs.forEach((p) => {
                    skillDesc.push(<p className="mb-1" key={uniqid()}>{p}</p>)
                })
            })

            const coreTechniqueUniqid = uniqid();
            coreTechnique.push(
                <h6 className="mt-2 mb-1" key={coreTechniqueUniqid}>
                    Core Technique: {thisSkillData.coreTechnique.name}&nbsp;
                    <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(index + 100)} title="Show core technique description">
                        <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                    </button>
                </h6>
            )

            const descParagraphs = thisSkillData.coreTechnique.description.map((desc) =>
                <p className="mb-1" key={uniqid()}>{desc}</p>
            );

            coreTechnique.push(
                <DescriptionAlert key={uniqid()} display={showSkillDesc[index + 100]}>{descParagraphs}</DescriptionAlert>
            );

            thisSkillData.coreTechnique.levels.forEach((lev) => {
                if (skillLevel && skillLevel.level && lev.level < skillLevel.level) {
                    coreTechnique.push(<p className="mb-1 noIndent" key={uniqid()}><b>Level-{lev.level} (gained): </b>{lev.description}</p>)
                }
            })

            if (skillLevel.level && skillLevel.level > 1) {
                selectLevelOneTechnique.push(<div className="mt-2 mb-2" key={uniqid()}><FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;Select a level-1 {skillLevel.skill} technique:</div>)
                const levelOneTechniques = thisSkillData.techniques.filter((t) => t.level === 1);
                levelOneTechniques.forEach((tec, levelIndex) => {
                    let paragraphs: any[] = [];
                    paragraphs = tec.paragraphs.map((p) => <div className="mb-1" key={uniqid()}>{p}</div>);
                    const select = <label><input type="radio" value={tec.name} key={uniqid()} checked={getTechniqueIsPicked(skillLevel.skill, tec.name, tec.level)} data-skill={skillLevel.skill} data-technique={tec.name} onChange={pickTechnique}></input> <b>{tec.name} (Level-{tec.level})</b></label>;

                    const level1Desc = <DescriptionAlert display={showSkillDesc[((index + 1) * 1000) + levelIndex]}>{paragraphs}</DescriptionAlert>

                    selectLevelOneTechnique.push(
                        <div className="mb-2" key={uniqid()}>
                            {select}&nbsp;
                            <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(((index + 1) * 1000) + levelIndex)} title="Show level one technique description">
                                <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
                            </button>
                            {level1Desc}
                        </div>
                    )
                })
            }

        }

        const showSkillDescButton =
            <button className="ml-2 btn btn-outline-info btn-tiny" type="button" onClick={(e) => onSetShowDescription(index)} title="Show Skill description">
                <FontAwesomeIcon icon={faEye} title="Show Description"></FontAwesomeIcon >
            </button>;

        return (
            <div className="mb-3 pb-2 border-bottom" key={uniqid()}>
                <h5>{skillLevel.skill}-{skillLevel.level ? (skillLevel.level) - 1 : ""} {showSkillDescButton}</h5>
                <p className="mb-2 noIndent">{shortIntro}</p>
                <DescriptionAlert display={showSkillDesc[index]}>
                    {skillDesc}
                </DescriptionAlert>
                <div>
                    <FontAwesomeIcon icon={faCaretRight} title="text-info"></FontAwesomeIcon >&nbsp;
                    You gain the core technique for the {skillLevel.skill} discipline, with abilities up to your {skillLevel.skill} skill level-{skillLevel.level ? (skillLevel.level) - 1 : "0"}:
                </div>
                {coreTechnique}
                {selectLevelOneTechnique}
                {char.showValidation && char.levelOne.validationCodes.indexOf("levelOnePsychicTechniqueNotSelected-" + index) !== -1 &&
                    <ValidationAlert msg={"You must select a level-1 " + skillLevel.skill + " technique"} />
                }
                {char.levelOne.validationCodes.indexOf("levelOneSytheticAdaptationReqProgramOrFix-" + index) !== -1 &&
                    <ValidationAlert msg={"Synthetic Adaptation requires either Fix-0 or Program-0 skill"} />
                }
            </div>
        );
    }

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Psychic Disciplines {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                {isPsychic &&
                    <div className="psychicSkills">
                        {allCharactersPsychicSkills.map((s, index) => getPsychicSkillDisplay(s, index))}
                        {allCharactersPsychicSkills.length === 0 &&
                            <>You must select at least one psychic skill level (above) before you can select your psychic techniques.</>
                        }
                    </div>
                }

                {isWildTalent &&
                    <WildPsychicTalent
                        charTraits={props.charTraits}
                        level={1}
                        onSelectWildTalentPsychicDiscipline={props.onSelectWildTalentPsychicDiscipline}
                        onSelectWildTalentTechnique={props.onSelectWildTalentTechnique}
                    />
                }

                {!isPsychic && !isWildTalent &&
                    <div>Only characters with the Psychic or Partial Psychic class, or who have the Wild Psychic Talent focus, can learn psychic disciplines.</div>
                }

                {/* <div><pre>{JSON.stringify(lookups.psychicTechniques, null, 2)}</pre></div> */}

            </div>

        </div>
    );

}

export default CharacterDesignStep6Psychic;