import React from "react";
import uniqid from "uniqid";
import { FittingInstalled, ShipDerivedStats } from "../../classes/ShipDerivedStats";
import { StarshipTraits } from "../../interfaces/StarshipTraits";
import { Lookups } from "../../lookups/Lookups";
import { formatLargeCredits } from "../../utilities/Utilities";

interface IProps {
    shipTraits: StarshipTraits;
    type: string;
}

const ListFittingsInstalled: React.FunctionComponent<IProps> = (props: IProps) => {

    const lookups = Lookups.getInstance();

    const shipStatsDerived = new ShipDerivedStats(props.shipTraits);

    let output: any = [];
    shipStatsDerived.fittingsInstalled.filter((f) => f.fittingType === props.type).forEach((fi) => {

        let name = "?";
        let shortDesc = "?";
        let additionalMass = 0;
        const theFitting = lookups.shipFittings.find((fit) => fit.id === fi.id);
        if (theFitting) {
            
            //name
            if (fi.quantity === 1) {
                if (theFitting.nameAfterPurchase) {
                    name = theFitting.nameAfterPurchase;
                } else {
                    name = theFitting.name;
                }
            } else {
                if (theFitting.nameAfterPurchasePlural) {
                    name = theFitting.nameAfterPurchasePlural;
                } else {
                    if (theFitting.namePlural) {
                        name = theFitting.namePlural;
                    } else {
                        name = theFitting.name;
                    }
                }
            }

            // special handling for Foxer Drones
            if (theFitting.id === "SD5") {
                if(props.shipTraits.foxerDrones.length >0) {
                    const foxerDroneAmmo = props.shipTraits.foxerDrones[0];
                    if (foxerDroneAmmo) {
                        const totalAmmo = 5 + (foxerDroneAmmo.extraFreeMass * 5);
                        shortDesc = "+2 AC for one round when fired, Ammo " + foxerDroneAmmo.roundsRemaining + " (of " + totalAmmo + ")"; 
                        additionalMass = foxerDroneAmmo.extraFreeMass; 
                        if(additionalMass > 0) {
                            name = name + " (+" + additionalMass + " ammo)"; 
                        }
                    };
                }
            } else {
                shortDesc = theFitting.shortDesc;
            }

        }

        const getPrice = (fitting: FittingInstalled) => {
            if (fi.fittingType === "weapons") {
                const theWeapon = lookups.shipFittings.find((f) => f.id === fitting.id);
                if (theWeapon) {
                    if (theWeapon.weaponUsesAmmo && theWeapon.weaponCostPerAmmo) {
                        return formatLargeCredits(fi.totalCost) + "/" + formatLargeCredits(theWeapon.weaponCostPerAmmo);
                    } else {
                        return formatLargeCredits(fi.totalCost)
                    }
                } else {
                    return "?";
                }
            } else {
                return formatLargeCredits(fi.totalCost);
            }
        }

        output.push(
            <div className="row border-bottom pt-1 pb-1" key={uniqid()} >
                <div className={"col-8 col-sm-8 col-md-4 col-lg-3 col-xl-3 small"}><b>{name}</b></div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>x{fi.quantity}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>{getPrice(fi)}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Power&nbsp;{fi.totalPower}</div>
                <div className={"col-4 col-sm-4 col-md-2 col-lg-1 col-xl-1 small"}>Mass&nbsp;{fi.totalMass + additionalMass}</div>
                <div className={"col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 small"}>{shortDesc}</div>
            </div>
        )
    });

    if (output.length === 0) {
        output.push(
            <div className="row border-bottom pt-1 pb-1" key={uniqid()} >
                <div className={"col-12 small"}><b>None</b></div>
            </div>
        )
    }
    return <>{output}</>;
}

export default ListFittingsInstalled;
