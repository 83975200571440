import React from "react";
import { faDice, faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CharacterDerivedStats, CreationStep } from "../classes/CharacterDerivedStats";

import { CharacterTraits } from "../interfaces/CharacterTraits";

import ValidationAlert from "./ValidationAlert";
import { Lookups } from "../lookups/Lookups";

interface IProps {
    charTraits: CharacterTraits;
    onSetHitPoints: (rolledHP: number, level: number) => void;
    onSwitchCollapsed: (sectionName: string) => void;
}

const CharacterDesignStep8HitPoints: React.FunctionComponent<IProps> = (props: IProps) => {

    const char = props.charTraits;

    const sectionName = "hitPoints";
    const switchDisplay = () => { props.onSwitchCollapsed(sectionName); }

    const isCollapsed = props.charTraits.basicTraits.collapsedSections.indexOf(sectionName) !== -1;

    let sectionClassName = "uncollapsed";
    if (isCollapsed) { sectionClassName = "collapsed"; }

    const getCollapseIcon = () => {
        if (isCollapsed) { return <FontAwesomeIcon icon={faChevronCircleUp} title="text-info" className="floatRight"></FontAwesomeIcon >; }
        return <FontAwesomeIcon icon={faChevronCircleDown} title="text-info" className="floatRight"></FontAwesomeIcon >;
    }

    const charDerivedStats = new CharacterDerivedStats(props.charTraits);
    charDerivedStats.calculateAttributeLevels(CreationStep.AllSteps, 100, 1);
    charDerivedStats.calculateHitPointsLevels(CreationStep.AllSteps, 1);
    charDerivedStats.calculateFocusLevels(CreationStep.AllSteps);

    const onSetHitPoints = () => {

        // Check if has 'Tough' benefit from Alien origin focus:
        let hasTough = false;
        const alienFocus = charDerivedStats.focusLevels.find((f) => f.focus.indexOf("Alien -") !== -1);
        if (alienFocus) {
            const lookups = Lookups.getInstance();
            const selectedFocusData = lookups.focuses.find((fd) => fd.focus === props.charTraits.levelOne.freeFocusLevelPick.focus);
            if (selectedFocusData?.benefits) {
                if (selectedFocusData.benefits.find((ben) => ben.benefit === "tough")) {
                    hasTough = true;
                }
            }
        }

        let rolledHP = 0;
        if (hasTough) {
            rolledHP = 6;
        } else {
            rolledHP = Math.floor(Math.random() * 6) + 1;
        }
        props.onSetHitPoints(rolledHP, 1);
    }

    const levelOneHP = charDerivedStats.hitPointsLevels.find((hp) => hp.level === 1);

    return (
        <div className="chargenSection">

            <div onClick={(e) => switchDisplay()} className="collapsible"><h2 >Hit Points at Level 1 {getCollapseIcon()}</h2></div>

            <div className={sectionClassName}>

                <div className="mb-2">
                    {props.charTraits.levelOne.rolledHitPoints === 0 &&
                        <>
                            <button className="btn btn-primary btn-tiny d-inline btn-pickSkill" onClick={() => onSetHitPoints()}>
                                <FontAwesomeIcon icon={faDice} title="text-info" className="mr-1"></FontAwesomeIcon >
                                Roll
                            </button> Roll 1d6 for Hit Points
                        </>
                    }
                    {props.charTraits.levelOne.rolledHitPoints > 0 && levelOneHP &&
                        <>
                            <div className="mb-1">
                                <b>Hit Points:</b> {levelOneHP.totalHitPointsAtLevel} HP
                            </div>
                            <div>
                                <ul className="noBullet">
                                    <li><i>{levelOneHP.history.join("; ")}</i></li>
                                    <li><button className="btn btn-secondary btn-tiny" onClick={onSetHitPoints}>Reroll</button>&nbsp;{props.charTraits.levelOne.hitPointRerolls} reroll{props.charTraits.levelOne.hitPointRerolls === 1 ? "" : "s"}</li>
                                </ul>
                            </div>
                        </>
                    }
                    {char.showValidation && char.levelOne.validationCodes.indexOf("levelOneHitPointsNotRolled") !== -1 &&
                        <div>
                            <ValidationAlert msg={"Hit Points must be rolled"} />
                        </div>
                    }
                </div>

            </div>

        </div>
    );

}

export default CharacterDesignStep8HitPoints;