import React from "react";
import $ from "jquery";

import { StarshipTraits } from "../../interfaces/StarshipTraits";
import SelectFoxerDrones from "./SelectFoxerDrones";

interface IProps {
    shipTraits: StarshipTraits;
    displayModal: boolean;
    onSetFoxerDronesExtraMass: (weaponUniqid: string, extraFreeMass: number) => void;
    onSetFoxerDronesRemaining: (weaponUniqId: string, roundsRemaining: number) => void;
    onClose: () => void;
}

const SelectFoxerDronesModal: React.FunctionComponent<IProps> = (props: IProps) => {

    const modalId = "foxerdrones_modal";

    if (props.displayModal) {
        ($("#" + modalId) as any).modal('show');
    }

    if (!props.displayModal) {
        ($("#" + modalId) as any).modal('hide');
    }

    const onCloseModal = () => {
        props.onClose();
    }

    return (
        <div id={modalId} className="modal" tabIndex={-1} role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Select Foxer Drones</h5>
                        <button type="button" className="close" onClick={(e) => onCloseModal()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        {props.displayModal &&
                            <SelectFoxerDrones
                                shipTraits={props.shipTraits}
                                onSetFoxerDronesExtraMass={props.onSetFoxerDronesExtraMass}
                                onSetFoxerDronesRemaining={props.onSetFoxerDronesRemaining}
                            />
                        }

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={(e) => onCloseModal()}>Close</button>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default SelectFoxerDronesModal;

